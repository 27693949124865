import React from 'react';
import ReactQuill from 'react-quill';
import { asField } from 'informed';
import 'react-quill/dist/quill.snow.css';

const QuillInput = asField(({ fieldState, fieldApi, ...props }) => {
  const { value } = fieldState;
  const { setValue } = fieldApi;
  return (
    <React.Fragment>
      <ReactQuill
        value={value}
        onChange={(value) => setValue(value)}
        {...props}
      />
      {fieldState.error && <small className={fieldState.error ? 'text-danger' : null}>{fieldState.error}</small>}
    </React.Fragment>
  );
});

export default QuillInput;
