import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

const InputMask = ({
  value,
  onChange,
  mask,
  className,
  error,
  label,
  forwardedRef,
  ...rest
}) => (
  <>
    <div className={`input-type-style ${className}`}>
      <div>
        <MaskedInput style={error ? { border: 'solid 1px red' } : null} className="form-control" mask={mask} value={value} onChange={onChange} {...rest} />
      </div>
      <label className={`input-label-style ${error ? 'red' : 'dim'}`}>{label}</label>
      {error && <span className="input-helper red">{error}</span>}
    </div>
  </>
);

InputMask.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  mask: PropTypes.func.isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
};

InputMask.defaultProps = {
  className: '',
  value: '',
  error: null,
};

export default InputMask;
