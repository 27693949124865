import React, { Fragment } from 'react';
import { Form } from 'informed';
import { get } from 'lodash';
import axios from 'axios';
import RelatorioBase from './RelatorioBase';
import { Footer, TextInput } from '../../components';
import { getAxiosConfig } from '../../services';
import { API_URL } from '../../consts';

class CorrecoesAvaliador extends RelatorioBase {
    constructor(props) {
        super(props);
        this.URL = '/relatorio/extratos';
        this.state = {
            ...this.state,
            loading: false,
            data: [],
            avaliador: undefined,
            formValues: {},
            disabled: true,
            page: 1,
            sorted: [
                {
                    id: 'nome',
                    desc: false,
                },
            ],
        };
    }

    removeSelected = () => {
      this.formApi.reset();
      this.setState({ formValues: get(this.formApi.getState(), 'values') }, () => this.fetchData('/relatorio/extratos'));
    };

    componentDidMount() {
        this.fetchData('/relatorio/extratos');
    }

    detalheExtrato(usuarioId) {
      const { history } = this.props;
      history.push(`/relatorios/extrato/${usuarioId}`);
    }

    exportExtrato = () => {
      this.setState({ loadingExport: true });
      axios({
        url: `${API_URL}/relatorio/extratos/export`,
        method: 'GET',
        responseType: 'blob',
        ...getAxiosConfig(),
      }).then((response) => {
        this.setState({ loadingExport: false });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'extrato.xlsx');
        document.body.appendChild(link);
        link.click();
      });
    }

    getColumns() {
        return [
            {
                Header: this.getHeaderWithSortIcon('Avaliador', 'nome'),
                accessor: 'nome',
                minWidth: 160,
            },
            {
                Header: this.getHeaderWithSortIcon('CPF', 'cpf'),
                accessor: 'cpf',
                maxWidth: 140,
                Cell: row => row.value,
            },
            {
                Header: this.getHeaderWithSortIcon('Correções', 'enviados'),
                accessor: 'enviados',
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                sortable: false,
                Cell: row => (
                  <button
                    type="button"
                    className="redacao-hyperlink"
                    onClick={() => this.detalheExtrato(row.original.usuario_id)}
                  >
                      <i className="far fa-eye" />
                  </button>
                ),
            },

        ];
    }

    render() {
        const { formValues, loadingExport } = this.state;
        return (
          <Fragment>
              <div className="container-fluid">
                  <div className="page-container-border">
                      <section>
                          <div className="row">
                              <div className="col-md-12 col-xs-12 col-sm-12">
                                  <h1 className="text-title">Extrato Correções</h1>
                              </div>
                          </div>
                          <Form
                            id="searchForm"
                            getApi={(formApi) => { this.formApi = formApi; }}
                            onSubmit={(submitValues) => {
                                this.setState({ formValues: submitValues }, () => this.fetchData('/relatorio/extratos'));
                            }}
                          >
                              <div className="row">
                                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4 mt-2">
                                      <TextInput
                                        label="Avaliador ou CPF"
                                        field="nome_cpf"
                                        type="text"
                                      />
                                  </div>
                                  <div style={{ paddingTop: 14 }} className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4 mt-4 flex">
                                    <button
                                      type="submit"
                                      className="btn btn-primary flex-start no-print"
                                    >
                                        Buscar
                                    </button>
                                      {get(formValues, 'nome_cpf') && (
                                        <button
                                          type="button"
                                          onClick={this.removeSelected}
                                          className="btn btn-primary flex-start no-print"
                                        >
                                          {get(formValues, 'nome_cpf')}
                                          <i style={{ position: 'relative', right: '-15px' }} className="fas fa-times" />
                                        </button>
                                      )}
                                  </div>
                                  {
                                    this.hasPermission('exportar_relatorio') && (
                                      <div className="col-4 mb-4 mt-4 text-right">
                                        {/* <button onClick={this.printAll} className=" btn btn-more no-print"><i className="fas fa-print "/></button> */}
                                        <button
                                          type="button"
                                          onClick={this.exportExtrato}
                                          disabled={loadingExport}
                                          className=" btn btn-more ml-3 no-print"
                                        >
                                            <i className="fas fa-file-excel " />
                                        </button>
                                      </div>
                                    )
                                  }
                              </div>
                          </Form>
                          <div className="row">
                              <div className="col-12 mt-3">
                                  {this.renderTable(
                                    this.getColumns(),
                                    'tabela-coordenacao-geral',
                                  )}
                              </div>
                          </div>
                      </section>
                  </div>
              </div>
              <Footer />
          </Fragment>
        );
    }
}

export default CorrecoesAvaliador;
