import React from 'react';
import { find } from 'lodash';


export const getHeaderWithSortIcon = (label, accessor, sorted = {}) => {
  const field = find(sorted, f => f.id === accessor);
  let sortIcon = '';
  if (field) {
    sortIcon = (
      <i
        className={`right-arrow fas fa-arrow-${field.desc ? 'down' : 'up'}`}
        style={{ fontSize: 10 }}
      />
    );
  }
  return <span>{sortIcon}{label}</span>;
};

export const getPorcentagem = (valor, total) => {
  if (total === 0) {
    return 0;
  }
  return valor / total;
};

export const commaSeparatedDecimalSortMethod = (a, b) => {
  if (a && b) {
    const opA = a.replace(',', '.').replace('%', '');
    const opB = b.replace(',', '.').replace('%', '');

    if (+opA > +opB) {
      return 1;
    }
    if (+opA < +opB) {
      return -1;
    }
    return 0;
  }
  if (a) {
    return 1;
  }
  if (b) {
    return -1;
  }
  return 0;
};


export const dateSortMethod = (a, b) => {
  if (a && b) {
    const dateStringA = `${a.split('/')[1]}/${a.split('/')[0]}/${a.split('/')[2]}`
    const dateStringB = `${b.split('/')[1]}/${b.split('/')[0]}/${b.split('/')[2]}`
    return new Date(dateStringA).getTime() - new Date(dateStringB).getTime();
  }
  if (a) { return 1; }
  if (b) { return -1; }
  return 0;
}
