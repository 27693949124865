import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { API_URL } from '../../../../consts';
import { ComponentBase } from '../../../../base';


class QuartaCorrecaoPolo extends ComponentBase {
  get columns() {
    return [
      {
        header: '#',
        accessor: 'row',
        minWidth: 50,
        Cell: row => <div>{row.index + 1}</div>,
      },
      {
        header: 'Polo',
        accessor: 'time_descricao',
        Cell: d => (<div className="column-left">{d.value}</div>),
        next: {
          pathname: '/relatorios/v2/quarta-correcao-por-polo',
          stateFields: ['polo'],
        },
        minWidth: 80,
      },
      {
        header: '1ª Cota disponibilizada',
        accessor: 'dis_cota_1',
      },
      {
        header: 'Quartas corrigidas da 1ª cota',
        accessor: 'cor_cota_1',
      },
      {
        header: '2ª Cota disponibilizada',
        accessor: 'dis_cota_2',
      },
      {
        header: 'Quartas corrigidas da 2ª cota',
        accessor: 'cor_cota_2',
      },
      {
        header: '3ª Cota disponibilizada',
        accessor: 'dis_cota_3',
      },
      {
        header: 'Quartas corrigidas da 3ª cota',
        accessor: 'cor_cota_3',
      },
      {
        header: '4ª Cota disponibilizada',
        accessor: 'dis_cota_4',
      },
      {
        header: 'Quartas corrigidas da 4ª cota',
        accessor: 'cor_cota_4',
      },
    ];
  }

  render() {
    const exportData = {
      url: `${API_URL}/v2/relatorios/quarta-correcao/geral/excel`,
      filename: 'quarta_correcao_geral.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        title="QUARTA CORREÇÃO | Polos"
        dataUrl="v2/relatorios/quarta-correcao/geral"
        showAvaliadorInput={false}
        showTimeInput={false}
        columns={this.columns}
        codigo="QUARTA_CORRECAO"
        exportData={exportData}
        caption={{}}
        codigoMensagem="QUARTA_CORRECAO_POLOS"
      />
    );
  }
}

export default QuartaCorrecaoPolo;
