import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { get } from 'lodash';
import { API_URL, OCORRENCIA } from '../consts';
import {
  Footer,
  DetalhesOcorrencia,
  Loading,
} from '../components';
import Redacao from '../containers/Redacao';
import { getAxiosConfig } from '../services';
import { getShapes, lineToObject } from '../utils';
import OcorrenciaBase from './OcorrenciaBase';


class Ocorrencia extends OcorrenciaBase {
  constructor(props) {
    super(props);
    this.state = {
      loadingOcorrencia: true,
      loadingRedacao: true,
      fetchImagemSuccess: false,
      fetchNewImagemSuccess: false,
      shapes: {
        lines: [],
        arrows: [],
      },
      options: [],
      ocorrenciaRespondida: {
        descricao: '',
        img: '',
        resposta: '',
        data_solicitacao: '',
        data_resposta: '',
        status: '',
        situacao: '',
        ocorrencia_propria: '',
        nova_imagem: '',
      },
      active: 'old',
      rotacaoImagem: 0,
    };
    this.fetchOcorrencia = this.fetchOcorrencia.bind(this);
    this.getLines = this.getLines.bind(this);
    this.getLines = this.getLines.bind(this);
  }

  componentDidMount() {
    this.fetchOcorrencia();
  }

  componentDidUpdate(prevProps) {
    const oldId = get(prevProps, 'match.params.id');
    const newId = get(this.props, 'match.params.id');
    if (oldId !== newId) {
      this.componentDidMount();
    }
  }

  ChangeImage(tab) {
    if (tab === 'new') {
      this.setState({ fetchImagemSuccess: false, fetchNewImagemSuccess: true });
      this.setState({ active: 'new' });
    } else {
      this.setState({ fetchImagemSuccess: true, fetchNewImagemSuccess: false });
      this.setState({ active: 'old' });
    }
  }

  fetchOcorrencia() {
    this.setState({ loadingOcorrencia: true, loadingRedacao: true });

    const {
      match: { params: { id } },
      location: { state },
    } = this.props;
    axios.get(`${API_URL}/tickets/${id}`, {
      params: state,
      ...getAxiosConfig()
    })
      .then((response) => {
        const shapes = getShapes(get(response.data, 'annotations'));
        this.setState({
            shapes,
            podeEncaminharOcorrencia: get(response.data, 'chat.can_forward_chat'),
            podeResponderOriginal: get(response.data, 'pode_responder_original'),
            podeResponderOcorrencia: get(response.data, 'chat.can_awnser_chat'),
            src: response.data.img,
            ocorrenciaRespondida: response.data,
            loadingOcorrencia: false,
            loadingRedacao: false,
            angulo_imagem: get(response.data, 'image_angle'),
        });
      })
      .catch(this.handleError);
  }

  renderRedacao() {
    const {
      shapes,
      ocorrenciaRespondida,
      src,
      fetchImagemSuccess,
      active,
      fetchNewImagemSuccess,
      podeResponderOcorrencia,
      angulo_imagem,
    } = this.state;
    const edit = this.hasPermission('change_ocorrencia') && podeResponderOcorrencia;
    if (ocorrenciaRespondida.status.id === OCORRENCIA.STATUS.REESCANEAMENTO_RESPONDIDO) {
      return (
        <Fragment>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button onClick={() => this.ChangeImage('old')} type="button" className={`nav-link ${active === 'old' ? 'active' : ''}`}> Original</button>
          </li>
          <li className="nav-item">
            <button onClick={() => this.ChangeImage('new')} type="button" className={`nav-link ${active === 'new' ? 'active' : ''}`}> Nova Imagem</button>
          </li>
        </ul>
        {
          active === 'old' ? (
            <Redacao
              readOnly={!edit}
              ref={(node) => { this.redacao = node; }}
              src={src}
              shapes={shapes}
              show={fetchImagemSuccess}
              angulo_imagem={angulo_imagem}
              onInitCanvas={(callback) => { this.setState({ fetchImagemSuccess: true }); if (callback) callback(); }}
            />
          ) : (
            <Redacao
              readOnly
              ref={(node) => { this.redacao = node; }}
              src={ocorrenciaRespondida.nova_imagem.link_imagem}
              shapes={shapes}
              show={fetchNewImagemSuccess}
              angulo_imagem={angulo_imagem}
              onInitCanvas={(callback) => { this.setState({ fetchNewImagemSuccess: true }); if (callback) callback(); }}
            />
          )
        }
        </Fragment>
        );
    }
    return (
      <Redacao
        readOnly={!edit}
        ref={(node) => { this.redacao = node; }}
        src={src}
        shapes={shapes}
        show={fetchImagemSuccess}
        onInitCanvas={(callback) => { this.setState({ fetchImagemSuccess: true }); if (callback) callback(); }}
        angulo_imagem={angulo_imagem}
      />
    );
  }

  getLines() {
    return this.redacao.lines.map(lineToObject);
  }

  render() {
    const {
      loadingRedacao,
      ocorrenciaRespondida,
      loadingOcorrencia,
      options,
      podeEncaminharOcorrencia,
      podeResponderOriginal,
      podeResponderOcorrencia,
    } = this.state;
    const edit = this.hasPermission('change_ocorrencia') && podeResponderOcorrencia;
    const ver_tipo_correcao = this.hasPermission('ver_tipo_correcao');
    return (
      <section>
          <div className="container-fluid">
            <div className="page-content-correcao">
              <div className="row">
                <div className="col-sm-12 col-md-9" style={{ marginBottom: 40 }}>
                  {
                    loadingRedacao
                     ? <Loading />
                     : this.renderRedacao()
                  }
                </div>
                <div className="col-sm-12 col-md-3 p-0">
                  {
                    loadingOcorrencia
                     ? <Loading />
                     : (
                      <DetalhesOcorrencia
                        ocorrencia={ocorrenciaRespondida}
                        edit={edit}
                        ver_tipo_correcao={ver_tipo_correcao}
                        options={options}
                        getLines={this.getLines}
                        historico={ocorrenciaRespondida.history}
                        podeEncaminharOcorrencia={podeEncaminharOcorrencia}
                        podeResponderOriginal={podeResponderOriginal}
                        podeResponderOcorrencia={podeResponderOcorrencia}
                        podeSolicitarReescaneamento={this.hasFeature('pode_solicitar_reescaneamento')}
                      />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        <Footer />
      </section>
    );
  }
}

Ocorrencia.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};


export default Ocorrencia;
