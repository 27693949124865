import React from 'react';
import axios from 'axios';
import { get, find } from 'lodash';
import { toast } from 'react-toastify';
import ReactTable from 'react-table';
import { toastConfig } from '../../utils';
import { getAxiosConfig } from '../../services';
import { API_URL, HIERARQUIA } from '../../consts';
import { Loading } from '../../components';
import { ComponentBase } from '../../base';

class RelatorioBase extends ComponentBase {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      formValues: {},
      polo: undefined,
      time: undefined,
      avaliador: undefined,
      mediaNacional: {},
      mediaTotal: {},
      loading: false,
      loadingPolo: false,
      data: [],
      tolerancia: 0,
    };
  }

  backgroundColor(state, rowInfo) {
    const status = get(rowInfo, 'original.status');
    switch (status) {
      case 2:
        return '#F6EEC3';
      case 3:
        return '#F0D9D2';
      case 4:
        return '#fdc1a1';
      default:
        return '';
    }
  }

  getHeaderWithSortIcon(label, accessor) {
    const { sorted } = this.state;
    const field = find(sorted, f => f.id === accessor);
    let sortIcon = '';
    if (field) {
      sortIcon = (
        <i
          className={`right-arrow fas fa-arrow-${field.desc ? 'down' : 'up'}`}
          style={{ fontSize: 10 }}
        />
      );
    }
    return (
      <span>
        {sortIcon}
        {label}
      </span>
    );
  }

  printAll = () => {
    window.print();
  };

  verificaParams(fetch = true) {
    const { customFilters } = this.state;
    const {
      location: { state },
    } = this.props;
    if (state !== undefined && state !== null) {
      const { polo, time, avaliador } = state;

      this.setState(
        {
          polo,
          time,
          avaliador,
          customFilters: {
            ...customFilters,
            polo: polo && polo.value,
            time: time && time.value,
            corretor: avaliador && avaliador.value,
          },
        },
        () => {
          if (fetch) {
            this.fetchData(this.URL);
            this.fetchAvaliadores();
          }
        },
      );
    } else if (fetch) {
      this.fetchData(this.URL);
    }
  }

  fetchTolerancia(parametro) {
    this.setState({ loadingPolo: true });
    axios
      .get(`${API_URL}/relatorio/parametros`, {
        params: {
          parametro,
        },
        ...getAxiosConfig(),
      })
      .then((response) => {
        this.setState({
          tolerancia: response.data,
        });
      })
      .catch(() => {
        toast.error('Erro ao carregar a tolerância', toastConfig);
      });
  }

  fetchPolos() {
    this.setState({ loadingPolo: true });
    axios
      .get(`${API_URL}/times`, {
        params: {
          id_tipo_hierarquia: HIERARQUIA.POLO,
        },
        ...getAxiosConfig(),
      })
      .then((response) => {
        const polos = response.data.map(polo => ({
          value: polo.id,
          label: polo.descricao,
        }));
        this.setState({
          polos,
          loadingPolo: false,
        });
      })
      .catch(() => {
        toast.error('Erro ao carregar os polos', toastConfig);
      });
  }

  fetchTimes() {
    const { polo, time } = this.state;
    axios
      .get(`${API_URL}/times`, {
        params: {
          polo: get(polo, 'value'),
          id_tipo_hierarquia: HIERARQUIA.TIME,
        },
        ...getAxiosConfig(),
      })
      .then((response) => {
        const times = response.data.map(time => ({
          value: time.id,
          label: time.descricao,
        }));
        this.setState({ times, time });
      })
      .catch(() => {
        toast.error('Erro ao carregar os times', toastConfig);
      });
  }

  fetchAvaliadores() {
    const { time, podeVerTimes } = this.state;
    axios
      .get(`${API_URL}/times`, {
        params: {
          id: get(time, 'value'),
          id_tipo_hierarquia: HIERARQUIA.TIME,
        },
        ...getAxiosConfig(),
      })
      .then((response) => {
        const times = response.data;
        if (!podeVerTimes) {
          const result = get(response, 'data[0]', []);
          this.setState({
            polo: result.polo,
            time: {
              value: result.id,
              label: result.descricao,
            },
          });
        }
        if (times.length === 0) {
          this.setState({
            avaliadores: [],
          });
        } else {
          let avaliadores = [];
          if (time) {
            avaliadores = times[0].usuarios.map(usuario => ({
              value: usuario.id,
              label: usuario.nome,
            }));
          } else {
            times.map(item => item.usuarios.map(usuario => (avaliadores.push({
              value: usuario.id,
              label: usuario.nome,
            }))));
          }
          this.setState({ avaliadores });
        }
      })
      .catch(() => {
        toast.error('Erro ao carregar os usuarios', toastConfig);
      });
  }

  fetchData(apiEndPoint) {
    const {
      page, pageSize, sorted, formValues, customFilters,
    } = this.state;

    const ordering = sorted
      && sorted.map(field => `${field.desc ? '-' : ''}${field.id}`).join(',');
    this.setState({ loading: true });
    axios
      .get(`${API_URL}${apiEndPoint}`, {
        params: {
          page,
          size: pageSize,
          ordering,
          ...customFilters,
          ...formValues,
        },
        ...getAxiosConfig(),
      })
      .then((response) => {
        const { results, total_pages } = response.data;
        this.setState({
          data: results,
          total_pages,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        toast.error('Erro para carregar o relatório', toastConfig);
      });
  }

  replaceHistory(field, value) {
    const history = get(this.props, 'history');
    const state = get(this.props, 'location.state', {});
    if (state) {
      state[field] = value;
      history.replace({ state });
    }
  }

  fetchMediaNacional(url) {
    const { formValues } = this.state;
    const params = { ...formValues };
    const config = { params, ...getAxiosConfig() };
    axios
      .get(`${API_URL}/${url}`, config)
      .then((response) => {
        const mediaNacional = response.data;
        this.setState({ mediaNacional });
      })
      .catch(() => {
        toast.error('Erro para carregar a média nacional', toastConfig);
      });
  }

  fetchMedia(url) {
    const { formValues, customFilters } = this.state;
    const params = { ...formValues, ...customFilters };
    const config = { params, ...getAxiosConfig() };
    axios
      .get(`${API_URL}/${url}`, config)
      .then((response) => {
        const mediaTotal = response.data;
        this.setState({ mediaTotal });
      })
      .catch(() => {
        toast.error('Erro para carregar a média nacional', toastConfig);
      });
  }

  renderTable(columns, className) {
    const {
 data, loading, page, total_pages, pageSize,
} = this.state;
    return (
      <ReactTable
        className={`-highlight ${className}`}
        defaultPageSize={10}
        previousText=""
        nextText=""
        noDataText="Sem conteúdo"
        pageText="Pág."
        ofText="de"
        rowsText="registros"
        manual
        data={data}
        columns={columns}
        loading={loading}
        loadingText={<Loading />}
        page={page - 1}
        pages={total_pages}
        pageSize={pageSize}
        onSortedChange={sorted => this.setState({ sorted }, () => this.fetchData(this.URL))}
        getTrProps={(state, rowInfo) => ({
          style: {
            backgroundColor: this.backgroundColor(state, rowInfo),
          },
        })}
        onPageSizeChange={(pageSizeChanged, pageIndex) => {
          this.setState(
            { page: pageIndex + 1, pageSize: pageSizeChanged },
            () => {
              this.fetchData(this.URL);
            },
          );
        }}
        onPageChange={(pageIndex) => {
          this.setState({ page: pageIndex + 1 }, () => {
            this.fetchData(this.URL);
          });
        }}
      />
    );
  }
}

export default RelatorioBase;
