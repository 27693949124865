import React from 'react'


class RedacaoDesempenhoCard extends React.Component {
  render() {
    const {
      redacao,
      fetchRedacao,
    } = this.props

    return (
      <div key={redacao.id} className="panel-body-avisos mt-3">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex">
              {redacao.mascara && (
                <button
                  type="button"
                  className="redacao-hyperlink"
                  onClick={() => fetchRedacao(redacao)}
                >
                  <h3 className="title-card-avisos">{redacao.mascara}</h3>
                </button>
              )}
              <h3 className="title-card-avisos ml-2">{redacao.tipo}</h3>
            </div>
          <div className="d-flex align-items-center">
            <div className="info-redacao-desempenho">
              <i className="fas fa-clock" />
              <span className="ml-2">
                DATA CRIAÇÃO: {redacao.data_criacao}
              </span>
            </div>
            <div className="info-redacao-desempenho ml-2">
              <i className="fas fa-clock" />
              <span className="ml-2">
                DATA DISTRIBUIÇÃO: {redacao.distribuido_em || '-'}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RedacaoDesempenhoCard
