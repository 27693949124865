import axios from 'axios';
import { API_URL } from '../consts';

export const fetchRecursos = async (params) => {
  const res = await axios.get(`${API_URL}/recursos/recurso`, { params });
  return res.data;
};

export const fetchRecurso = async (provaId, params) => {
  const res = await axios.get(`${API_URL}/recursos/recurso/${provaId}`, { params });
  return res.data;
};

export const fetchTiposDeferimento = async () => {
  const res = await axios.get(`${API_URL}/recursos/tipos-deferimento`);
  return res.data;
};

export const fetchStatusRecurso = async () => {
  const res = await axios.get(`${API_URL}/recursos/status`);
  return res.data;
};

export const deferirRecurso = async (provaId, params) => {
  const res = await axios.put(`${API_URL}/recursos/recurso/${provaId}/deferir`, params);
  return res.data;
};

export const divulgarRecurso = async (provaId, params) => {
  const res = await axios.put(`${API_URL}/recursos/recurso/${provaId}/divulgar`, params);
  return res.data;
};
