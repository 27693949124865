import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import PropTypes from 'prop-types';

class SupervisoresAtualizacaoLoteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeValue = (field) => {
    this.setState(prevState => ({
      [field]: !prevState[field],
    }));
  }

  renderContent() {
    const {
      pode_corrigir_4,
    } = this.state;
    const {
      nr_avaliadores,
      onConfirm,
      onClose,
      podeHabilitarQuarta,
      podeTrocarCota,
    } = this.props;

    const nrAvaliadoresInvalido = nr_avaliadores > 400;

    return (
      <div>
        <div className="modal-header pb-0">
          <div className="row w-100">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <h1 className="text-title">Atualizar em lote</h1>
            </div>
          </div>
        </div>
        <div className="modal-body pt-0">
          <div className="row">
            <div className="col-md-12">
              <p>Foram selecionados <strong className={nrAvaliadoresInvalido && 'red'}>{nr_avaliadores}</strong> supervisores para atualização em lote.</p>
            </div>
          </div>
          <div className="row mt-3">
            {
              nrAvaliadoresInvalido ? (
                <div className="col-md-12">
                  <p className="red h6">Não é possível atualizar mais de 400 supervisores em um lote.</p>
                </div>
              ) : (
                <>
                  {
                    podeTrocarCota && (
                      <div className="col-md-6">
                        <label htmlFor="form-state-name">Cota</label>
                        <input
                          className="form-control"
                          onChange={event => this.setState({ max_correcoes_dia: event.target.value })}
                        />
                      </div>
                    )
                  }
                  {
                    podeHabilitarQuarta && (
                      <div className="col-md-6">
                        <label htmlFor="form-state-name">Pode Corrigir</label>
                        <div>
                          <label className="ml-4">
                            <input
                              type="checkbox"
                              value={pode_corrigir_4}
                              onChange={() => this.onChangeValue('pode_corrigir_4')}
                            /> 4ª
                          </label>
                        </div>
                      </div>
                    )
                  }
                  <div className="col-md-12 mt-3 text-right">
                    <button type="button" className="btn btn-default mr-3" onClick={onClose}>CANCELAR</button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => onConfirm(this.state)}
                    >
                      ATUALIZAR SUPERVISORES
                    </button>
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      onClose,
      visible,
    } = this.props;

    return (
      <section>
        <Rodal closeOnEsc visible={visible} onClose={onClose}>
          {this.renderContent()}
        </Rodal>
      </section>
    );
  }
}

SupervisoresAtualizacaoLoteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  podeHabilitarQuarta: PropTypes.bool,
  podeTrocarCota: PropTypes.bool,
  nr_avaliadores: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

SupervisoresAtualizacaoLoteModal.defaultProps = {
  podeHabilitarQuarta: false,
  podeTrocarCota: false,
};


export default SupervisoresAtualizacaoLoteModal;
