import React from 'react';
import { toast } from 'react-toastify';
import BancoRedacoes from '../../components/v2/banco_redacoes/BancoRedacoes';
import { ComponentBase } from '../../base';
import { Footer } from '../../components';
import fetchBancoRedacoes from '../../services/banco_redacoes';
import { fetchHistoricoDeviosCorrecoes, fetchDesvios } from '../../services/redacao';

class BancoRedacoesPage extends ComponentBase {
  state = {
    redacao: undefined,
    fetching: false,
    fetchImagemError: false,
    historicoCorrecoes: [],
    desvios: [],
    fetchingHistoricoCorrecoes: false,
  }

  onSubmit = (params) => {
    this.setState({ fetching: true, fetchingHistoricoCorrecoes: true });
    fetchBancoRedacoes(params)
      .then((redacao) => {
        const setStateCallback = this.hasPermission('ver_historico_candidato') ? this.fetchHistorico : null;
        this.setState({
          redacao,
          fetchImagemError: !redacao.src,
        }, setStateCallback);
      }).catch(() => toast.error('Nenhuma redação encontrada.'))
      .finally(() => this.setState({ fetching: false, fetchingHistoricoCorrecoes: false }));
  }

  fetchHistorico() {
    const { redacao } = this.state;
    fetchHistoricoDeviosCorrecoes(redacao.id).then(data => this.setState({ historicoCorrecoes: data }));
    fetchDesvios({ projeto: redacao.projeto_id }).then(data => this.setState({ desvios: data }));
  }

  onBackClick = () => this.setState({ redacao: undefined });

  render() {
    const {
      redacao, fetching, fetchImagemError, historicoCorrecoes, fetchingHistoricoCorrecoes,
      desvios,
    } = this.state;

    return (
      <>
        <div className="container">
          <div className="page-container-border">
            <div className="row">
              <div className="col">
                <h1 className="text-title">Redações</h1>
              </div>
            </div>
            <BancoRedacoes
              onSubmit={this.onSubmit}
              verHistoricoCandidato={this.hasPermission('ver_historico_candidato')}
              verDadosCandidato={this.hasPermission('ver_dados_candidato')}
              verNomeAvaliadorHistorico={this.hasPermission('pode_ver_nome_avaliador_historico')}
              historicoCorrecoes={historicoCorrecoes}
              onBackClick={this.onBackClick}
              redacao={redacao}
              fetchImagemError={fetchImagemError}
              fetchingHistoricoCorrecoes={fetchingHistoricoCorrecoes}
              fetching={fetching}
              desvios={desvios}
              downloadImagemOriginal={this.hasPermission('download_imagem_original')}
            />
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default BancoRedacoesPage;
