import React from 'react';
import { API_URL } from '../../../../consts';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { ComponentBase } from '../../../../base';


class TerceiraCorrecaoPolo extends ComponentBase {
  get columns() {
    return [
      {
        header: '#',
        accessor: 'row',
        width: 50,
        Cell: row => <div>{row.index + 1}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Polo',
        accessor: 'polo_descricao',
        withSortIcon: true,
        minWidth: 180,
        Cell: d => <div className="column-left">{d.value}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: 'Terceiras Nacional',
          },
        },
      },
      {
        header: 'Time',
        accessor: 'time_descricao',
        withSortIcon: true,
        minWidth: 180,
        next: {
          pathname: '/relatorios/v2/terceira-correcao-time',
          stateFields: ['polo', 'time'],
        },
        Cell: d => <div className="column-left">{d.value}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Terceiras corrigidas',
        accessor: 'terceiras_corrigidas',
        withSortIcon: true,
        defaultValue: 0,
        minWidth: 110,
        footer: {
          mediaNacional: {
            accessor: 'terceiras_corrigidas',
            default: '-',
          },
        },
      },
      {
        header: 'Terceiras aproveitadas',
        accessor: 'terceiras_aproveitadas',
        withSortIcon: true,
        defaultValue: 0,
        sortMethod: true,
        minWidth: 130,
        footer: {
          mediaNacional: {
            accessor: 'terceiras_aproveitadas',
            default: '-',
          },
        },
      },
      {
        header: 'Geraram 4ª correção',
        accessor: 'foram_quarta',
        withSortIcon: true,
        defaultValue: 0,
        sortMethod: true,
        minWidth: 130,
        footer: {
          mediaNacional: {
            accessor: 'foram_quarta',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitados após quarta correção',
        accessor: 'aproveitadas_quarta',
        withSortIcon: true,
        defaultValue: 0,
        sortMethod: true,
        minWidth: 130,
        footer: {
          mediaNacional: {
            accessor: 'aproveitadas_quarta',
            default: '-',
          },
        },
      },
      {
        header: 'Não aproveitadas após quarta correção',
        accessor: 'nao_aproveitadas_quarta',
        withSortIcon: true,
        defaultValue: 0,
        sortMethod: true,
        minWidth: 130,
        footer: {
          mediaNacional: {
            accessor: 'nao_aproveitadas_quarta',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitamento dentro das que foram para quarta (%)',
        accessor: 'aproveitamento_quarta',
        withSortIcon: true,
        sortMethod: true,
        minWidth: 150,
        footer: {
          mediaNacional: {
            accessor: 'aproveitamento_quarta',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitamentos dentro das terceiras corrigidas (%)',
        accessor: 'aproveitamento_terceira',
        withSortIcon: true,
        sortMethod: true,
        minWidth: 150,
        footer: {
          mediaNacional: {
            accessor: 'aproveitamento_terceira',
            default: '-',
          },
        },
      },
    ];
  }

  render() {
    const backAction = this.hasPermission('ver_relatorio_terceira_correcao_geral') ? {
      path: '/relatorios/v2/terceira-correcao-geral',
      state: [],
    } : undefined;

    const exportData = {
      url: `${API_URL}/v2/relatorios/terceira-correcao/polos/excel`,
      filename: 'terceira_correcao_polo.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        isPoloInputRequired
        showAvaliadorInput={false}
        title="TERCEIRA CORREÇÃO | Times"
        dataUrl="v2/relatorios/terceira-correcao/polos"
        mediaNacionalUrl="v2/relatorios/terceira-correcao/geral/media"
        columns={this.columns}
        exportData={exportData}
        backAction={backAction}
        codigoMensagem="TERCEIRA_CORRECAO_TIMES"
        codigo="TERCEIRA_CORRECAO_POLO"
        caption={{}}
      />
    );
  }

};

export default TerceiraCorrecaoPolo;
