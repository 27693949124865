import React from 'react';
import {
 get, keyBy, sortBy,
} from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import SupervisoresAtualizacaoLoteModal from '../components/v2/usuarios/SupervisoresAtualizacaoLoteModal';
import Container from '../components/v2/Container';
import Supervisores from '../components/v2/usuarios/Supervisores';
import RelatorioForm from '../components/v2/relatorio/RelatorioForm';
import AvaliadoresForm from '../components/v2/usuarios/AvaliadoresForm';
import { getAxiosConfig } from '../services';
import {
  toastConfig,
  operatorChoiceFilterMethod,
  defaultFilter,
  alertasFilter,
  correcoesHabilitadasFilter,
} from '../utils';
import dictionary from '../dictionary';
import { getError } from '../services/utils';
import { setAuthorizedToken } from '../services/auth';
import { API_URL, COTA_SUPERVISOR_OPTIONS } from '../consts';
import { ComponentBase } from '../base';

const getUserGroup = (groups) => {
  const userGroup = {};
  groups.forEach((g) => {
    g.user_set.forEach((userId) => {
      userGroup[userId] = g.name;
    });
  });
  return userGroup;
};

const METHODS = {
  operatorChoiceFilterMethod: operatorChoiceFilterMethod,
  defaultFilter: defaultFilter,
  alertasFilter: alertasFilter,
  correcoesHabilitadasFilter: correcoesHabilitadasFilter,
}

class SupervisoresPage extends ComponentBase {
  state = {
    users: [],
    filteredUsers: [],
    correcoes: [],
    filters: {},
    avaliadoresFilters: {},
    hierarquias: [],
    currentUser: {},
    fetchingCurrentUser: false,
    fetchingUsers: true,
    avaliadoresSelecionados: [],
    openModal: '',
    ultimaAtualizacao: null,
    correcoesUsuarios: [],
  }

  componentDidMount() {
    axios.all([
      this.fetchHierarquias(),
      this.fetchGroups(),
    ])
      .then(([hierarquias, groups]) => {
        const userGroup = getUserGroup(groups);
        this.fetchUsers(hierarquias, userGroup);
      });
    this.fetchCorrecoesUsuario();
    this.fetchControleRelatorio();
  }

  fetchUsers = (hierarquias, userGroup) => {
    const mapUser = (user) => {
      const { correcoesUsuarios } = this.state;
      const hierarquia = hierarquias[user.hierarquia];
      const hierarquia_responsavel = hierarquias[hierarquia.id_hierarquia_usuario_pai];
      const correcoesUsuario = correcoesUsuarios.find(a => a.usuario_id === user.id);
      return {
        ...user,
        hierarquia: get(hierarquia, 'descricao'),
        hierarquia_responsavel: get(hierarquia_responsavel, 'descricao'),
        time_id: get(hierarquia, 'id'),
        polo_id: get(hierarquia_responsavel, 'id'),
        group: userGroup[user.id],
        corrigidas: get(correcoesUsuario, 'nr_corrigidas'),
        ultima_correcao: get(correcoesUsuario, 'ultima_correcao'),
      };
    };

    axios.get(`${API_URL}/v3/users/me/supervisores`, getAxiosConfig())
      .then((response) => {
        const users = response.data.map(mapUser);
        this.setState({
          fetchingUsers: false,
          users: sortBy(users, ['hierarquia_responsavel', 'hierarquia', 'name']),
          filteredUsers: sortBy(users, ['hierarquia_responsavel', 'hierarquia', 'name']),
      });
    });
  }

  fetchCurrentUser = (user) => {
    this.setState({ fetchingCurrentUser: true });
    axios.get(`${API_URL}/v3/users/${user.id}`, getAxiosConfig())
      .then(response => this.setState({ currentUser: response.data, fetchingCurrentUser: false }))
      .catch(() => this.setState({ currentUser: { nome: user.name }, fetchingCurrentUser: false }));
  }

  fetchHierarquias = () => axios.get(`${API_URL}/v3/hierarquias`, getAxiosConfig())
      .then((response) => {
        const hierarquias = keyBy(response.data, 'id');
        this.setState({ hierarquias });
        return hierarquias;
      })

  fetchGroups = () => axios.get(`${API_URL}/v3/groups`, getAxiosConfig())
      .then(response => response.data)

  fetchCorrecoesUsuario = () => {
    axios.get(`${API_URL}/v2/relatorios/correcoes-usuario`, getAxiosConfig())
      .then((response) => {
        const mapUser = (user) => {
          const correcoesUsuario = response.data.find(a => a.usuario_id === user.id);
          return {
            ...user,
            corrigidas: get(correcoesUsuario, 'nr_corrigidas'),
            ultima_correcao: get(correcoesUsuario, 'ultima_correcao'),
          };
        };
        this.setState(prevState => ({
          correcoesUsuarios: response.data,
          users: prevState.users.map(mapUser),
          filteredUsers: prevState.users.map(mapUser),
        }));
      });
  }

  filterUsers = (filters) => {
    const { users, avaliadoresFilters } = this.state;
    const filteredUsers = users.filter((user) => {
      const commonFilter = Object.keys(filters).map((key) => {
        if (filters[key]) {
          return user[key] === filters[key];
        }
        return true;
      });
      const customFilters = Object.keys(avaliadoresFilters).map(key => {
        const filterMethod = METHODS[avaliadoresFilters[key].filterMethod];
        return filterMethod(avaliadoresFilters[key], user);
      });
      return [...customFilters, ...commonFilter].every(val => val === true);
    });
    this.setState({ filteredUsers });
  }

  fetchControleRelatorio = () => {
    axios.get(`${API_URL}/v2/relatorios/controle-relatorio/APROVEITAMENTO_NOTAS`, getAxiosConfig())
      .then(response => this.setState({ ultimaAtualizacao: response.data.atualizado_em }));
  }

  updateUser = (user) => {
    axios.patch(`${API_URL}/v3/users/${user.id}`, user, getAxiosConfig())
      .catch(error => {
        if (get(error, 'response.data.max_correcoes_dia[0]')) {
          toast.error(get(error, 'response.data.max_correcoes_dia[0]'))
        } else {
          toast.error('Ocorreu um erro ao atualizar o supervisor. Por favor, tente novamente.');
        }
      });
  }

  handleChange = (userId, changes) => {
    const { users, filteredUsers } = this.state;
    const user = {
      ...users.find(d => d.id === userId),
      ...changes,
    };
    this.setState({
      users: users.map(d => (d.id === userId ? user : d)),
      filteredUsers: filteredUsers.map(d => (d.id === userId ? user : d)),
    });
    this.updateUser(user);
  }

  handleAction = (action, ...args) => {
    this[`handle${action.charAt(0).toUpperCase() + action.slice(1)}`](...args);
  }

  handleClearFilters = () => {
    this.setState({
      avaliadoresFilters: {},
    }, () => this.filterUsers({}));
  }

  changeCustomFilters = (value, field, filterMethod) => {
    this.setState(prevState => ({
      avaliadoresFilters: {
        ...prevState.avaliadoresFilters,
        [field]: { value, id: field, filterMethod },
      },
    }));
  }

  selecionarAvaliador = (id) => {
    const { avaliadoresSelecionados } = this.state;
    if (avaliadoresSelecionados.includes(id)) {
      this.setState({ avaliadoresSelecionados: avaliadoresSelecionados.filter(value => value !== id) });
    } else {
      this.setState({ avaliadoresSelecionados: [...avaliadoresSelecionados, id] });
    }
  }

  selecionarTodosAvaliadores = (ids) => {
    this.setState({ avaliadoresSelecionados: ids });
  }

  atualizarEmLote = (values) => {
    const {
      avaliadoresSelecionados,
      filteredUsers,
      users,
    } = this.state;

    this.setState({
      fetchingUsers: true,
      openModal: null,
    });

    const newFilteredUsers = filteredUsers.map((user) => {
      if (avaliadoresSelecionados.includes(user.id)) {
        return { ...user, ...values };
      }
      return user;
    });

    const newUsers = users.map((user) => {
      if (avaliadoresSelecionados.includes(user.id)) {
        return { ...user, ...values };
      }
      return user;
    });

    const params = {
      users: avaliadoresSelecionados,
      ...values,
    };
    axios.post(`${API_URL}/v3/users/atualizar-usuarios`, params, getAxiosConfig())
      .then(() => {
        this.setState({
          avaliadoresSelecionados: [],
          users: newUsers,
          filteredUsers: newFilteredUsers,
          fetchingUsers: false,
        });
        toast.success('Avaliadores atualizados com sucesso.');
      })
      .catch((error) => {
        this.setState({ fetchingUsers: false });
        if (get(error, 'response.data.max_correcoes_dia[0]')) {
          toast.error(get(error, 'response.data.max_correcoes_dia[0]'))
        } else {
          toast.error('Ocorreu um erro ao atualizar o supervisor. Por favor, tente novamente.');
        }
      });
  }

  handleAcessarComo = (cpf) => {
    axios.post(`${API_URL}/login-as/`, { user: cpf }, getAxiosConfig())
      .then(({ data }) => {
        setAuthorizedToken(data.token)
        localStorage.removeItem('listSettings');
        window.location.href = '/';
      })
      .catch((error) => {
        const { status, data } = getError(error);
        let message = dictionary.ERRO_DESCONHECIDO;
        if (status === 403 && data.detail) {
          message = data.detail;
        } else if (status === 400 && data.user) {
          message = data.user[0];
        }
        toast.error(message, toastConfig);
      })
  }

  render() {
    const {
      filteredUsers,
      hierarquias,
      currentUser,
      fetchingCurrentUser,
      fetchingUsers,
      fetchingTotalCorrecoes,
      avaliadoresFilters,
      avaliadoresSelecionados,
      openModal,
      ultimaAtualizacao,
    } = this.state;

    return (
      <Container title="Supervisores">
        <RelatorioForm
          fetchingRelatorio={fetchingUsers}
          onApplyFilters={this.filterUsers}
          showDateRangeInput={false}
          caption={{}}
          onClearFilters={this.handleClearFilters}
          atualizaLote={() => this.setState({ openModal: 'atualizarLote' })}
          showAvaliadorInput={false}
          showClearButton
          showBatchUpdateButton={this.hasFeature('atualizar_corretores_em_lote') && this.hasPermission('atualizar_supervisores_em_lote')}
          returnFilterDescription={false}
        >
          <AvaliadoresForm
            onChange={this.changeCustomFilters}
            corrigidas={avaliadoresFilters.corrigidas}
            dsp={avaliadoresFilters.dsp}
            aproveitamento={avaliadoresFilters.aproveitamento}
            pre_teste={avaliadoresFilters.pre_teste}
            correcoes={avaliadoresFilters.correcoes}
            max_correcoes_dia={avaliadoresFilters.max_correcoes_dia}
            status={avaliadoresFilters.status}
            alertas={avaliadoresFilters.alertas}
            showTextoCota={this.hasFeature('mostrar_cota')}
            showCorrecoesHabilitadas={this.hasFeature('mostrar_correcoes_habilitadas')}
            podeHabilitarQuarta
            cotaOptions={COTA_SUPERVISOR_OPTIONS}
          />
        </RelatorioForm>
        <Supervisores
          onAction={this.handleAction}
          users={filteredUsers}
          hierarquias={hierarquias}
          onCurrentUserChange={this.fetchCurrentUser}
          currentUser={currentUser}
          fetchingCurrentUser={fetchingCurrentUser}
          fetchingUsers={fetchingUsers}
          fetchingAproveitamento={fetchingTotalCorrecoes}
          showPolo={this.hasFeature('mostrar_polo')}
          showTime={this.hasFeature('mostrar_time')}
          showCorrecoesHabilitadas={this.hasFeature('mostrar_correcoes_habilitadas')}
          showCota={this.hasFeature('mostrar_cota')}
          avaliadoresSelecionados={avaliadoresSelecionados}
          selecionarAvaliador={this.selecionarAvaliador}
          selecionarTodosAvaliadores={ids => this.selecionarTodosAvaliadores(ids)}
          showAtualizacaoLote={this.hasFeature('atualizar_corretores_em_lote')}
          podeHabilitarQuarta={this.hasPermission('habilitar_quarta_supervisores')}
          podeTrocarCota={this.hasPermission('trocar_cota_supervisores')}
          onAcessarComo={this.handleAcessarComo}
          showAcessarComo={this.hasFeature('acessar_como_alguem') && this.hasPermission('acessar_como_supervisor')}
          cotaOptions={COTA_SUPERVISOR_OPTIONS}
          noDataText="Nenhum supervisor encontrado com este filtro."
        />
        <SupervisoresAtualizacaoLoteModal
          visible={openModal === 'atualizarLote'}
          onClose={() => this.setState({ openModal: null })}
          onConfirm={this.atualizarEmLote}
          nr_avaliadores={avaliadoresSelecionados.length}
          podeHabilitarQuarta={this.hasPermission('habilitar_quarta_supervisores')}
          podeTrocarCota={this.hasPermission('trocar_cota_supervisores')}
        />
        {
          ultimaAtualizacao && (
            <div className="d-flex justify-content-end mt-2">
              <small>Última atualização: {ultimaAtualizacao}</small>
            </div>
          )
        }
      </Container>
    );
  }
}

export default SupervisoresPage;
