/* eslint-disable react/sort-comp */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
 get, isEmpty, isNumber, size,
} from 'lodash';
import { Form } from 'informed';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getAxiosConfig } from '../services';
import { API_URL } from '../consts';
import Footer from '../components/Footer';
import { toastConfig } from '../utils';
import {
  QuillInput,
  ReactSelectInput,
  Loading,
  TextInputGroup,
  TextInput,
} from '../components';
import dictionary from '../dictionary';
import { getError } from '../services/utils';

const initialValues = {
  descricao: '',
  data_inicio_date: '',
  data_inicio_time: '',
  data_termino_date: '',
  data_termino_time: '',
  usuarios: [],
};

class NovoAviso extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingUsuarios: true,
      fetchingAviso: true,
      hasEmptyValues: true,
      usuarios: [],
      allUsuariosSelected: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    const id = get(this.props, 'match.params.id');
    Promise.all([
      this.fetchUsuarios(),
      this.fetchAviso(id),
    ])
      .then((responses) => {
        const [usuarios, aviso] = responses;
        if (aviso) {
          const allUsuariosSelected = aviso.usuarios.length === usuarios.length;
          this.setState({ allUsuariosSelected });
          if (!allUsuariosSelected) {
            this.formApi.setValue('usuarios', aviso.usuarios);
          }
        }
      });
  }

  onValueChange(values) {
    this.checkEmptyValues(values);
  }

  getTitulo() {
    const id = get(this.props, 'match.params.id');
    if (id === 'new') {
      return 'Novo aviso';
    }
    return `Aviso ${id}`;
  }

  handleSubmit = (values) => {
    this.setState({ submitting: true });
    const { history } = this.props;
    const { allUsuariosSelected, usuarios } = this.state;
    const id = get(this.props, 'match.params.id');
    const postData = {
      ...values,
      data_inicio: `${values.data_inicio_date} ${values.data_inicio_time}`,
      usuarios: allUsuariosSelected ? usuarios.map(d => d.value) : values.usuarios,
    };
    if (values.data_termino_date && values.data_termino_time) {
      postData.data_termino = `${values.data_termino_date} ${values.data_termino_time}`;
    } else {
      postData.data_termino_date = null;
      postData.data_termino_time = null;
    }

    if (id === 'new') {
      axios.post(`${API_URL}/avisos`, postData, getAxiosConfig())
        .then(() => {
          toast.success(dictionary.SUCESSO_POST_AVISO, toastConfig);
          history.push('/avisos');
        })
        .catch((error) => {
          this.setState({ submitting: false });
          const { data, status } = getError(error);
          if (status === 400) {
            this.loadFormErrors(data);
          } else {
            const message = data.detail || dictionary.ERRO_POST_AVISO;
            toast.error(message, toastConfig);
          }
        });
    } else {
      axios.patch(`${API_URL}/avisos/${id}`, postData, getAxiosConfig())
        .then(() => {
          toast.success(dictionary.SUCESSO_PATCH_AVISO, toastConfig);
          history.push('/avisos');
        })
        .catch((error) => {
          this.setState({ submitting: false });
          const { data, status } = getError(error);
          if (status === 400) {
            this.loadFormErrors(data);
          } else {
            const message = data.detail || dictionary.ERRO_POST_AVISO;
            toast.error(message, toastConfig);
          }
        });
    }
  }

  fetchAviso(id) {
    if (id === 'new') {
      this.setState({ fetchingAviso: false });
      return Promise.resolve(null);
    }

    return axios.get(`${API_URL}/avisos/${id}/form`, getAxiosConfig())
      .then((response) => {
        this.formApi.setValues({ ...response.data, usuarios: [] });
        this.checkEmptyValues(response.data);
        this.setState({ fetchingAviso: false });
        return response.data;
      })
      .catch(this.handleError);
  }

  handleError(error) {
    const { history } = this.props;
    const { status, data } = getError(error);

    switch (status) {
      case 401:
      case 403:
        toast.error(data.detail, toastConfig);
        history.push('/');
        break;
      case 404:
        toast.error(dictionary.GET_AVISO_404, toastConfig);
        history.push('/avisos');
        break;
      default:
        toast.error(dictionary.ERRO_DESCONHECIDO, toastConfig);
    }
  }

  fetchUsuarios() {
    return axios.get(`${API_URL}/v3/users/me/subordinados`, {
      params: {
        qualquer_tipo_hierarquia: true,
      },
      ...getAxiosConfig(),
    })
      .then((response) => {
        const usuarios = response.data.data.map(d => ({
          value: d[0], label: d[1],
        }));
        this.setState({ usuarios, fetchingUsuarios: false });
        return usuarios;
      })
      .catch(() => this.setState({ fetchingUsuarios: false }));
  }

  checkEmptyValues(values) {
    const hasEmptyValues = Object.keys(values).some(
      field => (
        field === 'data_termino_date' || field === 'data_termino_time' ? ''
        : isNumber ? !values[field] : isEmpty(values[field])
      ),
    );
    this.setState({ hasEmptyValues });
  }

  loadFormErrors(errors) {
    Object.keys(errors).filter(field => size(errors[field]) > 0).forEach((field) => {
      this.formApi.setError(field, errors[field][0]);
    });
  }

  selectAllUsuarios = () => {
    this.formApi.setValue('usuarios', []);
    this.setState({ allUsuariosSelected: true });
  }

  unselectAllUsuarios = () => {
    this.setState({ allUsuariosSelected: false });
  }

  renderSelectAllUsuarios() {
    const { allUsuariosSelected } = this.state;
    if (allUsuariosSelected) {
      return (
        <button
          type="button"
          className="btn btn-default btn-sm mt-2"
          onClick={this.unselectAllUsuarios}
        >
          Limpar todos
        </button>
      );
    }
    return (
      <button
        type="button"
        className="btn btn-default btn-sm mt-2"
        onClick={this.selectAllUsuarios}
      >
        Selecionar todos
      </button>
    );
  }

  render() {
    const {
      fetchingUsuarios,
      submitting,
      fetchingAviso,
      hasEmptyValues,
      usuarios,
      allUsuariosSelected,
    } = this.state;
    const submitDisabled = submitting || hasEmptyValues;

    return (
      <Form
        onChange={formState => this.setState({ formState })}
        onSubmit={this.handleSubmit}
        getApi={(formApi) => { this.formApi = formApi; }}
        onValueChange={this.onValueChange}
        initialValues={initialValues}
      >
        {() => {
          if (fetchingAviso) {
            return <Loading />;
          }

          return (
            <Fragment>
              <section className="novo-aviso">
                <div className="container">
                  <div className="page-container-border">
                    <section>
                      <div className="row">
                        <div className="col">
                          <h1 className="text-title">{this.getTitulo()}</h1>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col pt-4">
                          <div className="mensagem-aviso">
                            <TextInput label="Título" field="titulo" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col pt-4">
                          <div className="mensagem-aviso">
                            <label>Descrição</label>
                            <QuillInput field="descricao" />
                          </div>
                        </div>
                      </div>
                      <div className="row pt-4">
                        <div className="col-sm-12">
                          <div className="periodo-ativacao">
                            <label>Período de Ativação: </label>
                          </div>
                        </div>
                        <div className="col pt-2">
                          <TextInputGroup
                            label="Data de início"
                            field="data_inicio_date"
                            type="date"
                            placeholder="23/06/2018"
                          />
                        </div>
                        <div className="col pt-2">
                          <TextInputGroup
                            label="Hora de início"
                            field="data_inicio_time"
                            type="time"
                            placeholder="14:00"
                          />
                        </div>
                      </div>
                      <div className="row pt-4">
                        <div className="col pt-2">
                          <TextInputGroup
                            label="Data de término"
                            field="data_termino_date"
                            type="date"
                            placeholder="24/06/2018"
                          />
                        </div>
                        <div className="col pt-2">
                          <TextInputGroup
                            label="Hora de término"
                            field="data_termino_time"
                            type="time"
                            className="form-control"
                            placeholder="14:00"
                          />
                        </div>
                      </div>
                      <div className="row pt-4">
                        <div className="col-sm-12 pt-2">
                          <label>Corretores</label>
                          <div className="mb-1">
                            <ReactSelectInput
                              isMulti
                              field="usuarios"
                              placeholder={allUsuariosSelected ? '[Todos usuários selecionados]' : 'Selecione...'}
                              isDisabled={allUsuariosSelected}
                              options={usuarios}
                              autoSize={false}
                              isLoading={fetchingUsuarios}
                            />
                            {this.renderSelectAllUsuarios()}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 pt-2 text-right">
                          <Link to="/avisos">
                            <button type="button" className="btn bg-dark-default-color mt-4 mr-3">
                              CANCELAR
                            </button>
                          </Link>
                          <button
                            type="submit"
                            className={`btn bg-default-color mt-4 ${submitDisabled ? 'disabled' : ''}`}
                            disabled={submitDisabled}
                          >
                            SALVAR
                          </button>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </section>
              <Footer />
            </Fragment>
          );
        }}
      </Form>
    );
  }
}

NovoAviso.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default NovoAviso;
