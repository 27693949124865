import React from 'react';
import PropTypes from 'prop-types';
import ImagemRedacaoExternaHeader from './ImagemRedacaoExternaHeader';
import ImagemRedacaoExternaContent from './ImagemRedacaoExternaContent';

const ImagemRedacaoExterna = ({ desvios, shapes }) => (
  <>
    <ImagemRedacaoExternaHeader desvios={desvios} />
    <ImagemRedacaoExternaContent shapes={shapes} />
  </>
);

ImagemRedacaoExterna.propTypes = {
  desvios: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    indicador: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    espessura: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    comprimento: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    descricao: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    valor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  shapes: PropTypes.shape({
    id: PropTypes.number,
  }),
};

ImagemRedacaoExterna.defaultProps = {
  desvios: [],
  shapes: {},
};

export default ImagemRedacaoExterna;
