import React, { Fragment } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import shortid from 'shortid';
import { toast } from 'react-toastify';
import {
  ModalConfirmacao,
  Footer,
  ModalVerificarAuxiliar,
  Loading,
  TabelaBuscarProjeto,
} from '../components';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services';
import CorrecaoBase from './CorrecaoBase';
import { toastConfig } from '../utils';

class ListaCorrecao extends CorrecaoBase {
  constructor(props) {
    super(props);
    this.handleBuscarMaisUm = this.handleBuscarMaisUm.bind(this);
    this.onCloseModalSupervisor = this.onCloseModalSupervisor.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onCloseBuscar = this.onCloseBuscar.bind(this);
    this.state = {
      modal_supervisor: false,
      modal_supervisor_errors: {},
      id_correcao: null,
      listaCorrecoes: [],
      visible: false,
      loading: true,
      permissions: {
        pode_buscar_mais_um: true,
      },
      formState: {},
      iniciada: 0,
    };
  }

  componentDidMount() {
    this.fetchCorrecoes();
    this.fetchPrimeiraIniciada();
  }

  fetchPermissao() {
    axios.get(`${API_URL}/permissoes`, getAxiosConfig())
    .then((response) => {
      const { permissions } = response.data;
      this.setState({ permissions });
    });
  }

  postCorretor() {
    const data = {
        data_validade_correcao: Date.now(),
    };
    axios.post(`${API_URL}/correcoes/contador`, data, getAxiosConfig())
      .then()
      .catch((error) => {
        this.setState({ submitting: false });
        this.handleError(error);
      });
  }

  fetchContador() {
    axios.get(`${API_URL}/correcoes/contador`, getAxiosConfig())
    .then((response) => {
      const { contador } = response.data;
      this.setState({
        contador,
      });
    });
  }

  fetchPrimeiraIniciada() {
    axios.get(`${API_URL}/correcoes/iniciada`, getAxiosConfig())
    .then((response) => {
      const iniciada = response.data;
      this.setState({ iniciada });
    })
    .catch(this.handleError);
  }

  fetchCorrecoes() {
    axios.get(`${API_URL}/correcoes/`, getAxiosConfig())
    .then((response) => {
      const { records } = response.data;
      this.setState({
        listaCorrecoes: records,
        loading: false,
     });
    })
    .catch(this.handleError);
  }

  handleBuscarMaisUm(projeto_id) {
    const iniciadas = this.state.iniciada;
    if (this.hasFeature('limitar_tempo_avaliador')) {
      this.fetchContador();
      const { contador } = this.state;
      if (iniciadas === 0) {
        this.setState({ visible: true });
      } else if (contador === 0) {
        toast.error('Seu tempo de exercício está esgotado!', toastConfig);
      } else {
        this.setState({ buscandoMaisUm: true });
        this.buscarMaisUm(() => this.setState({ buscandoMaisUm: false }), projeto_id);
      }
    } else {
      this.setState({ buscandoMaisUm: true });
      this.buscarMaisUm(() => this.setState({ buscandoMaisUm: false }), projeto_id);
    }
  }

  onCloseModalSupervisor() {
    this.setState({ modal_supervisor: false, buscandoMaisUm: false });
  }

  onClose() {
    this.setState({ visible: false });
  }

  onCloseBuscar() {
    this.setState({ buscandoMaisUm: true, visible: false });
    this.buscarMaisUm(() => this.setState({ buscandoMaisUm: false }));
    this.postCorretor();
  }

  render() {
    const {
      listaCorrecoes,
      visible,
      modal_supervisor,
      modal_supervisor_errors,
      id_correcao,
      buscandoMaisUm,
    } = this.state;

    return (
      <Fragment>
        <ModalVerificarAuxiliar
          visible={modal_supervisor}
          modal_supervisor_errors={modal_supervisor_errors}
          onClose={this.onCloseModalSupervisor}
          history={this.props.history}
          id_correcao={id_correcao}
        />
        <ModalConfirmacao
          visible={visible}
          onClose={this.onClose}
          title="Atenção"
          content={this.getMessage('INICIAR_EXERCICIO')}
          buttonFunction={this.onCloseBuscar}
          textSim="SIM"
          textNao="NÃO"
        />
        <section className="lista-correcoes-section">
          <div className="container">
            <div className="page-container-border">
            {
              this.hasPermission('minhas_correcoes_avaliador') && (
              <Fragment>
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <h1 className="text-title">Minhas correções</h1>
                  </div>
                </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12 card-correcoes">
                  {
                    this.state.loading ? <Loading /> : (
                    listaCorrecoes.map(record => (
                    <div key={record} className="card">
                      <div className="card-body pt-5 pb-4">
                        <div className="row">
                          <div className="col-12">
                          {this.hasPermission('ver_informacao_correcao') && (
                              <span className="tag-status-correcoes success">
                                REDAÇÕES CORRIGIDAS: <span>{get(record, 'total_corrigidas')}</span>
                              </span>
                            )}
                          {this.hasFeature('ocorrencia') && (
                            <span className="tag-status-correcoes danger">
                              OCORRÊNCIAS PENDENTES: <span>{get(record, 'total_ocorrencias_pendentes')}</span>
                            </span>
                          )}
                          {this.hasFeature('ocorrencia') && (
                            <span className="tag-status-correcoes gray">
                              TOTAL DE OCORRÊNCIAS: <span>{get(record, 'total_ocorrencias')}</span>
                            </span>
                          )}
                          {this.hasPermission('fazer_correcao_4') && (
                            <button type="button" className="btn btn-primary pull-right full-height">NOVA 4ª CORREÇÃO</button>
                          )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )))}
                </div>
              </div>
              {this.state.loading ? <Loading /> : (
                <div className="row">
                  {this.hasPermission('ver_informacao_correcao') && (
                    <div className="col col-xs-12 panel-default panel-darkblue">
                      <div className="panel-title">
                        <div className="row">
                          <div className="col">
                            <h2 className="pt-2">Buscar +1</h2>
                          </div>
                        </div>
                      </div>
                      <TabelaBuscarProjeto buscandoMaisUm={buscandoMaisUm} onBuscarMaisUm={this.handleBuscarMaisUm} />
                    </div>
                  )}
                </div>
              )}
              </Fragment>
                  )
                }
              {
                this.hasFeature('ver_lista-correcao_time') && (
              <Fragment>
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <h1 className="text-title">Correções do time</h1>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12 card-correcoes">
                    <div className="card">
                      <div className="card-body pt-5 pb-4">
                        <div className="row">
                          <div className="col-12">
                          {this.hasPermission('ver_informacao_correcao') && (
                            <span className="tag-status-correcoes success">
                              REDAÇÕES CORRIGIDAS: <span>0</span>
                            </span>
                          )}
                          {this.hasFeature('ocorrencia') && (
                            <span className="tag-status-correcoes danger">
                              OCORRÊNCIAS PENDENTES: <span>0</span>
                            </span>
                          )}
                          {this.hasFeature('ocorrencia') && (
                            <span className="tag-status-correcoes gray">
                              TOTAL DE OCORRÊNCIAS: <span>0</span>
                            </span>
                          )}
                          {this.hasPermission('fazer_correcao_4') && (
                            <button type="button" className="btn btn-primary pull-right full-height">NOVA 4ª CORREÇÃO</button>
                          )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
              )
            }
             {
                this.hasPermission('minhas_correcoes_supervisor') && (
                <Fragment>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                    {this.hasPermission('ver_informacao_correcao')
                    ? <h1 className="text-title">Minhas correções</h1>
                    : <h1 className="text-title">Acompanhamento geral das ocorrências</h1>
                    }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 card-correcoes">
                      {listaCorrecoes.map(record => (
                      <div className="card" key={shortid.generate()}>
                        <div className="card-body pt-5 pb-4">
                          <div className="row">
                            <div className="col-12">
                            {this.hasPermission('ver_informacao_correcao') && (
                              <div className="tag-status-correcoes w-100 success">
                                <span>REDAÇÕES CORRIGIDAS: </span><span>{get(record, 'total_corrigidas')}</span>
                              </div>
                            )}
                            </div>
                            <div className="col-12">
                              <div className="tag-list">
                                <div className="tag-status-correcoes">
                                  <span className="tag-title gray">OCORRÊNCIAS PARA O {get(record, 'supervisor.perfil')} RESPONDER</span>
                                  <ul className="tag-status-correcoes-list">
                                    <li>Recebidas <span>{get(record, 'supervisor.recebidas')}</span></li>
                                    <li>Respondidas <span>{get(record, 'supervisor.respondidas')}</span></li>
                                    <li>Pendentes <span>{get(record, 'supervisor.pendentes')}</span></li>
                                  </ul>
                                </div>
                                <div className="tag-status-correcoes">
                                  <span className="tag-title gray">OCORRÊNCIAS ENVIADAS PARA O {get(record, 'coordenador.perfil')}</span>
                                  <ul className="tag-status-correcoes-list">
                                    <li>Enviadas <span>{get(record, 'coordenador.enviadas')}</span></li>
                                    <li>Respondidas <span>{get(record, 'coordenador.respondidas')}</span></li>
                                    <li>Pendentes <span>{get(record, 'coordenador.pendentes')}</span></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        ))}
                    </div>
                  </div>
                </Fragment>
              )
            }
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}
export default ListaCorrecao;
