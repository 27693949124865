import React, { Fragment } from 'react';
import axios from 'axios';
import Footer from '../components/Footer';
import { handleRequestError } from '../services/utils';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services';

class DadosPessoais extends React.Component {
  state = {
    foto: '',
    nome: '',
    localizacao: '',
    time: '',
    total_correcoes: '',
    telefone: '',
    email: '',
  }

  componentDidMount() {
    this.fetchDadosPessoais();
  }

  fetchDadosPessoais() {
    axios.get(`${API_URL}/dados-pessoais`, getAxiosConfig())
      .then(response => this.setState({ ...response.data }))
      .catch(handleRequestError);
  }

  render() {
    const {
      foto,
      nome,
      localizacao,
      time,
      total_correcoes,
      telefone,
      email,
    } = this.state;
    return (
      <Fragment>
        <div className="container">

          <section>
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <h1 className="text-title">Dados Pessoais</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 body-dados">
                <img src={foto} alt={`Foto de ${nome}`} />
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 body-dados">
                <h2 className="text-name">{nome}
                  <span>
                    <i className="fas fa-map-marker-alt" />

                  </span>
                  <span>{localizacao}</span>
                </h2>
                <p className="text-occupation">TIME: {time}</p>
                <button type="button" className="btn btn-primary bg-default-color">ALTERAR SENHA</button>
                <br />
                <span><button type="button" className="btn btn-disabled"><span>TOTAL DE CORREÇÕES: </span><span>{total_correcoes}</span></button></span>
                <h3>
                  <i className="fas fa-user" />
                  <span>
                  Informações de contato
                  </span>
                </h3>
                <div className="body-dados--contato">
                  <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <p>Telefone:</p>
                      </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 pull-left">
                      <p className="text-default-blue">{telefone}</p>
                    </div>
                  </div>
                  <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <p>Email:</p>
                      </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 pull-left">
                      <p className="text-default-blue">{email}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default DadosPessoais;
