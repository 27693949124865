import React from 'react';
import { EnviarSairButton, EnviarBuscarMaisUmButton } from '../../..';
import dictionary from '../../../../dictionary';

const TicketContentSection = ({
  title,
  children,
  onSendTicket,
  onChangeTicketContentType,
  ticketContentType,
  isSendButtonDisabled,
  sendingTicket,
}) => (
  <>
    <div className="card-body">
      <div className="card-body-title">
        {title}
      </div>
      <div className="card-body-form">
        {children}
      </div>
    </div>
      <div className="p-2">
        <div className="row mb-2">
          <div className="col-md-6 pr-1">
            <EnviarSairButton
              onClick={() => onSendTicket({ sair: true })}
              disabled={sendingTicket || !isSendButtonDisabled()}
              clickOnDisabledMessage={dictionary.POST_OCORRENCIA_PREENCHER_CAMPOS}
            />
          </div>
          <div className="col-md-6 pl-1">
            <EnviarBuscarMaisUmButton
              onClick={() => onSendTicket()}
              disabled={sendingTicket || !isSendButtonDisabled()}
              clickOnDisabledMessage={dictionary.POST_OCORRENCIA_PREENCHER_CAMPOS}
            />
          </div>
          {
            ticketContentType && (
            <div className="col-md-12">
              <button
                type="button"
                onClick={() => onChangeTicketContentType(null)}
                className="btn bg-warning-color mt-2 mb-2"
              >
                CANCELAR
              </button>
            </div>
          )
          }
        </div>
      </div>
  </>
);

export default TicketContentSection;
