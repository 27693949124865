import React, { Component } from 'react';
import axios from 'axios';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as pages from '.';
import * as relatorios from './relatorios';
import {
  PrivateRoute,
  Chat,
} from '../components';
import { API_URL } from '../consts';
import { getAxiosConfig, isAuthenticated } from '../services';
import AvaliadoresPage from './v2/AvaliadoresPage';
import { getError } from '../services/utils';
import RedirecionamentoAuxiliar4aCorrecao from '../components/RedirecionamentoAuxiliar4aCorrecao';
import { toastConfig } from '../utils';
import SwitchRelatorio from '../components/v2/relatorio/SwitchRelatorio';
import QuartaCorrecaoAuxiliar from './QuartaCorrecaoAuxiliar';
import BancoRedacoesPage from './v2/BancoRedacoesPage';
import AdminRouter from './admin/Router';
import RecursosPage from './v2/recursos/RecursosPage';
import RecursosListPage from './v2/recursos/RecursosListPage';

export const UserContext = React.createContext({
  user: {},
  features: [],
  messages: {},
  chat: {},
  projeto: {},
});

class Main extends Component {
  constructor(props) {
    super(props);
    this.onLogout = this.onLogout.bind(this);

    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common.Authorization = `JWT ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  }

  state = {
    user: {},
    features: [],
    chat: {},
    mainLoading: true,
    messages: {},
    projeto: {},
  }

  componentDidMount() {
    if (isAuthenticated()) {
      const axiosConfig = getAxiosConfig();
      axios.all([
        axios.get(`${API_URL}/permissions`, axiosConfig),
        axios.get(`${API_URL}/features`, axiosConfig),
        axios.get(`${API_URL}/mensagens/formatted`, axiosConfig),
        axios.get(`${API_URL}/projeto/atual`, axiosConfig),
      ])
        .then(axios.spread((
          permissionsResponse,
          featuresResponse,
          messagesResponse,
          projetoReponse,
        ) => {
          this.setState({
            user: permissionsResponse.data,
            features: featuresResponse.data.features,
            messages: messagesResponse.data,
            projeto: projetoReponse.data,
            mainLoading: false,
          });

          if (featuresResponse.data.features.chat) {
            this.fetchChat();
          }
        }))
        .catch((error) => {
          const { history } = this.props;
          const { status, data } = getError(error);
          if (status === 403 || status === 401) {
              history.push('/login');
              toast.error(data.detail, toastConfig);
          } else {
            history.push('/');
            this.setState({ mainLoading: false });
          }
          if (status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('authorized');
          }
        });
    }
  }

  onLogout() {
    this.setState({
      user: {},
      features: [],
      chat: {},
      messages: {},
      projeto: {},
      mainLoading: false,
    });
  }

  fetchChat() {
    axios.get(`${API_URL}/chat/inbox`, getAxiosConfig())
      .then((response) => {
        const {
          current_user,
          records,
          responsavel,
          signature,
          chat_disabled,
        } = response.data;
        if (!chat_disabled) {
          this.setState({
            chat: {
              current_user,
              signature,
              responsavel,
              contacts: records,
              loading: false,
            },
          });
        }
      });
  }

  render() {
    const {
      user, features, messages, projeto, chat, mainLoading,
    } = this.state;
    const { onLogout } = this;

    return (
      <UserContext.Provider value={{
        user, features, chat, onLogout, projeto, messages,
      }}
      >
        <RedirecionamentoAuxiliar4aCorrecao user={user}>
          {
            isAuthenticated()
              ? !mainLoading && (
                <React.Fragment>
                  <Chat user={user} features={features} chat={chat} />
                  <AdminRouter />
                  <Switch>
                    <PrivateRoute
                      path="/manutencao"
                      component={pages.Manutencao}
                    />
                    <PrivateRoute
                      path="/configurar-alertas"
                      permission="configurar_alertas"
                      component={pages.ConfigurarAlertasPage}
                    />
                    <PrivateRoute
                      path="/v2/avaliadores"
                      permission="ver_menu_avaliadores"
                      component={AvaliadoresPage}
                    />
                    <PrivateRoute
                      path="/supervisores"
                      permission="ver_menu_supervisores"
                      component={pages.SupervisoresPage}
                    />
                    <PrivateRoute
                      exact
                      path="/"
                      component={pages.DashboardCorretor}
                    />
                    <PrivateRoute
                      exact
                      path="/correcoes"
                      permission="ver_menu_correcoes"
                      component={pages.ListaCorrecao}
                    />
                    <PrivateRoute
                      path="/correcoes/:id"
                      permission="ver_menu_correcoes"
                      component={pages.Correcao}
                    />
                    <PrivateRoute
                      exact
                      path="/tickets"
                      permission="ver_menu_ocorrencias"
                      component={pages.ListaOcorrencia}
                    />
                    <PrivateRoute
                      exact
                      path="/tickets/encaminhadas"
                      permission="ver_menu_ocorrencias"
                      component={pages.ListaOcorrencia}
                    />
                    <PrivateRoute
                      path="/tickets/:id"
                      permission="ver_menu_ocorrencias"
                      component={pages.Ocorrencia}
                    />
                    <PrivateRoute
                      exact
                      path="/avaliadores"
                      permission="ver_menu_avaliadores"
                      component={pages.ListaAvaliadores}
                    />
                    <PrivateRoute
                      exact
                      path="/avisos"
                      permission="ver_menu_avisos"
                      component={pages.CentralAvisos}
                    />
                    <PrivateRoute
                      path="/avisos/:id"
                      permission="ver_menu_avisos"
                      component={pages.NovoAviso}
                    />
                    <PrivateRoute
                      path="/resumo-correcoes/:id"
                      permission="ver_menu_resumo_correcoes"
                      component={pages.TabelaResumoCorrecoes}
                    />
                    <PrivateRoute
                      path="/resumo-correcoes"
                      permission="ver_menu_resumo_correcoes"
                      component={pages.ListaResumoCorrecoes}
                    />
                    <PrivateRoute
                      path="/banco-redacoes"
                      permission="ver_menu_redacoes"
                      component={BancoRedacoesPage}
                    />
                    <PrivateRoute
                      exact
                      path="/triagem-fea"
                      permission="participar_triagem_fea"
                      component={pages.ListaTriagemFea}
                    />
                    <PrivateRoute
                      path="/triagem-fea/:id"
                      permission="participar_triagem_fea"
                      component={pages.TriagemFea}
                    />
                    <PrivateRoute
                      exact
                      permission="ver_relatorio_extrato"
                      path="/relatorios/extrato"
                      component={relatorios.CorrecoesAvaliador}
                    />
                    <PrivateRoute
                      permission="ver_relatorio_extrato"
                      path="/relatorios/extrato/:id"
                      component={relatorios.ResumoAvaliador}
                    />
                    <PrivateRoute
                      shouldRenderMenu={false}
                      permission="redirecionar_auxiliar_4a_correcao"
                      path="/quarta-correcao-auxiliar/:correcaoId"
                      component={props => <QuartaCorrecaoAuxiliar {...props} navbar={false} />}
                    />
                    <PrivateRoute
                      exact
                      path="/recursos/:provaId"
                      component={RecursosPage}
                    />
                    <PrivateRoute
                      exact
                      path="/recursos"
                      component={RecursosListPage}
                    />
                    <SwitchRelatorio />
                    <Redirect to="/404" />
                  </Switch>
                </React.Fragment>
              ) : <Redirect to="/login" />
          }
        </RedirecionamentoAuxiliar4aCorrecao>
      </UserContext.Provider>
    );
  }
}

export default withRouter(Main);
