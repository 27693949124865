import PropType from 'prop-types';
import React from 'react';
import shortid from 'shortid';
import { Loading } from '../..';
import HistoricoCorrecao from './HistoricoCorrecao';
import HistoricoCorrecoesNotaFinal from './HistoricoCorrecoesNotaFinal';

const HistoricoCorrecoes = ({
  historicoCorrecoes, verNomeAvaliadorHistorico, fetching, showNotaFinal, redacao, verHeader,
  forceShowSituacao,
}) => (
  <div className={verHeader ? 'card card-correcao' : ''}>
    <div id="correcao-historico" className="collapse">
      {fetching && <Loading />}
      {(!fetching && historicoCorrecoes.length > 0) && (
        <>
          <div className="mt-3 mb-3 text-center">
            {historicoCorrecoes.map((correcao, index) => (
              <HistoricoCorrecao
                key={shortid.generate()}
                correcao={correcao}
                index={index}
                verNomeAvaliadorHistorico={verNomeAvaliadorHistorico}
                verHeader={verHeader}
              />
            ))}
          </div>
        </>
      )}
      <HistoricoCorrecoesNotaFinal showNotaFinal={showNotaFinal} forceShowSituacao={forceShowSituacao} redacao={redacao} />
    </div>
  </div>
);

HistoricoCorrecoes.propTypes = {
  verNomeAvaliadorHistorico: PropType.bool.isRequired,
  forceShowSituacao: PropType.bool.isRequired,
  fetching: PropType.bool.isRequired,
  historicoCorrecoes: PropType.arrayOf(PropType.shape({
    id: PropType.number,
    tipo: PropType.string,
    avaliador: PropType.string,
  })).isRequired,
  showNotaFinal: PropType.bool.isRequired,
  redacao: PropType.shape({
    nota: PropType.oneOfType([
      PropType.number,
      PropType.string,
    ]),
    situacao: PropType.string,
  }).isRequired,
  verHeader: PropType.bool,
};

HistoricoCorrecoes.defaultProps = {
  verHeader: true,
};

export default HistoricoCorrecoes;
