import React, { Component } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import * as pages from "./pages";
import { Manutencao } from "./pages";
import Main from "./pages/Main";
import { BASE_URL } from "./consts";
import "./register-css";
import VistaProvaExternaIndex from "./components/v2/vista-de-prova/VistaProvaExternIndex";

class App extends Component {
  render() {
    if (process.env.REACT_APP_EM_MANUTENCAO) {
      return <Manutencao />;
    }

    return (
      <BrowserRouter basename={BASE_URL}>
        <React.Fragment>
          <ToastContainer />
          <Switch>
            <Route
              path="/login-redirect/:token"
              component={pages.LoginRedirect}
            />
            <Route path="/404" component={pages.NotFound} />
            <Route path="/login/confirmation" component={pages.Login2} />
            <Route path="/login" component={pages.Login} />
            <Route
              path="/navegador-incompativel"
              component={pages.NavegadorIncompativel}
            />
            <Route path="/preteste" component={pages.RedirectPreTeste} />
            <Route
              path="/vista-prova-externa/:token/projeto/:projetoId"
              component={pages.VistaProvaExternaPage}
            />
            <Route
              path="/vista-prova-externa/:token"
              component={VistaProvaExternaIndex}
            />

            <Main />
            <Redirect to="/404" />
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

export default App;
