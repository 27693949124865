import React, { useContext } from 'react';
import { get } from 'lodash';
import BancoRedacoesContext from '../../../contexts/BancoRedacoesContext';

const ImagemRedacaoTitle = () => {
  const { redacao, verDadosCandidato } = useContext(BancoRedacoesContext);
  return (
    <>
      {redacao.candidato ? (
        <>
          <p>
            Código imagem <strong>{`#${redacao.id || ''}`}</strong>
          </p>
          <p>
            <strong>Inscrito: </strong> {get(redacao, 'candidato.nome')}
          </p>
          {
            verDadosCandidato && (
              <>
                <p>
                  <strong>CPF: </strong> {get(redacao, 'candidato.cpf')}
                </p>
                <p>
                  <strong>Inscrição: </strong> {get(redacao, 'candidato.co_inscricao')}
                </p>
              </>
            )
          }
        </>
      ) : (
        <p>Código imagem <strong>- {`#${redacao.id || ''}`}</strong></p>
      )}
    </>
  );
};

export default ImagemRedacaoTitle;
