import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';
import DistribuicaoNotasSituacaoGeral from '../../../pages/v2/relatorios/distribuicao_notas/DistribuicaoNotasSituacaoGeral';
import DistribuicaoNotasSituacaoPolo from '../../../pages/v2/relatorios/distribuicao_notas/DistribuicaoNotasSituacaoPolo';
import DistribuicaoNotasSituacaoTime from '../../../pages/v2/relatorios/distribuicao_notas/DistribuicaoNotasSituacaoTime';
import DistribuicaoNotasCompetenciaGeral from '../../../pages/v2/relatorios/distribuicao_notas_competencia/DistribuicaoNotasCompetenciaGeral';
import DistribuicaoNotasCompetenciaPolo from '../../../pages/v2/relatorios/distribuicao_notas_competencia/DistribuicaoNotasCompetenciaPolo';
import DistribuicaoNotasCompetenciaTime from '../../../pages/v2/relatorios/distribuicao_notas_competencia/DistribuicaoNotasCompetenciaTime';
import PadraoOuroGeral from '../../../pages/v2/relatorios/padrao_ouro/PadraoOuroGeral';
import PadraoOuroPolo from '../../../pages/v2/relatorios/padrao_ouro/PadraoOuroPolo';
import PadraoOuroTime from '../../../pages/v2/relatorios/padrao_ouro/PadraoOuroTime';
import PadraoOuroAvaliador from '../../../pages/v2/relatorios/padrao_ouro/PadraoOuroAvaliador';
import AproveitamentoNotasGeral from '../../../pages/v2/relatorios/aproveitament-notas/AproveitamentoNotasGeral';
import AproveitamentoNotasPolo from '../../../pages/v2/relatorios/aproveitament-notas/AproveitamentoNotasPolo';
import AproveitamentoNotasTime from '../../../pages/v2/relatorios/aproveitament-notas/AproveitamentoNotasTime';
import AproveitamentoNotasAvaliador from '../../../pages/v2/relatorios/aproveitament-notas/AproveitamentoNotasAvaliador';
import PanoramaGeralOcorrencias from '../../../pages/v2/relatorios/panorama-geral-ocorrencias/PanoramaGeralOcorrencias';
import PanoramaGeralOcorrenciasSupervisor from '../../../pages/v2/relatorios/panorama-geral-ocorrencias/PanoramaGeralOcorrenciasSupervisor';
import AcompanhamentoGeral from '../../../pages/v2/relatorios/acompanhamento_correcoes/AcompanhamentoGeral';
import AcompanhamentoAuditoria from '../../../pages/v2/relatorios/acompanhamento-auditoria/AcompanhamentoAuditoria';
import QuartaCorrecaoPolo from '../../../pages/v2/relatorios/quarta_correcao/QuartaCorrecaoPolo';
import QuartaCorrecaoGeral from '../../../pages/v2/relatorios/quarta_correcao/QuartaCorrecaoGeral';
import TerceiraCorrecaoGeral from '../../../pages/v2/relatorios/terceira_correcao/TerceiraCorrecaoGeral';
import TerceiraCorrecaoPolo from '../../../pages/v2/relatorios/terceira_correcao/TerceiraCorrecaoPolo';
import TerceiraCorrecaoAvaliador from '../../../pages/v2/relatorios/terceira_correcao/TerceiraCorrecaoAvaliador';
import TerceiraCorrecaoTime from '../../../pages/v2/relatorios/terceira_correcao/TerceiraCorrecaoTime';
import RelatorioGeral from '../../../pages/v2/relatorios/relatorio_geral/RelatorioGeral';
import RelatorioGeralPolo from '../../../pages/v2/relatorios/relatorio_geral/RelatorioGeralPolo';
import RelatorioGeralTime from '../../../pages/v2/relatorios/relatorio_geral/RelatorioGeralTime';


class SwitchRelatorio extends React.Component {
  render() {
    return (
      <Switch>
        <PrivateRoute
          permission="ver_relatorio_acompanhamento_geral"
          path="/relatorios/v2/acompanhamento-geral"
          component={AcompanhamentoGeral}
        />
        <PrivateRoute
          permission="ver_relatorio_distribuicao_notas_situacao_geral"
          path="/relatorios/v2/distribuicao-notas-situacao-polo"
          component={DistribuicaoNotasSituacaoGeral}
        />
        <PrivateRoute
          permission="ver_relatorio_distribuicao_notas_situacao_polo"
          path="/relatorios/v2/distribuicao-notas-situacao-time"
          component={DistribuicaoNotasSituacaoPolo}
        />
        <PrivateRoute
          permission="ver_relatorio_distribuicao_notas_situacao_time"
          path="/relatorios/v2/distribuicao-notas-situacao-avaliadores"
          component={DistribuicaoNotasSituacaoTime}
        />
        <PrivateRoute
          permission="ver_relatorio_distribuicao_notas_competencia_geral"
          path="/relatorios/v2/distribuicao-notas-competencia-polo"
          component={DistribuicaoNotasCompetenciaGeral}
        />
        <PrivateRoute
          permission="ver_relatorio_distribuicao_notas_competencia_polo"
          path="/relatorios/v2/distribuicao-notas-competencia-time"
          component={DistribuicaoNotasCompetenciaPolo}
        />
        <PrivateRoute
          permission="ver_relatorio_distribuicao_notas_competencia_time"
          path="/relatorios/v2/distribuicao-notas-competencia-avaliadores"
          component={DistribuicaoNotasCompetenciaTime}
        />
        <PrivateRoute
          permission="ver_relatorio_padrao_ouro_geral"
          path="/relatorios/v2/padrao-ouro-polo"
          component={PadraoOuroGeral}
        />
        <PrivateRoute
          permission="ver_relatorio_padrao_ouro_polo"
          path="/relatorios/v2/padrao-ouro-time"
          component={PadraoOuroPolo}
        />
        <PrivateRoute
          permission="ver_relatorio_padrao_ouro_time"
          path="/relatorios/v2/padrao-ouro-avaliadores"
          component={PadraoOuroTime}
        />
        <PrivateRoute
          permission="ver_relatorio_padrao_ouro_avaliador"
          path="/relatorios/v2/padrao-ouro-individual"
          component={PadraoOuroAvaliador}
        />
        <PrivateRoute
          permission="ver_relatorio_aproveitamento_geral"
          path="/relatorios/v2/aproveitamento-polo"
          component={AproveitamentoNotasGeral}
        />
        <PrivateRoute
          permission="ver_relatorio_aproveitamento_polo"
          path="/relatorios/v2/aproveitamento-time"
          component={AproveitamentoNotasPolo}
        />
        <PrivateRoute
          permission="ver_relatorio_aproveitamento_time"
          path="/relatorios/v2/aproveitamento-avaliadores"
          component={AproveitamentoNotasTime}
        />
        <PrivateRoute
          permission="ver_relatorio_aproveitamento_avaliador"
          path="/relatorios/v2/aproveitamento-individual"
          component={AproveitamentoNotasAvaliador}
        />
        <PrivateRoute
          permission="ver_relatorio_acompanhamento_auditoria"
          path="/relatorios/v2/acompanhamento-auditoria"
          component={AcompanhamentoAuditoria}
        />
        <PrivateRoute
          permission="ver_relatorio_quarta_correcao_polo"
          path="/relatorios/v2/quarta-correcao-por-polo"
          component={QuartaCorrecaoPolo}
        />
        <PrivateRoute
          permission="ver_relatorio_quarta_correcao_geral"
          path="/relatorios/v2/quarta-correcao-geral"
          component={QuartaCorrecaoGeral}
        />
        <PrivateRoute
          permission="ver_relatorio_terceira_correcao_geral"
          path="/relatorios/v2/terceira-correcao-geral"
          component={TerceiraCorrecaoGeral}
        />
        <PrivateRoute
          permission="ver_relatorio_terceira_correcao_polo"
          path="/relatorios/v2/terceira-correcao-polo"
          component={TerceiraCorrecaoPolo}
        />
        <PrivateRoute
          permission="ver_relatorio_terceira_correcao_time"
          path="/relatorios/v2/terceira-correcao-time"
          component={TerceiraCorrecaoTime}
        />
        <PrivateRoute
          permission="ver_relatorio_terceira_correcao_avaliador"
          path="/relatorios/v2/terceira-correcao-avaliador"
          component={TerceiraCorrecaoAvaliador}
        />
        <PrivateRoute
          permission="ver_relatorio_geral"
          path="/relatorios/v2/relatorio-geral-polo"
          component={RelatorioGeral}
        />
        <PrivateRoute
          permission="ver_relatorio_geral_polo"
          path="/relatorios/v2/relatorio-geral-time"
          component={RelatorioGeralPolo}
        />
        <PrivateRoute
          permission="ver_relatorio_geral_time"
          path="/relatorios/v2/relatorio-geral-avaliador"
          component={RelatorioGeralTime}
        />
        <PrivateRoute
          permission="ver_relatorio_panorama_geral_ocorrencias"
          path="/relatorios/v2/panorama-geral-ocorrencias"
          component={PanoramaGeralOcorrencias}
        />
        <PrivateRoute
          permission="ver_relatorio_panorama_geral_ocorrencias_supervisor"
          path="/relatorios/v2/panorama-geral-ocorrencias-supervisor"
          component={PanoramaGeralOcorrenciasSupervisor}
        />
      </Switch>
    );
  }
}

export default SwitchRelatorio;
