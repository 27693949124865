import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';


const SelectInput = ({
    label, placeholder, noOptionsMessage, error, ...rest
  }) => (
  <div className="input-select-v2">
    <label className="mt-2 mr-4 label-min-width">{label}</label>
    <Select
      placeholder={placeholder}
      isClearable
      isSearchable
      className="w-100"
      classNamePrefix={error ? 'input-select--has-error' : null}
      noOptionsMessage={() => noOptionsMessage}
      {...rest}
    />
    {error && <small className={error ? 'helper--has-error' : null}>{error}</small>}
  </div>
);

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  error: PropTypes.string,
};

SelectInput.defaultProps = {
  placeholder: 'Selecione',
  noOptionsMessage: 'Nenhum registro encontrado',
  error: null,
};

export default SelectInput;
