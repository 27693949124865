import React, { useContext } from 'react';
import { get } from 'lodash';
import shortid from 'shortid';
import HistoricoCorrecaoContext from '../../../contexts/HistoricoCorrecaoContext';
import BancoRedacoesContext from '../../../contexts/BancoRedacoesContext';


const HistoricoCorrecaoContent = () => {
  const { correcao } = useContext(HistoricoCorrecaoContext);
  const { desvios } = useContext(BancoRedacoesContext);

  return (
    <>
      <table className="table-tag">
        <tbody>
          <tr className="table-header">
            <th className="table-data">Soma</th>
            {desvios.map(desvio => (<th className="table-data" key={shortid.generate()}>{desvio.descricao}</th>))}
          </tr>
          {
            !correcao.show_situacao
            && (
              <tr className="table-row">
                <td className="table-data">{correcao.nota_final}</td>
                {desvios.map(desvio => (<th className="table-data" key={shortid.generate()}>{get(correcao.desvios.find(d => d.descricao === desvio.descricao), 'nota', desvio.total_pontos)}</th>))}
              </tr>
            )
          }
        </tbody>
      </table>
      { correcao.show_situacao && <span className="table-situacao">{correcao.situacao}</span> }
    </>
  );
};

export default HistoricoCorrecaoContent;
