import { SET_FORM_API, SET_FORM_ERRORS, SET_FORM_STATE, SET_PROVA_DATA, SET_PROVA_LOADING, SET_RECURSOS_DATA, SET_RECURSOS_LOADING, SET_SUBMIT, SET_TIPOS_DEFERIMENTO, SET_UPDATED_RECURSO_DATA } from './types';

const RecursoReducer = (state, action) => {
  switch (action.type) {
    case SET_PROVA_LOADING:
      return {
        ...state,
        loadingProva: action.payload,
      };

    case SET_PROVA_DATA:
      return {
        ...state,
        prova: action.payload,
        loadingProva: false,
      };

    case SET_RECURSOS_LOADING:
      return {
        ...state,
        loadingRecurso: action.payload,
      };

    case SET_RECURSOS_DATA:
      return {
        ...state,
        recurso: action.payload,
        loadingRecurso: false,
      };

    case SET_SUBMIT:
      return {
        ...state,
        submitting: action.payload,
      };

    case SET_UPDATED_RECURSO_DATA:
      return {
        ...state,
        recurso: {
          ...state.recurso,
          qna: state.recurso.push(action.payload),
          submitting: false,
        },
      };

      case SET_FORM_ERRORS:
        return {
          ...state,
          errors: {
            ...state.errors,
            ...action.payload,
          },
        };

        case SET_FORM_API:
          return {
            ...state,
            formApi: action.payload,
          };

        case SET_FORM_STATE:
          return {
            ...state,
            formState: action.payload,
          };

      case SET_TIPOS_DEFERIMENTO:
        return {
          ...state,
          tiposDeferimento: action.payload,
        };

    default:
      return state;
  }
};

export default RecursoReducer;
