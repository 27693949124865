import PropTypes from 'prop-types';
import React from 'react';

const Status = ({ status }) => {
  const {
    icon, color, className, nome,
  } = status;

  return (
    <div>
      <i className={`mr-1 ${className} fas ${icon || 'fas-circle'}`} style={color ? { color } : {}} />
      {nome}
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.shape({
    icon: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    nome: PropTypes.string,
  }),
};

Status.defaultProps = {
  status: {},
};

export default Status;
