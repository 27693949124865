import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { toastConfig } from '../utils';

class PularSairButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { onClick, disabled, clickOnDisabledMessage } = this.props;
    if (disabled && clickOnDisabledMessage) {
      toast.warning(clickOnDisabledMessage, toastConfig);
    } else if (onClick) {
      onClick();
    }
  }

  render() {
    const { disabled } = this.props;

    return (
      <button
        type="button"
        className={`btn btn-primary bg-default-color-pular-sair ${disabled ? 'disabled' : ''}`}
        onClick={this.handleClick}
      >
        PULAR E SAIR
      </button>
    );
  }
}

PularSairButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  clickOnDisabledMessage: PropTypes.string,
};

PularSairButton.defaultProps = {
  disabled: false,
  onClick: null,
  clickOnDisabledMessage: '',
};

export default PularSairButton;
