import React from 'react';
import axios from 'axios';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services/auth';
import { ComponentBase } from '../base';

class ContadorCorrecao extends ComponentBase {
  constructor(props) {
    super(props);
    this.state = {
      corrigidas: 0,
      total_correcoes: 0,
      fetching: true,
    };
  }

  componentDidMount() {
    this.fetchCorrigidas();
  }

  fetchCorrigidas() {
    axios.get(`${API_URL}/correcoes/`, getAxiosConfig())
    .then((response) => {
      const { ...corrigidas } = response.data.records;
      this.setState({
        corrigidas: corrigidas[0].total_corrigidas,
        total_correcoes: corrigidas[0].total_correcoes,
        fetching: false,
      });
    });
  }

  deveExibirMensagemExercicioCompleto = () => !this.state.fetching && this.state.corrigidas === this.state.total_correcoes;

  render() {
    const { corrigidas, total_correcoes } = this.state;

    return (
      <div className="panel-body">
        <p>
          {this.getMessage('CONTADOR_CORRECOES')}
        </p>
        <div className="text-num-correcoes-feita">
          <center>
            <article className="text-center mt-4 mb-4">
              <span className="destaque">
                {corrigidas}
              </span>
              <span>
                / {total_correcoes}
              </span>
              <p>Redações Corrigidas</p>
              {this.deveExibirMensagemExercicioCompleto() && <p className="exercicio-completo-message">{this.getMessage('CONTADOR_EXERCICIO_FINALIZADO')}</p>}
            </article>
          </center>
        </div>
      </div>
    );
  }
}

export default ContadorCorrecao;
