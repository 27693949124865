import React from 'react';
import PropTypes from 'prop-types';

const getErrorList = (errors) => {
  let messages = [];
  Object.keys(errors).forEach((error) => { messages = messages.concat(errors[error]); });
  return messages;
};

const ErrorList = ({ errors }) => (
  <ul>
    {getErrorList(errors).map(message => <li key={message}>{message}</li>)}
  </ul>
);

ErrorList.propTypes = {
  errors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
};

ErrorList.defaultProps = {
  errors: {},
};

export default ErrorList;
