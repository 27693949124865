import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Rodal from 'rodal';
import 'react-table/react-table.css';
import queryString from 'query-string';
import axios from 'axios';
import { get } from 'lodash';
import dictionary from '../dictionary';
import {
  Footer,
  ErrorList,
  Loading,
} from '../components';
import { getAxiosConfig } from '../services/auth';
import Redacao from '../containers/Redacao';
import { API_URL } from '../consts';
import { ComponentBase } from '../base';
import { getError } from '../services/utils';
import { toastConfig } from '../utils';

class TabelaResumoCorrecoes extends ComponentBase {
  constructor(props) {
    super(props);
    this.state = {
      notaAvaliadores: [],
      loadingNotas: true,
      nome: '',
      loadingRedacao: false,
      isOpen: false,
      src: '',
    };
    this.handleError = this.handleError.bind(this);
    this.fetchNotaAvaliador = this.fetchNotaAvaliador.bind(this);
    this.styleBorder = { borderRight: "2px solid white" };
  }

  handleError(error) {
    const { history } = this.props;
    const { status, data } = getError(error);
    switch (status) {
      case 400:
        toast.error(<ErrorList errors={data} />, toastConfig);
        break;
      case 401:
      case 403:
      case 404:
        const { location } = this.props;
        const { next } = queryString.parse(location.search);
        if (next) {
          toast.error('Usuário ainda não possui um resumo de correções.', toastConfig);
          history.push(next);
          break;
        }
        history.push('/404');
        break;
      default:
        toast.error(dictionary.ERRO_DESCONHECIDO, toastConfig);
    }
  }

  componentDidMount() {
    this.fetchNotaAvaliador();
  }

  get next() {
    const { location } = this.props;
    const { next } = queryString.parse(location.search);
    return next || '/resumo-correcoes';
  }

  fetchNotaAvaliador() {
    const { match: { params: { id } } } = this.props;
    axios.get(`${API_URL}/correcoes/resumo/${id}`, getAxiosConfig())
    .then((response) => {
      const { analises, nome } = response.data;
      this.setState({
        nome,
        notaAvaliadores: analises,
        loadingNotas: false,
      });
    }).catch(this.handleError);
  }

  fetchRedacao = async (redacao_id) => {
    const config = {
      ...getAxiosConfig(),
      params: {
        mascara_redacao: redacao_id,
      },
    };
    this.setState(prevState => ({
      loadingRedacao: { ...prevState.loadingRedacao, [redacao_id]: true },
    }));
    const res = await axios.get(`${API_URL}/v2/banco-redacoes`, config);
    const src = get(res, 'data.src');
    if (!src) {
      toast.error('Ocorreu um erro ao carregar a imagem.');
    }
    this.setState(prevState => ({
      src,
      isOpen: src !== null,
      fetchImagemError: src === null,
      loadingRedacao: { ...prevState.loadingRedacao, [redacao_id]: false },
    }));
  }

  renderRedacao() {
    const {
      src,
      fetchImagemError,
    } = this.state;
    if (fetchImagemError) {
      return null;
    }

    if (!src) {
      return null;
    }

    return (
      <div style={{ margin: '40px' }}>
        <Redacao
          ref={(node) => {
            this.redacao = node;
          }}
          scrollTools
          src={src}
          show
          fetchImagemError={fetchImagemError}
        />
      </div>
    );
  }

  render() {
    const { notaAvaliadores, nome, loadingRedacao, isOpen, src } = this.state;
    const { history } = this.props;
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="page-container-border">
            <section>
              <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                  <h1 className="text-title">Resumo das Correções</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-3">
                  {
                    this.state.loadingNotas ? (
                      <Fragment>
                        <Loading />
                      </Fragment>
                    ) : (
                      <ReactTable
                        data={notaAvaliadores}
                        previousText=""
                        nextText=""
                        pageText="Exibindo"
                        ofText="de"
                        rowsText="registros"
                        defaultPageSize={30}
                        resizable={false}
                        pageSizeOptions={[5, 10, 20, 25, 30]}
                        className=" -highlight table-resumo-correcoes"
                        columns={[
                          {
                            Header: nome,
                            columns: [
                              {
                                id: 'id_row',
                                Header: '',
                                acessor: 'row',
                                minWidth: 75,
                                Cell: d => (
                                  <div className={d.original.id.classe}>{d.index + 1}</div>
                                ),
                              },
                              {
                                id: 'id_correcao',
                                Header: () => (
                                  <button className="btn btn-primary" type="button" onClick={() => history.push(this.next)}>
                                    <i className="fas fa-chevron-left" /> Voltar
                                  </button>
                                ),
                                acessor: 'id_prova',
                                minWidth: 280,
                                Cell: d => (
                                  <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() => this.fetchRedacao(get(d, 'original.redacao'))}
                                    disabled={loadingRedacao[d.original.redacao]}
                                  >
                                    {
                                      loadingRedacao[d.original.redacao]
                                      ? <i className="fas fa-spinner fa-pulse" />
                                      : d.original.redacao
                                    }
                                  </button>
                                ),
                              },
                            ],
                          },
                          {
                            Header: 'Correção',
                            columns: [
                              {
                                id: 'id_situacao',
                                Header: 'NOTA',
                                acessor: 'row',
                                maxWidth: 100,
                                show: this.hasFeature('mostrar_nota_correcao_resultado'),
                                Cell: d => (
                                  <div className={d.original.correcao.classe}>{d.original.nota}</div>
                                ),
                              },
                              {
                                id: 'id_situacao',
                                Header: 'SIT',
                                acessor: 'row',
                                maxWidth: 100,
                                Cell: d => (
                                  <div className={d.original.correcao.classe}>{d.original.correcao.situacao}</div>
                                ),
                              },
                              {
                                id: 'c1_0_0',
                                Header: 'CI',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.correcao.classe}>{d.original.correcao.c1}</div>
                                ),
                              },
                              {
                                id: 'c1_0_1',
                                Header: 'CII',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.correcao.classe}>{d.original.correcao.c2}</div>
                                ),
                              },
                              {
                                id: 'c1_0_2',
                                Header: 'CIII',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.correcao.classe}>{d.original.correcao.c3}</div>
                                ),
                              },
                              {
                                id: 'c1_0_3',
                                Header: 'CIV',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.correcao.classe}>{d.original.correcao.c4}</div>
                                ),
                              },
                              {
                                id: 'c1_0_4',
                                Header: 'CV',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.correcao.classe}>
                                    {d.original.correcao.c5 === -1 ? 0 : d.original.correcao.c5}
                                  </div>
                                ),
                              },
                              {
                                id: 'c1_0_ddh',
                                Header: 'DDH',
                                maxWidth: 60,
                                Cell: d => d.original.correcao.c5 === -1 && <i className="fas fa-check" />,
                              },
                              {
                                id: 'c1_0_SOMA',
                                Header: 'SOMA',
                                maxWidth: 80,
                                headerStyle: this.styleBorder,
                                style: this.styleBorder,
                                Cell: d => (
                                  <div className={d.original.correcao.classe}>{d.original.correcao.soma}</div>
                                ),

                              },
                            ],
                          },

                          {
                            Header: 'Referência',
                            columns: [
                              {
                                id: 'id_situacao',
                                Header: 'SIT',
                                acessor: 'row',
                                maxWidth: 100,
                                Cell: d => (
                                  <div className={d.original.referencia.classe}>{d.original.referencia.situacao}</div>
                                ),
                              },
                              {
                                id: 'c1_0_0',
                                Header: 'CI',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.referencia.classe}>{d.original.referencia.c1}</div>
                                ),
                              },
                              {
                                id: 'c1_0_1',
                                Header: 'CII',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.referencia.classe}>{d.original.referencia.c2}</div>
                                ),
                              },
                              {
                                id: 'c1_0_2',
                                Header: 'CIII',
                                maxWidth: 60,
                                Cell: d => (
                                <div className={d.original.referencia.classe}>{d.original.referencia.c3}</div>
                                ),
                              },
                              {
                                id: 'c1_0_3',
                                Header: 'CIV',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.referencia.classe}>{d.original.referencia.c4}</div>
                                ),
                              },
                              {
                                id: 'c1_0_4',
                                Header: 'CV',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.referencia.classe}>
                                    {d.original.referencia.c5 === -1 ? 0 : d.original.referencia.c5}
                                  </div>
                                ),
                              },
                              {
                                id: 'c1_0_ddh',
                                Header: 'DDH',
                                maxWidth: 60,
                                Cell: d => d.original.referencia.c5 === -1 && <i className="fas fa-check" />,
                              },
                              {
                                id: 'c1_0_SOMA',
                                Header: 'SOMA',
                                maxWidth: 80,
                                style: this.styleBorder,
                                headerStyle: this.styleBorder,
                                Cell: d => (
                                  <div className={d.original.referencia.classe}>{d.original.referencia.soma}</div>
                                ),
                              },
                            ],
                          },
                          {
                            Header: 'Diferença',
                            columns: [
                              {
                                id: 'id_situacao',
                                Header: 'SIT',
                                acessor: 'row',
                                maxWidth: 100,
                                Cell: d => (
                                  <div className={d.original.diferenca.classe}>{d.original.diferenca.situacao}</div>
                                ),
                              },
                              {
                                id: 'c1_0_0',
                                Header: 'CI',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.diferenca.classe}>{d.original.diferenca.c1}</div>
                                ),
                              },
                              {
                                id: 'c1_0_1',
                                Header: 'CII',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.diferenca.classe}>{d.original.diferenca.c2}</div>
                                ),
                              },
                              {
                                id: 'c1_0_2',
                                Header: 'CIII',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.diferenca.classe}>{d.original.diferenca.c3}</div>
                                ),
                              },
                              {
                                id: 'c1_0_3',
                                Header: 'CIV',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.diferenca.classe}>{d.original.diferenca.c4}</div>
                                ),
                              },
                              {
                                id: 'c1_0_4',
                                Header: 'CV',
                                maxWidth: 60,
                                Cell: d => (
                                  <div className={d.original.diferenca.classe}>{d.original.diferenca.c5}</div>
                                ),
                              },
                              {
                                id: 'c1_0_SOMA',
                                Header: 'SOMA',
                                maxWidth: 80,
                                style: this.styleBorder,
                                headerStyle: this.styleBorder,
                                Cell: d => (
                                  <div className={d.original.diferenca.classe}>{d.original.diferenca.soma}</div>
                                ),
                              },
                            ],
                          },
                          {
                            Header: 'Dados',
                            columns: [
                              {
                                id: 'data_inicio',
                                Header: 'Data Início',
                                acessor: 'row',
                                minWidth: 150,
                                Cell: d => (
                                  <div>{d.original.dados.data_inicio}</div>
                                ),
                              },
                              {
                                id: 'data_inicio',
                                Header: 'Data Término',
                                acessor: 'row',
                                minWidth: 150,
                                Cell: d => (
                                  <div>{d.original.dados.data_termino}</div>
                                ),
                              },
                              {
                                id: 'data_inicio',
                                Header: 'Duração',
                                acessor: 'row',
                                minWidth: 100,
                                Cell: d => (
                                  <div>{d.original.dados.duracao}</div>
                                ),
                              },
                            ],
                          },
                        ]}
                      />
                    )
                  }
                </div>
              </div>
              <Rodal
                visible={isOpen}
                onClose={() => this.setState({ isOpen: false, src: '' })}
                closeOnEsc
                className="responsabilidade-modal non-fixed-rodal"
              >
                {src && this.renderRedacao()}
              </Rodal>
            </section>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

TabelaResumoCorrecoes.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(TabelaResumoCorrecoes);
