import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../consts';
import ColumnRules from './v2/ColumnRules';

const UserImportColumnRules = () => {
  const [perfis, setPerfis] = useState([]);
  const [projetos, setProjetos] = useState([]);

  const rules = [
    {
      name: 'cpf',
      required: true,
      options: null,
    },
    {
      name: 'nome',
      required: true,
      options: null,
    },
    {
      name: 'email',
      required: true,
      options: null,
    },
    {
      name: 'funcao',
      required: true,
      options: perfis,
    },
    {
      name: 'projeto',
      required: false,
      options: projetos,
    },
    {
      name: 'time',
      required: false,
      options: ['O time deve ter sido previamente cadastrado ou estar incluído na planilha de times.'],
    },
    {
      name: 'ddd_celular',
      required: false,
      options: null,
    },
    {
      name: 'tel_celular',
      required: false,
      options: null,
    },
    {
      name: 'ddd_residencial',
      required: false,
      options: null,
    },
    {
      name: 'tel_residencial',
      required: false,
      options: null,
    },
    {
      name: 'logradouro',
      required: false,
      options: null,
    },
    {
      name: 'numero',
      required: false,
      options: null,
    },
    {
      name: 'complemento',
      required: false,
      options: null,
    },
    {
      name: 'bairro',
      required: false,
      options: null,
    },
    {
      name: 'cep',
      required: false,
      options: null,
    },
    {
      name: 'municipio',
      required: false,
      options: null,
    },
    {
      name: 'uf',
      required: false,
      options: null,
    },
  ];

  useEffect(() => {
    axios.get(`${API_URL}/v3/groups`)
      .then((response) => {
        setPerfis(response.data.map(d => d.name));
      });

    axios.get(`${API_URL}/projeto/all`)
      .then((response) => {
        setProjetos(response.data.map(d => d.descricao));
      });
  }, []);

  return <ColumnRules rules={rules} />;
};

export default UserImportColumnRules;
