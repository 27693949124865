import React from 'react';
import { API_URL } from '../../../../consts';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { ComponentBase } from '../../../../base';

class TerceiraCorrecaoGeral extends ComponentBase {
  get columns() {
    return [
      {
        header: '#',
        accessor: 'row',
        width: 50,
        Cell: row => <div>{row.index + 1}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Polo',
        withSortIcon: true,
        accessor: 'polo_descricao',
        next: {
          pathname: '/relatorios/v2/terceira-correcao-polo',
          stateFields: ['polo'],
        },
        Cell: d => <div className="column-left">{d.value}</div>,
        minWidth: 180,
        footer: {
          mediaNacional: {
            accessor: '-',
            default: 'Terceiras Nacional',
          },
        },
      },
      {
        header: 'Terceira corrigidas',
        accessor: 'terceiras_corrigidas',
        withSortIcon: true,
        defaultValue: 0,
        minWidth: 110,
        footer: {
          mediaNacional: {
            accessor: 'terceiras_corrigidas',
            default: '-',
          },
        },
      },
      {
        header: 'Terceiras aproveitadas',
        accessor: 'terceiras_aproveitadas',
        withSortIcon: true,
        defaultValue: 0,
        sortMethod: true,
        minWidth: 130,
        footer: {
          mediaNacional: {
            accessor: 'terceiras_aproveitadas',
            default: '-',
          },
        },
      },
      {
        header: 'Geraram 4ª correção',
        accessor: 'foram_quarta',
        withSortIcon: true,
        defaultValue: 0,
        sortMethod: true,
        minWidth: 130,
        footer: {
          mediaNacional: {
            accessor: 'foram_quarta',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitas após quarta correção',
        accessor: 'aproveitadas_quarta',
        withSortIcon: true,
        defaultValue: 0,
        sortMethod: true,
        minWidth: 130,
        footer: {
          mediaNacional: {
            accessor: 'aproveitadas_quarta',
            default: '-',
          },
        },
      },
      {
        header: 'Não aproveitas após quarta correção',
        accessor: 'nao_aproveitadas_quarta',
        withSortIcon: true,
        defaultValue: 0,
        sortMethod: true,
        minWidth: 130,
        footer: {
          mediaNacional: {
            accessor: 'nao_aproveitadas_quarta',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitamento dentro das que foram para quarta (%)',
        accessor: 'aproveitamento_quarta',
        withSortIcon: true,
        sortMethod: true,
        minWidth: 150,
        footer: {
          mediaNacional: {
            accessor: 'aproveitamento_quarta',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitamento dentro das terceiras corrigidas (%)',
        accessor: 'aproveitamento_terceira',
        withSortIcon: true,
        sortMethod: true,
        minWidth: 150,
        footer: {
          mediaNacional: {
            accessor: 'aproveitamento_terceira',
            default: '-',
          },
        },
      },
    ];
  }

  render() {
    const exportData = {
      url: `${API_URL}/v2/relatorios/terceira-correcao/geral/excel`,
      filename: 'terceira_correcao_geral.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        showAvaliadorInput={false}
        showTimeInput={false}
        title="TERCEIRA CORREÇÃO | Polos"
        dataUrl="v2/relatorios/terceira-correcao/geral"
        mediaNacionalUrl="v2/relatorios/terceira-correcao/geral/media"
        columns={this.columns}
        exportData={exportData}
        codigoMensagem="TERCEIRA_CORRECAO_POLOS"
        codigo="TERCEIRA_CORRECAO_GERAL"
        caption={{}}
      />
    );
  }
}

export default TerceiraCorrecaoGeral;
