import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import dictionary from '../dictionary';
import { getError } from '../services/utils';
import { ErrorList } from '../components';
import { toastConfig } from '../utils';
import { ComponentBase } from '../base';

class OcorrenciaBase extends ComponentBase {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.state = {
      loadingSelect: true,
      hierarquias: [],
      supervisor: [],
      filtroCoordenador: '',
      labelTabs: false,
    };
  }

  showCoordenador() {
    const { location } = this.props;
    if (location.state) {
      const { supervisor, id_usuario_responsavel } = location.state;
      const filtroCoordenador = { supervisor: id_usuario_responsavel };
      this.setState({
        supervisor: [supervisor],
        filtroCoordenador,
        labelTabs: true,
      });
    }
  }

  handleError(error) {
    const { history } = this.props;
    const { status, data } = getError(error);

    switch (status) {
      case 400:
        toast.error(<ErrorList errors={data} />, toastConfig);
        break;
      case 401:
      case 403:
        toast.error(
          <center>
            <i className="fas fa-exclamation-triangle" />
            <p className="mb-2 mt-2">{data.detail}</p>
          </center>,
          toastConfig,
        );
        history.push('/login');
        break;
      case 404:
        toast.error(dictionary.NENHUMA_OCORRENNCIA_DISPONIVEL, toastConfig);
        history.push('/tickets');
        break;
      default:
        toast.error(
        <center>
          <i className="fas fa-exclamation-triangle" />
          <p className="mb-2 mt-2">{dictionary.ERRO_DESCONHECIDO}</p>
        </center>,
        toastConfig,
      );
    }
  }
}

  OcorrenciaBase.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

export default OcorrenciaBase;
