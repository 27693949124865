import React from 'react';
import Rodal from 'rodal';
import PropTypes from 'prop-types';


class ModalTempoSessao extends React.Component {
  render() {
    const {
      visible,
      onClose,
      minutos,
      segundos,
      onConfirm,
      message,
    } = this.props;

    return (
        <Rodal visible={visible} onClose={onClose}>
          <div className="modalConfirmacao">
            <div className="boxTitulo">
              <h1 className="tituloConfirmacao">Tempo de sessão</h1>
            </div>
            <pre className="pre">
              <p className="mb-0">{message}</p>
              <p className="mt-2 mb-0">Sua sessão expira em:</p>
              <div style={{ fontSize: '60px' }}>
                {minutos}:{segundos}
              </div>
            </pre>
            <div>
              <div className="botoesConfirmacao mt-3">
                <button type="button" className="btn btn-primary botaoConfirmacao" onClick={onConfirm}>
                  Continuar no sistema de correção
                </button>
              </div>
            </div>
          </div>
        </Rodal>
      );
  }
}

ModalTempoSessao.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  minutos: PropTypes.string.isRequired,
  segundos: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string,
};

ModalTempoSessao.defaultProps = {
  message: '',
};

export default ModalTempoSessao;
