import React from 'react';
import { get } from 'lodash';
import { ChatInbox, PopupChat } from '.';
import { ComponentBase } from '../base';

class Chat extends ComponentBase {
    render() {
        const {
          responsavel,
          current_user,
          contacts,
          signature,
          loading,
        } = get(this.props, 'chat', {});
        if (!loading && signature) {
          if (current_user.inbox) {
            return <ChatInbox current_user={current_user} contacts={contacts} signature={signature} />;
          }
          return (
            <PopupChat
              current_user={current_user}
              responsavel={responsavel}
              signature={signature}
            />
          );
        }
        return null;
    }
}

export default Chat;
