import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../consts';
import ColumnRules from './ColumnRules';

const HierarquiaImportColumnRules = () => {
  const [tipos, setTipos] = useState([]);

  const rules = [
    {
      name: 'nome',
      required: true,
      options: null,
    },
    {
      name: 'tipo',
      required: true,
      options: tipos,
    },
    {
      name: 'time_pai',
      required: false,
      options: ['O nome do time responsável. O time deve já existir ou fazer parte da própria planilha.'],
    },
    {
      name: 'usuario_responsavel',
      required: false,
      options: ['O CPF do usuário responsável'],
    },
  ];

  useEffect(() => {
    axios.get(`${API_URL}/v3/tipo-hierarquia`)
      .then((response) => {
        setTipos(response.data.map(d => d.descricao));
      });
  }, []);

  return <ColumnRules rules={rules} />;
};

export default HierarquiaImportColumnRules;
