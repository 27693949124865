import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DropdownCard = ({ title, children, initialState }) => {
  const [isOpen, setOpen] = useState(initialState);
  return (
    <div className="card card-ocorrencia card-detalhes-ocorrencia">
      <button
        className="card-header-ocorrencia d-flex justify-content-between align-items-center"
        type="button"
        onClick={() => setOpen(!isOpen)}
      >
        <span>{title}</span>
        <i className={`fas ${isOpen ? 'fa-angle-up' : 'fa-angle-down'}`} />
      </button>
      {isOpen && children}
    </div>
  );
};

DropdownCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  initialState: PropTypes.bool,
};

DropdownCard.defaultProps = {
  title: '',
  children: null,
  initialState: true,
};

export default DropdownCard;
