import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { API_URL } from '../../../../consts';
import { ComponentBase } from '../../../../base';

class PanoramaGeralOcorrencias extends ComponentBase {
  get columns() {
    return [
      {
        header: '#',
        accessor: 'row',
        Cell: row => <div>{row.index + 1}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Polo',
        withSortIcon: true,
        accessor: 'polo_descricao',
        Cell: d => (<div className="column-left">{d.value}</div>),
        minWidth: 120,
        next: {
          pathname: '/relatorios/v2/panorama-geral-ocorrencias-supervisor',
          stateFields: ['polo'],
        },
        footer: {
          mediaNacional: {
            accessor: '',
            default: 'Panorama nacional',
          },
        },
      },
      {
        header: 'Ocorrências geradas',
        accessor: 'sum_total',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_total',
            default: '-',
          },
        },
      },
      {
        header: 'Ocorrências de imagem',
        accessor: 'sum_ocorrencias_imagem',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_imagem',
            default: '-',
          },
        },
      },
      {
        header: 'Ocorrências pedagógicas',
        accessor: 'sum_ocorrencias_pedagogica',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_pedagogica',
            default: '-',
          },
        },
      },
      {
        header: 'Ocorrências de imagem pendentes',
        accessor: 'sum_ocorrencias_imagem_pendentes',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_imagem_pendentes',
            default: '-',
          },
        },
      },
      {
        header: 'Ocorrências ped. pendentes',
        accessor: 'sum_ocorrencias_pedagogica_pendentes',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_pedagogica_pendentes',
            default: '-',
          },
        },
      },
      {
        header: 'Ocorrências ped. respondidas',
        accessor: 'sum_ocorrencias_pedagogica_respondidas',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_pedagogica_respondidas',
            default: '-',
          },
        },
      },
      {
        header: 'Pendentes com os supervisores',
        accessor: 'sum_ocorrencias_pendentes_supervisor',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_pendentes_supervisor',
            default: '-',
          },
        },
      },
      {
        header: 'Pendentes com o coord. de polo',
        accessor: 'sum_ocorrencias_pendentes_coord_polo',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_pendentes_coord_polo',
            default: '-',
          },
        },
      },
      {
        header: 'Pendentes com o coord. fgv',
        accessor: 'sum_ocorrencias_pendentes_coord_fgv',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_pendentes_coord_fgv',
            default: '-',
          },
        },
      },
    ];
  }

  exibeDadosOcorrencia(hierarquia_id, descricao, id_usuario_responsavel) {
    const { history } = this.props;
    const supervisor = { value: hierarquia_id, label: descricao };
    history.push({
      pathname: '/ocorrencias',
      state: {
        supervisor,
        id_usuario_responsavel,
      },
    });
  }

  render() {
    const exportData = {
      url: `${API_URL}/v2/relatorios/panorama-geral-ocorrencia/geral/excel`,
      filename: 'panorama-geral-ocorrencia.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        showAvaliadorInput={false}
        showTimeInput={false}
        title="PANORAMA GERAL OCORRÊNCIAS"
        dataUrl="v2/relatorios/panorama-geral-ocorrencia/geral"
        mediaNacionalUrl="v2/relatorios/panorama-geral-ocorrencia/geral/media"
        columns={this.columns}
        exportData={exportData}
        caption={[]}
        codigoMensagem="PANORAMA_GERAL_OCORRENCIAS"
        codigo="PANORAMA_GERAL_OCORRENCIA"
      />
    );
  }
}

export default PanoramaGeralOcorrencias;
