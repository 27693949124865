import React from 'react';
import {
 get, keyBy, sortBy, head
} from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import Container from '../../components/v2/Container';
import Avaliadores from '../../components/v2/usuarios/Avaliadores';
import { getAxiosConfig } from '../../services';
import { API_URL } from '../../consts';
import MotivoTrocaStatusModal from '../../components/v2/usuarios/MotivoTrocaStatusModal';
import AvaliadoresAtualizacaoLoteModal from '../../components/v2/usuarios/AvaliadoresAtualizacaoLoteModal';
import RelatorioForm from '../../components/v2/relatorio/RelatorioForm';
import AvaliadoresForm from '../../components/v2/usuarios/AvaliadoresForm';
import mapArrayToObject from '../../utils/map-array-to-object';
import { ComponentBase } from '../../base';
import {
  toastConfig,
  operatorChoiceFilterMethod,
  defaultFilter,
  alertasFilter,
  correcoesHabilitadasFilter,
} from '../../utils';
import dictionary from '../../dictionary';
import { getError } from '../../services/utils';
import { setAuthorizedToken } from '../../services/auth';
import { fetchMeusTimes } from '../../services/hierarquia';

const getUserGroup = (groups) => {
  const userGroup = {};
  groups.forEach((g) => {
    g.user_set.forEach((userId) => {
      userGroup[userId] = g.name;
    });
  });
  return userGroup;
};

const EXCLUDE_FILTER_FIELDS = [
  'polo_descricao',
  'time_descricao',
  'avaliador_descricao',
]

const METHODS = {
  operatorChoiceFilterMethod: operatorChoiceFilterMethod,
  defaultFilter: defaultFilter,
  alertasFilter: alertasFilter,
  correcoesHabilitadasFilter: correcoesHabilitadasFilter,
}

class AvaliadoresPage extends ComponentBase {
  state = {
    users: [],
    filteredUsers: [],
    filters: {},
    avaliadoresFilters: {},
    hierarquias: [],
    currentUser: {},
    statuses: [],
    fetchingCurrentUser: false,
    fetchingUsers: true,
    hierarquiasOptions: [],
    confirmingTrocaStatus: false,
    fetchingAproveitamento: false,
    trocaStatus: {
      user: null,
      statusAtual: {},
      novoStatus: {},
    },
    suspensoes: [],
    fetchingSuspensoes: false,
    contador: 0,
    tempo_adicional: '00:00:00',
    aproveitamentos: [],
    avaliadoresSelecionados: [],
    ultimaAtualizacao: null,
    correcoesUsuarios: [],
    enviandoRecapacitacao: false,
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({
      fetchingUsers: true,
    });
    const filters = JSON.parse(localStorage.getItem('avaliadoresFilters'));
    this.setFilters(head(filters));
    axios.all([
      this.fetchHierarquias(),
      this.fetchGroups(),
      this.fetchAlertas(),
    ])
      .then(([hierarquias, groups, alertas]) => {
        const userGroup = getUserGroup(groups);
        this.fetchUsers(hierarquias, userGroup, alertas, filters);
      });

    this.fetchStatuses();
    if (this.hasFeature('mostrar_aproveitamento')) {
      this.fetchAproveitamento();
    }
    this.fetchCorrecoesUsuario();
    this.fetchHierarquiasOptions();
    this.fetchControleRelatorio();
  }

  setFilters(filters) {
    this.setHierarquiaFilters(filters);
    const avaliadoresFilters = JSON.parse(localStorage.getItem('customAvaliadoresFilters'));
    this.setState({ avaliadoresFilters: head(avaliadoresFilters) || {} })
  }

  fetchUsers = (hierarquias, userGroup, alertas, filters) => {
    const mapUser = (user) => {
      const { aproveitamentos, correcoesUsuarios } = this.state;
      const hierarquia = hierarquias[user.hierarquia];
      const hierarquia_responsavel = hierarquias[hierarquia.id_hierarquia_usuario_pai];
      const aproveitamento = aproveitamentos.find(a => a.usuario_id === user.id);
      const correcoesUsuario = correcoesUsuarios.find(a => a.usuario_id === user.id);
      return {
        ...user,
        hierarquia: get(hierarquia, 'descricao'),
        time_id: get(hierarquia, 'id'),
        hierarquia_responsavel: get(hierarquia_responsavel, 'descricao'),
        polo_id: get(hierarquia_responsavel, 'id'),
        alertas: alertas[user.id],
        group: userGroup[user.id],
        usuario_id: user.id,
        aproveitamento: get(aproveitamento, 'aproveitamento_dentro_corrigidas'),
        corrigidas: get(correcoesUsuario, 'nr_corrigidas'),
        ultima_correcao: get(correcoesUsuario, 'ultima_correcao'),
      };
    };

    axios.get(`${API_URL}/v3/users/me/subordinados`, getAxiosConfig())
      .then((response) => {
        const users = mapArrayToObject(response.data).map(mapUser);
        this.setState({
          fetchingUsers: false,
          users: sortBy(users, ['hierarquia_responsavel', 'hierarquia', 'name']),
          filteredUsers: sortBy(users, ['hierarquia_responsavel', 'hierarquia', 'name']),
        }, () => this.filterUsers(head(filters)));
    });
  }

  fetchCurrentUser = (user) => {
    this.setState({ fetchingCurrentUser: true });
    axios.get(`${API_URL}/v3/users/${user.id}`, getAxiosConfig())
      .then(response => this.setState({ currentUser: response.data, fetchingCurrentUser: false }))
      .catch(() => this.setState({ currentUser: { nome: user.name }, fetchingCurrentUser: false }));
    this.fetchSuspensoes(user.id);
    if (this.hasFeature('alterar_contador_corretores')) {
      this.fetchContador(user.id);
    }
  }

  fetchSuspensoes = (userId) => {
    this.setState({ fetchingSuspensoes: true });
    const params = { user: userId };
    axios.get(`${API_URL}/correcoes/suspensoes`, { params, ...getAxiosConfig() })
      .then(response => this.setState({ suspensoes: response.data, fetchingSuspensoes: false }));
  }

  fetchContador(userId) {
    axios.get(`${API_URL}/correcoes/${userId}/contador`, getAxiosConfig())
    .then((response) => {
      const { contador, tempo_adicional } = response.data;
      this.setState({
        contador: Date.now() + contador,
        tempo_adicional,
      });
    })
    .catch(() => this.setState({ contador: 0 }));
  }

  fetchHierarquias = () => axios.get(`${API_URL}/v3/hierarquias`, getAxiosConfig())
      .then((response) => {
        const hierarquias = keyBy(response.data, 'id');
        this.setState({ hierarquias });
        return hierarquias;
      })

  fetchGroups = () => axios.get(`${API_URL}/v3/groups`, getAxiosConfig())
      .then((response) => {
        const groups = response.data;
        return groups;
      })

  fetchAlertas = () => axios.get(`${API_URL}/alertas`, getAxiosConfig())
      .then((response) => {
        const alertas = response.data.reduce((acc, alerta) => {
          const item = acc[alerta.usuario];
          if (item) {
            acc[alerta.usuario] = [...item, alerta.nome];
            return acc;
          }
          acc[alerta.usuario] = [alerta.nome];
          return acc;
        }, {});
        return alertas;
      })

  fetchAproveitamento = () => {
    this.setState({ fetchingAproveitamento: true });
    axios.get(`${API_URL}/v2/relatorios/aproveitamento-notas/times`, getAxiosConfig())
      .then((response) => {
        const mapUser = (user) => {
          const aproveitamento = response.data.find(a => a.usuario_id === user.id);
          return {
            ...user,
            aproveitamento: get(aproveitamento, 'aproveitamento_dentro_corrigidas'),
          };
        };
        this.setState(prevState => ({
          aproveitamentos: response.data,
          users: prevState.users.map(mapUser),
          filteredUsers: prevState.users.map(mapUser),
          fetchingAproveitamento: false,
        }));
      });
    }

  fetchCorrecoesUsuario = () => {
    axios.get(`${API_URL}/v2/relatorios/correcoes-usuario`, getAxiosConfig())
      .then((response) => {
        const mapUser = (user) => {
          const correcoesUsuario = response.data.find(a => a.usuario_id === user.id);
          return {
            ...user,
            corrigidas: get(correcoesUsuario, 'nr_corrigidas'),
            ultima_correcao: get(correcoesUsuario, 'ultima_correcao'),
          };
        };
        this.setState(prevState => ({
          correcoesUsuarios: response.data,
          users: prevState.users.map(mapUser),
          filteredUsers: prevState.users.map(mapUser),
        }));
      });
  }

  fetchStatuses = () => axios.get(`${API_URL}/v3/status_corretor/status_corretor`, getAxiosConfig())
      .then((response) => {
        this.setState({ statuses: response.data });
      })

  fetchControleRelatorio = () => {
    axios.get(`${API_URL}/v2/relatorios/controle-relatorio/CORRECOES_USUARIO`, getAxiosConfig())
      .then(response => this.setState({ ultimaAtualizacao: response.data.atualizado_em }))
  }

  updateUser = (user) => {
    axios.patch(`${API_URL}/v3/users/${user.id}`, user, getAxiosConfig())
      .catch(() => {
        toast.error('Ocorreu um erro ao atualizar o avaliador. Por favor, tente novamente.');
      });
  }

  handleChange = (userId, changes) => {
    const { users, filteredUsers } = this.state;
    const user = {
      ...users.find(d => d.id === userId),
      ...changes,
    };
    this.setState({
      users: users.map(d => (d.id === userId ? user : d)),
      filteredUsers: filteredUsers.map(d => (d.id === userId ? user : d)),
    });
    this.updateUser(user);
  }

  handleStatusChange = (userId, statusId) => {
    const { users, statuses } = this.state;
    const user = users.find(d => d.id === userId);
    this.setState({
      openModal: 'trocaStatus',
      trocaStatus: {
        userId,
        user: user.name,
        statusAtual: statuses.find(d => d.id === user.status),
        novoStatus: statuses.find(d => d.id === +statusId),
      },
    });
  }

  handleStatusChangeConfirmation = (motivoTrocaStatusId) => {
    this.setState({ confirmingTrocaStatus: true });
    const { trocaStatus } = this.state;
    const { users, filteredUsers } = this.state;
    const user = users.find(d => d.id === trocaStatus.userId);
    const filteredUser = filteredUsers.find(d => d.id === trocaStatus.userId);
    user.status = trocaStatus.novoStatus.id;
    filteredUser.status = trocaStatus.novoStatus.id;
    this.setState({ users, filteredUsers });
    axios.post(`${API_URL}/v3/status_corretor/troca_status`, {
      status_atual: trocaStatus.novoStatus.id,
      corretor: trocaStatus.userId,
      motivo: motivoTrocaStatusId,
    }, getAxiosConfig())
      .then(() => {
        this.setState({ confirmingTrocaStatus: false });
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao atualizar o avaliador. Por favor, tente novamente.');
        this.setState({ confirmingTrocaStatus: false });
      });
  }

  handleAction = (action, ...args) => {
    this[`handle${action.charAt(0).toUpperCase() + action.slice(1)}`](...args);
  }

  handleAlterarContador = (id, values) => {
    const data = { id, tempo_restante: values.tempo_restante };
    axios.patch(`${API_URL}/correcoes/contador`, data, getAxiosConfig())
    .then((response) => {
      const contador = get(response, 'data.contador')
      const tempo_adicional = get(response, 'data.tempo_adicional')
      this.setState({ contador: new Date(contador).getTime(), tempo_adicional  });
      toast.success('Alterações salvas com sucesso!');
    })
    .catch((e) => {
      const message = get(e, 'response.data.non_field_errors[0]')
      toast.error(message || 'Erro ao salvar as alterações. Tente novamente mais tarde!');
    });
  }

  changeCustomFilters = (value, field, filterMethod) => {
    this.setState(prevState => ({
      avaliadoresFilters: {
        ...prevState.avaliadoresFilters,
        [field]: { value, id: field, filterMethod },
      },
    }));
  }

  filterUsers = (filters={}) => {
    const { users, avaliadoresFilters } = this.state;
    const filteredUsers = users.filter((user) => {
      const commonFilter = Object.keys(filters).map((key) => {
        if (filters[key] && !EXCLUDE_FILTER_FIELDS.includes(key)) {
          return user[key] === filters[key];
        }
        return true;
      });
      const customFilters = Object.keys(avaliadoresFilters).map(key => {
        const filterMethod = METHODS[avaliadoresFilters[key].filterMethod]
        return filterMethod(avaliadoresFilters[key], user)
      });
      return [...customFilters, ...commonFilter].every(val => val === true);
    });
    localStorage.setItem('avaliadoresFilters', JSON.stringify([filters]));
    localStorage.setItem('customAvaliadoresFilters', JSON.stringify([avaliadoresFilters]));
    this.setState({ filteredUsers });
  }

  fetchHierarquiasOptions = () => {
    fetchMeusTimes()
    .then((response) => {
      this.setState({
        hierarquiasOptions: response,
      });
    });
  }

  handleClearFilters = () => {
    this.setState({
      avaliadoresFilters: {},
    }, () => this.filterUsers({}));
  }

  selecionarAvaliador = (id) => {
    const { avaliadoresSelecionados } = this.state;
    if (avaliadoresSelecionados.includes(id)) {
      this.setState({ avaliadoresSelecionados: avaliadoresSelecionados.filter(value => value !== id) })
    } else {
      this.setState({ avaliadoresSelecionados: [...avaliadoresSelecionados, id] })
    }
  }

  selecionarTodosAvaliadores = (ids) => {
    this.setState({ avaliadoresSelecionados: ids })
  }

  atualizarEmLote = (values) => {
    const { max_correcoes_dia, ...rest } = values
    const {
      avaliadoresSelecionados,
      filteredUsers,
      users,
    } = this.state;

    this.setState({
      fetchingUsers: true,
      openModal: null,
    });

    const newFilteredUsers = filteredUsers.map(user => {
      if (avaliadoresSelecionados.includes(user.id)) {
        if (max_correcoes_dia) {
          return { ...user, ...values };
        }
        return { ...user, ...rest };
      }
      return user;
    })

    const newUsers = users.map(user => {
      if (avaliadoresSelecionados.includes(user.id)) {
        if (max_correcoes_dia) {
          return { ...user, ...values };
        }
        return { ...user, ...rest };
      }
      return user;
    })

    const params = {
      users: avaliadoresSelecionados,
      ...rest,
    }
    if (max_correcoes_dia) {
      params['max_correcoes_dia'] = max_correcoes_dia
    }
    axios.post(`${API_URL}/v3/users/atualizar-usuarios`, params, getAxiosConfig())
      .then(() => {
        this.setState({
          avaliadoresSelecionados: [],
          users: newUsers,
          filteredUsers: newFilteredUsers,
          fetchingUsers: false,
        })
        toast.success('Avaliadores atualizados com sucesso.');
      })
      .catch(() => {
        this.setState({ fetchingUsers: false });
        toast.error('Ocorreu um erro ao atualizar o avaliador. Por favor, tente novamente.');
      });
  }

  handleAcessarComo = (cpf) => {
    axios.post(`${API_URL}/login-as/`, { user: cpf }, getAxiosConfig())
      .then(({ data }) => {
        setAuthorizedToken(data.token)
        localStorage.removeItem('listSettings');
        window.location.href = '/';
      })
      .catch((error) => {
        const { status, data } = getError(error);
        let message = dictionary.ERRO_DESCONHECIDO;
        if (status === 403 && data.detail) {
          message = data.detail;
        } else if (status === 400 && data.user) {
          message = data.user[0];
        }
        toast.error(message, toastConfig);
      })
  }

  handleEnviarRecapacitacao = (corretor) => {
    const { users, filteredUsers } = this.state;
    this.setState({ enviandoRecapacitacao: true })
    axios.post(`${API_URL}/v3/recapacitacao`, { corretor }, getAxiosConfig())
      .then(({ data }) => {
        toast.success('Avaliador adicionado a recapacitação com sucesso!', toastConfig);
        const user = {
          ...users.find(d => d.id === corretor),
          recapacitacao_habilitada_em: null,
          recapacitacao_criada_em: get(data, 'recapacitacao_criada_em'),
        };
        this.setState({
          users: users.map(d => (d.id === corretor ? user : d)),
          filteredUsers: filteredUsers.map(d => (d.id === corretor ? user : d)),
          enviandoRecapacitacao: false,
        });
      })
      .catch((error) => {
        const defaultMessage = 'Ocorreu um erro ao enviar recapacitação. Por favor, tente novamente.';
        const errorMessage = get(error, 'response.data.non_field_errors[0]', defaultMessage);
        toast.error(errorMessage, toastConfig);
        this.setState({ enviandoRecapacitacao: false })
      })

  }

  render() {
    const {
      filteredUsers,
      hierarquias,
      currentUser,
      fetchingCurrentUser,
      fetchingUsers,
      statuses,
      suspensoes,
      fetchingSuspensoes,
      openModal,
      trocaStatus,
      confirmingTrocaStatus,
      fetchingAproveitamento,
      contador,
      tempo_adicional,
      avaliadoresFilters,
      hierarquiasOptions,
      avaliadoresSelecionados,
      ultimaAtualizacao,
      enviandoRecapacitacao,
    } = this.state;

    const { history } = this.props;

    return (
      <Container title="Avaliadores">
        <RelatorioForm
          fetchingRelatorio={fetchingUsers}
          onApplyFilters={this.filterUsers}
          showDateRangeInput={false}
          caption={{}}
          onClearFilters={this.handleClearFilters}
          atualizaLote={() => this.setState({ openModal: 'atualizarLote' })}
          showClearButton
          setHierarquiaFilters={func => this.setHierarquiaFilters = func}
          showBatchUpdateButton={this.hasFeature('atualizar_corretores_em_lote') && this.hasPermission('atualizar_avaliadores_em_lote')}
        >
          <AvaliadoresForm
            showDSP={this.hasFeature('mostrar_dsp')}
            showAproveitamento={this.hasFeature('mostrar_aproveitamento')}
            showPreTeste={this.hasFeature('mostrar_pre_teste')}
            onChange={this.changeCustomFilters}
            corrigidas={avaliadoresFilters.corrigidas}
            dsp={avaliadoresFilters.dsp}
            aproveitamento={avaliadoresFilters.aproveitamento}
            pre_teste={avaliadoresFilters.pre_teste}
            correcoes={avaliadoresFilters.correcoes}
            max_correcoes_dia={avaliadoresFilters.max_correcoes_dia}
            status={avaliadoresFilters.status}
            alertas={avaliadoresFilters.alertas}
            showAlertas={this.hasFeature('mostrar_alertas')}
            showCota={this.hasFeature('mostrar_cota')}
            showStatus={this.hasFeature('mostrar_status')}
            statuses={statuses}
            showCorrecoesHabilitadas={this.hasFeature('mostrar_correcoes_habilitadas')}
            podeHabilitarTerceira={this.hasFeature('habilitar_terceira_para_avaliadores')}
          />
        </RelatorioForm>
        <Avaliadores
          updateTable={() => this.fetchData()}
          onAction={this.handleAction}
          users={filteredUsers}
          hierarquias={hierarquias}
          onCurrentUserChange={this.fetchCurrentUser}
          currentUser={currentUser}
          fetchingCurrentUser={fetchingCurrentUser}
          fetchingUsers={fetchingUsers}
          statuses={statuses}
          suspensoes={suspensoes}
          fetchingSuspensoes={fetchingSuspensoes}
          contador={contador}
          tempo_adicional={tempo_adicional}
          onAlterarContador={this.handleAlterarContador}
          fetchingAproveitamento={fetchingAproveitamento}
          showDSP={this.hasFeature('mostrar_dsp')}
          showContador={this.hasFeature('alterar_contador_corretores')}
          showHistoricoSuspensao={this.hasFeature('mostrar_historico_suspensao')}
          showPolo={this.hasFeature('mostrar_polo')}
          showTime={this.hasFeature('mostrar_time')}
          showCorrecoesHabilitadas={this.hasFeature('mostrar_correcoes_habilitadas')}
          showCota={this.hasFeature('mostrar_cota')}
          showStatus={this.hasFeature('mostrar_status')}
          showAlertas={this.hasFeature('mostrar_alertas')}
          showAproveitamento={this.hasFeature('mostrar_aproveitamento')}
          showPreTeste={this.hasFeature('mostrar_pre_teste')}
          showLinkParaResumo={this.hasFeature('link_para_resumo_na_lista_avaliadores')}
          showRecapacitacao={this.hasPermission('ver_envio_recapacitacao') && this.hasFeature('enviar_corretor_recapacitacao')}
          history={history}
          mudarTimeAvaliador={this.hasPermission('mudar_time_avaliador')}
          hierarquiasOptions={hierarquiasOptions}
          avaliadoresSelecionados={avaliadoresSelecionados}
          selecionarAvaliador={this.selecionarAvaliador}
          selecionarTodosAvaliadores={ids => this.selecionarTodosAvaliadores(ids)}
          onAcessarComo={this.handleAcessarComo}
          showAcessarComo={this.hasFeature('acessar_como_alguem') && this.hasPermission('acessar_como_alguem')}
          showAtualizacaoLote={this.hasFeature('atualizar_corretores_em_lote')}
          podeHabilitarTerceira={this.hasFeature('habilitar_terceira_para_avaliadores')}
          noDataText="Nenhum avaliador encontrado com este filtro."
          enviarRecapacitacao={this.handleEnviarRecapacitacao}
          enviandoRecapacitacao={enviandoRecapacitacao}
        />
        <AvaliadoresAtualizacaoLoteModal
          visible={openModal === 'atualizarLote'}
          onClose={() => this.setState({ openModal: null })}
          onConfirm={this.atualizarEmLote}
          nr_avaliadores={avaliadoresSelecionados.length}
          podeHabilitarTerceira={this.hasFeature('habilitar_terceira_para_avaliadores')}
        />
        <MotivoTrocaStatusModal
          {...trocaStatus}
          visible={openModal === 'trocaStatus'}
          onClose={() => this.setState({ openModal: null })}
          onConfirm={this.handleStatusChangeConfirmation}
          confirming={confirmingTrocaStatus}
        />
        {
          ultimaAtualizacao && (
            <div className="d-flex justify-content-end mt-2">
              <small>Última atualização: {ultimaAtualizacao}</small>
            </div>
          )
        }
      </Container>
    );
  }
}

export default AvaliadoresPage;
