import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

const Caption = ({ title, captions }) => (
  <ul className="pull-right legendas mb-0">
    {
      title && <li>{title}</li>
    }
    {
      captions.map(caption => (
        <li key={shortid.generate()}><i className={`fas fa-${caption.shape} ${caption.color}`} /> {caption.label}</li>
      ))
    }
  </ul>
);

Caption.propTypes = {
  title: PropTypes.string,
  captions: PropTypes.arrayOf(PropTypes.shape({
    shape: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
  })),
};

Caption.defaultProps = {
  title: null,
  captions: [],
};

export default Caption;
