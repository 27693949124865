import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PermissionContext from '../contexts/PermissionContext';
import fetchPermissions from '../services/permissions';

const PermissionState = ({ children }) => {
  const [permissions, setPermissions] = useState({});

  const getPermissions = async () => {
    const data = await fetchPermissions();
    setPermissions(get(data, 'permissions') || []);
  };

  const hasPermission = permission => get(permissions, '__all__') === true || permissions[permission] === true;

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <PermissionContext.Provider
      value={{
        hasPermission,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

PermissionState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PermissionState;
