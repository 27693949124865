import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';


const LegendItem = ({ color, label, tooltip }) => (
  <>
    <ReactTooltip place="top" type="dark" effect="solid" id="legend-tooltip" />
    <li className="legends-list-item">
      <i className="legends-list-item-icon fas fa-circle" style={{ color }} data-tip={tooltip} data-for="legend-tooltip" />
      {label}
    </li>
  </>
);

LegendItem.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
};

LegendItem.defaultProps = {
  color: '#4A4747',
  label: '',
  tooltip: undefined,
};

export default LegendItem;
