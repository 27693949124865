import React, { Fragment } from 'react';
import axios from 'axios';
import { size } from 'lodash';
import { Link } from 'react-router-dom';
import {
  Loading,
} from '../components';
import Footer from '../components/Footer';
import CorrecaoBase from './CorrecaoBase';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services';

class CentralAvisos extends CorrecaoBase {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visualizarAviso: false,
      avisos: [],
      id: '',
      selectedId: null,
      fetchingAvisos: true,
    };
    // this.handleButtonExcluir = this.handleButtonExcluir.bind(this);
    this.onClose = this.onClose.bind(this);
    this.verTodaMensagem = this.verTodaMensagem.bind(this);
    this.loadNextPage = this.loadNextPage.bind(this);
    this.loadPreviousPage = this.loadPreviousPage.bind(this);
    this.render_avisos = this.render_avisos.bind(this);
    // this.excluirAviso = this.excluirAviso.bind(this);
}

  componentDidMount() {
    this.fetchAvisos();
  }

  onClose() {
    this.setState({ visible: false, selectedId: null });
  }

  // handleButtonExcluir(selectedId) {
  //   this.setState({ visible: true, selectedId });
  // }

  fetchAvisos(url=`${API_URL}/avisos`) {
    axios.get(url, {
      params: { time: true },
      ...getAxiosConfig()
    })
    .then((response) => {
      const { results, next, previous } = response.data;
      this.setState({
        avisos: results,
        nextPageUrl: next,
        previousPageUrl: previous,
        fetchingAvisos: false,
      });
    });
  }

  verTodaMensagem() {
    const { visualizarAviso } = this.state;
    this.setState({
      visualizarAviso: !visualizarAviso,
    });
  }

  loadNextPage() {
    const { nextPageUrl } = this.state;
    this.fetchAvisos(nextPageUrl);
  }

  loadPreviousPage() {
    const { previousPageUrl } = this.state;
    this.fetchAvisos(previousPageUrl);
  }

  renderNextPageButton() {
    const { nextPageUrl } = this.state;
    if (nextPageUrl) {
      return <button className="btn btn btn-primary pull-right" onClick={this.loadNextPage}>próxima</button>
    }
  }

  renderPreviousPageButton() {
    const { previousPageUrl } = this.state;
    if (previousPageUrl) {
      return <button className="btn btn btn-primary pull-left" onClick={this.loadPreviousPage}>anterior</button>
    }
  }

  // excluirAviso() {
  //   const { selectedId } = this.state;
  //   axios.delete(`${API_URL}/notificacoes/${selectedId}`, getAxiosConfig())
  //   .then(() => {
  //     this.setState({
  //       visible: false,
  //     });
  //     toast.success('Aviso excluido com sucesso', toastConfig);
  //   })
  //   .catch(() => {
  //     this.setState({
  //       visible: false,
  //     });
  //     toast.error('Erro ao excluir Aviso. Tente novamente mais tarde!', toastConfig);
  //   });
  // }

  render_avisos(aviso) {
    const {
      visualizarAviso,
    } = this.state;
    return (
      <div key={aviso.id} className="panel-body-avisos mt-3">
        <div className="header-card-avisos">
          {aviso.titulo && (
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <h3 className="title-card-avisos">{aviso.titulo}</h3>
              </div>
            </div>
          )}
        </div>
        <article className="content-aviso">
          <div className="row">
            <div className="col-12">
              <article className={`msg-aviso ${visualizarAviso ? 'show' : 'hidden'}`}>
                <p dangerouslySetInnerHTML={{ __html: aviso.descricao }} />
              </article>
              <button
                type="button"
                className="btn btn-link-ver-mais mb-2"
                onClick={this.verTodaMensagem}
              >
                <i className="fas fa-angle-double-down" />
              </button>
            </div>
          </div>
        </article>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <div className="info-central-avisos mb-3 mt-1">
              <i className="fas fa-clock" />
              <span className="ml-2">
                DATA INICIAL: {aviso.data_inicio}
              </span>
            </div>
          </div>
          {<div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            {aviso.data_termino ? <div className="info-central-avisos mb-3 mt-1">
              <i className="fas fa-clock" />
              <span className="ml-2">
                DATA FINAL: {aviso.data_termino}
              </span>
            </div> : null}
          </div>}
          <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
            <Link to={`/avisos/${aviso.id}`}>
              <button className="btn btn btn-primary mt-1 w-100">
                <i className="fas fa-edit mr-2" />
                EDITAR
              </button>
            </Link>
          </div>
          {/*<div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
            <button type="button" className="btn btn-secondary mt-1 w-100" onClick={() => this.handleButtonExcluir(aviso.id)}>
              <i className="fas fa-times-circle mr-2" />
              EXCLUIR
            </button>
          </div>*/}
        </div>
      </div>
    )
  }

  render() {
    const {
      avisos, fetchingAvisos,
    } = this.state;
    return (
      <Fragment>
        <section>
          <div className="container">
            <div className="page-container-border">
              <div className="title-page">
                <div className="row">
                  <div className="col">
                    <h1 className="text-title">Central de Avisos</h1>
                  </div>
                </div>
              </div>
              {/*<section className="filtro">
                <div className="row">
                  <div className="col-xs-12 col-sm-4">
                    <div className="input-group mt-3">
                      <input type="text" className="form-control" placeholder="Procurar" />
                    </div>
                  </div>
                </div>
              </section>*/}
              <section className="box-central-avisos">
                <div className="row">
                  <div className="panel-default">
                    <div className="panel-title">
                      <div className="row">
                        <div className="col-xs-12 col-sm-6">
                          <h2>
                            <i className="fas fa-bell mr-2" />
                            Lista de Avisos
                          </h2>
                        </div>
                        <div className="col-xs-12 col-sm-6 text-right">
                          <Link to="/avisos/new">
                            <button type="button" className="btn btn-primary">
                              <i className="fas fa-plus" /> NOVO AVISO
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {fetchingAvisos && <Loading />}
                    {!fetchingAvisos && size(avisos) === 0 && <p className="p-3 m-0">Nenhum aviso cadastrado.</p>}
                    {avisos.map(aviso => this.render_avisos(aviso))}
                    <div className="panel-body">
                      {this.renderPreviousPageButton()}
                      {this.renderNextPageButton()}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/*<ModalConfirmacao
            visible={visible}
            onClose={this.onClose}
            title="Central de Avisos"
            content="Tem certeza de que deseja excluir este aviso?"
            buttonFunction={this.excluirAviso}
            textSim="EXCLUIR"
            textNao="CANCELAR"
          />*/}
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default CentralAvisos;
