import XLSX from 'xlsx';
import { CORRECAO } from './consts';


export const formatString = (str) => {
  if (str) {
    return parseFloat(str);
  }
  return '-';
};

export const formatStringGeral = (str) => {
  if (str && str !== '-') {
    return parseFloat(str);
  }
  return '-';
};

export const exportDistribuicaoNotas = (rows, mediaTime, mediaNacional, possuiCompetencia5) => {
  const data = [
    ['', '', '', '', 'COMPÊTENCIA I (%)', '', '', '', '', '', 'COMPÊTENCIA II (%)', '', '', '', '', 'COMPÊTENCIA III (%)', '', '', '', '', '', 'COMPÊTENCIA IV (%)', '', '', '', '', ''],
    ['', 'Avaliador', 'Corrigidas', 'Com Nota Normal', 0, 1, 2, 3, 4, 5, 1, 2, 3, 4, 5, 0, 1, 2, 3, 4, 5, 0, 1, 2, 3, 4, 5],
  ];

  if (possuiCompetencia5) {
    data[0].push('COMPÊTENCIA V (%)');
    data[1].push('ddh', 0, 1, 2, 3, 4, 5);
  }

  rows.forEach((row, index) => {
    const relatorioArray = [
      index + 1,
      row.corretor,
      row.nr_corrigidas,
      row.nr_com_nota_normal,
      formatString(row.competencia1_0),
      formatString(row.competencia1_1),
      formatString(row.competencia1_2),
      formatString(row.competencia1_3),
      formatString(row.competencia1_4),
      formatString(row.competencia1_5),
      formatString(row.competencia2_1),
      formatString(row.competencia2_2),
      formatString(row.competencia2_3),
      formatString(row.competencia2_4),
      formatString(row.competencia2_5),
      formatString(row.competencia3_0),
      formatString(row.competencia3_1),
      formatString(row.competencia3_2),
      formatString(row.competencia3_3),
      formatString(row.competencia3_4),
      formatString(row.competencia3_5),
      formatString(row.competencia4_0),
      formatString(row.competencia4_1),
      formatString(row.competencia4_2),
      formatString(row.competencia4_3),
      formatString(row.competencia4_4),
      formatString(row.competencia4_5),
    ];
    if (possuiCompetencia5) {
      relatorioArray.push(
        formatString(row.competencia5_ddh),
        formatString(row.competencia5_0),
        formatString(row.competencia5_1),
        formatString(row.competencia5_2),
        formatString(row.competencia5_3),
        formatString(row.competencia5_4),
        formatString(row.competencia5_5),
      );
    }
    data.push(relatorioArray);
  });

  const mediaTimeRow = [
    '-',
    'Media Dist. Time',
    mediaTime.nr_corrigidas || '-',
    mediaTime.nr_com_nota_normal || '-',
    formatString(mediaTime.competencia1_0),
    formatString(mediaTime.competencia1_1),
    formatString(mediaTime.competencia1_2),
    formatString(mediaTime.competencia1_3),
    formatString(mediaTime.competencia1_4),
    formatString(mediaTime.competencia1_5),
    formatString(mediaTime.competencia2_1),
    formatString(mediaTime.competencia2_2),
    formatString(mediaTime.competencia2_3),
    formatString(mediaTime.competencia2_4),
    formatString(mediaTime.competencia2_5),
    formatString(mediaTime.competencia3_0),
    formatString(mediaTime.competencia3_1),
    formatString(mediaTime.competencia3_2),
    formatString(mediaTime.competencia3_3),
    formatString(mediaTime.competencia3_4),
    formatString(mediaTime.competencia3_5),
    formatString(mediaTime.competencia4_0),
    formatString(mediaTime.competencia4_1),
    formatString(mediaTime.competencia4_2),
    formatString(mediaTime.competencia4_3),
    formatString(mediaTime.competencia4_4),
    formatString(mediaTime.competencia4_5),
  ];

  const mediaNacionalRow = [
    '-',
    'Media Dist. Nacional',
    mediaNacional.nr_corrigidas || '-',
    mediaNacional.nr_com_nota_normal || '-',
    formatString(mediaNacional.competencia1_0),
    formatString(mediaNacional.competencia1_1),
    formatString(mediaNacional.competencia1_2),
    formatString(mediaNacional.competencia1_3),
    formatString(mediaNacional.competencia1_4),
    formatString(mediaNacional.competencia1_5),
    formatString(mediaNacional.competencia2_1),
    formatString(mediaNacional.competencia2_2),
    formatString(mediaNacional.competencia2_3),
    formatString(mediaNacional.competencia2_4),
    formatString(mediaNacional.competencia2_5),
    formatString(mediaNacional.competencia3_0),
    formatString(mediaNacional.competencia3_1),
    formatString(mediaNacional.competencia3_2),
    formatString(mediaNacional.competencia3_3),
    formatString(mediaNacional.competencia3_4),
    formatString(mediaNacional.competencia3_5),
    formatString(mediaNacional.competencia4_0),
    formatString(mediaNacional.competencia4_1),
    formatString(mediaNacional.competencia4_2),
    formatString(mediaNacional.competencia4_3),
    formatString(mediaNacional.competencia4_4),
    formatString(mediaNacional.competencia4_5),
  ];

  if (possuiCompetencia5) {
    mediaTimeRow.push(
      formatString(mediaTime.competencia5_ddh),
      formatString(mediaTime.competencia5_0),
      formatString(mediaTime.competencia5_1),
      formatString(mediaTime.competencia5_2),
      formatString(mediaTime.competencia5_3),
      formatString(mediaTime.competencia5_4),
      formatString(mediaTime.competencia5_5),
    );
    mediaNacionalRow.push(
      formatString(mediaNacional.competencia5_ddh),
      formatString(mediaNacional.competencia5_0),
      formatString(mediaNacional.competencia5_1),
      formatString(mediaNacional.competencia5_2),
      formatString(mediaNacional.competencia5_3),
      formatString(mediaNacional.competencia5_4),
      formatString(mediaNacional.competencia5_5),
    );
  }

  data.push([]);
  data.push(mediaTimeRow);
  data.push(mediaNacionalRow);

  const wb = XLSX.utils.book_new();
  let wsAll = XLSX.utils.aoa_to_sheet(data);
  wsAll = {
    ...wsAll,
    '!merges': [
      { s: { r: 0, c: 4 }, e: { r: 0, c: 9 } },
      { s: { r: 0, c: 10 }, e: { r: 0, c: 14 } },
      { s: { r: 0, c: 15 }, e: { r: 0, c: 20 } },
      { s: { r: 0, c: 21 }, e: { r: 0, c: 26 } },
      { s: { r: 0, c: 27 }, e: { r: 0, c: 33 } },
    ],
  };
  XLSX.utils.book_append_sheet(wb, wsAll, 'Distribuição de Notas');
  XLSX.writeFile(wb, 'relatorio-de-distribuicao-de-notas.xlsx');
};

export const exportDistribuicaoNotasSituacao = (rows, mediaTime, mediaNacional, isEnem) => {
  const data = [['', 'Avaliadores', 'Situação', 'AN(%)', 'Cópia(%)', 'FT(%)', 'NATT(%)']];
  rows.forEach((row, index) => {
    const relatorioArray = [
      index + 1,
      row.corretor,
      row.nr_situacoes,
      row.nr_fea,
      row.nr_copia,
      row.nr_ft,
      row.nr_natt,
    ];
    if (isEnem) {
      relatorioArray.push(row.nr_pd);
    }
    data.push(relatorioArray);
  });

  const mediaTimeRow = [
    '-',
    'Média Dist. Time',
    mediaTime.nr_situacoes,
    mediaTime.nr_fea,
    mediaTime.nr_copia,
    mediaTime.nr_ft,
    mediaTime.nr_natt,
  ];

  const mediaNacionalRow = [
    '-',
    'Média Dist. Nacional',
    mediaNacional.nr_situacoes,
    mediaNacional.nr_fea,
    mediaNacional.nr_copia,
    mediaNacional.nr_ft,
    mediaNacional.nr_natt,
  ];

  if (isEnem) {
    data[0].push('PD(%)');
    mediaTimeRow.push(mediaTime.nr_pd);
    mediaNacionalRow.push(mediaNacional.nr_pd);
  }

  data.push([]);
  data.push(mediaTimeRow);
  data.push(mediaNacionalRow);

  const wb = XLSX.utils.book_new();
  const wsAll = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, wsAll, 'Distribuição Notas Situação');
  XLSX.writeFile(wb, 'relatorio-de-distribuicao-de-notas-por-situacao.xlsx');
};

export const exportResponsabilidadeAvaliador = (rows, corretor, possuiCompetencia5) => {
  if (possuiCompetencia5) {
    const data = [
      [corretor, '', '', '', '', '', '', '', '', '', '', 'Avaliador Espelho', '', '', '', '', '', '', '', 'Terceiro Avaliador'],
      ['Redação', 'Data', 'Aproveitamento', 'Situação', 'CI', 'CII', 'CIII', 'CIV', 'CV', 'DDH', 'Nota', 'Situação', 'CI', 'CII', 'CIII', 'CIV', 'CV', 'DDH', 'Nota', 'Situação', 'CI', 'CII', 'CIII', 'CIV', 'CV', 'DDH', 'Nota'],
    ];
    rows.forEach((row) => {
      const relatorioArray = [
        row.redacao,
        row.data.toString(),
        row.aproveitamento === '1' ? 'SIM' : 'NÃO',
        CORRECAO.SITUACOES_ABREVIACAO[row.avaliador_situacao],
        row.avaliador_c1,
        row.avaliador_c2,
        row.avaliador_c3,
        row.avaliador_c4,
        row.avaliador_is_ddh ? 0 : row.avaliador_c5,
        row.avaliador_is_ddh ? '√' : '',
        row.avaliador_soma,
        CORRECAO.SITUACOES_ABREVIACAO[row.espelho_situacao],
        row.espelho_c1,
        row.espelho_c2,
        row.espelho_c3,
        row.espelho_c4,
        row.espelho_is_ddh ? 0 : row.espelho_c5,
        row.espelho_is_ddh ? '√' : '',
        row.espelho_soma,
        CORRECAO.SITUACOES_ABREVIACAO[row.terceiro_situacao],
        row.terceiro_c1,
        row.terceiro_c2,
        row.terceiro_c3,
        row.terceiro_c4,
        row.terceiro_is_ddh ? 0 : row.terceiro_c5,
        row.terceiro_is_ddh ? '√' : '',
        row.terceiro_soma,
      ];
      data.push(relatorioArray);
    });
    const wb = XLSX.utils.book_new();
    let wsAll = XLSX.utils.aoa_to_sheet(data);
    wsAll = {
      ...wsAll,
      '!merges': [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },
        { s: { r: 0, c: 11 }, e: { r: 0, c: 18 } },
        { s: { r: 0, c: 19 }, e: { r: 0, c: 26 } },
      ],
    };
    XLSX.utils.book_append_sheet(wb, wsAll, 'Responsabilidade por Avaliador');
    XLSX.writeFile(wb, 'relatorio-responsabilidade-por-avaliador.xlsx');
  } else {
    const data = [
      [corretor, '', '', '', '', '', '', '', '', 'Avaliador Espelho', '', '', '', '', '', 'Terceiro Avaliador'],
      ['Redação', 'Data', 'Aproveitamento', 'Situação', 'CI', 'CII', 'CIII', 'CIV', 'Nota', 'Situação', 'CI', 'CII', 'CIII', 'CIV', 'Nota', 'Situação', 'CI', 'CII', 'CIII', 'CIV', 'Nota'],
    ];
    rows.forEach((row) => {
      const relatorioArray = [
        row.redacao,
        row.data.toString(),
        row.aproveitamento === '1' ? 'SIM' : 'NÃO',
        CORRECAO.SITUACOES_ABREVIACAO[row.avaliador_situacao],
        row.avaliador_c1,
        row.avaliador_c2,
        row.avaliador_c3,
        row.avaliador_c4,
        row.avaliador_soma,
        CORRECAO.SITUACOES_ABREVIACAO[row.espelho_situacao],
        row.espelho_c1,
        row.espelho_c2,
        row.espelho_c3,
        row.espelho_c4,
        row.espelho_soma,
        CORRECAO.SITUACOES_ABREVIACAO[row.terceiro_situacao],
        row.terceiro_c1,
        row.terceiro_c2,
        row.terceiro_c3,
        row.terceiro_c4,
        row.terceiro_soma,
      ];
      data.push(relatorioArray);
    });
    const wb = XLSX.utils.book_new();
    let wsAll = XLSX.utils.aoa_to_sheet(data);
    wsAll = {
      ...wsAll,
      '!merges': [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 8 } },
        { s: { r: 0, c: 9 }, e: { r: 0, c: 16 } },
        { s: { r: 0, c: 17 }, e: { r: 0, c: 24 } },
      ],
    };
    XLSX.utils.book_append_sheet(wb, wsAll, 'Responsabilidade por Avaliador');
    XLSX.writeFile(wb, 'relatorio-responsabilidade-por-avaliador.xlsx');
  }
};

export const exportDistribuicaoNotasGlobal = (rows, mediaNacional, possuiCompetencia5) => {
  const data = [
    ['', 'COMPÊTENCIA I (%)', '', '', '', '', '', 'COMPÊTENCIA II (%)', '', '', '', '', 'COMPÊTENCIA III (%)', '', '', '', '', '', 'COMPÊTENCIA IV (%)'],
    ['Time', 0, 1, 2, 3, 4, 5, 1, 2, 3, 4, 5, 0, 1, 2, 3, 4, 5, 0, 1, 2, 3, 4, 5],
  ];
  if (possuiCompetencia5) {
    data[0].push('', '', '', '', '', 'COMPÊTENCIA V (%)');
    data[1].push('ddh', 0, 1, 2, 3, 4, 5);
  }

  rows.forEach((row) => {
    const relatorioArray = [
      row.time,
      formatString(row.competencia1_0),
      formatString(row.competencia1_1),
      formatString(row.competencia1_2),
      formatString(row.competencia1_3),
      formatString(row.competencia1_4),
      formatString(row.competencia1_5),
      formatString(row.competencia2_1),
      formatString(row.competencia2_2),
      formatString(row.competencia2_3),
      formatString(row.competencia2_4),
      formatString(row.competencia2_5),
      formatString(row.competencia3_0),
      formatString(row.competencia3_1),
      formatString(row.competencia3_2),
      formatString(row.competencia3_3),
      formatString(row.competencia3_4),
      formatString(row.competencia3_5),
      formatString(row.competencia4_0),
      formatString(row.competencia4_1),
      formatString(row.competencia4_2),
      formatString(row.competencia4_3),
      formatString(row.competencia4_4),
      formatString(row.competencia4_5),
    ];
    if (possuiCompetencia5) {
      relatorioArray.push(
        formatString(row.competencia5_ddh),
        formatString(row.competencia5_0),
        formatString(row.competencia5_1),
        formatString(row.competencia5_2),
        formatString(row.competencia5_3),
        formatString(row.competencia5_4),
        formatString(row.competencia5_5),
      );
    }
    data.push(relatorioArray);
  });

  const mediaNacionalRow = [
    'Media Dist. Nacional',
    formatString(mediaNacional.competencia1_0),
    formatString(mediaNacional.competencia1_1),
    formatString(mediaNacional.competencia1_2),
    formatString(mediaNacional.competencia1_3),
    formatString(mediaNacional.competencia1_4),
    formatString(mediaNacional.competencia1_5),
    formatString(mediaNacional.competencia2_1),
    formatString(mediaNacional.competencia2_2),
    formatString(mediaNacional.competencia2_3),
    formatString(mediaNacional.competencia2_4),
    formatString(mediaNacional.competencia2_5),
    formatString(mediaNacional.competencia3_0),
    formatString(mediaNacional.competencia3_1),
    formatString(mediaNacional.competencia3_2),
    formatString(mediaNacional.competencia3_3),
    formatString(mediaNacional.competencia3_4),
    formatString(mediaNacional.competencia3_5),
    formatString(mediaNacional.competencia4_0),
    formatString(mediaNacional.competencia4_1),
    formatString(mediaNacional.competencia4_2),
    formatString(mediaNacional.competencia4_3),
    formatString(mediaNacional.competencia4_4),
    formatString(mediaNacional.competencia4_5),
  ];
  if (possuiCompetencia5) {
    mediaNacionalRow.push(
      formatString(mediaNacional.competencia5_ddh),
      formatString(mediaNacional.competencia5_0),
      formatString(mediaNacional.competencia5_1),
      formatString(mediaNacional.competencia5_2),
      formatString(mediaNacional.competencia5_3),
      formatString(mediaNacional.competencia5_4),
      formatString(mediaNacional.competencia5_5),
    );
  }

  data.push([], mediaNacionalRow);

  const wb = XLSX.utils.book_new();
  let wsAll = XLSX.utils.aoa_to_sheet(data);
  wsAll = {
    ...wsAll,
    '!merges': [
      { s: { r: 0, c: 1 }, e: { r: 0, c: 6 } },
      { s: { r: 0, c: 7 }, e: { r: 0, c: 11 } },
      { s: { r: 0, c: 12 }, e: { r: 0, c: 17 } },
      { s: { r: 0, c: 18 }, e: { r: 0, c: 23 } },
      { s: { r: 0, c: 24 }, e: { r: 0, c: 30 } },
    ],
  };
  XLSX.utils.book_append_sheet(wb, wsAll, 'Distribuição de Notas Global');
  XLSX.writeFile(wb, 'relatorio-de-distribuicao-de-notas-global.xlsx');
};

export const exportAcompanhamentoGeral = (rows) => {
  const data = [
    ['', '', '', 'Aguardando'],
    ['Tipo', 'Total', '%', 'Ocorrência', 'Correção', 'Corrigidas', '%'],
  ];

  rows.forEach((row) => {
    const relatorioArray = [
      row.tipo,
      row.disponiveis,
      row.porcentagem_disponiveis,
      row.total_em_ocorrencia,
      row.total_em_correcao,
      row.total_finalizadas,
      row.porcentagem_finalizadas,
    ];
    data.push(relatorioArray);
  });

  const wb = XLSX.utils.book_new();
  let wsAll = XLSX.utils.aoa_to_sheet(data);
  wsAll = {
    ...wsAll,
    '!merges': [
      { s: { r: 0, c: 3 }, e: { r: 0, c: 4 } },
    ],
  };
  XLSX.utils.book_append_sheet(wb, wsAll, 'Acompanhamento Geral');
  XLSX.writeFile(wb, 'relatorio-acompanhamento-geral.xlsx');
};

export const exportGeralPolo = (rows) => {
  const data = [['Time', 'Total de Corrigidas', 'Corrigidas (Média/Dia)', 'Tempo Médio (Minutos)', 'Aproveitamento']];
  rows.forEach((row) => {
    const relatorioArray = [
      row.time_descricao,
      row.total_corrigidas,
      row.total_corrigidas_por_dia,
      row.tempo_medio,
      row.aproveitamento_nota,
    ];
    data.push(relatorioArray);
  });

  const wb = XLSX.utils.book_new();
  const wsAll = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, wsAll, 'Geral por Polo');
  XLSX.writeFile(wb, 'relatorio-geral-polo.xlsx');
};

export const exportGeralDoTime = (rows, mediaGeral) => {
  const data = [['Avaliador', 'Total de Corrigidas', 'Corrigidas (Média/Dia)', 'Aproveitamento (%)', 'Tempo Médio (Minutos)']];
  rows.forEach((row) => {
    const relatorioArray = [
      row.corretor,
      row.total,
      row.media_diaria,
      row.pct_responsavel_discrepancia,
      row.media_minutos_correcao,
    ];
    data.push(relatorioArray);
  });

  const mediaGeralRow = [
    mediaGeral.corretor,
    mediaGeral.total,
    mediaGeral.media_diaria,
    mediaGeral.pct_responsavel_discrepancia,
    mediaGeral.media_minutos_correcao,
  ];

  data.push([]);
  data.push(mediaGeralRow);

  const wb = XLSX.utils.book_new();
  const wsAll = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, wsAll, 'Geral do Time');
  XLSX.writeFile(wb, 'relatorio-geral-do-time.xlsx');
};


export const exportTerceiraCorrecaoGeral = (rows) => {
  const data = [[
    'Corretor',
    'Terceiras corrigidas',
    'Terceiras aproveitadas',
    'Foram para quarta correção',
    'Aproveitadas após quarta correção',
    'Não aproveitadas após quarta correção',
    'Aproveitamento dentro das que foram para quarta (%)',
    'Aproveitamento dentro das terceiras corrigidas (%)',
  ]];
  rows.forEach((row) => {
    const relatorioArray = [
      row.corretor,
      row.sum_corrigidas,
      row.sum_aproveitadas,
      row.sum_geraram_quarta,
      row.sum_aproveitadas_quarta,
      row.sum_nao_aproveitadas,
      row.pct_aproveitadas_quarta,
      row.pct_corrigidas,
    ];
    data.push(relatorioArray);
  });

  const wb = XLSX.utils.book_new();
  const wsAll = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, wsAll, 'Terceiras Correções - Geral');
  XLSX.writeFile(wb, 'relatorio-terceiras-geral.xlsx');
};

export const exportExtratoDiario = (resume, rows) => {
  const blankLine = [];
  const data = [[
    'Avaliador',
    resume.nome,
    '',
    '',
    '',
    '',
    '',
    'CPF',
    resume.cpf,
  ], [
    'Correções Efetivas',
    resume.enviados,
  ], blankLine,
  [
    'Resumo por tipo de correção',
  ],
  [
    '1ª',
    '2ª',
    '3ª',
    '4ª',
    'Desempenho',
    'Auditoria',
  ],
  [
    resume.enviados_primeira,
    resume.enviados_segunda,
    resume.enviados_terceira,
    resume.enviados_quarta,
    resume.enviados_desempenho,
    resume.enviados_auditoria,
  ], blankLine,
  [
    'Data',
    '1ª',
    '2ª',
    '3ª',
    '4ª',
    'Desempenho',
    'Auditoria',
    'Glosadas',
    'Consideradas',
  ]];

  rows.forEach((row) => {
    const relatorioArray = [
      row.data,
      row.enviados_primeira,
      row.enviados_segunda,
      row.enviados_terceira,
      row.enviados_quarta,
      row.enviados_desempenho,
      row.enviados_auditoria,
      row.glosadas,
      row.enviados,
    ];
    data.push(relatorioArray);
  });

  const wb = XLSX.utils.book_new();
  const wsAll = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, wsAll, 'Extrato Diário');
  XLSX.writeFile(wb, 'extrato-correcao.xlsx');
};
