import PropType from 'prop-types';
import React from 'react';

const HistoricoCorrecoesNotaFinal = ({ showNotaFinal, redacao: { nota, situacao, show_situacao }, forceShowSituacao }) => {
  let shouldShowSituacao = show_situacao;
  if (show_situacao === undefined) {
    shouldShowSituacao = true;
  }

  if (showNotaFinal) {
    if (nota && situacao) {
      return (
        <>
          <p className="p-1">Nota Final: <strong>{nota}</strong></p>
          {(forceShowSituacao || shouldShowSituacao) && (
            <p className="p-1">Situação: <strong>{situacao}</strong></p>
          )}
        </>
      );
    }
    return <p className="p-1 font-italic">Correção não finalizada</p>;
  }
  return null;
};

HistoricoCorrecoesNotaFinal.propTypes = {
  showNotaFinal: PropType.bool.isRequired,
  redacao: PropType.shape({
    nota: PropType.oneOfType([
      PropType.number,
      PropType.string,
    ]),
    situacao: PropType.string,
    show_situacao: PropType.bool,
  }).isRequired,
  forceShowSituacao: PropType.bool,
};

HistoricoCorrecoesNotaFinal.defaultProps = {
  forceShowSituacao: true,
};

export default HistoricoCorrecoesNotaFinal;
