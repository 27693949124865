import React from 'react';
import PropTypes from 'prop-types';

class AvisosImportantesItem extends React.Component {
  static propTypes = {
    descricao: PropTypes.string,
    criado_em: PropTypes.string,
    titulo: PropTypes.string,
  };

  static defaultProps = {
    descricao: null,
    criado_em: null,
    titulo: null,
  };

  render() {
    const {
      descricao,
      criado_em,
      titulo,
    } = this.props;

    return (
      <div className="panel-body-aviso">
        <div className="aviso-text">
          <h4 className="aviso-title">{titulo}</h4>
          <span dangerouslySetInnerHTML={{ __html: descricao }} />
        </div>
        <p className="aviso-date">{criado_em}</p>
      </div>
    );
  }
}

export default AvisosImportantesItem;
