import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { API_URL } from '../../../../consts';
import { ComponentBase } from '../../../../base';

class PanoramaGeralOcorrenciasSupervisor extends ComponentBase {
  get columns() {
    return [
      {
        header: '#',
        accessor: 'row',
        Cell: row => <div>{row.index + 1}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
          mediaTime: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Polo',
        withSortIcon: true,
        accessor: 'polo_descricao',
        Cell: d => (<div className="column-left">{d.value}</div>),
        minWidth: 80,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
          mediaTime: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Supervisor',
        withSortIcon: true,
        accessor: 'supervisor',
        Cell: d => (<div className="column-left">{d.value}</div>),
        minWidth: 200,
        footer: {
          mediaNacional: {
            accessor: '',
            default: 'Panorama nacional',
          },
          mediaTime: {
            accessor: '',
            default: 'Panorama do polo',
          },
        },
      },
      {
        header: 'Ocorrências geradas',
        accessor: 'sum_total',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_total',
            default: '-',
          },
          mediaTime: {
            accessor: 'sum_total',
            default: '-',
          },
        },
      },
      {
        header: 'Ocorrências de imagem',
        accessor: 'sum_ocorrencias_imagem',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_imagem',
            default: '-',
          },
          mediaTime: {
            accessor: 'sum_ocorrencias_imagem',
            default: '-',
          },
        },
      },
      {
        header: 'Ocorrências pedagógicas',
        accessor: 'sum_ocorrencias_pedagogica',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_pedagogica',
            default: '-',
          },
          mediaTime: {
            accessor: 'sum_ocorrencias_pedagogica',
            default: '-',
          },
        },
      },
      {
        header: 'Ocorrências de imagem pendentes',
        accessor: 'sum_ocorrencias_imagem_pendentes',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_imagem_pendentes',
            default: '-',
          },
          mediaTime: {
            accessor: 'sum_ocorrencias_imagem_pendentes',
            default: '-',
          },
        },
      },
      {
        header: 'Ocorrências ped. pendentes',
        accessor: 'sum_ocorrencias_pedagogica_pendentes',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_pedagogica_pendentes',
            default: '-',
          },
          mediaTime: {
            accessor: 'sum_ocorrencias_pedagogica_pendentes',
            default: '-',
          },
        },
      },
      {
        header: 'Ocorrências ped. respondidas',
        accessor: 'sum_ocorrencias_pedagogica_respondidas',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_pedagogica_respondidas',
            default: '-',
          },
          mediaTime: {
            accessor: 'sum_ocorrencias_pedagogica_respondidas',
            default: '-',
          },
        },
      },
      {
        header: 'Pendentes com os supervisores',
        accessor: 'sum_ocorrencias_pendentes_supervisor',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_pendentes_supervisor',
            default: '-',
          },
          mediaTime: {
            accessor: 'sum_ocorrencias_pendentes_supervisor',
            default: '-',
          },
        },
      },
      {
        header: 'Pendentes com o coord. de polo',
        accessor: 'sum_ocorrencias_pendentes_coord_polo',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_pendentes_coord_polo',
            default: '-',
          },
          mediaTime: {
            accessor: 'sum_ocorrencias_pendentes_coord_polo',
            default: '-',
          },
        },
      },
      {
        header: 'Pendentes com o coord. fgv',
        accessor: 'sum_ocorrencias_pendentes_coord_fgv',
        minWidth: 120,
        footer: {
          mediaNacional: {
            accessor: 'sum_ocorrencias_pendentes_coord_fgv',
            default: '-',
          },
          mediaTime: {
            accessor: 'sum_ocorrencias_pendentes_coord_fgv',
            default: '-',
          },
        },
      },
    ];
  }

  exibeDadosOcorrencia(hierarquia_id, descricao, id_usuario_responsavel) {
    const { history } = this.props;
    const supervisor = { value: hierarquia_id, label: descricao };
    history.push({
      pathname: '/ocorrencias',
      state: {
        supervisor,
        id_usuario_responsavel,
      },
    });
  }

  render() {
    const backAction = this.hasPermission('ver_relatorio_panorama_geral_ocorrencias') ? {
      path: '/relatorios/v2/panorama-geral-ocorrencias',
      state: ['polo'],
    } : undefined;

    const exportData = {
      url: `${API_URL}/v2/relatorios/panorama-geral-ocorrencia/supervisores/excel`,
      filename: 'panorama-geral-ocorrencia-supervisores.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        showAvaliadorInput={false}
        showTimeInput={false}
        title="PANORAMA GERAL OCORRÊNCIAS | Supervisores"
        dataUrl="v2/relatorios/panorama-geral-ocorrencia/supervisores"
        mediaNacionalUrl="v2/relatorios/panorama-geral-ocorrencia/geral/media"
        mediaPoloUrl="v2/relatorios/panorama-geral-ocorrencia/supervisores/media"
        columns={this.columns}
        exportData={exportData}
        backAction={backAction}
        caption={[]}
        codigoMensagem="PANORAMA_GERAL_OCORRENCIAS_SUPERVISORES"
        codigo="PANORAMA_GERAL_OCORRENCIA"
      />
    );
  }
}

export default PanoramaGeralOcorrenciasSupervisor;
