import React from 'react';

const Container = ({ title, children }) => (
  <div className="container-fluid">
    <div className="page-container-border">
      <div className="row">
        <div className="col-md-12 col-xs-12 col-sm-12">
          <h1 className="text-title">{title}</h1>
        </div>
        <div className="col-md-12 col-xs-12 col-sm-12">
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default Container;
