import React, { Component, Fragment } from 'react';
import * as Talk from 'talkjs';
import PropTypes from 'prop-types';
import axios from 'axios';
import { CHAT_APP_ID, API_URL } from '../consts';
import { getAxiosConfig } from '../services/auth';

class PopupChat extends Component {
  constructor(props) {
    super(props);
    this.talkSession = undefined;
    this.state = {
      numeroNotificacoes: 0,
      hideChatButton: false,
    };
  }

  componentWillUnmount() {
    if (this.popup) {
      this.popup.destroy();
    }
  }

  componentDidMount() {
    const { current_user, responsavel, signature } = this.props;
    Talk.ready
      .then(() => {
        const me = new Talk.User(current_user);

        this.talkSession = new Talk.Session({
          appId: CHAT_APP_ID,
          me,
          signature,
        });
        const other = new Talk.User(responsavel);
        // const conversationId = Talk.oneOnOneId(me, other);
        const users = [];
        users.push(responsavel.id);
        users.push(current_user.id);
        const conversationId = users.sort().join('_');
        const conversation = this.talkSession.getOrCreateConversation(conversationId);
        conversation.setParticipant(me);
        conversation.setParticipant(other);
        const popup = this.talkSession.createPopup(conversation, {
          keepOpen: false,
          showCloseInHeader: true,
          launcher: 'never',
        });
        popup.mount({ show: false });
        popup.on('close', () => this.setState({ hideChatButton: false }));
        this.popup = popup;
        this.talkSession.unreads.on('change', (conversationIds) => {
          this.counter = conversationIds.length;
          this.setState({
            numeroNotificacoes: this.counter,
          });
        });

        this.talkSession.on('message', (message) => {
          const { messageTimestamp } = this.state;
          if (message.isByMe && messageTimestamp !== message.timestamp) {
            this.setState({ messageTimestamp: message.timestamp });
            const data = {
              remetente: message.senderId,
              destinatario: responsavel.id,
              mensagem: message.body,
            };
            axios.post(`${API_URL}/chat/mensagem`, data, getAxiosConfig());
          }
        });

        const button = this.container;
        button.addEventListener('click', (e) => {
          e.preventDefault();
          // popup.show();
          this.setState({ hideChatButton: true }, popup.show());
        });
      });
  }

  render() {
    const { numeroNotificacoes, hideChatButton } = this.state;

    return (
      <Fragment>
      <div
        ref={(c) => { this.container = c; }}
        className="chat-popup-button"
        style={{ display: hideChatButton ? 'none' : 'initial' }}
      >
          <i className="far fa-comment mr-2" />
          <span>CHAT</span>
        {
          numeroNotificacoes !== 0 && (
            <p className="chat-popup-counter">{numeroNotificacoes}</p>
          )
        }
      </div>
      </Fragment>
    );
  }
}

PopupChat.propTypes = {
  current_user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    email: PropTypes.string,
    configuration: PropTypes.string,
    photoUrl: PropTypes.string,
  }).isRequired,
  responsavel: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    email: PropTypes.string,
    configuration: PropTypes.string,
    photoUrl: PropTypes.string,
  }).isRequired,
  signature: PropTypes.string.isRequired,
};

export default PopupChat;
