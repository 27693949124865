import React from 'react';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { BASE_URL } from '../consts';

export const handleRequestError = (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      toast.error(
        <center>
          <i className="fas fa-exclamation-triangle" />
          <p className="mb-2 mt-2">Dados Inválidos</p>
        </center>,
      {
        position: toast.POSITION.BOTTOM_CENTER,
      },
    );
    } else if (error.response.status === 401 || error.response.status === 403) {
      window.location.replace(`${BASE_URL}/login`);
    } else if (error.response.status === 404) {
      window.location.replace(`${BASE_URL}/404`);
    } else {
      toast.error(
        <center>
          <i className="fas fa-exclamation-triangle" />
          <p className="mb-2 mt-2">Erro desconhecido</p>
        </center>,
      {
        position: toast.POSITION.BOTTOM_CENTER,
      },
    );
    }
  } else if (error.request) {
    toast.error(
      <center>
        <i className="fas fa-exclamation-triangle" />
        <p className="mb-2 mt-2">Erro de conexão com o servidor.</p>
      </center>,
    {
      position: toast.POSITION.BOTTOM_CENTER,
    },
  );
  } else {
    toast.error(
      <center>
        <i className="fas fa-exclamation-triangle" />
        <p className="mb-2 mt-2">Erro desconhecido</p>
      </center>,
    {
      position: toast.POSITION.BOTTOM_CENTER,
    },
  );
  }
};

export const setFormErrors = (formApi, errors) => {
  Object.keys(errors).forEach(field => formApi.setError(field, errors[field][0]));
};

export const getError = error => ({
  status: get(error, 'response.status'),
  data: get(error, 'response.data'),
});
