import React from 'react';
import Rodal from 'rodal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Text, Checkbox } from 'informed';
import 'rodal/lib/rodal.css';
import userPlaceholder from '../assets/images/users/placeholder.svg';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services';
import { getError } from '../services/utils';
import dictionary from '../dictionary';
import { toastConfig } from '../utils';
import { SelectInput } from '.';

class DadosAvaliador extends React.Component {

  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.string,
    avaliador: PropTypes.string,
    patchCallback: PropTypes.func,
    mudarTimeAvaliador: PropTypes.bool,
    hierarquias: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })),
    auxiliar: PropTypes.bool,
    podeCorrigir3: PropTypes.bool,
  };

  static defaultProps = {
    visible: false,
    onClose: null,
    patchCallback: null,
    id: null,
    avaliador: '',
    hierarquias: [],
    mudarTimeAvaliador: false,
    auxiliar: false,
    podeCorrigir3: false,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  state = {
    submitting: false,
  };

  componentDidUpdate(prevProps) {
    const prevAvaliador = get(prevProps, 'avaliador', {});
    const avaliador = get(this.props, 'avaliador', {});
    if (prevAvaliador.id !== avaliador.id) {
      this.setFormValues();
    }
  }

  setFormValues() {
    const avaliador = get(this.props, 'avaliador', {});
    this.formApi.setValues({
      pode_corrigir_1: avaliador.pode_corrigir_1,
      pode_corrigir_2: avaliador.pode_corrigir_2,
      pode_corrigir_3: avaliador.pode_corrigir_3,
      max_correcoes_dia: avaliador.max_correcoes_dia,
      auxiliar: avaliador.auxiliar,
      status_id: get(avaliador, 'status.id'),
      time_id: get(avaliador, 'time_id'),
    });
  }

  handleSubmit(values) {
    const { onClose, patchCallback } = this.props;
    const id = get(this.props, 'avaliador.id');
    const avaliador_time_id = get(this.props, 'avaliador.time_id');
    this.setState({ submitting: true });

    const data = {
      ...values,
      time_id: values.time_id || avaliador_time_id,
    }

    axios.patch(`${API_URL}/correcoes/corretores/${id}`, data, getAxiosConfig())
      .then((response) => {
        onClose();
        patchCallback(response.data);
        toast.success(dictionary.SUCESSO_PATCH, toastConfig);
      })
      .catch((error) => {
        const { status, data } = getError(error);
        if (status === 400 && data.non_field_errors) {
          toast.error(data.non_field_errors[0], toastConfig);
        } else if (status === 403) {
          toast.error(data.detail, toastConfig);
        } else {
          toast.error(dictionary.ERRO_PATCH_CORRETOR, toastConfig);
        }
      })
      .then(() => this.setState({ submitting: false }));
  }

  handleCancel() {
    const { onClose } = this.props;
    onClose();
    this.setFormValues();
  }

  render() {
    const {
      visible,
      onClose,
      avaliador,
      mudarTimeAvaliador,
      hierarquias,
      auxiliar,
      podeCorrigir3,
    } = this.props;
    const { submitting } = this.state;
    return (
      <section className="modal-dados-avaliador">
      <Form
        onChange={formState => this.setState({ formState })}
        getApi={(formApi) => { this.formApi = formApi; }}
        onSubmit={this.handleSubmit}
      >
        {({ formState }) => (
          <Rodal visible={visible} onClose={onClose}>
            <div className="modal-header">
              <div className="row w-100">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1 className="text-title">Dados Pessoais</h1>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <figure className="avaliador-img">
                    <img src={userPlaceholder} className="img-responsive" alt="avaliador-img" />
                  </figure>
                  <div className="desempenho-section">
                    <div className="desempenho-section-title">
                      {/* <p className="mb-0 pb-1 line-down">COEF. DESEMPENHO: <span className="avaliador-font-sm ml-1">{get(avaliador, 'ranking')}</span></p> */}
                      {/* <span className="avaliador-section-ranking"></span> */}
                    </div>
                    <div className="modal-form">
                      <div className="row mt-2">
                        <div className="col-md-4">
                          <span className="avaliador-font-sm"><strong>Habilitações:</strong></span>
                        </div>
                        <div className="col-md-8">
                          <div className="form-check">
                            <Checkbox field="pode_corrigir_1" id="correcao-1" /> <label htmlFor="correcao-1" className="avaliador-font-sm">1ª correção</label>
                            <Checkbox field="pode_corrigir_2" id="correcao-2" /> <label htmlFor="correcao-2" className="avaliador-font-sm">2ª correção</label>
                          </div>
                          <div className="form-check">
                            {podeCorrigir3 && (
                              <React.Fragment>
                                <Checkbox field="pode_corrigir_3" id="correcao-3" /> <label htmlFor="correcao-3" className="avaliador-font-sm">3ª correção</label>
                              </React.Fragment>
                            )}
                            {auxiliar && (
                              <React.Fragment>
                                <Checkbox field="auxiliar" id="auxiliar" /> <label htmlFor="auxiliar" className="avaliador-font-sm">Auxiliar</label>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3 mb-3">
                        <div className="col-md-4">
                          <span className="avaliador-font-sm"><strong>Cota:</strong></span>
                        </div>
                        <div className="col-md-8">
                          <Text field="max_correcoes_dia" className="form-control" type="number" min="0" max="200" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-7">
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="avaliador-inf">
                        {get(avaliador, 'nome')}
                        <i className="fa fa-map-marker-alt ml-3" />
                        <span className="avaliador-font-sm ml-2">
                          {get(avaliador, 'local')}
                        </span>
                      </h4>
                    </div>
                    <div className="col-md-12">
                      <span className="avaliador-font-sm default">
                        <strong>TIME:</strong>
                      </span>
                      {mudarTimeAvaliador ? (
                        <div className="select-situacao mt-2">
                          <SelectInput
                            field="time_id"
                            options={hierarquias}
                          />
                        </div>
                      ) :(
                        <span className="ml-2 avaliador-font-sm">{get(avaliador, 'time',)}</span>
                      )}
                    </div>
                    <div className="col-md-12 mt-2">
                      <span className="avaliador-font-sm default">
                        <strong>Status:</strong>
                      </span>
                      <div className="select-situacao mt-2">
                        <SelectInput
                          field="status_id"
                          options={[
                            { value: 1, label: 'Ativo' },
                            { value: 3, label: 'Suspenso' },
                            { value: 4, label: 'Desligado' },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="btn-tag">
                        <span className="avaliador-font-sm">
                          <strong>TOTAL DE CORREÇÕES: {get(avaliador, 'total_correcoes')}</strong>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="data-section">
                        <h5 className="pb-3"><i className="fas fa-user mr-2" /> Dados Principais</h5>
                        <div className="inf-section mt-3">
                          <p className="avaliador-font-sm">Telefone:
                            <strong className="default ml-2">{get(avaliador, 'telefone')}</strong>
                          </p>
                        </div>
                        <div className="inf-section">
                          <p className="avaliador-font-sm">Email:
                            <strong className="default text-lw ml-2">{get(avaliador, 'email')}</strong>
                          </p>
                        </div>
                        <div className="inf-section">
                          <p className="avaliador-font-sm">Nº de vezes Suspenso:
                            <strong className="default text-lw ml-2">{get(avaliador, 'total_suspensoes')}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-6">
                  <button
                    type="button"
                    className="btn bg-warning-color ml-2"
                    onClick={this.handleCancel}
                    disabled={submitting}
                  >
                    CANCELAR
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="submit"
                    className="btn bg-default-color ml-2"
                    disabled={submitting}
                  >
                    SALVAR
                  </button>
                </div>
              </div>
            </div>
          </Rodal>
        )}
      </Form>
      </section>
    );
  }
}

export default DadosAvaliador;
