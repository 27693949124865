import React, { Fragment } from 'react';
import { Form } from 'informed';
import { get } from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactTable from 'react-table';
import { Footer, TextInput, Loading } from '../../components';
import RelatorioBase from './RelatorioBase';
import { getAxiosConfig } from '../../services';
import { API_URL } from '../../consts';
import { toastConfig } from '../../utils';
import { exportExtratoDiario } from '../../exportRelatorios';

class ResumoAvaliador extends RelatorioBase {
  constructor(props) {
    super(props);
    this.state = {
        tab: 'dia',
        extrato: undefined,
        loading: true,
        sorted: [
          {
              id: 'data',
              desc: false,
          },
      ],
    };
  }

  removeSelected = () => {
    this.setState({ dates: '' });
    document.getElementById('searchForm').reset();
  };

  componentDidMount() {
    const { match: { params: { id } } } = this.props;
    this.fetchExtrato();
    this.setState({ customFilters: { usuario_id: id } }, this.fetchExtratoDiario);
  }

  fetchExtrato() {
    const { match: { params: { id } } } = this.props;
    this.setState({ loading: true });
    axios.get(`${API_URL}/relatorio/extratos/${id}`, getAxiosConfig())
      .then((response) => {
          this.setState({ extrato: response.data, loading: false });
      })
      .catch(() => toast.error('Erro para carregar o relatório', toastConfig));
  }

  fetchExtratoDiarioTotal() {
    const {
      formValues, customFilters,
    } = this.state;
    this.setState({ loadingExtratoDiarioTotal: true });
    axios
      .get(`${API_URL}/relatorio/extratos-diarios-total`, {
        params: {
          ...formValues,
          ...customFilters,
        },
        ...getAxiosConfig(),
      })
      .then((response) => {
        const { results } = response.data;
        this.setState({
          total: results[0],
        });
      })
      .catch(() => {
        this.setState({ loadingExtratoDiarioTotal: false });
        toast.error('Erro para carregar o relatório', toastConfig);
      });
  }

  fetchExtratoDiario() {
    const {
      sorted, formValues, customFilters,
    } = this.state;
    const ordering = sorted
      && sorted.map(field => `${field.desc ? '-' : ''}${field.id}`).join(',');
    this.setState({ loadingExtratoDiario: true });
    this.fetchExtratoDiarioTotal();
    axios
      .get(`${API_URL}/relatorio/extratos-diarios`, {
        params: {
          ordering,
          ...customFilters,
          ...formValues,
        },
        ...getAxiosConfig(),
      })
      .then((response) => {
        this.setState({
          data: response.data,
          loadingExtratoDiario: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        toast.error('Erro para carregar o relatório', toastConfig);
      });
  }

  changeTab = (tab) => {
    if (tab === 'dia') {
      this.setState({ tab: 'dia' });
    } else {
      this.setState({ tab: 'mascara' });
    }
  };

  exportFile = () => {
    const { data, extrato } = this.state;
    exportExtratoDiario(extrato, data);
  }

  renderDia() {
    const {
      data,
      loadingExtratoDiario,
      total,
    } = this.state;
    const columns = [
      {
          Header: this.getHeaderWithSortIcon('Data', 'data'),
          accessor: 'data',
          maxWidth: 120,
          Footer: () => <p>Total</p>,
      },
      {
          Header: this.getHeaderWithSortIcon('1ª', 'enviados_primeira'),
          accessor: 'enviados_primeira',
          Footer: () => <p>{get(total, 'enviados_primeira')}</p>,
      },
      {
          Header: this.getHeaderWithSortIcon('2ª', 'enviados_segunda'),
          accessor: 'enviados_segunda',
          Footer: () => <p>{get(total, 'enviados_segunda')}</p>,

      },
      {
          Header: this.getHeaderWithSortIcon('3ª', 'enviados_terceira'),
          accessor: 'enviados_terceira',
          Footer: () => <p>{get(total, 'enviados_terceira')}</p>,

      },
      {
          Header: this.getHeaderWithSortIcon('4ª', 'enviados_quarta'),
          accessor: 'enviados_quarta',
          Footer: () => <p>{get(total, 'enviados_quarta')}</p>,

      },
      {
          Header: this.getHeaderWithSortIcon('Desempenho', 'enviados_desempenho'),
          accessor: 'enviados_desempenho',
          Footer: () => <p>{get(total, 'enviados_desempenho')}</p>,

      },
      {
          Header: this.getHeaderWithSortIcon('Auditoria', 'enviados_auditoria'),
          accessor: 'enviados_auditoria',
          Footer: () => <p>{get(total, 'enviados_auditoria')}</p>,

      },
      {
          Header: this.getHeaderWithSortIcon('Glosadas', 'glosadas'),
          accessor: 'glosadas',
          Footer: () => <p>{get(total, 'glosadas')}</p>,

      },
      {
          Header: this.getHeaderWithSortIcon('Consideradas', 'enviados'),
          accessor: 'enviados',
          Footer: () => <p>{get(total, 'enviados')}</p>,

      },
    ];

    return (
      <Fragment>
        <Form
          id="searchForm"
          onSubmit={(submitValues) => {
              this.setState({ formValues: submitValues }, this.fetchExtratoDiario);
          }}
        >
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4 mt-2 no-print">
              <TextInput
                label="Data de Início"
                field="data_inicio"
                type="date"
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4 mt-2 no-print">
              <TextInput
                label="Data de Fim"
                field="data_final"
                type="date"
              />
            </div>
            <div
              style={{ paddingTop: 14 }}
              className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4 mt-4 flex"
            >
              <button
                type="submit"
                className="btn btn-primary flex-start no-print"
              >
                Buscar
              </button>
            </div>
          </div>
        </Form>
        <ReactTable
          className="-highlight"
          previousText=""
          nextText=""
          noDataText="Sem conteúdo"
          pageText="Pág."
          ofText="de"
          rowsText="registros"
          manual
          data={data}
          columns={columns}
          loading={loadingExtratoDiario}
          loadingText={<Loading />}
          onSortedChange={sorted => this.setState({ sorted }, () => this.fetchExtratoDiario())}
          getTrProps={(state, rowInfo) => ({
            style: {
              backgroundColor: this.backgroundColor(state, rowInfo),
            },
          })}
          showPagination={false}
        />
      </Fragment>
    );
  }

  renderMascara() {
    const { data, loading } = this.state;
    const columns = [
        {
            Header: this.getHeaderWithSortIcon('Seq.', 'seq'),
            accessor: 'avaliador',
            minWidth: 160,
        },
        {
            Header: this.getHeaderWithSortIcon('Data e Hora', 'data_hora'),
            accessor: 'data_hora',
            maxWidth: 120,
        },
        {
            Header: this.getHeaderWithSortIcon('Tipo', 'tipo'),
            accessor: 'tipo',
        },
        {
            Header: this.getHeaderWithSortIcon('Máscara', 'mascara'),
            accessor: 'mascara',

        },
        {
            Header: this.getHeaderWithSortIcon('Situação', 'situacao'),
            accessor: 'situacao',

        },

    ];

    return (
      <Fragment>
          <Form
            id="searchForm"
            onSubmit={(formValues) => {
                this.setState({ dates: formValues });
            }}
          >
              <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4 mt-2">
                      <TextInput
                        label="Busca"
                        field="avaliador"
                        type="text"
                      />
                  </div>
                  <div style={{paddingTop: 14}} className="col-lg-8 col-md-8 col-sm-12 col-xs-12 mb-4 mt-4 flex">
                      <button type="submit" className={`btn btn-primary flex-start `}>Buscar</button>
                      {/*{avaliador && <button onClick={this.removeSelected} className="btn btn-primary flex-start">{avaliador.avaliador} <i style={{position: 'relative', right: '-15px'}} className="fas fa-times"/></button>}*/}

                  </div>
                  <div style={{paddingTop: 14}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-4">

                  </div>

              </div>

          </Form>
          <ReactTable
            className="-highlight"
            defaultPageSize={10}
            previousText=""
            nextText=""
            noDataText="Sem conteúdo"
            pageText="Pág."
            ofText="de"
            rowsText="registros"
            manual
            data={data}
            columns={columns}
            loading={loading}
          />
      </Fragment>
    );
  }

  render() {
    const { tab, extrato, loading } = this.state;

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="page-container-border">
          {loading && <Loading />}
          {!loading && (
            <section>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="flex">
                    <h1
                      style={{ marginBottom: 0, border: 'none', padding: '30px 0px 0px 0px' }}
                      className="text-title text-flex"
                    >
                      Avaliador: <span className="default">{get(extrato, 'nome')}</span>
                    </h1>
                    <p className="text-flex text-box"><b>CPF:</b> <span>{get(extrato, 'cpf', '')}</span></p>
                  </div>
                  <hr style={{ marginTop: 0 }} />
                </div>
                <div className="col-lg-3 col-md-3 col-xs-12 col-sm-12 ">
                  <p className="text-flex text-box"><b>Correções Efetivas:</b> <span>{get(extrato, 'enviados')}</span></p>
                </div>
                  <div className="col-9 text-right">
                      <button
                        type="button"
                        onClick={this.printAll}
                        className=" btn btn-more no-print"
                      >
                          <i className="fas fa-print " />
                      </button>
                      {
                        this.hasPermission('exportar_relatorio') && (
                          <button
                            type="button"
                            className="btn btn-more ml-3 no-print"
                            onClick={this.exportFile}
                          >
                            <i className="fas fa-file-excel" />
                          </button>
                        )
                      }
                  </div>
              </div>
              <hr style={{ marginTop: '0.5rem' }} />
              <div className="row">
                <div className="col-12">
                  <h3 className="subtitle mb-3">Resumo por tipo de correção</h3>
                  <table className="table table-resumo">
                    <thead>
                      <tr>
                        <th>1ª</th>
                        <th>2ª</th>
                        <th>3ª</th>
                        <th>4ª</th>
                        <th>Desempenho</th>
                        <th>Auditoria</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{get(extrato, 'enviados_primeira')}</td>
                        <td>{get(extrato, 'enviados_segunda')}</td>
                        <td>{get(extrato, 'enviados_terceira')}</td>
                        <td>{get(extrato, 'enviados_quarta')}</td>
                        <td>{get(extrato, 'enviados_desempenho')}</td>
                        <td>{get(extrato, 'enviados_auditoria')}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr />
                <div className="col-12 mt-4">
                  <ul className="nav nav-tabs mb-4">
                    <li className=" nav-item">
                      <button
                        onClick={() => this.changeTab('dia')}
                        type="button"
                        className={`clickable nav-link ${tab === 'dia' ? 'active' : ''}`}> Correções
                          por dia
                      </button>
                    </li>
                  </ul>
                  {tab === 'dia' ? this.renderDia() : this.renderMascara()}
                </div>
              </div>
            </section>
          )}
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default ResumoAvaliador;
