import React, { useReducer } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import TicketContentType from '../card/TicketContentType';
import dictionary from '../../../../dictionary';
import { API_URL } from '../../../../consts';
import { TICKET_TYPE } from '../../consts';
import { toastConfig } from '../../../../utils';
import { getAxiosConfig } from '../../../../services';

const NewTicketCard = ({
  onChangeAccordion,
  getAccordionArrow,
  getAccordionClass,
  possui_competencia_5,
  onTicketSended,
  onShowTicket,
  history,
  actionType,
  onError,
  onChangeTicketContentType,
  ticketContentType,
  getPostData,
}) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SENDING_TICKET':
        return {
          ...state,
          sendingTicket: true,
        };

      case 'TICKET_SEND_ERROR':
        return {
          ...state,
          sendingTicket: false,
        };

      case 'REACH_MAX_ATTEMPTS_SAVE':
        toast.error(dictionary.NETWORK_ERROR, toastConfig);
        return {
          ...state,
          attemptsToSave: 0,
          sendingTicket: false,
        };

      case 'TRYING_TO_SEND_AGAIN':
        return {
          ...state,
          attemptsToSave: state.attemptsToSave + 1,
        };

      default:
        return null;
    }
  };

  const initialState = {
    sendingTicket: false,
    attemptsToSave: 0,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const {
   sendingTicket, attemptsToSave,
  } = state;

  const isSendButtonDisabled = () => {
    const {
      type, id, question,
    } = getPostData;

    const outras_duvidas = 8;
    const values = ticketContentType === 'DUVIDA_PEDAGOGICA' ? (
      {
        correction: id,
        question,
      }
    ) : (
      {
        type,
        correction: id,
        question: type === outras_duvidas ? question : true,
      }
    );

    return Object.values(values).every(value => value);
  };

  const handleChangeAccordion = () => {
    if (actionType === 'show_ticket_only') {
      onShowTicket();
      onChangeTicketContentType(null);
    } else {
      onChangeAccordion('criarOcorrencia');
    }
  };


  const sendTicket = async (sair) => {
    const MAX_ATTEMPTS_SAVE = 5;
    const TIMEOUT_ATTEMPTS_SAVE = 1500;
    dispatch({ type: 'SENDING_TICKET' });

    const {
      id,
      question,
      correcao,
      angulo_imagem,
      correcao_situacao_id,
      competencia1,
      competencia2,
      competencia3,
      competencia4,
      competencia5,
      type,
    } = getPostData;

    const duvidas_type = TICKET_TYPE;

    const data = {
      type: ticketContentType === 'DUVIDA_PEDAGOGICA' ? duvidas_type : type,
      correction: id,
      question,
      correcao_situacao_id: correcao_situacao_id || null,
      dados_correcao: correcao,
      angulo_imagem: angulo_imagem || 0,
      competencia1,
      competencia2,
      competencia3,
      competencia4,
      competencia5,
    };

    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(`${API_URL}/tickets`, data, getAxiosConfig());
      if (sair) {
        history.push('/correcoes');
        toast.success('Ocorrência salva com sucesso!', toastConfig);
      } else {
        onTicketSended();
      }
    } catch (err) {
      const networkError = !err.response;
      if (networkError) {
        if (attemptsToSave > MAX_ATTEMPTS_SAVE) {
          dispatch({
            type: 'REACHED_MAX_ATTEMPTS_SAVE',
          });
        } else {
          dispatch({
            type: 'TRYING_TO_SEND_AGAIN',
          });
          setTimeout(() => sendTicket(), TIMEOUT_ATTEMPTS_SAVE);
        }
      } else {
        dispatch({ type: 'TICKET_SEND_ERROR' });
        onError(err);
      }
    }
  };


  return (
    <div className="card card-ocorrencias">
      <button
        type="button"
        className="card-header-correcao align-items-center"
        onClick={() => handleChangeAccordion()}
      >
        OCORRÊNCIA
        <i className={`fas ${actionType ? 'fa-times' : getAccordionArrow('criarOcorrencia')} float-right`} />
      </button>
      <div className={`collapse ${getAccordionClass('criarOcorrencia')}`}>
        <TicketContentType
          ticketContentType={ticketContentType}
          onChangeTicketContentType={onChangeTicketContentType}
          possui_competencia_5={possui_competencia_5}
          isSendButtonDisabled={isSendButtonDisabled}
          onSendTicket={sendTicket}
          sendingTicket={sendingTicket}
        />
      </div>
    </div>
  );
};

NewTicketCard.propTypes = {
  getPostData: PropTypes.shape({
    type: PropTypes.number,
    id: PropTypes.number,
    question: PropTypes.string,
    correcao_situacao_id: PropTypes.number,
    correcao: PropTypes.string,
    angulo_imagem: PropTypes.number,
    competencia1: PropTypes.number,
    competencia2: PropTypes.number,
    competencia3: PropTypes.number,
    competencia4: PropTypes.number,
    competencia5: PropTypes.number,
    competencia_type: PropTypes.number,
    situation_type: PropTypes.number,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onChangeAccordion: PropTypes.func,
  getAccordionArrow: PropTypes.func,
  getAccordionClass: PropTypes.func,
  possui_competencia_5: PropTypes.bool,
  onTicketSended: PropTypes.func,
  onShowTicket: PropTypes.func,
  onError: PropTypes.func,
  actionType: PropTypes.string,
  onChangeTicketContentType: PropTypes.func,
  ticketContentType: PropTypes.string,
};

NewTicketCard.defaultProps = {
  getPostData: {},
  onChangeAccordion: () => {},
  onTicketSended: () => {},
  onShowTicket: () => {},
  onError: () => {},
  onChangeTicketContentType: () => {},
  getAccordionArrow: () => {},
  getAccordionClass: () => {},
  actionType: '',
  ticketContentType: '',
  possui_competencia_5: false,
};

export default withRouter(NewTicketCard);
