import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import RecursoContext from '../contexts/RecursoContext';
import RecursosReducer from '../reducers/recursos/RecursoReducer';
import { SET_FORM_ERRORS, SET_RECURSOS_DATA, SET_RECURSOS_LOADING, SET_SUBMIT, SET_TIPOS_DEFERIMENTO } from '../reducers/recursos/types';
import { deferirRecurso, divulgarRecurso, fetchRecurso, fetchTiposDeferimento } from '../services/recursos';


const RecursoState = ({ children, match }) => {
  const initialState = {
    prova: {},
    recurso: {},
    errors: {},
    formApi: {},
    loadingRecurso: true,
    tiposDeferimento: [],
    submitting: false,
  };

  const [state, dispatch] = useReducer(RecursosReducer, initialState);

  const {
    recurso, loadingRecurso,
    errors, formApi, formState,
    tiposDeferimento, submitting,
  } = state;

  const { provaId } = match.params;

  const fetchRecursos = async () => {
    dispatch({ type: SET_RECURSOS_LOADING, payload: true });
    try {
      const data = await fetchRecurso(provaId);
      dispatch({ type: SET_RECURSOS_DATA, payload: data });
    } catch (error) {
      toast.error('Ocorreu um error. Tente novamente.');
      dispatch({ type: SET_RECURSOS_LOADING, payload: false });
    }
  };

  const getTiposDeferimento = async () => {
    const data = await fetchTiposDeferimento();
    dispatch({ type: SET_TIPOS_DEFERIMENTO, payload: data });
  };

  const handleDeferir = async (params) => {
    dispatch({ type: SET_SUBMIT, payload: true });
    try {
      await deferirRecurso(provaId, params);
      toast.success('Recurso respondido com sucesso!');
      fetchRecursos();
    } catch (error) {
      const { status } = error.response;
      if (status === 400) {
        dispatch({ type: SET_FORM_ERRORS, payload: error.response.data });
        const non_field_errors = get(error, 'response.data.non_field_errors', []);
        if (non_field_errors) {
          toast.error(non_field_errors.join(', '));
        }
      } else {
        toast.error('Ocorreu um erro. Tente novamente.');
      }
      dispatch({ type: SET_SUBMIT, payload: false });
    }
  };

  const handleDivulgar = async (params) => {
    dispatch({ type: SET_SUBMIT, payload: true });
    try {
      await divulgarRecurso(provaId, params);
      toast.success('Recurso respondido com sucesso!');
      fetchRecursos();
    } catch (error) {
      const { status } = error.response;
      if (status === 400) {
        dispatch({ type: SET_FORM_ERRORS, payload: error.response.data });
      } else {
        toast.error('Ocorreu um erro. Tente novamente.');
      }
    }
  };

  const handleChangeForm = fs => dispatch({ type: 'SET_FORM_STATE', payload: fs });

  const handleGetApi = api => dispatch({ type: 'SET_FORM_API', payload: api });

  useEffect(() => {
    fetchRecursos();
    getTiposDeferimento();
  }, []);

  return (
    <RecursoContext.Provider
      value={{
        recurso,
        loadingRecurso,
        errors,
        formApi,
        formState,
        handleDeferir,
        handleDivulgar,
        handleChangeForm,
        handleGetApi,
        tiposDeferimento,
        submitting,
      }}
    >
      {children}
    </RecursoContext.Provider>
  );
};

RecursoState.propTypes = {
  children: PropTypes.node.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      provaId: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(RecursoState);
