import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';
import BancoRedacoesContext from '../contexts/BancoRedacoesContext';
import HistoricosDesviosCorrecoesContext from '../contexts/HistoricosDesviosCorrecoesContext';
import HistoricoDesviosCorrecoesReducer from '../reducers/historico/HistoricoDesviosCorrecoesReducer';
import { SET_DESVIOS_DATA, SET_DESVIOS_ERROR, SET_HISTORICO_DESVIOS_CORRECOES_DATA, SET_HISTORICO_DESVIOS_CORRECOES_ERROR } from '../reducers/historico/types';
import { fetchDesvios, fetchHistoricoDeviosCorrecoes } from '../services/redacao';

const HistoricoDesviosCorrecoesState = ({ children, redacaoId, projetoId }) => {
  const initialState = {
    historicosDesviosCorrecoes: [],
    desvios: [],
    desviosError: false,
    loadingHistoricosDesviosCorrecoes: true,
    historicoDesviosCorrecoesError: false,
  };

  const [state, dispatch] = useReducer(HistoricoDesviosCorrecoesReducer, initialState);
  const {
    historicosDesviosCorrecoes, loadingHistoricos, historicoError, desvios,
  } = state;

  const getHistoricosDesviosCorrecoes = async () => {
    try {
      const data = await fetchHistoricoDeviosCorrecoes(redacaoId);
      dispatch({ type: SET_HISTORICO_DESVIOS_CORRECOES_DATA, payload: data });
    } catch (error) {
      dispatch({ type: SET_HISTORICO_DESVIOS_CORRECOES_ERROR });
    }
  };

  const getDesvios = async () => {
    try {
      const data = await fetchDesvios({ projeto: projetoId });
      dispatch({ type: SET_DESVIOS_DATA, payload: data });
    } catch (error) {
      dispatch({ type: SET_DESVIOS_ERROR });
    }
  };

  useEffect(() => {
    if (redacaoId && projetoId) {
      getHistoricosDesviosCorrecoes();
      getDesvios();
    }
  }, [redacaoId]);

  return (
    <BancoRedacoesContext.Provider
      value={{ desvios }}
    >
      <HistoricosDesviosCorrecoesContext.Provider
        value={{
          historicosDesviosCorrecoes,
          loadingHistoricos,
          historicoError,
          getHistoricosDesviosCorrecoes,
        }}
      >
        {children}
      </HistoricosDesviosCorrecoesContext.Provider>
    </BancoRedacoesContext.Provider>
  );
};

HistoricoDesviosCorrecoesState.propTypes = {
  children: PropTypes.node,
  redacaoId: PropTypes.number,
  projetoId: PropTypes.number,
};

HistoricoDesviosCorrecoesState.defaultProps = {
  children: null,
  redacaoId: null,
  projetoId: null,
};

export default HistoricoDesviosCorrecoesState;
