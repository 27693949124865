import React from 'react';


const BancoRedacoesContext = React.createContext({
  onSubmit: () => null,
  verHistoricoCandidato: false,
  verDadosCandidato: false,
  downloadImagemOriginal: false,
  fetching: false,
  onBackClick: () => null,
  redacao: null,
  historicoCorrecoes: [],
  fetchingHistoricoCorrecoes: false,
  verNomeAvaliadorHistorico: false,
  showLegend: false,
  desvios: [],
});

export default BancoRedacoesContext;
