import React from 'react';
import { withFormState } from 'informed';
import PropTypes from 'prop-types';
import {
  TextAreaInput, ReactSelectInput, EnviarSairButton, EnviarBuscarMaisUmButton,
} from '../../..';
import TicketContentSection from './TicketContentSection';
import { TICKET_TYPES } from '../../consts';
import dictionary from '../../../../dictionary';

const TicketContentType = ({
  ticketContentType,
  onChangeTicketContentType,
  isSendButtonDisabled,
  onSendTicket,
  sendingTicket,
}) => {
  const PROBLEMA_IMAGEM = TICKET_TYPES.filter(ticketType => ticketType.category === 2);

  switch (ticketContentType) {
    case 'DUVIDA_PEDAGOGICA':
      return (
        <TicketContentSection
          title="DÚVIDA PEDAGÓGICA"
          onSendTicket={onSendTicket}
          onChangeTicketContentType={onChangeTicketContentType}
          ticketContentType={ticketContentType}
          isSendButtonDisabled={isSendButtonDisabled}
          sendingTicket={sendingTicket}
        >
          <TextAreaInput label="OBSERVAÇÃO" field="question" placeholder="Descreva o motivo da ocorrência" rows="3" />
        </TicketContentSection>
      );
    case 'PROBLEMA_IMAGEM':
      return (
        <TicketContentSection
          title="PROBLEMA DE IMAGEM"
          onSendTicket={onSendTicket}
          onChangeTicketContentType={onChangeTicketContentType}
          ticketContentType={ticketContentType}
          isSendButtonDisabled={isSendButtonDisabled}
          sendingTicket={sendingTicket}
        >
          <ReactSelectInput
            isClearable
            label="PROBLEMA DE IMAGEM"
            field="type"
            options={PROBLEMA_IMAGEM}
          />
          <TextAreaInput
            label="OBSERVAÇÃO"
            field="question"
            placeholder="Descreva o motivo da ocorrência"
            rows="3"
          />
        </TicketContentSection>
      );
    default:
      return (
        <div className="card-body">
          <button
            type="button"
            onClick={() => onChangeTicketContentType('DUVIDA_PEDAGOGICA')}
            className="btn bg-warning-color w-100"
          >
            DÚVIDAS PEDAGÓGICAS
          </button>
          <button
            type="button"
            onClick={() => onChangeTicketContentType('PROBLEMA_IMAGEM')}
            className="btn bg-warning-color w-100 mt-2 mb-2"
          >
            PROBLEMAS DE IMAGEM
          </button>
          <div className="row mt-3">
            <div className="col-md-6 pr-1">
              <EnviarSairButton
                onClick={() => onSendTicket({ sair: true })}
                disabled={sendingTicket || !isSendButtonDisabled()}
                clickOnDisabledMessage={dictionary.POST_OCORRENCIA_PREENCHER_CAMPOS}
              />
            </div>
            <div className="col-md-6 pl-1">
              <EnviarBuscarMaisUmButton
                onClick={() => onSendTicket()}
                disabled={sendingTicket || !isSendButtonDisabled()}
                clickOnDisabledMessage={dictionary.POST_OCORRENCIA_PREENCHER_CAMPOS}
              />
            </div>
          </div>
        </div>
      );
  }
};

TicketContentType.propTypes = {
  ticketContentType: PropTypes.string,
  onChangeTicketContentType: PropTypes.func,
  possui_competencia_5: PropTypes.bool,
  formState: PropTypes.shape({
    values: PropTypes.object,
  }).isRequired,
};

TicketContentType.defaultProps = {
  ticketContentType: '',
  onChangeTicketContentType: () => {},
  possui_competencia_5: false,
};

export default withFormState(TicketContentType);
