import React from 'react';
import img from '../assets/images/manutencao.png';
import imgLogo from '../assets/images/logo/logo.png';

class Manutencao extends React.Component {
  render() {
    return (
      <section className="manutencao">
        <div className="row">
          <div className="col">
            <div className=" text-center">
              <figure className="logo-manutencao">
                <img src={imgLogo} alt="logo-fgv" />
              </figure>
              <figcaption className="pt-3">
                <h2>Estamos em <strong>manutenção!</strong></h2>
                <h5>Em breve estaremos no ar. Por favor, aguarde.</h5>
              </figcaption>
              <figure className="img-manutencao">
                <img src={img} className="img-responsive" alt="img-manutencao" />
              </figure>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Manutencao;
