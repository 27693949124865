import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL, BASE_URL } from '../consts';
import { getAxiosConfig } from '../services/auth';
import dictionary from '../dictionary';
import { toastConfig } from '../utils';
import { getError } from '../services/utils';
import {
  ErrorList,
} from '../components';
import { ComponentBase } from '../base';

class CorrecaoBase extends ComponentBase {
  constructor(props) {
    super(props);
    this.buscarMaisUm = this.buscarMaisUm.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleError(error) {
    const { history } = this.props;
    const { status, data } = getError(error);

    switch (status) {
      case 400:
        toast.error(<ErrorList errors={data} />, toastConfig);
        break;
      case 401:
        toast.error(data.detail, toastConfig);
        history.push('/login');
        break;
      case 403:
        toast.error(data.detail, toastConfig);
        history.push('/');
        break;
      case 404:
        toast.error(this.getMessage('NENHUMA_REDACAO_DISPONIVEL'), toastConfig);
        history.push('/');
        break;
      default:
        toast.error(dictionary.ERRO_DESCONHECIDO, toastConfig);
    }
  }


  buscarMaisUm(errorCallback, projeto_id) {
    const { tipo_auditoria_id } = this.state;
    const params = {
      tipo: tipo_auditoria_id,
      projeto: projeto_id,
    };
    const headers = getAxiosConfig();
    const config = {
      params,
      ...headers,
    };
    axios.get(`${API_URL}/correcoes/buscar-mais-um`, config)
      .then((response) => {
        const { id_correcao, abrir_modal_quarta } = response.data;
        if (abrir_modal_quarta && id_correcao) {
          this.setState({
            id_correcao,
            modal_supervisor: true,
          });
        } else {
          window.location.replace(`${BASE_URL}/correcoes/${id_correcao}`);
        }
      })
      .catch((error) => {
        if (errorCallback) errorCallback();
        this.handleError(error);
      });
  }
}

CorrecaoBase.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};


export default CorrecaoBase;
