import React from 'react';
import axios from 'axios';
import { debounce, get } from 'lodash';
import { toast } from 'react-toastify';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services';
import Container from '../components/v2/Container';
import Loading from '../components/Loading';


class ConfigurarAlertasPage extends React.Component {
  updateParametros = debounce((id, valor) => {
    const params = { valor };
    axios.put(`${API_URL}/v2/relatorios/tolerancia/configuracao/${id}`, params, getAxiosConfig())
      .then(() => {
        this.setState(prevState => ({
          errors: { ...prevState.errors, [id]: null },
        }));
      })
      .catch((e) => {
        const mensagem = get(e, 'response.data.valor');
        this.setState(prevState => ({
          errors: { ...prevState.errors, [id]: mensagem },
        }));
      });
  }, 500)

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      parametros: {},
      parametrosAlerta: [],
      errors: {},
      errorsAlerta: {},
    };
  }

  componentDidMount() {
    this.fetchParametros();
    this.fetchParametrosAlerta();
  }

  handleFormChange = (event, relatorio) => {
    const {
      name,
      value,
      id,
    } = event.target;
    const { parametros } = this.state;
    const newParametros = parametros[relatorio].map((parametro) => {
      if (parametro.coluna === name) {
        parametro.valor = value;
      }
      return parametro;
    });
    this.setState(prevState => ({
      parametros: {
        ...prevState.parametros,
        [relatorio]: newParametros,
      }
    }));
    this.updateParametros(id, value);
  }

  handleAlertasChange = (id, event) => {
    const { value } = event.target;
    this.setState(prevState => ({
      parametrosAlerta: prevState.parametrosAlerta.map((parametro) => {
        if (parametro.id === id) {
          parametro.valor = value
        }
        return parametro
      })
    }));
    this.updateParametrosAlertas(id, value);
  }

  updateParametrosAlertas = debounce((id, valor) => {
    const params = { valor };
    axios.put(`${API_URL}/alertas/parametros/${id}`, params, getAxiosConfig())
      .then(() => {
        this.setState(prevState => ({
          errorsAlerta: { ...prevState.errors, [id]: null },
        }));
      })
      .catch((e) => {
        const mensagem = get(e, 'response.data.valor');
        this.setState(prevState => ({
          errorsAlerta: { ...prevState.errors, [id]: mensagem },
        }));
      });
  }, 500)

  fetchParametros() {
    this.setState({ loading: true });
    axios.get(`${API_URL}/v2/relatorios/tolerancia/configuracao`, getAxiosConfig())
      .then((response) => {
        const parametros = response.data.reduce((acc, param) => {
          if (acc[param.relatorio_descricao]) {
            acc[param.relatorio_descricao] = [...acc[param.relatorio_descricao], param];
            return acc;
          }
          acc[param.relatorio_descricao] = [param];
          return acc;
        }, {})
        this.setState({ parametros, loading: false });
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao carregar os parâmetros. Por favor, tente novamente.');
        this.setState({ loading: false });
      });
  }

  fetchParametrosAlerta() {
    axios.get(`${API_URL}/alertas/parametros`, getAxiosConfig())
      .then((response) => {
        const parametrosAlerta = response.data;
        this.setState({ parametrosAlerta });
      })
  }

  renderContent() {
    const {
      loading,
      parametros,
      errors,
      errorsAlerta,
      parametrosAlerta,
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <form className="d-flex flex-column mt-3">
        {
          Object.keys(parametros).map(relatorio => (
            <div key={relatorio} className="mt-3">
              <div className="col col-xs-12 panel-default">
                <div className="panel-title">
                  <h2 className="pt-1">{relatorio}</h2>
                </div>
                <div className="panel-body">
                  {
                    parametros[relatorio].map(parametro => (
                      <div key={parametro.id}>
                        <label className="mt-2 mr-4 label-min-width">
                          {parametro.descricao}:
                        </label>
                        <input
                          type="text"
                          className="form-control w-50"
                          value={parametro.valor || ''}
                          name={parametro.coluna}
                          id={parametro.id}
                          onChange={event => this.handleFormChange(event, relatorio)}
                        />
                        <small style={{ color: 'red' }}>{errors[parametro.id]}</small>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          ))
        }
        <div className="mt-3">
          <div className="col col-xs-12 panel-default">
            <div className="panel-title">
              <h2 className="pt-1">Alertas dos Avaliadores</h2>
            </div>
            <div className="panel-body">
              {
                parametrosAlerta.map(parametro => (
                  <div key={parametro.id}>
                    <label className="mt-2 mr-4 label-min-width">
                      {parametro.descricao}:
                    </label>
                    <input
                      type="text"
                      className="form-control w-50"
                      value={parametro.valor || ''}
                      id={parametro.id}
                      onChange={event => this.handleAlertasChange(parametro.id, event)}
                    />
                    <small style={{ color: 'red' }}>{errorsAlerta[parametro.id]}</small>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </form>
    )
  }

  render() {
    return (
      <Container title="Configurar Alertas">
        {this.renderContent()}
      </Container>
    );
  }
}

export default ConfigurarAlertasPage;
