import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import QRious from 'qrious';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get, size } from 'lodash';

import imgLogo from '../assets/images/logo/logo.png';
import dictionary from '../dictionary';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services';
import { getError, setFormErrors } from '../services/utils';
import { toastConfig } from '../utils';
import { Loading } from '../components';
import { setAuthorizedToken } from '../services/auth';


class Login2 extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  state = {
    qrcode: null,
    loading: true,
  }

  componentDidMount() {
    const stateFromLogin = get(this.props, 'location.state');

    if (stateFromLogin) {
      const { uri, account, secret, environment, ...rest } = stateFromLogin;
      const value = `${account};${environment};${secret}`
      const qrcode = new QRious({ value });
      this.setState({
        loading: false,
        qrcode: qrcode.toDataURL(),
        account,
        secret,
        ...rest,
      });
      this.loginPooling()
    }
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  loginPooling() {
    const { history, location } = this.props;
    const username = get(location, 'state.account');
    const params = { username }
    this._interval = setInterval(() => {
      axios.get(`${API_URL}/login_pooling`, { params })
        .then(response => {
          if (response.data.token) {
            setAuthorizedToken(response.data.token);
            history.push('/');
          }
          if (response.data.invalidado) {
            history.push('/')
          }
        })
    }, 5000)
  }

  handleSubmit(values) {
    this.setState({ submitting: true });
    const { history } = this.props;
    const { secret } = this.state;

    axios.post(`${API_URL}/totp/`, {
      ...values,
      secret,
    }, getAxiosConfig())
      .then((response) => {
        setAuthorizedToken(response.data.token);
        history.push('/');
      })
      .catch(this.handleError);
  }

  handleError(error) {
    this.setState({ submitting: false });

    const { history } = this.props;
    const { status, data } = getError(error);
    const codeErrors = get(data, 'code', []);

    switch (status) {
      case 400:
        if (size(codeErrors) > 0) {
          setFormErrors(this.formApi, { code: codeErrors });
        } else {
          toast.error(dictionary.ERRO_DESCONHECIDO, toastConfig);
        }
        break;
      case 401:
      case 403:
        toast.error(dictionary.LOGIN_INVALIDO, toastConfig);
        history.push('/login');
        break;
      default:
        toast.error(dictionary.ERRO_DESCONHECIDO, toastConfig);
    }
  }

  renderForm() {
    const {
      qrcode, secret_is_new,
    } = this.state;
    return (
      <div className="row row-eq-height">
        <div className="col-12">
          <h2>Código de verificação</h2>
          <p><i className="fas fa-lock" /> Abra o app <b>Educat 2FA</b> em seu celular e confirme o seu acesso ao sistema.</p>
        </div>
        {secret_is_new && (
        <React.Fragment>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-1">
            <h3>Código QR</h3>
            <img alt="qrcode" className="qr-code" src={qrcode} />
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 mt-1">
            <div className="login-instructions">
              <h3>Instruções</h3>
              <p><span>1. </span>Instale o app <b>Educat 2FA</b> em seu smartphone: <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/educat-2fa/id1479287903?l=pt&ls=1">iOS</a> ou <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.educat_2fa&hl=pt-BR">Android</a>.</p>
              <p><span>2. </span>Escaneie o QR-Code pelo aplicativo.</p>
              <p><span>3. </span>Aguarde pela mensagem de confirmação na tela do seu aplicativo.</p>
              <hr />
              <p>Caso apareça a mensagem de código inválido, tente ressincronizar o seu
celular com o sistema de correção seguindo os passos deste <Link to="">manual.</Link>
              </p>
            </div>
          </div>
        </React.Fragment>
         )}
        <div className="col-12">
          <div className="row">
            <div className="col">
              <div className="text-center recuperar-senha mt-5 pt-5">
                <Link to="/login">
                  <span>Voltar para a tela de login</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      loading,
    } = this.state;

    const stateFromLogin = get(this.props, 'location.state');
    if (!stateFromLogin) {
      return <Redirect to="/login" />;
    }

    return (
      <section className="login-bg login-2">
        <div className="container">
          <div className="box-form ">
            <figure>
              <img src={imgLogo} className="logo-login" alt="logo-fgv" />
            </figure>
            {loading ? <Loading /> : this.renderForm()}
          </div>
        </div>
      </section>
    );
  }
}

Login2.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(Login2);
