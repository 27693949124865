import React from 'react';
import { Link } from 'react-router-dom';
import img404 from '../assets/images/404/img404.svg';
import { CONTATO } from '../consts';

class NotFound extends React.Component {
  render() {
    return (
      <div className="not-found text-center">
        <figure>
          <img src={img404} alt="img-error-404" className="img-fluid" />
        </figure>
        <h1>404</h1>
        <h2><strong>Página não encontrada</strong></h2>
        <p>Se o problema persistir, entre em contato com <strong>{CONTATO.EMAIL}</strong> informando o erro  <br /> e continue para à <Link to="/"><u><strong>página inicial do site</strong></u></Link>.
        </p>
      </div>
    );
  }
}

export default NotFound;
