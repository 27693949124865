import React from 'react';
import PropTypes from 'prop-types';

class StatusTag extends React.Component {
  static propTypes = {
    cor: PropTypes.string,
    icone: PropTypes.string,
    descricao: PropTypes.string,
  };

  static defaultProps = {
    cor: null,
    icone: 'fas fa-circle',
    descricao: 'Sem Status',
  }

  render() {
    const { cor, icone, descricao } = this.props;

    return (
      <React.Fragment>
        <div className="status-tag">
          <i className={`${icone} ${cor}`} />
          <p>{descricao}</p>
        </div>
      </React.Fragment>
    );
  }
}

export default StatusTag;
