import React, { Fragment } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { getAxiosConfig } from '../services';
import { API_URL } from '../consts';
import Footer from '../components/Footer';

class CarometroSupervisor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listaCarometro: [],
    };
  }

  componentDidMount() {
    this.fetchListaCartometro();
  }

  fetchListaCartometro() {
    axios.get(`${API_URL}/avaliadores`, getAxiosConfig())
    .then((response) => {
      const { records } = response.data;
      this.setState({ listaCarometro: records });
    });
  }

  render() {
    const { listaCarometro } = this.state;

    return (
      <Fragment>
        <div className="container">
          <div className="page-container-border">
            <section>
              <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                  <h1 className="text-title">Carômetro de Avaliadores <span>TIME MG01</span></h1>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 filtro">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Procurar" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 filtro">
                  <div className="form-group form-select">
                    <select className="form-control" id="exampleFormControlSelect1">
                      <option>Filtrar por</option>
                      <option>Resolvido</option>
                      <option>Pendente</option>
                      <option>Fechado</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 filtro">
                  <button type="button" className="btn btn-primary bg-primary-color  pull-left">BUSCAR</button>
                </div>
              </div>
              <div className="row">
                {listaCarometro.map(record => (
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="row carometro">
                      <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 imagen-avaliador">
                        <img alt="perfil" src={(get(record, 'foto'))} />
                      </div>
                      <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 info-avaliador">
                        <p><span>Nome: </span>{get(record, 'nome')}</p>
                        <p><span>Time: </span>{get(record, 'time')}</p>
                        <p><span>Email: </span>{get(record, 'email')}</p>
                        <p><span>Telefone: </span>{get(record, 'telefone')}</p>
                        <p><span>Correções: </span>{get(record, 'correcoes')}</p>
                        <p>
                          <span>Coef. Desempenho: </span>
                          <span className="pontos">{get(record, 'ranking')}</span>
                        </p>
                        <p className="updated">Atualizado dia {get(record, 'atualizacao.data')}</p>
                      </div>
                    </div>
                  </div>
                  ))}
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export default CarometroSupervisor;
