import { get } from 'lodash';
import React, { useContext } from 'react';
import Redacao from '../../../containers/Redacao';
import RecursoContext from '../../../contexts/RecursoContext';
import HistoricoDesviosCorrecoesState from '../../../states/HistoricoDesviosCorrecoesState';
import { getShapesWithSetas } from '../../../utils';
import Loading from '../../Loading';
import DropdownCard from '../dropdown_card/DropdownCard';
import RecursoHeader from './RecursoHeader';
import RecursosDetails from './RecursosDetails';

const Recurso = () => {
  const { loadingRecurso, recurso } = useContext(RecursoContext);

  const renderRedacao = () => {
    if (loadingRecurso) {
      return <Loading />;
    }
    return (
      <Redacao
        src={get(recurso, 'correcao.link_imagem_recortada')}
        show={!loadingRecurso}
        fetchImagemError={loadingRecurso}
        isAbleToEdit={false}
        shapes={getShapesWithSetas(
          get(recurso, 'correcao.correcao'),
          get(recurso, 'correcao.setas'),
        )}
      />
    );
  };

  return (
    <HistoricoDesviosCorrecoesState
      redacaoId={get(recurso, 'redacao.id')}
      projetoId={get(recurso, 'redacao.projeto')}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-8">
            {renderRedacao()}
          </div>
          <div className="col-4">
            <DropdownCard title="RECURSOS">
              <div className="p-3">
                <RecursoHeader />
                <RecursosDetails />
              </div>
            </DropdownCard>
          </div>
        </div>
      </div>
    </HistoricoDesviosCorrecoesState>
  );
};

export default Recurso;
