import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { API_URL } from '../../../../consts';
import { ComponentBase } from '../../../../base';
import { statusCorretorCaption } from '../../../../components/v2/consts';

class PadraoOuroTime extends ComponentBase {
  get columns() {
    return (
        [
        {
          header: '#',
          accessor: 'row',
          width: 50,
          Cell: row => <div>{row.index + 1}</div>,
          footer: {
            mediaNacional: {
              accessor: '',
              default: '-',
            },
            mediaTime: {
              accessor: '',
              default: '-',
            },
          },
        },
        {
          header: 'Polo',
          withSortIcon: true,
          accessor: 'polo_descricao',
          Cell: d => (<div className="column-left">{d.value}</div>),
          minWidth: 200,
          footer: {
            mediaNacional: {
              accessor: '',
              default: 'Padrão Ouro Nacional',
            },
            mediaTime: {
              accessor: '',
              default: 'Padrão Ouro Time',
            },
          },
        },
        {
          header: 'Time',
          withSortIcon: true,
          accessor: 'time_descricao',
          Cell: d => (<div className="column-left">{d.value}</div>),
          minWidth: 200,
          footer: {
            mediaNacional: {
              accessor: '',
              default: '-',
            },
            mediaTime: {
              accessor: '',
              default: '-',
            },
          },
        },
        {
          header: 'Avaliadores',
          withSortIcon: true,
          accessor: 'avaliador',
          next: {
            pathname: '/relatorios/v2/padrao-ouro-individual',
            stateFields: ['polo', 'time', 'avaliador'],
          },
          Cell: d => (<div className="column-left">{d.value}</div>),
          minWidth: 300,
          footer: {
            mediaNacional: {
              accessor: '',
              default: '-',
            },
            mediaTime: {
              accessor: '',
              default: '-',
            },
          },
        },
        {
          header: 'Padrão ouro corrigida',
          accessor: 'nr_padrao_ouro',
          withSortIcon: true,
          minWidth: 120,
          defaultValue: 0,
          footer: {
            mediaNacional: {
              accessor: 'nr_padrao_ouro',
              default: '-',
            },
            mediaTime: {
              accessor: 'nr_padrao_ouro',
              default: '-',
            },
          },
        },
        {
          header: 'Discrepância padrão ouro (%)',
          accessor: 'media_discrepancia_padrao_ouro',
          withSortIcon: true,
          sortMethod: true,
          minWidth: 120,
          defaultValue: '0,00%',
          footer: {
            mediaNacional: {
              accessor: 'media_discrepancia_padrao_ouro',
              default: '-',
            },
            mediaTime: {
              accessor: 'media_discrepancia_padrao_ouro',
              default: '-',
            },
          },
        },
      ]
    );
  }

  render() {
    const backAction = this.hasPermission('ver_relatorio_padrao_ouro_polo') ? {
      path: '/relatorios/v2/padrao-ouro-time',
      state: ['polo'],
    } : undefined;

    const exportData = {
      url: `${API_URL}/v2/relatorios/padrao-ouro/times/excel`,
      filename: 'padrao_ouro_por_time.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    const caption = {
      title: 'Legenda',
      captions: statusCorretorCaption,
    }

    return (
      <Relatorio
        isPoloInputRequired
        isTimeInputRequired
        title="PADRÃO-OURO | Avaliadores"
        dataUrl="v2/relatorios/padrao-ouro/times"
        mediaNacionalUrl="v2/relatorios/padrao-ouro/geral/media"
        mediaTimeUrl="v2/relatorios/padrao-ouro/geral/media"
        fetchCorretoresInativos="correcoes/corretores_inativos"
        backAction={backAction}
        columns={this.columns}
        exportData={exportData}
        caption={caption}
        codigoMensagem="PADRAO_OURO_AVALIADOR"
        codigo="PADRAO_OURO"
        baseAvaliadores
      />
    );
  }
}

export default PadraoOuroTime;
