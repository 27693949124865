import React, { Component } from 'react';
import { PropTypes } from 'prop-types';


class OperatorChoiceInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opperator: '===',
      text: '',
    };
  }

  onFormChange(event, field) {
    const { onChange } = this.props;
    const { value } = event.target;
    this.setState({ [field]: value }, () => onChange(this.state));
  }

  render() {
    const { label, className, text, opperator } = this.props;

    return (
      <div className={className}>
        <label className="mt-2 mr-4 label-min-width">{label}</label>
        <div className="d-flex">
          <select className="form-control w-auto" value={opperator} onChange={event => this.onFormChange(event, 'opperator')}>
            <option value="===">=</option>
            <option value=">=">≥</option>
            <option value="<=">≤</option>
          </select>
          <input
            type="text"
            value={text}
            className="form-control ml-1"
            onChange={event => this.onFormChange(event, 'text')}
          />
        </div>
      </div>
    );
  }
}

OperatorChoiceInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  opperator: PropTypes.string,
};

OperatorChoiceInput.defaultProps = {
  label: '',
  className: '',
  text: '',
  opperator: '===',
};

export default OperatorChoiceInput;
