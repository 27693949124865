import { asField } from 'informed';
import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import InputMask from './InputMask';

const numberMask = createNumberMask({
  prefix: '',
  suffix: '',
  decimalSymbol: '.',
  allowDecimal: true,
  includeThousandsSeparator: false,
  decimalLimit: 2,
});

export default asField(({
  fieldApi, fieldState, onChange, className, ...props
}) => (
  <div className={`text-input-form-state ${className} ${fieldState.error ? 'red' : ''}`}>
    <InputMask
      mask={numberMask}
      value={fieldState.value}
      onChange={(event) => {
        fieldApi.setError(null);
        fieldApi.setValue(event.target.value);
        if (onChange) {
          onChange(event.target.value);
        }
      }}
      {...props}
    />
    {fieldState.error && <span className="input-helper red">{fieldState.error}</span>}
  </div>
));
