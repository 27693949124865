import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Redacao from '../../../containers/Redacao';
import BancoRedacoesContext from '../../../contexts/BancoRedacoesContext';
import HistoricoCorrecoes from '../historico_correcoes/HistoricoCorrecoes';

const ImagemRedacaoExternaContent = ({ shapes }) => {
  const {
    redacao: {
      src,
      nota,
      situacao,
      show_situacao,
    }, fetchImagemError,
    historicoCorrecoes, fetchingHistoricoCorrecoes,
  } = useContext(BancoRedacoesContext);

  return (
    <div className="row">
      <div className="col-md-8 col-sm-8 col-xs-8">
        <Redacao
          src={src}
          shapes={shapes}
          show={!fetchImagemError}
          fetchImagemError={fetchImagemError}
          isAbleToEdit={false}
          canRotacionar={false}
        />
      </div>
      <div className="col-md-4 col-sm-4 col-xs-4">
          <HistoricoCorrecoes
            historicoCorrecoes={historicoCorrecoes}
            fetching={fetchingHistoricoCorrecoes}
            redacao={{ nota, situacao, show_situacao }}
            verHeader={false}
            forceShowSituacao={false}
            showNotaFinal
          />
      </div>
    </div>
  );
};

ImagemRedacaoExternaContent.propTypes = {
  shapes: PropTypes.shape({
    id: PropTypes.number,
  }),
};

ImagemRedacaoExternaContent.defaultProps = {
  shapes: {},
};

export default ImagemRedacaoExternaContent;
