import React from 'react';
import Rodal from 'rodal';
import Redacao from '../../../containers/Redacao';


class RedacaoDesempenhoModal extends React.Component {
  render() {
    const {
      src,
      isOpen,
      onClose,
      redacao,
    } = this.props;

    return (
      <Rodal
        visible={isOpen}
        onClose={onClose}
        closeOnEsc
        className="redacao-modal non-fixed-rodal"
      >
        <div className="row" style={{ margin: '20px' }}>
          <div className="col-md-8 col-sm-8 col-xs-8">
            <Redacao scrollTools src={src} show />
          </div>
          {
            (src && redacao) && (
              <div className="col-md-4 col-sm-4 col-xs-4">
                <div className="tabela-gabarito">
                  <div className="avaliador-table-labels">
                    <h3 className="no-border-text-title">Gabarito</h3>
                  </div>
                  <table className="table-tag">
                    <tbody>
                      <tr className="table-header">
                        <th className="table-data">Soma</th>
                        <th className="table-data">I</th>
                        <th className="table-data">II</th>
                        <th className="table-data">III</th>
                        <th className="table-data">IV</th>
                        <th className="table-data">V</th>
                      </tr>
                      <tr className="table-row">
                        <td className="table-data">{redacao.nota_final}</td>
                        <td className="table-data">{redacao.id_competencia1}</td>
                        <td className="table-data">{redacao.id_competencia2}</td>
                        <td className="table-data">{redacao.id_competencia3}</td>
                        <td className="table-data">{redacao.id_competencia4}</td>
                        <td className="table-data">{redacao.id_competencia5 === -1 ? 'DDH' : redacao.id_competencia5}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )
          }
        </div>
      </Rodal>
    );
  }
}

export default RedacaoDesempenhoModal
