import React, { Fragment } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { BarChart } from 'd3plus-react';

const config = {
  shapeConfig: {
    fill: d => d.color,
    label: d => d.id,
    width: 80,
  },
  // tooltipConfig: {
  //   title: '',
  //   thead: d => [`${d.y} Corrigidas`],
  //   background: '#fff',
  //   width: '150px',
  //   tbody: [
  //     [(d) => {
  //       const b = `
  //       <div className="row">
  //         <div className="col">
  //           <small> ${d.labels} CORREÇÃO </small>
  //         </div>
  //       </div>`;
  //      return b;
  //       }],
  //   ],
  // },
  legend: true,
  id: 'id',
  type: 'Bar',
  x: 'x',
  y: 'y',
  tooltipConfig: {
    title: '',
    thead: d => [`${d.y} Corrigidas`],
    width: '150px',
    tbody: [[d => `<small> ${d.id} </small>`]],
  },
  stacked: true,
  size: d => d.x,
  baseline: 10,
  title: '',
  legendConfig: {
    align: 'left',
    shapeConfig: {
      height: 25,
      width: 25,
      rx: 25,
      ry: 25,
    },
    padding: 10,
  },
};


class CorrecoesGraph extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Fragment>
        {
          isEmpty(data)
            ? (
              <div className="panel-body">
                <article className="painel-no-data">
                  <p>A quantidade necessária de correções para a visualização do gráfico ainda não foi atingida.</p>
                </article>
              </div>
            )
            : (
                <Fragment>
                  <p className="label-correcoes">
                    Correções
                  </p>
                  <section className="mt-4">
                    <BarChart config={{ ...config, data }} />
                  </section>
                  <p className="label-dia">
                    Dias
                  </p>
                  <section className="mt-4">
                    <section className="legend-graph">
                      <div className="row">
                        <div className="col">
                          {/* <ul className="ml-4">
                            {data.map(x => (
                                <Fragment>
                                  <li key={x.id}>
                                    <div className={`shape-legend mb-2 legend_${x.id}`} />
                                    <span className="label-legend">
                                      {x.labels}
                                    </span>
                                  </li>
                                </Fragment>
                              ))
                            }
                          </ul> */}
                        </div>
                      </div>
                    </section>
                    {/* <section>
                      <div className="row">
                        <div className="col">
                          <article className="painel-info-atualizacao">
                            <p className="text-no-data">
                              Atualizado pela última vez: <strong>{data.criado_em}</strong>
                            </p>
                          </article>
                        </div>
                        <div className="col">
                          <article>
                            <div className="box-total-graph pull-right mr-4">
                              <span className="label-total"><strong>TOTAL DO TIME:</strong></span>
                              <span className="ml-2">{data.total}</span>
                            </div>
                          </article>
                        </div>
                      </div>
                    </section> */}
                  </section>
                </Fragment>
              )
            }
      </Fragment>
    );
  }
}

CorrecoesGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    id: PropTypes.string,
    color: PropTypes.string,
  })),
};

CorrecoesGraph.defaultProps = {
  data: [],
};

export default CorrecoesGraph;
