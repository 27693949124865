import React from 'react';
import Rodal from 'rodal';
import { get } from 'lodash';
import { Form } from 'informed';
import axios from 'axios';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services';
import { getYear } from '../utils';
import TextInput from './TextInput';


class ModalVerificarAuxiliar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: {},
      modal_supervisor_errors: {},
      auxiliar1: '',
      auxiliar2: '',
      validated: false,
    };
  }

  onValidate() {
    const { id_correcao } = this.props;
    const { formState } = this.state;
    const data = { ...formState.values, id_correcao };
    axios.post(`${API_URL}/verificar-codigo-auxiliar`, data, getAxiosConfig())
      .then((response) => {
        const { auxiliar1, auxiliar2 } = response.data;
        this.setState({
          validated: true,
          modal_supervisor_errors: {},
          auxiliar1,
          auxiliar2,
        });
      })
      .catch((err) => {
        this.setState({
          modal_supervisor_errors: get(err, 'response.data'),
          validated: false,
          auxiliar1: get(err, 'response.data.auxiliar1'),
          auxiliar2: get(err, 'response.data.auxiliar2'),
        });
      });
  }

  onSubmit() {
    const { history, id_correcao } = this.props;
    history.push(`/correcoes/${id_correcao}`);
  }

  render() {
    const { visible, onClose } = this.props;
    const { modal_supervisor_errors, validated, auxiliar1, auxiliar2 } = this.state;
    return (
      <div className="modal-supervisor">
        <Rodal visible={visible} onClose={onClose}>
          <div className="modal-title-supervisor">
            <h3>Correções 4ª</h3>
          </div>
          <div className="modal-body-supervisor">
            <div className="modal-content-supervisor">
              <h5 className="modal-content-supervisor-title">ENEM REDAÇÕES {getYear()}</h5>
              <hr className="modal-line-supervisor" />
              <div className="modal-content-supervisor-body">
                <Form
                  onChange={formState => this.setState({ formState })}
                >
                  <div className="text-input">
                    <div className="input-line">
                      <TextInput
                        field="code1"
                        label="TOKEN AUXILIAR 1"
                        style={auxiliar1 ? { borderColor: '#5cb85c' } : modal_supervisor_errors.code1 && { borderColor: 'red' }}
                      />
                      {auxiliar1 && (
                        <div>
                          <label className="nome-auxiliar">{auxiliar1}</label>
                          <i className="fa fa-check" style={{ fontSize: 20, marginLeft: 10, color: '#5cb85c' }} />
                        </div>
                      )}
                    </div>
                    {
                      modal_supervisor_errors.code1
                        && modal_supervisor_errors.code1.map(error => (
                          <label style={{ color: 'red', fontSize: 14 }}>{error}</label>
                        ))
                    }
                    <div className="input-line">
                      <TextInput
                        field="code2"
                        label="TOKEN AUXILIAR 2"
                        style={auxiliar2 ? { borderColor: '#5cb85c' } : modal_supervisor_errors.code2 && { borderColor: 'red' }}
                      />
                      {auxiliar2 && (
                        <div>
                          <label className="nome-auxiliar">{auxiliar2}</label>
                          <i className="fa fa-check" style={{ fontSize: 20, marginLeft: 10, color: '#5cb85c' }} />
                        </div>
                      )}
                    </div>
                    {
                      modal_supervisor_errors.code2
                        && modal_supervisor_errors.code2.map(error => (
                          <label style={{ color: 'red', fontSize: 14 }}>{error}</label>
                        ))
                    }
                  </div>
                </Form>
                <div className="button-view">
                  <button
                    type="button"
                    className="btn-verificar"
                    onClick={() => this.onValidate()}
                  >
                    VERIFICAR TOKENS
                  </button>
                  <button
                    type="button"
                    className={validated ? 'btn-confirmar' : 'btn-confirmar-disabled'}
                    disabled={!validated}
                    onClick={() => this.onSubmit()}
                  >
                    CONFIRMAR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Rodal>
      </div>
    );
  }
}

export default ModalVerificarAuxiliar;
