import React from 'react';
import { get } from 'lodash';
import { Form } from 'informed';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import RangeDateInput from '../inputs/RangeDateInput';
import HierarquiaInput from '../inputs/HierarquiaInput';
import { fetchMeusPolos, fetchMeusTimes, fetchAvaliadores } from '../../../services/hierarquia';
import { ComponentBase } from '../../../base';
import Caption from '../layout/Caption';

class RelatorioForm extends ComponentBase {
  constructor(props) {
    super(props);
    this.state = {
      shouldApplyFilter: true,
      polo: undefined,
      polos: [],
      fetchingPolos: false,
      time: undefined,
      times: [],
      fetchingTimes: false,
      avaliador: undefined,
      avaliadores: [],
      fetchingAvaliadores: false,
      errors: null,
      autoSelectPolo: true,
      autoSelectTime: true,
    }
    this.setFilters = this.setFilters.bind(this);
  }

  autoSelectArrayWithOneObject = (
    data,
    callbackFunction = () => null,
  ) => {
    if (data) {
      if (data.length === 1) {
        callbackFunction(data[0]);
      }
    }
  };

  setInitialValues = () => {
    const {
      location: { state },
    } = this.props;
    let autoSelectPolo = true;
    let autoSelectTime = true;

    if (state) {
      if (state.polo) {
        autoSelectPolo = false;
        this.onPoloChange(state.polo);
      }
      if (state.time) {
        autoSelectTime = false;
        this.onTimeChange(state.time);
      }
      this.setState({ autoSelectTime, autoSelectPolo, ...state }, () => {
        this.onApplyFilters();
        this.fetchPolos();
      });
    } else {
      this.fetchPolos();
    }
  }

  componentDidMount = () => {
    const { setHierarquiaFilters } = this.props;
    this.setInitialValues();
    if (setHierarquiaFilters) {
      setHierarquiaFilters(this.setFilters);
    }
  }

  setData = () => {
    const {
      polos,
      times,
      avaliadores,
      polo,
      time,
      avaliador,
      shouldApplyFilter,
    } = this.state;
    const {
      onApplyFilters, isPoloInputRequired, isTimeInputRequired, isAvaliadorInputRequired,
    } = this.props;

    const options = [];
    if (isPoloInputRequired) {
      options.push(polos);
    }
    if (isTimeInputRequired) {
      options.push(times);
    }
    if (isAvaliadorInputRequired) {
      options.push(avaliadores);
    }

    const checkOptionsValues = options.every(a => a.length === 1);

    if (checkOptionsValues && shouldApplyFilter) {
      onApplyFilters({
        polo_id: get(polo, 'value', null),
        time_id: get(time, 'value', null),
        avaliador_id: get(avaliador, 'value', null),
      });
      this.setState({
        shouldApplyFilter: false,
      });
    }
  }

  fetchPolos = () => {
    const { autoSelectPolo } = this.state;
    this.setState({ fetchingPolos: true });
    fetchMeusPolos()
    .then((data) => {
      this.setState({ fetchingPolos: false });
      this.setState({
        polos: data.map(p => ({ label: p.descricao, value: p.id })),
      }, () => {
        if (autoSelectPolo) {
          this.autoSelectArrayWithOneObject(this.state.polos, this.onPoloChange)
        }
      });
    });
  }

  onPoloChange = (polo) => {
    const { showTimeInput } = this.props;
    const { errors, autoSelectTime } = this.state;

    const novoPoloSelecionado = get(polo, 'value') !== get(this.state, 'polo.value')

    if (novoPoloSelecionado) {
      this.setState(prevState => ({
        polo,
        time: null,
        avaliador: null,
      }));
      if (polo) {
        this.setState({
          errors: {
            ...errors,
            polo: null,
          },
        });
        if (showTimeInput) {
          this.setState({ fetchingTimes: true });
          fetchMeusTimes({ id_hierarquia_usuario_pai: get(polo, 'value') }).then(
            (data) => {
              this.setState({
                times: data.map(t => ({ label: t.descricao, value: t.id })),
                fetchingTimes: false,
              }, () => {
                if (autoSelectTime) {
                  this.autoSelectArrayWithOneObject(this.state.times, this.onTimeChange)
                }
              });
            },
          );
        }
      } else {
        this.setState({
          avaliadores: [],
          times: [],
          avaliador: null,
          time: null,
        });
      }
    }
  }

  onTimeChange = (time) => {
    const { showAvaliadorInput } = this.props;
    const { errors } = this.state;

    const novoTimeSelecionado = get(time, 'value') !== get(this.state, 'time.value')

    if (novoTimeSelecionado) {
      this.setState(prevState => ({
        time,
        avaliador: null,
       }));
      if (time) {
        this.setState({
          errors: {
            ...errors,
            time: null,
          },
        });
        if (showAvaliadorInput) {
          this.setState({ fetchingAvaliadores: true });
          fetchAvaliadores(get(time, 'value'))
            .then(
              (data) => {
                this.setState({
                  avaliadores: data.map(a => ({ label: a.last_name, value: a.id })),
                  fetchingAvaliadores: false,
                });
              },
            );
        }
      } else {
        this.setState({
          avaliadores: [],
          avaliador: null,
        });
      }
    }
  }

  onAvaliadorChange = (avaliador) => {
    const { errors } = this.state;
    this.setState({
      avaliador,
      errors: {
        ...errors,
        avaliador: null,
      },
    });
  }

  onApplyFilters = () => {
    const {
      onApplyFilters,
      isPoloInputRequired,
      isTimeInputRequired,
      isAvaliadorInputRequired,
      returnFilterDescription,
    } = this.props;
    const {
      polo, time, avaliador,
    } = this.state;

    const checkPoloInput = isPoloInputRequired && !get(polo, 'value');
    const checkTimeInput = isTimeInputRequired && !get(time, 'value');
    const checkAvaliadorInput = isAvaliadorInputRequired && !get(avaliador, 'value');

    const checkInputErrors = [
      { isInputCorrect: checkPoloInput, input: 'polo' },
      { isInputCorrect: checkTimeInput, input: 'time' },
      { isInputCorrect: checkAvaliadorInput, input: 'avaliador' },
    ];

    const errorMessage = 'Este campo não pode ficar em branco.';

    checkInputErrors.forEach((error) => {
      if (error.isInputCorrect) {
        this.setState(prevState => ({
          errors: { ...prevState.errors, [error.input]: errorMessage },
        }));
      }
    });

    if (!checkPoloInput && !checkTimeInput && !checkAvaliadorInput) {
      this.setState({
        errors: null,
      });
      onApplyFilters({
        polo_id: get(polo, 'value'),
        time_id: get(time, 'value'),
        usuario_id: get(avaliador, 'value'),
        data_inicio: this.formApi.getValue('data_inicio'),
        data_final: this.formApi.getValue('data_final'),
        polo_descricao: returnFilterDescription && get(polo, 'label'),
        time_descricao: returnFilterDescription && get(time, 'label'),
        avaliador_descricao: returnFilterDescription && get(avaliador, 'label'),
      });
    }
  }

  handleBackPage = () => {
    const { history, backAction: { path, state } } = this.props;
    const historyState = {};

    state.forEach((item) => {
      historyState[item] = this.state[item];
    });

    history.push({
      pathname: path,
      state: historyState,
    });
  }

  setFilters(filters={}) {
    const data = {
      polo: filters.polo_id && {
        value: filters.polo_id,
        label: filters.polo_descricao,
      },
      time: filters.time_id && {
        value: filters.time_id,
        label: filters.time_descricao,
      },
      avaliador: filters.usuario_id && {
        value: filters.usuario_id,
        label: filters.avaliador_descricao,
      },
    }
    if (data.polo) {
      this.onPoloChange(data.polo);
    }
    if (data.time) {
      this.onTimeChange(data.time);
    }
    this.setState({ ...data })
  }

  handleClearFilters = () => {
    const { onClearFilters } = this.props;
    this.setState({
      polo: null,
      time: null,
      avaliador: null,
    }, () => onClearFilters())
  }

  render() {
    const {
      polo, polos, fetchingPolos,
      time, times, fetchingTimes,
      avaliador, avaliadores, fetchingAvaliadores,
      errors,
    } = this.state;

    const {
      fetchingRelatorio,
      showAvaliadorInput,
      showTimeInput,
      showDateRangeInput,
      backAction,
      showPoloInput,
      caption,
      children,
      showClearButton,
      atualizaLote,
      showBatchUpdateButton,
    } = this.props;
    return (
      <Form
        getApi={(formApi) => { this.formApi = formApi; }}
      >
        {backAction && (
          <button className="btn btn-link position-absolute left-0 p-0 top-0 mt-4" type="button" onClick={this.handleBackPage}>
            <i className="fa fa-arrow-alt-circle-left mr-2" />
            Voltar
          </button>
        )}
        <div className="d-flex w-100 justify-content-between">
          {
            showPoloInput && (
              <HierarquiaInput
                polo={polo}
                polos={polos}
                onPoloChange={this.onPoloChange}
                fetchingPolos={fetchingPolos}
                time={time}
                times={times}
                onTimeChange={this.onTimeChange}
                fetchingTimes={fetchingTimes}
                avaliador={avaliador}
                avaliadores={avaliadores}
                onAvaliadorChange={this.onAvaliadorChange}
                fetchingAvaliadores={fetchingAvaliadores}
                showAvaliadorInput={showAvaliadorInput}
                showTimeInput={showTimeInput}
                errors={errors}
              />
            )
          }
          {children}
          {showDateRangeInput && (
            <div className="grid-form-1 grid-form-rows-3 w-100">
              <RangeDateInput />
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center mb-4 mt-4">
          {
            showClearButton && (
              <button
                type="button"
                disabled={fetchingRelatorio}
                className="btn btn-default mr-3"
                onClick={this.handleClearFilters}
              >
                  LIMPAR FILTROS
              </button>
            )
          }
          <button
            type="button"
            disabled={fetchingRelatorio}
            className="btn btn-primary"
            onClick={this.onApplyFilters}
          >
              APLICAR FILTROS
          </button>
          <div className="w-100 d-flex justify-content-center">
            <Caption
              title={caption.title}
              captions={caption.captions}
            />
          </div>
          {
            showBatchUpdateButton && (
              <button
                disabled={fetchingRelatorio}
                type="button"
                className="btn btn-primary mr-3"
                onClick={atualizaLote}
              >
                  ATUALIZAR EM LOTE
              </button>
            )
          }
        </div>
      </Form>
    );
  }
}

RelatorioForm.propTypes = {
  isPoloInputRequired: PropTypes.bool,
  isTimeInputRequired: PropTypes.bool,
  isAvaliadorInputRequired: PropTypes.bool,
  showPoloInput: PropTypes.bool,
  fetchingRelatorio: PropTypes.bool.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  showAvaliadorInput: PropTypes.bool,
  showClearButton: PropTypes.bool,
  showTimeInput: PropTypes.bool,
  showDateRangeInput: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  backAction: PropTypes.shape({
    state: PropTypes.arrayOf(PropTypes.string),
    path: PropTypes.string,
  }),
  caption: PropTypes.shape({
    title: PropTypes.string,
    captions: PropTypes.arrayOf(PropTypes.shape({
      shape: PropTypes.string,
      color: PropTypes.string,
      label: PropTypes.string,
    })),
  }),
  onClearFilters: PropTypes.func,
  showBatchUpdateButton: PropTypes.bool,
  atualizaLote: PropTypes.func,
  returnFilterDescription: PropTypes.bool,
};

RelatorioForm.defaultProps = {
  isPoloInputRequired: false,
  isTimeInputRequired: false,
  isAvaliadorInputRequired: false,
  showAvaliadorInput: true,
  showPoloInput: true,
  showTimeInput: true,
  backAction: undefined,
  showDateRangeInput: true,
  showClearButton: false,
  onClearFilters: () => {},
  showBatchUpdateButton: false,
  atualizaLote: () => {},
  returnFilterDescription: true,
  caption: {
    title: 'Legenda',
    captions: [
      {
        label: 'Alerta',
        shape: 'circle',
        color: 'warning-dark',
      },
    ],
  },
};

export default withRouter(RelatorioForm);
