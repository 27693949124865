import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { get, find } from 'lodash';
import StatusTag from '../components/StatusTag';
import { API_URL, StatusOcorrencia } from '../consts';
import { getAxiosConfig } from '../services';
import { Loading, TableDateFilterInput } from '../components';
import OcorrenciaBase from '../pages/OcorrenciaBase';
import { defaultFilterMethod, dateFilterMethod } from '../table';
import { getListSettings, setPageSettings } from '../utils';
import TableFilterSelect from '../components/v2/inputs/TableFilterSelect';

const makeDefaultState = () => ({
  sorted: [
    {
      id: 'created_at',
      desc: true,
    },
  ],
  page: 0,
  pageSize: 10,
  expanded: {},
  resized: [],
  filtered: [],
});


class TabelaOcorrencia extends OcorrenciaBase {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      listOcorrencia: [],
      ...makeDefaultState(),
    };
    this.fetchOcorrencia = this.fetchOcorrencia.bind(this);
  }

  componentDidMount() {
    const { url } = this.props;
    const pageSettings = get(getListSettings(), url, makeDefaultState());
    this.setState({
      ...pageSettings,
    }, this.fetchOcorrencia);
  }

  componentDidUpdate(prevProps) {
    const { filtroCoordenador } = this.props;
    if (prevProps.filtroCoordenador !== filtroCoordenador) {
      this.fetchOcorrencia();
    }
  }

  getVerUrl(id) {
    return `/tickets/${id}`;
  }

  fetchOcorrencia() {
    this.setState({ loading: true });
    const {
      page,
      pageSize,
      filtered,
      sorted,
    } = this.state;
    const { url, filtroCoordenador } = this.props;
    const filters = {};
    filtered.forEach((filter) => {
      filters[filter.id] = filter.value;
    });
    const ordering = sorted.map(field => `${field.desc ? '-' : ''}${field.id}`).join(',');

    axios.get(`${API_URL}${url}`, {
        params: {
          page: page + 1,
          page_size: pageSize,
          ordering,
          ...filtroCoordenador,
          ...filters,
        },
        ...getAxiosConfig(),
      })
      .then((response) => {
        const { results, total_pages } = response.data;
        const listOcorrencia = results.map(record => ({
          ...record,
          status: (
            <StatusTag
              icone={get(record, 'status.icon')}
              cor={get(record, 'status.class_name')}
              descricao={get(record, 'status.name')}
            />
          ),
          ver: (
            <Link
              to={{
                pathname: this.getVerUrl(get(record, 'id')),
                state: filtroCoordenador,
              }}
              className="btn btn-more"
            >
              <i className="fa fa-eye" />
            </Link>
          ),
        }));
        this.setState({
          listOcorrencia,
          total_pages,
          loading: false,
        });
        setPageSettings(url, {
          page,
          pageSize,
          filtered,
          sorted,
        });
      })
      .catch((error) => {
        const { status } = error.response;
        if (status === 404 && page > 0) {
          this.setState({ page: page - 1 }, this.fetchOcorrencia())
        } else {
          this.handleError(error);
          this.setState({
            loading: false,
          });
        }
      });
  }

  getHeaderWithSortIcon(label, accessor) {
    const { sorted } = this.state;

    const field = find(sorted, f => f.id === accessor);
    let sortIcon = '';
    if (field) {
      sortIcon = (
        <i
          className={`pull-right fas fa-arrow-${field.desc ? 'down' : 'up'}`}
          style={{ fontSize: 10 }}
        />
      );
    }
    return <span>{sortIcon}{label}</span>;
  }

  getColumns = () => {
    const { ver_tipo_correcao, ver_column_autor, ver_encaminhado_em } = this.props;
    const columns = [
      {
        Header: this.getHeaderWithSortIcon('Código Imagem', 'essay'),
        accessor: 'essay',
        minWidth: 90,
      },
      {
        Header: this.getHeaderWithSortIcon('Código ocorrência', 'id'),
        accessor: 'id',
        minWidth: 110,
      },
      {
        Header: this.getHeaderWithSortIcon('Tipo correção', 'correction_type'),
        accessor: 'correction_type',
        minWidth: 120,
        show: ver_tipo_correcao || false,
      },
      {
        Header: this.getHeaderWithSortIcon('Data de abertura', 'created_at'),
        accessor: 'created_at',
        Filter: TableDateFilterInput,
        filterMethod: dateFilterMethod,
        minWidth: 150,
      },
      {
        Header: this.getHeaderWithSortIcon('Data de encaminhamento', 'forwarded_at'),
        accessor: 'forwarded_at',
        Filter: TableDateFilterInput,
        filterMethod: dateFilterMethod,
        minWidth: 150,
        show: ver_encaminhado_em,
      },
      {
        Header: this.getHeaderWithSortIcon('Classificação', 'category'),
        accessor: 'category',
        minWidth: 120,
      },
      {
        Header: this.getHeaderWithSortIcon('Referência', 'type'),
        accessor: 'type',
      },
      {
        Header: this.getHeaderWithSortIcon('Autor', 'author'),
        accessor: 'author',
        minWidth: 100,
        show: ver_column_autor || false,
      },
      {
        Header: this.getHeaderWithSortIcon('Responsável', 'answerable'),
        accessor: 'answerable',
        minWidth: 120,
      },
      {
        Header: this.getHeaderWithSortIcon('Status', 'status'),
        accessor: 'status',
        Filter: props => <TableFilterSelect {...props} options={StatusOcorrencia.filter(s => !s.permission || this.hasPermission(s.permission))} />,
      },
      {
        Header: 'Ver',
        accessor: 'ver',
        filterable: false,
        sortable: false,
        width: 80,
      },
    ];

    return columns;
  }

  render() {
    const {
      listOcorrencia,
      loading,
      page,
      pageSize,
      filtered,
      total_pages,
    } = this.state;

    return (
      <Fragment>
        <div className="text-right">
          <button
            type="button"
            className="btn btn-default btn-sm mb-3"
            onClick={() => this.setState({ ...makeDefaultState() }, this.fetchOcorrencia)}
          >
            limpar filtros
          </button>
        </div>
        <ReactTable
          manual
          data={listOcorrencia}
          columns={this.getColumns()}
          className="table-lista-ocorrencias"
          previousText=""
          nextText=""
          noDataText="Sem conteúdo"
          pageText="Pág."
          ofText="de"
          rowsText="registros"
          loading={loading}
          minRows={0}
          loadingText={<Loading />}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          pages={total_pages}
          page={page}
          pageSize={pageSize}
          filtered={filtered}
          onPageChange={page => this.setState({ page }, this.fetchOcorrencia)}
          onPageSizeChange={(pageSize, page) => this.setState({ pageSize, page }, this.fetchOcorrencia)}
          onFilteredChange={filtered => this.setState({ filtered, page: 0 }, this.fetchOcorrencia)}
          onSortedChange={sorted => this.setState({ sorted }, this.fetchOcorrencia)}
        />
      </Fragment>
    );
  }
}

TabelaOcorrencia.defaultProps = {
  defaultFiltered: [],
  ver_column_responsavel: true,
  ver_encaminhado_em: false,
};

export default withRouter(TabelaOcorrencia);
