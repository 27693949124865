import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Table from '../Table';

class RelatorioTable extends React.Component {
  rowColor(state, rowInfo) {
    const status = get(rowInfo, 'original.status.status_id');
    switch (status) {
      case 3:
        return {
          bg: '#E59E00',
          color: '#FFFFFF',
        };
      case 4:
        return {
          bg: '#DB6969',
          color: '#FFFFFF',
        };
      default:
        return '';
    }
  }

  render() {
    const { loading } = this.props;
    return (
      <Table
        previousText=""
        nextText=""
        className="table-default columns-adjusment table-v2-relatorios"
        getTrProps={(state, rowInfo) => ({
          style: {
            backgroundColor: this.rowColor(state, rowInfo).bg,
            color: this.rowColor(state, rowInfo).color,
          },
        })}
        loading={loading}
        {...this.props}
      />
    );
  }
}

RelatorioTable.propTypes = {
  loading: PropTypes.bool,
};

RelatorioTable.defaultProps = {
  loading: false,
};

export default RelatorioTable;
