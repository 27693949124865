import React from 'react';


const HistoricoCorrecaoContext = React.createContext({
  correcao: null,
  index: null,
  verNomeAvaliadorHistorico: false,
});

export default HistoricoCorrecaoContext;
