import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class MinhasOcorrenciasItem extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    data: PropTypes.string,
    status_color: PropTypes.string,
    status_descricao: PropTypes.string,
  };

  static defaultProps = {
    id: null,
    data: 'Sem Data',
    status_color: null,
    status_descricao: 'Sem Status',
  };

  render() {
    const {
      id, data, status_color, status_descricao,
    } = this.props;

    return (
    <div className="row ocorrencia">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <p>#{id}</p>
          <p>Inicio:<span>{data}</span></p>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <div className={`tag-status-ocorrencias ${status_color}`}>{status_descricao}</div>
          <div className="link-detalhes">
            <button type="button" className="btn btn-primary"><Link to={`/ocorrencias/${id}`}>DETALHES</Link></button>
          </div>
        </div>
    </div>
    );
  }
}

export default MinhasOcorrenciasItem;
