import React from 'react'
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { getAxiosConfig } from '../../../services';
import { API_URL } from '../../../consts'
import axios from 'axios'
import ImportacaoRedacaoDesempenhoForm from './ImportacaoRedacaoDesempenhoForm'


class ImportacaoRedacaoDesempenhoPage extends React.Component {
  state = {
    form: {},
    error: {},
    src: null,
    notaFinal: null,
    loading: false,
    submitting: false,
  }

  fetchRedacao = async () => {
    const { form } = this.state;
    const config = {
      ...getAxiosConfig(),
      params: {
        mascara_redacao: form.redacao,
      },
    };
    this.setState({ loading: true, src: null })
    axios.get(`${API_URL}/v2/banco-redacoes`, config)
      .then(res => {
        const src = get(res, 'data.src');
        if (!src) {
          toast.error('Ocorreu um erro ao carregar a imagem.');
        }
        this.setState(prevState => ({
          src,
          loading: false,
          isOpen: src !== null,
        }));
      })
      .catch(err => {
        if (err.response.status === 404) {
          toast.error('Redação não encontrada.');
        } else {
          toast.error('Ocorreu um erro ao carregar a redação.');
        }
        this.setState({ src: null, loading: false })
      })
  }

  handleFormChange = (form) => {
    const { projeto } = this.props;
    const peso_competencia = get(projeto, 'peso_competencia')
    let notaFinal = 0;
    [1, 2, 3, 4, 5].forEach((i) => {
      let nota_competencia = form[`competencia${i}`];
      if (nota_competencia === -1) {
        nota_competencia = 0;
      }
      notaFinal += +nota_competencia * peso_competencia;
    });
    this.setState(prevState => ({
      notaFinal,
      form,
    }));
  }

  handleSubmitRedacaoOuro = () => {
    const { form, submitting } = this.state;
    const { history } = this.props;

    if (!submitting) {
      this.setState({ submitting: true })
      axios.post(`${API_URL}/importacao/redacao-desempenho`, form)
        .then(res => {
          toast.success('Redação de desempenho importada ao sistema.');
          history.push('/admin/redacoes-desempenho')
          this.setState({ submitting: false });
        })
        .catch(err => {
          toast.error('Ocorreu um erro no envio do formulário, favor verificar os campos e enviar novamente.');
          const errors = get(err, 'response.data');
          Object.entries(errors).forEach((e) => {
            if (this.formApi.fieldExists(e[0])) {
              this.formApi.setError(e[0], e[1]);
            }
          });
          this.setState({ submitting: false, errors: get(err, 'response.data', {}) });
        })
    }
  }

  render() {
    const {
      src,
      notaFinal,
      loading,
      submitting,
      errors,
    } = this.state

    const { history } = this.props;

    return (
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <div className="page-container-border position-relative">
          <section className="mt-3">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <h1 className="text-title">
                  Importação de redações de desempenho
                </h1>
                <div className="pull-right">
                </div>
              </div>
            </div>
            <button
              className="btn btn-link position-absolute left-0 p-0 top-0 mt-4"
              type="button"
              onClick={() => history.push('/admin/redacoes-desempenho')}
            >
              <i className="fa fa-arrow-alt-circle-left mr-2" />
              Voltar
            </button>
          </section>
          <div className="col-md-12 col-xs-12 col-sm-12">
            <ImportacaoRedacaoDesempenhoForm
              onChange={this.handleFormChange}
              fetchRedacao={this.fetchRedacao}
              getApi={api => this.formApi = api}
              onSubmit={this.handleSubmitRedacaoOuro}
              notaFinal={notaFinal || '-'}
              loading={loading}
              submitting={submitting}
              errors={errors}
              src={src}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ImportacaoRedacaoDesempenhoPage
