import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { getAxiosConfig } from '../services';


class ExportExcelButton extends React.Component {
  state = {
    loading: false,
  }

  exportExcel = () => {
    const { url, filename, params } = this.props;
    this.setState({ loading: true });
    axios.get(url, { params, responseType: 'blob', ...getAxiosConfig() })
      .then((res) => {
        saveAs(res.data, filename);
        this.setState({ loading: false });
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao tentar fazer download do arquivo.');
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading } = this.state;
    const { disabled } = this.props;
    return (
      <button
        type="button"
        onClick={this.exportExcel}
        className="btn btn-disabled pull-right excel-icon"
        disabled={loading || disabled}
      />
    );
  }
}

ExportExcelButton.propTypes = {
  url: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  params: PropTypes.oneOfType([PropTypes.object]),
  disabled: PropTypes.bool,
};

ExportExcelButton.defaultProps = {
  params: {},
  disabled: false,
};

export default ExportExcelButton;
