import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ImportRuleset = ({ sheets, children }) => {
  const [activeSheet, setActiveSheet] = useState(null);

  return (
    <div>
        <p>Verifique as regras de cada uma das planilhas:</p>
        <ul className="nav nav-tabs">
          {sheets.map((sheet, i) => (
            <li className={classnames('nav-item', { 'ml-2': i > 0 })} key={sheet}>
              <button
                className={classnames('nav-link', { active: activeSheet === sheet })}
                onClick={() => setActiveSheet(activeSheet !== sheet && sheet)}
                type="button"
              >
                {sheet}
              </button>
            </li>
          ))}
        </ul>
        {children({ activeSheet })}
    </div>
  );
};

ImportRuleset.propTypes = {
  children: PropTypes.node.isRequired,
  sheets: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ImportRuleset;
