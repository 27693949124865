import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { fetchVistaProvaTokens } from "../../../services/banco_redacoes";

const VistaProvaExternaIndex = ({ match }) => {
  const { token } = match.params;
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    fetchVistaProvaTokens({ token }).then((data) => setTokens(data));
  }, []);

  return (
    <div>
      <div className="container">
        <div className="page-container-border">
          <div className="row">
            <div className="col">
              <h1 className="text-title">Questões</h1>
            </div>
          </div>
          {tokens.map((token) => (
            <Link
              to={`/vista-prova-externa/${token.value}/projeto/${token.projeto_id}`}
            >
              <button type="button" className="btn btn-primary m-1">
                {token.name}
              </button>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withRouter(VistaProvaExternaIndex);
