import React, { useState } from 'react';
import axios from 'axios';
import { Async } from 'react-select';
import debounce from 'debounce-promise';
import Container from '../../components/v2/Container';
import { API_URL, BASE_URL } from '../../consts';
import { setAuthorizedToken, getAxiosConfig } from '../../services/auth';

const LoginAsPage = () => {
  const [user, setUser] = useState('');
  const [error, setError] = useState('');

  const fetchUsers = debounce((search) => {
      return axios.get(`${API_URL}/external_auth/users`, {
        params: { search },
        ...getAxiosConfig(),
      })
        .then(response => response.data.results);
    }, 500)

  const loginAs = () => {
    if (!user) {
      setError('Informe um usuário.');
    } else {
      axios.get(`${API_URL}/external_auth/users/${user.id}/login_as_any`, getAxiosConfig())
        .then((response) => {
          setAuthorizedToken(response.data.token);
          window.location.href = `${BASE_URL}/`;
        })
        .catch((err) => {
          setError((err.response && JSON.stringify(err.response.data)) || 'Ocorreu um erro.');
        });
    }
  };

  return (
    <Container title="Entrar como qualquer usuário">
      <Async
        cacheOptions
        loadOptions={fetchUsers}
        onChange={setUser}
        getOptionLabel={d => `${d.last_name} (${d.username})`}
        getOptionValue={d => d.id}
        value={user}
        placeholder="Nome ou CPF"
      />
      <button type="button" onClick={loginAs} className="btn btn-primary mt-2">ENTRAR</button>
      {error && <p className="text-danger">{error}</p>}
    </Container>
  );
};

export default LoginAsPage;
