import PropTypes from 'prop-types';
import React from 'react';

const AnswerHeader = ({ title, subtitle }) => (
    <div>
      <p className="m-0 answer-title">
        {title}
      </p>
      <small className="answer-subtitle">{subtitle}</small>
    </div>
  );

AnswerHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

AnswerHeader.defaultProps = {
  title: '',
  subtitle: '',
};

export default AnswerHeader;
