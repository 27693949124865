import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Table from './Table';

const ColumnRules = ({ rules }) => (
  <Table
    columns={[
      {
        accessor: 'name',
        Header: 'Coluna',
        width: 200,
        className: 'text-left',
      },
      {
        accessor: 'required',
        Header: 'Obrigatório',
        width: 120,
        Cell: row => <i className={classnames('fa', { 'fa-check-circle text-success': row.value, 'fa-times-circle text-danger': !row.value })} />,
      },
      {
        accessor: 'options',
        Header: 'Descrição',
        Cell: row => (Array.isArray(row.value) ? row.value.join(', ') : 'N/A'),
        className: 'text-left',

      },
    ]}
    className="table-v2-relatorios"
    data={rules}
    sortable={false}
    showPagination={false}
    showPageSizeOptions={false}
    defaultPageSize={rules.length}
  />
);

ColumnRules.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    required: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string)]),
  })).isRequired,
};

export default ColumnRules;
