import React, { Fragment } from 'react';
import ReactTable from 'react-table';
import axios from 'axios';
import { find } from 'lodash';
import { getAxiosConfig } from '../services';
import { API_URL } from '../consts';


class TabelaBuscarProjeto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            relatorio: [],
            loading: false,
            sorted: [
                {
                  id: 'data',
                  desc: true,
                },
              ],
        };
    }

    componentDidMount() {
      this.fetchQuestaoUsuario()
    }

    fetchQuestaoUsuario() {
      this.setState({ loading: true })
      axios.get(`${API_URL}/projeto/questao_usuario`, getAxiosConfig())
        .then((response) => this.setState({ relatorio: response.data, loading: false }))
        .catch((error) => this.setState({ loading: false }));
    }

    getHeaderWithSortIcon(label, accessor) {
        const { sorted } = this.state;
        const field = find(sorted, f => f.id === accessor);
        let sortIcon = '';
        if (field) {
          sortIcon = (
            <i
              className={`right-arrow fas fa-arrow-${field.desc ? 'down' : 'up'}`}
              style={{ fontSize: 10 }}
            />
          );
        }
        return <span>{sortIcon}<strong>{label}</strong></span>;
      }

    renderTable() {
        const {
          relatorio,
          loading,
        } = this.state;
        const { onBuscarMaisUm, buscandoMaisUm } = this.props;

        const columns = [
            {
                Header: this.getHeaderWithSortIcon('Item', 'descricao'),
                accessor: 'descricao',

            },
            {
                Header: '',
                accessor: 'projeto_id',
                Cell: row => (
                  <div className="col">
                    <button
                      type="button"
                      id="_buscar_mais_um_dashboard"
                      className={`btn btn-primary-grey pull-right ${buscandoMaisUm || !row.original.habilitado ? 'disabled' : ''}`}
                      disabled={buscandoMaisUm || !row.original.habilitado}
                      onClick={() => onBuscarMaisUm(row.value)}
                    >
                      BUSCAR +1
                    </button>
                  </div>
                )
            },
        ];

        return (
         <Fragment>
            <ReactTable
              data={relatorio}
              className="-highlight tabela-acompanhamento-css"
              noDataText="Sem conteúdo"
              rowsText="registros"
              loading={loading}
              loadingText="Carregando informações..."
              pageSize={relatorio.length}
              columns={columns}
              resizable={false}
              showPagination={false}
              onSortedChange={sorted => this.setState({ sorted })}
            />
         </Fragment>
        );
      }

    render() {
      return (
        <Fragment>
          {this.renderTable()}
        </Fragment>
     );
    }
}

export default TabelaBuscarProjeto;
