import React, { useContext } from 'react';
import { Form } from 'informed';
import { TextInput } from '../..';
import BancoRedacoesContext from '../../../contexts/BancoRedacoesContext';

const BancoRedacoesForm = () => {
  const {
    onSubmit, verDadosCandidato, fetching,
  } = useContext(BancoRedacoesContext);

  return (
    <Form
      onSubmit={onSubmit}
      className="redacao-form mt-4"
    >
      <div className="row">
        <div className="col card-correcoes">
          <TextInput
            field="mascara_redacao"
            placeholder="Máscara da Redação"
            className="form-control"
          />
        </div>
        {
          verDadosCandidato && (
            <>
              <div className="col card-correcoes">
                <TextInput
                  field="cpf"
                  placeholder="CPF"
                  className="form-control"
                />
              </div>
              <div className="col card-correcoes">
                <TextInput
                  field="inscricao"
                  placeholder="Cód. Inscrição"
                  className="form-control"
                />
              </div>
            </>
          )
        }
        <button type="submit" disabled={fetching} className="btn btn-primary">
          {fetching ? 'BUSCANDO...' : 'BUSCAR'}
        </button>
      </div>
    </Form>
  );
};

export default BancoRedacoesForm;
