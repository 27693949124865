import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { ComponentBase } from '../../../../base';
import { API_URL } from '../../../../consts';

class PadraoOuroAvaliador extends ComponentBase {
  get columns() {
    return [
      {
        header: 'Corretor',
        columns: [
          {
            header: 'Redação',
            accessor: 'redacao',
            hasRedacao: true,
          },
          {
            header: 'Data',
            accessor: 'data',
          },
          {
            header: 'Discrepância',
            accessor: 'discrepou',
            withSortIcon: true,
            minWidth: 120,
            Cell: row => <div>{row.original.discrepou ? 'SIM' : 'NÃO'}</div>,
          },
          {
            header: 'Situação',
            accessor: 'avaliador_situacao',
            minWidth: 90,
          },
        ].concat(
          [1, 2, 3, 4].map((competencia) => {
            const numeral = {
              1: 'I',
              2: 'II',
              3: 'III',
              4: 'IV',
            }[competencia];

            return {
              header: `C${numeral}`,
              accessor: `avaliador_c${competencia}`,
              minWidth: 60,
            };
          }),
          {
            header: 'CV',
            accessor: 'avaliador_c5',
            Cell: d => d.original.avaliador_c5 === -1 ? 0 : d.original.avaliador_c5,
            minWidth: 60,
          },
          {
            header: 'DDH',
            accessor: 'avaliador_is_ddh',
            Cell: d => d.original.avaliador_is_ddh && <i className="fas fa-check" />,
            minWidth: 70,
          },
          {
            header: 'Nota',
            accessor: 'nota',
            minWidth: 70,
          },
        ),
      },
      {
        header: 'Nota de Referência',
        withSortIcon: true,
        columns: [
          {
            header: 'Situação',
            accessor: 'referencia_situacao',
            width: 90,
          },
        ].concat(
          [1, 2, 3, 4].map((competencia) => {
            const numeral = {
              1: 'I',
              2: 'II',
              3: 'III',
              4: 'IV',
            }[competencia];

            return {
              header: `C${numeral}`,
              accessor: `referencia_c${competencia}`,
              width: 60,
            };
          }),
          {
            header: 'CV',
            accessor: 'referencia_c5',
            Cell: d => d.original.referencia_c5 === -1 ? 0 : d.original.referencia_c5,
            minWidth: 60,
          },
          {
            header: 'DDH',
            accessor: 'referencia_is_ddh',
            Cell: d => d.original.referencia_is_ddh && <i className="fas fa-check" />,
            minWidth: 70,
          },
          {
            header: 'Nota',
            accessor: 'nota_referencia',
            width: 70,
          },
        ),
      },
      {
        header: 'Diferenças em relação à nota de referêcia',
        withSortIcon: true,
        columns: [
          {
            header: 'Situação',
            Cell: row => (
              <div>
                {row.original.avaliador_situacao}/{row.original.referencia_situacao}
              </div>
            ),
            width: 90,
          },
        ].concat(
          [1, 2, 3, 4].map((competencia) => {
            const numeral = {
              1: 'I',
              2: 'II',
              3: 'III',
              4: 'IV',
            }[competencia];

            return {
              header: `C${numeral}`,
              accessor: `diferenca_c${competencia}`,
              width: 60,
            };
          }),
          {
            header: 'CV',
            accessor: 'diferenca_c5',
            Cell: d => d.original.diferenca_c5 === -1 ? 0 : d.original.diferenca_c5,
            minWidth: 60,
          },
          {
            header: 'Nota',
            accessor: 'nota_diferenca',
            width: 70,
          },
        ),
      },
    ];
  }

  render() {
    const backAction = this.hasPermission('ver_relatorio_padrao_ouro_time') ? {
      path: '/relatorios/v2/padrao-ouro-avaliadores',
      state: ['polo', 'time'],
    } : undefined;

    const exportData = {
      url: `${API_URL}/v2/relatorios/padrao-ouro/avaliador/excel`,
      filename: 'padrao_ouro_por_avaliador.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        isPoloInputRequired
        isTimeInputRequired
        isAvaliadorInputRequired
        title="PADRÃO-OURO | Individual"
        dataUrl="v2/relatorios/padrao-ouro/avaliador"
        columns={this.columns}
        exportData={exportData}
        backAction={backAction}
        codigoMensagem="PADRAO_OURO_INDIVIDUAL"
        codigo="PADRAO_OURO_AVALIADOR"
        caption={{}}
      />
    );
  }
}

export default PadraoOuroAvaliador;
