import React from 'react';
import Rodal from 'rodal';
import PropTypes from 'prop-types';

class ModalConfirmacao extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    textSim: PropTypes.string,
    textNao: PropTypes.string,
    buttonFunction: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
  };

  static defaultProps = {
    title: 'Título',
    content: 'Confirmação',
    textNao: '',
    textSim: '',
    submitting: false,
  };

  render() {
    const {
      visible,
      onClose,
      title,
      content,
      buttonFunction,
      textSim,
      textNao,
      submitting,
    } = this.props;

    return (
      <section className="modal-80">
        <Rodal visible={visible} onClose={onClose}>
          <div className="modalConfirmacao">
            <div className="boxTitulo">
              <h1 className="tituloConfirmacao">{title}</h1>
            </div>
            <pre className="pre">
              <p className="conteudoConfirmacao">{content}</p>
            </pre>
            <div>
              <div className="botoesConfirmacao">
                <button
                  type="button"
                  disabled={submitting}
                  className="btn btn-primary botaoConfirmacao"
                  onClick={buttonFunction}
                >
                  {textSim}
                </button>
                {textNao !== '' ? (
                  <button type="button" className="btn btn-secondary" onClick={onClose}>
                    {textNao}
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Rodal>
      </section>
    );
  }
}

export default ModalConfirmacao;
