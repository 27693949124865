import React from 'react';
import ImagemRedacaoHeader from './ImagemRedacaoHeader';
import ImagemRedacaoContent from './ImagemRedacaoContent';

const ImagemRedacao = () => (
  <>
    <ImagemRedacaoHeader />
    <ImagemRedacaoContent />
  </>
);

export default ImagemRedacao;
