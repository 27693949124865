import axios from "axios";
import { API_URL } from "../consts";

export const getToken = () => localStorage.getItem("token");
export const isAuthorized = () => +localStorage.getItem("authorized");

export const setUnauthorizedToken = (token) => {
  localStorage.setItem("token", token);
  localStorage.setItem("authorized", 0);
};

export const setAuthorizedToken = (token) => {
  localStorage.setItem("token", token);
  localStorage.setItem("authorized", 1);
  if (localStorage.getItem("storageTabActive") !== undefined) {
    localStorage.removeItem("storageTabActive");
  }
};

export const isAuthenticated = () => getToken() !== null && isAuthorized();

export const getAxiosConfig = () => ({
  headers: {
    Authorization: `JWT ${getToken()}`,
  },
});

export const login = ({ password, username }) =>
  axios.post(`${API_URL}/login/`, {
    password,
    username,
  });

export const logout = (history) => {
  if (history) {
    history.push("/login");
  }
  return axios.get(`${API_URL}/logout`, getAxiosConfig()).then(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("authorized");
    localStorage.removeItem("listSettings");
    localStorage.removeItem("selectValue");
    localStorage.removeItem("labelTabs");
    localStorage.removeItem("tableFilters");
  });
};
