import React from 'react';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { getAxiosConfig } from '../../../services';
import { API_URL } from '../../../consts';
import axios from 'axios';
import RedacoesDesempenho from './RedacoesDesempenho';
import RedacaoDesempenhoModal from './RedacaoDesempenhoModal';


class RedacoesDesempenhoPage extends React.Component {
  state = {
    loading: true,
    redacoes: [],
  }

  componentDidMount() {
    this.fetchRedacoesDesempenho();
  }

  fetchRedacoesDesempenho = async (filters = {}) => {
    const { loading } = this.props;
    if (!loading) {
      this.setState({ loading: true })
      const data = {
        ...getAxiosConfig(),
        params: filters,
      }
      axios.get(`${API_URL}/importacao/redacao-desempenho`, data)
        .then(response => {
          this.setState({ redacoes: response.data, loading: false });
        })
        .catch(err => {
          toast.error('Ocorreu um erro ao carregar as redações.');
          this.setState({ loading: false });
        })
    }
  }

  fetchRedacao = async (redacao) => {
    const config = {
      ...getAxiosConfig(),
      params: {
        mascara_redacao: redacao.mascara,
      },
    };
    this.setState(prevState => ({
      loadingRedacao: { ...prevState.loadingRedacao, [redacao.mascara]: true },
    }));
    const res = await axios.get(`${API_URL}/v2/banco-redacoes`, config);
    const src = get(res, 'data.src');
    if (!src) {
      toast.error('Ocorreu um erro ao carregar a imagem.');
    }
    this.setState(prevState => ({
      src,
      isOpen: src !== null,
      redacao: src && redacao,
      loadingRedacao: { ...prevState.loadingRedacao, [redacao.mascara]: false },
    }));
  }

  render() {
    const {
      loading,
      redacoes,
      isOpen,
      src,
      redacao,
    } = this.state;
    const { history } = this.props;

    return (
      <div className="container-fluid">
        <div className="page-container-border">
          <div className="row">
            <h1 className="ml-3 text-title d-flex justify-content-between w-100">
              Redações de desempenho
              <button
                className="btn btn-primary mr-3"
                onClick={() => history.push('/admin/importar-redacao-desempenho')}
                type="button"
              >
                Importar
              </button>
            </h1>
            <div className="col-md-12 col-xs-12 col-sm-12">
              <RedacoesDesempenho
                loading={loading}
                redacoes={redacoes}
                fetchRedacao={this.fetchRedacao}
                onFiltersChange={this.fetchRedacoesDesempenho}
              />
            </div>
          </div>
        </div>
        <RedacaoDesempenhoModal
          src={src}
          redacao={redacao}
          isOpen={isOpen}
          onClose={() => this.setState({ isOpen: false, src: '' })}
        />
      </div>
    )
  }
}

export default RedacoesDesempenhoPage
