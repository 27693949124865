import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { ComponentBase } from '../../../../base';
import { API_URL } from '../../../../consts';

class AcompanhamentoAuditoria extends ComponentBase {
  get columns() {
    return [
      {
        header: 'Auditor',
        withSortIcon: true,
        accessor: 'auditor',
        Cell: d => (<div className="column-left">{d.value}</div>),
        minWidth: 250,
      },
      {
        header: 'PD',
        withSortIcon: true,
        accessor: 'sum_pd',
      },
      {
        header: 'DDH',
        withSortIcon: true,
        accessor: 'sum_ddh',
      },
      {
        header: 'N. Máxima',
        withSortIcon: true,
        accessor: 'sum_nota_maxima',
      },
      {
        header: 'S. Exdrúxulas',
        withSortIcon: true,
        accessor: 'sum_situacao_esdruxula',
      },
      {
        header: 'Total',
        withSortIcon: true,
        accessor: 'sum_total',
      },
    ];
  }

  render() {
    const exportData = {
      url: `${API_URL}/v2/relatorios/acompanhamento-auditoria/geral/excel`,
      filename: 'acompanhamento-auditoria.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        showAvaliadorInput={false}
        showTimeInput={false}
        showPoloInput={false}
        title="ACOMPANHAMENTO AUDITORIA"
        dataUrl="v2/relatorios/acompanhamento-auditoria/geral"
        columns={this.columns}
        exportData={exportData}
        codigoMensagem="ACOMPANHAMENTO_AUDITORIA"
        codigo="ACOMPANHAMENTO_AUDITORIA"
        caption={{}}
      />
    );
  }
}

export default AcompanhamentoAuditoria;
