import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import { get } from 'lodash';
import { Alert, RadioBoxInput } from '../components';
import { login, setUnauthorizedToken } from '../services/auth';
import { setFormErrors } from '../services/utils';
import imgLogo from '../assets/images/logo/logo.png';

class SeletorProjeto extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    loggingIn: false,
    non_field_errors: [],
  };

  handleSubmit(values) {
    this.setState({ loggingIn: true });

    login(values).then((response) => {
      const { history } = this.props;
      setUnauthorizedToken(response.data.token);
      history.push('/login/confirmation', {
        ...response.data,
      });
    })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            const errors = get(error, 'response.data', {});
            const { non_field_errors, ...fieldErrors } = errors;
            this.setState({ non_field_errors: non_field_errors || [] });
            setFormErrors(this.formApi, fieldErrors);
          } else {
            alert('Ocorreu um erro no servidor.');
          }
        } else if (error.request) {
          alert('Erro de conexão com o servidor.');

        } else {
          alert('Erro desconhecido.');
        }
      })
      .then(() => this.setState({ loggingIn: false }));
  }

  render() {
    const { loggingIn, non_field_errors } = this.state;

    return (
      <section className="login-bg">
        <div className="container">
          <div className="box-form text-center border-0">
            <figure>
              <img src={imgLogo} className="logo-login" alt="logo-fgv" />
            </figure>
            <Form
              onSubmit={this.handleSubmit}
              getApi={(formApi) => { this.formApi = formApi; }}
            >
              <div className="form-sign">
                {non_field_errors.map(error => (
                  <Alert
                    category="danger"
                    onClick={() => this.setState({ non_field_errors: non_field_errors.filter(e => e !== error) })}
                  >
                    {error}
                  </Alert>
                ))}
                <div className="group-input mt-3 mb-3 seletor">
                  <h2 className="text-title d-flex">Selecione o Projeto</h2>
                  <RadioBoxInput
                    field='projeto'
                    options={[
                      {
                        value: '1',
                        label: 'Encceja - Ensino Médio'
                      },
                      {
                        value: '2',
                        label: 'Encceja - Ensino Fundamental'
                      }
                    ]}
                  />
                  {/* <SelectInput
                    field="projeto"
                    options={[
                      {
                        value: '1',
                        label: 'Encceja - Ensino Médio'
                      },
                      {
                        value: '2',
                        label: 'Encceja - Ensino Fundamental'
                      }
                    ]}
                  /> */}
                  
                </div>
                <button
                  type="submit"
                  className="btn bg-default-color-xs pull-right"
                  disabled={loggingIn}
                >
                  {loggingIn ? 'ENTRANDO...' : 'ENTRAR'}
                </button>
                

                
              </div>
            </Form>
          </div>
          
        </div>
      </section>
    );
  }
}

SeletorProjeto.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default SeletorProjeto;
