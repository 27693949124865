import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../../components/v2/Container';

const menu = [
  {
    link: '/user_import/enem/user',
    name: 'Importação de times e usuários (ENEM)',
  },
  {
    link: '/user_import/enem_pre_teste/user',
    name: 'Importação de times e usuários (ENEM PRÉ-TESTE)',
  },
  {
    link: '/user_import/enem_pre_teste/ouro',
    name: 'Importação de redações ouro (ENEM PRÉ-TESTE)',
  },
  {
    link: '/admin/login-as',
    name: 'Entrar como qualquer usuário',
  },
  // {
  //   link: '/user_import/enem_pre_teste/moda',
  //   name: 'Importação de redações moda (ENEM PRÉ-TESTE)',
  // },
];

const IndexPage = () => (
  <Container title="Importações">
    <ul>
      {menu.map(({ link, name }) => (
        <li key={link}>
          <Link to={link}>{name}</Link>
        </li>
      ))}
    </ul>
  </Container>
);

export default IndexPage;
