import React from 'react'
import { Form } from 'informed';
import { Loading, ReactSelectInput } from '../../../components'
import RedacaoDesempenhoCard from './RedacaoDesempenhoCard'
import { TIPO_REDACAO_DESEMPENHO } from '../../../consts';


class RedacoesDesempenho extends React.Component {
  renderCards = () => {
    const { loading, redacoes, fetchRedacao } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (redacoes.length === 0) {
      return <p className="mt-4">Nenhuma redação de desempenho cadastrada</p>;
    }

    return (
      <section className="box-redacoes-desempenho">
        <div className="panel-default">
          {redacoes.map(redacao => (
            <RedacaoDesempenhoCard redacao={redacao} fetchRedacao={fetchRedacao} />
          ))}
        </div>
      </section>
    );
  }

  render() {
    const { loading, onFiltersChange } = this.props;

    return (
      <Form
        onValueChange={onFiltersChange}
      >
        <div className="w-50">
          <ReactSelectInput
            label="Tipo redação"
            field="tipo_id"
            options={TIPO_REDACAO_DESEMPENHO}
            isClearable={true}
            isDisabled={loading}
          />
        </div>
        {this.renderCards()}
      </Form>
    );
  }
}

export default RedacoesDesempenho
