import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { ComponentBase } from '../../../../base';
import { API_URL } from '../../../../consts';

class AproveitamentoNotasPolo extends ComponentBase {
  get columns() {
    return (
      [{
        header: '#',
        accessor: 'row',
        minWidth: 50,
        Cell: row => <div>{row.index + 1}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Polo',
        withSortIcon: true,
        accessor: 'polo_descricao',
        Cell: d => (<div className="column-left">{d.value}</div>),
        minWidth: 200,
        footer: {
          mediaNacional: {
            accessor: '',
            default: 'Aproveitamento Nacional',
          },
        },
      },
      {
        header: 'Time',
        withSortIcon: true,
        accessor: 'time_descricao',
        Cell: d => (<div className="column-left w-100">{d.value}</div>),
        minWidth: 200,
        next: {
          pathname: '/relatorios/v2/aproveitamento-avaliadores',
          stateFields: ['polo', 'time'],
        },
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: '1ª e 2ª corrigidas',
        accessor: 'nr_corrigidas',
        withSortIcon: true,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_corrigidas',
            default: '-',
          },
        },
      },
      {
        header: 'Não discrepou',
        accessor: 'nr_nao_discrepantes',
        withSortIcon: true,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_nao_discrepantes',
            default: '-',
          },
        },
      },
      {
        header: 'Discrepou',
        accessor: 'nr_discrepantes',
        withSortIcon: true,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_discrepantes',
            default: '-',
          },
        },
      },
      {
        header: 'Redações aproveitadas',
        accessor: 'nr_aproveitadas',
        withSortIcon: true,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_aproveitadas',
            default: '-',
          },
        },
      },
      {
        header: 'Redações não aproveitadas',
        accessor: 'nr_nao_aproveitadas',
        withSortIcon: true,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_nao_aproveitadas',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitamentos dentro das discrepantes (%)',
        accessor: 'aproveitamento_dentro_discrepantes',
        withSortIcon: true,
        sortMethod: true,
        defaultValue: '0,00%',
        footer: {
          mediaNacional: {
            accessor: 'aproveitamento_dentro_discrepantes',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitamentos dentro das corrigidas (%)',
        accessor: 'aproveitamento_dentro_corrigidas',
        withSortIcon: true,
        sortMethod: true,
        defaultValue: '0,00%',
        footer: {
          mediaNacional: {
            accessor: 'aproveitamento_dentro_corrigidas',
            default: '-',
          },
        },
      },
    ]);
  }

  render() {
    const backAction = this.hasPermission('ver_relatorio_aproveitamento_geral') ? {
      path: '/relatorios/v2/aproveitamento-polo',
      state: [],
    } : undefined;

    const exportData = {
      url: `${API_URL}/v2/relatorios/aproveitamento-notas/polos/excel`,
      filename: 'aproveitamento_notas_por_polo.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        title="APROVEITAMENTO DE NOTAS | Times"
        dataUrl="v2/relatorios/aproveitamento-notas/polos"
        mediaNacionalUrl="v2/relatorios/aproveitamento-notas/geral/media"
        columns={this.columns}
        showAvaliadorInput={false}
        isPoloInputRequired
        backAction={backAction}
        exportData={exportData}
        codigoMensagem="APROVEITAMENTO_DE_NOTAS_TIMES"
        codigo="APROVEITAMENTO_NOTAS"
        caption={{}}
      />
    );
  }
}

export default AproveitamentoNotasPolo;
