import React from 'react';
import { BasicText, asField } from 'informed';

export default asField(({
  fieldState, field, label, helpBlock, numbers, icon, ...props
}) => (
  <div className="text-input-form-state">
    {label && <label htmlFor={field}>{label}</label>}
    <BasicText
      className={`form-control ${fieldState.error ? 'with-error' : null}`}
      id={field}
      fieldState={fieldState}
      maxLength={numbers}
      {...props}
    />
    {helpBlock && <small className="form-text text-muted">{helpBlock}</small>}
    {fieldState.error && <small className={fieldState.error ? 'text-danger' : null}>{fieldState.error}</small>}
    {icon && <i className={`fas fa-${icon}`} /> }
  </div>
));
