export const MODULE = process.env.REACT_APP_MODULE || 'enem';

export const alertaCaption = [
  {
    label: 'Alerta',
    shape: 'circle',
    color: 'warning-dark',
  },
];

export const statusCorretorCaption = [
  {
    label: 'Suspenso',
    shape: 'square',
    color: 'warning',
  },
  {
    label: 'Desligado',
    shape: 'square',
    color: 'red',
  },
];

export const caption = {
  title: 'Legenda',
  captions: [
    ...alertaCaption,
    ...statusCorretorCaption,
  ],
};

export const alertaOptions = [
  {
    label: '3 dias',
    value: '3 dias',
  },
  {
    label: '5 dias',
    value: '5 dias',
  },
  {
    label: 'DSP',
    value: 'DSP',
  },
  {
    label: 'Tempo médio em correção',
    value: 'Tempo médio em correção',
  },
];

export const correcoesHabilitadas = [
  {
    label: '1ª',
    value: 'pode_corrigir_1',
  },
  {
    label: '2ª',
    value: 'pode_corrigir_2',
  },
  {
    label: '3ª',
    value: 'pode_corrigir_3',
  },
];

export const correcoesHabilitadasSupervisores = [
  {
    label: '4ª',
    value: 'pode_corrigir_4',
  },
]

export const TICKET_TYPE = 1

export const TICKET_TYPES = [
  { value: 1, label: 'COMPETÊNCIA I', category: 1 },
  { value: 2, label: 'COMPETÊNCIA II', category: 1 },
  { value: 3, label: 'COMPETÊNCIA III', category: 1 },
  { value: 4, label: 'COMPETÊNCIA IV', category: 1 },
  { value: 5, label: 'COMPETÊNCIA V', category: 1 }, // tem que validar o projeto
  { value: 9, label: 'PROVA EM BRANCO', category: 2 },
  { value: 6, label: 'IMAGEM NÃO ENCONTRADA', category: 2 },
  { value: 7, label: 'IMAGEM CORTADA (FOLHA DOBRADA)', category: 2 },
  { value: 11, label: 'DIGITALIZAÇÃO MUITO CLARA/COM FALHAS', category: 2 },
  { value: 8, label: 'OUTRAS DÚVIDAS', category: 2 },
  { value: 12, label: 'CONFIRMAR INDÍCIO DE IDENTIFICAÇÃO', category: 2 },
];

const CORRECAO_SITUACOES = {};

if (MODULE === 'encceja') {
  CORRECAO_SITUACOES.SITUACOES = [
    { value: 14, label: 'Anulado' },
    { value: 15, label: 'Cópia integral' },
    { value: 16, label: 'Fuga ao tema' },
    { value: 17, label: 'Não atendimento à tipologia textual' },
  ];
} else {
  CORRECAO_SITUACOES.SITUACOES = [
    { value: 14, label: 'Formas elementares de anulação' },
    { value: 15, label: 'Cópia' },
    { value: 16, label: 'Fuga ao Tema' },
    { value: 17, label: 'Não atendimento ao tipo textual' },
    { value: 18, label: 'Parte desconectada' },
  ];
}

export { CORRECAO_SITUACOES };

export default caption;
