import React from 'react';
import PropTypes from 'prop-types';

const TableFilterSelect = ({ filter, onChange, options }) => (
  <select className="form-control" value={filter ? filter.value : ''} onChange={e => onChange(e.target.value)}>
    <option value="">-----</option>
    {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
  </select>
);

TableFilterSelect.propTypes = {
  filter: PropTypes.shape({
    value: PropTypes.any,
  }),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
  })).isRequired,
};

TableFilterSelect.defaultProps = {
  filter: null,
};

export default TableFilterSelect;
