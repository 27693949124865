import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { ComponentBase } from '../../../../base';
import { statusCorretorCaption } from '../../../../components/v2/consts';
import { API_URL } from '../../../../consts';

class AproveitamentoNotasTime extends ComponentBase {
  get columns() {
    return [
      {
        header: '#',
        accessor: 'row',
        minWidth: 50,
        Cell: row => <div>{row.index + 1}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
          mediaTime: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Polo',
        withSortIcon: true,
        accessor: 'polo_descricao',
        Cell: d => (<div className="column-left">{d.value}</div>),
        minWidth: 200,
        footer: {
          mediaNacional: {
            accessor: '',
            default: 'Aproveitamento Nacional',
          },
          mediaTime: {
            accessor: '',
            default: 'Aproveitamento Time',
          },
        },
      },
      {
        header: 'Time',
        withSortIcon: true,
        accessor: 'time_descricao',
        Cell: d => (<div className="column-left w-100">{d.value}</div>),
        minWidth: 200,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
          mediaTime: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Avaliador',
        withSortIcon: true,
        accessor: 'avaliador',
        minWidth: 200,
        Cell: d => (<div className="column-left w-100">{d.value}</div>),
        next: this.hasPermission('ver_relatorio_aproveitamento_avaliador') ? {
          pathname: '/relatorios/v2/aproveitamento-individual',
          stateFields: ['polo', 'time', 'avaliador'],
        } : null,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
          mediaTime: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: '1ª e 2ª corrigidas',
        accessor: 'nr_corrigidas',
        withSortIcon: true,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_corrigidas',
            default: '-',
          },
          mediaTime: {
            accessor: 'nr_corrigidas',
            default: '-',
          },
        },
      },
      {
        header: 'Não discrepou',
        accessor: 'nr_nao_discrepantes',
        withSortIcon: true,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_nao_discrepantes',
            default: '-',
          },
          mediaTime: {
            accessor: 'nr_nao_discrepantes',
            default: '-',
          },
        },
      },
      {
        header: 'Discrepou',
        accessor: 'nr_discrepantes',
        withSortIcon: true,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_discrepantes',
            default: '-',
          },
          mediaTime: {
            accessor: 'nr_discrepantes',
            default: '-',
          },
        },
      },
      {
        header: 'Redações aproveitadas',
        accessor: 'nr_aproveitadas',
        withSortIcon: true,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_aproveitadas',
            default: '-',
          },
          mediaTime: {
            accessor: 'nr_aproveitadas',
            default: '-',
          },
        },
      },
      {
        header: 'Redações não aproveitadas',
        accessor: 'nr_nao_aproveitadas',
        withSortIcon: true,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_nao_aproveitadas',
            default: '-',
          },
          mediaTime: {
            accessor: 'nr_nao_aproveitadas',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitamentos dentro das discrepantes (%)',
        accessor: 'aproveitamento_dentro_discrepantes',
        withSortIcon: true,
        sortMethod: true,
        defaultValue: '0,00%',
        footer: {
          mediaNacional: {
            accessor: 'aproveitamento_dentro_discrepantes',
            default: '-',
          },
          mediaTime: {
            accessor: 'aproveitamento_dentro_discrepantes',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitamentos dentro das corrigidas (%)',
        accessor: 'aproveitamento_dentro_corrigidas',
        withSortIcon: true,
        sortMethod: true,
        defaultValue: '0,00%',
        footer: {
          mediaNacional: {
            accessor: 'aproveitamento_dentro_corrigidas',
            default: '-',
          },
          mediaTime: {
            accessor: 'aproveitamento_dentro_corrigidas',
            default: '-',
          },
        },
      },
    ];
  }

  render() {
    const backAction = this.hasPermission('ver_relatorio_aproveitamento_polo') ? {
      path: '/relatorios/v2/aproveitamento-time',
      state: ['polo'],
    } : undefined;

    const exportData = {
      url: `${API_URL}/v2/relatorios/aproveitamento-notas/times/excel`,
      filename: 'aproveitamento_notas_por_time.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    const caption = {
      title: 'Legenda',
      captions: statusCorretorCaption,
    }

    return (
      <Relatorio
        title="APROVEITAMENTO DE NOTAS | Avaliadores"
        dataUrl="v2/relatorios/aproveitamento-notas/times"
        mediaNacionalUrl="v2/relatorios/aproveitamento-notas/geral/media"
        mediaTimeUrl="v2/relatorios/aproveitamento-notas/geral/media"
        fetchCorretoresInativos="correcoes/corretores_inativos"
        columns={this.columns}
        isTimeInputRequired
        isPoloInputRequired
        backAction={backAction}
        exportData={exportData}
        codigoMensagem="APROVEITAMENTO_DE_NOTAS_AVALIADORES"
        codigo="APROVEITAMENTO_NOTAS"
        caption={caption}
        baseAvaliadores
      />
    );
  }
}

export default AproveitamentoNotasTime;
