import React from 'react';
import { ComponentBase } from '../../../../base';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { API_URL } from '../../../../consts';
import { caption } from '../../../../components/v2/consts';
import { sortLocalizedFloat, sortLocalizedPercentage } from '../../../../utils';

class RelatorioGeralTime extends ComponentBase {
  get columns() {
    const mostrarDsp = this.hasFeature('mostrar_dsp');
    const columnsBase = [
      {
        header: '#',
        accessor: 'row',
        width: 50,
        Cell: row => <div>{row.index + 1}</div>,
        footer: {
          mediaTime: {
            accessor: '',
            default: '-',
          },
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Polo',
        minWidth: 100,
        withSortIcon: true,
        Cell: d => (<div className="column-left">{d.value}</div>),
        accessor: 'polo_descricao',
        footer: {
          mediaTime: {
            accessor: '',
            default: 'Time',
          },
          mediaNacional: {
            accessor: '',
            default: 'Nacional',
          },
        },
      },
      {
        header: 'Time',
        minWidth: 100,
        withSortIcon: true,
        Cell: d => (<div className="column-left">{d.value}</div>),
        accessor: 'time_descricao',
        footer: {
          mediaTime: {
            accessor: '',
            default: '-',
          },
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Avaliador',
        minWidth: 160,
        withSortIcon: true,
        Cell: d => (<div className="column-left">{d.value}</div>),
        accessor: 'avaliador',
        footer: {
          mediaTime: {
            accessor: '',
            default: '-',
          },
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Total de Corrigidas',
        accessor: 'nr_corrigidas',
        withSortIcon: true,
        defaultValue: 0,
        footer: {
          mediaTime: {
            accessor: 'nr_corrigidas',
            default: '-',
          },
          mediaNacional: {
            accessor: 'nr_corrigidas',
            default: '-',
          },
        },
      },
      {
        header: 'Média corrigidas (por dia)',
        accessor: 'media_diaria',
        withSortIcon: true,
        sortMethod: sortLocalizedFloat,
        defaultValue: 0,
        footer: {
          mediaTime: {
            accessor: 'media_diaria',
            default: '-',
          },
          mediaNacional: {
            accessor: 'media_diaria',
            default: '-',
          },
        },
      },
      {
        header: 'Tempo Médio (Minutos)',
        accessor: 'tempo_medio',
        defaultValue: '0,00',
        footer: {
          mediaTime: {
            accessor: 'tempo_medio',
            default: '-',
          },
          mediaNacional: {
            accessor: 'tempo_medio',
            default: '-',
          },
        },
        sortMethod: sortLocalizedFloat,
      },
      {
        header: 'Aproveitamento de notas (%)',
        accessor: 'aproveitamento',
        withSortIcon: true,
        maxWidth: 180,
        defaultValue: '0,00%',
        footer: {
          mediaTime: {
            accessor: 'aproveitamento',
            default: '-',
          },
          mediaNacional: {
            accessor: 'aproveitamento',
            default: '-',
          },
        },
        sortMethod: sortLocalizedPercentage,
      },
    ];


    if (mostrarDsp) {
      columnsBase.push({
        header: 'DSP',
        maxWidth: 80,
        withSortIcon: true,
        alertaNumeroBruto: true,
        accessor: 'dsp',
        footer: {
          mediaTime: {
            accessor: 'dsp',
            default: '-',
          },
          mediaNacional: {
            accessor: 'dsp',
            default: '-',
          },
        },
        sortMethod: sortLocalizedFloat,
      });
    }

    return columnsBase;
  }

  render() {
    const exportData = {
      url: `${API_URL}/v2/relatorios/relatorio-geral/time/excel`,
      filename: 'relatorio_geral_avaliadores.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    const backAction = this.hasPermission('ver_relatorio_geral_polo') ? {
      path: '/relatorios/v2/relatorio-geral-time',
      state: ['polo'],
    } : undefined;

    return (
      <Relatorio
        isPoloInputRequired
        isTimeInputRequired
        title="RELATÓRIO GERAL | Avaliadores"
        dataUrl="v2/relatorios/relatorio-geral/time"
        mediaNacionalUrl="v2/relatorios/relatorio-geral/geral/media"
        mediaTimeUrl="v2/relatorios/relatorio-geral/geral/media"
        fetchCorretoresInativos="correcoes/corretores_inativos"
        codigo="RELATORIO_GERAL"
        codigoMensagem="RELATORIO_GERAL_AVALIADORES"
        columns={this.columns}
        exportData={exportData}
        backAction={backAction}
        caption={caption}
        baseAvaliadores
        defaultPageSize={25}
      />
    );
  }
}

export default RelatorioGeralTime;
