import { get, size } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PermissionContext from '../../../contexts/PermissionContext';
import TableContext from '../../../contexts/TableContext';
import { defaultFilterMethod } from '../../../table';
import FooterTable from '../../FooterTable';
import Status from '../common/Status';
import TableFilterSelect from '../inputs/TableFilterSelect';
import Table from '../Table';


const RecursosList = ({ statusOptions }) => {
  const tableContext = useContext(TableContext);
  const { hasPermission } = useContext(PermissionContext);

  const statusMap = status => ({
    className: status.class_name,
    ...status,
  });

  const columns = [
    {
      accessor: 'id',
      Header: 'Código Recurso',
      filterMethod: defaultFilterMethod,
      Footer: () => <FooterTable media="Total" />,
    },
    {
      accessor: 'codigo_externo',
      Header: 'Código Externo',
      show: hasPermission('pode_ver_codigo_externo'),
      filterMethod: defaultFilterMethod,
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: 'redacao.id',
      Header: 'Máscara da Redação',
      filterMethod: defaultFilterMethod,
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: 'criado_em',
      Header: 'Data Abertura',
      filterMethod: defaultFilterMethod,
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: 'data_limite',
      Header: 'Data limite de resposta',
      filterMethod: defaultFilterMethod,
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: 'redacao.co_inscricao',
      Header: 'Candidato',
      show: hasPermission('ver_dados_candidato'),
      filterMethod: defaultFilterMethod,
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: 'responsavel',
      Header: 'Corretor',
      className: 'text-left',
      show: hasPermission('pode_ver_responsavel'),
      filterMethod: defaultFilterMethod,
      Footer: () => (
        <div className="text-center">
          <FooterTable media="-" />
        </div>
      ),
    },
    {
      accessor: 'status.id',
      Header: 'Status',
      Cell: cell => <Status status={statusMap(get(cell, 'original.status'))} />,
      Filter: props => <TableFilterSelect {...props} options={statusOptions} />,
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: 'ver',
      Header: 'Ver',
      width: 100,
      filterable: false,
      Footer: row => <FooterTable media={size(get(row, 'data'))} />,
      Cell: cell => (
        <Link
          className="btn btn-more"
          to={`/recursos/${get(cell, 'original.id')}`}
        >
          <i className="fa fa-eye" />
        </Link>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="page-container-border">
        <h1 className="text-title no-border">Recursos</h1>
        <hr className="m-0" />
        <Table
          className="mt-5"
          columns={columns}
          {...tableContext}
        />
      </div>
    </div>
  );
};

RecursosList.propTypes = {
  statusOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
};

export default RecursosList;
