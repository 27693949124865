import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { API_URL } from '../../../../consts';
import { ComponentBase } from '../../../../base';

class RelatorioGeralPolo extends ComponentBase {
  get columns() {
    const mostrarDsp = this.hasFeature('mostrar_dsp');
    const columnsBase = [
      {
        header: '#',
        accessor: 'row',
        width: 50,
        Cell: row => <div>{row.index + 1}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Polos',
        accessor: 'polo_descricao',
        withSortIcon: true,
        minWidth: 120,
        Cell: d => (<div className="column-left">{d.value}</div>),
        footer: {
          mediaNacional: {
            accessor: '',
            default: 'Nacional',
          },
        },
      },
      {
        header: 'Time',
        minWidth: 200,
        withSortIcon: true,
        accessor: 'time_descricao',
        next: {
          pathname: '/relatorios/v2/relatorio-geral-avaliador',
          stateFields: ['polo', 'time'],
        },
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
        Cell: d => (<div className="column-left">{d.value}</div>),
      },
      {
        header: 'Total corrigidas',
        accessor: 'nr_corrigidas',
        withSortIcon: true,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_corrigidas',
            default: '-',
          },
        },
      },
      {
        header: 'Média corrigidas (por dia)',
        accessor: 'media_diaria',
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'media_diaria',
            default: '-',
          },
        },
      },
      {
        header: 'Tempo médio (minutos)',
        accessor: 'tempo_medio',
        footer: {
          mediaNacional: {
            accessor: 'tempo_medio',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitamento de notas (%)',
        accessor: 'aproveitamento',
        footer: {
          mediaNacional: {
            accessor: 'aproveitamento',
            default: '-',
          },
        },
      },
    ];
    if (mostrarDsp) {
      columnsBase.push({
        header: 'DSP',
        accessor: 'dsp',
        alertaNumeroBruto: true,
        width: 180,
        footer: {
          mediaNacional: {
            accessor: 'dsp',
            default: '-',
          },
        },
      });
    }

    return columnsBase;
  }

  render() {
    const exportData = {
      url: `${API_URL}/v2/relatorios/relatorio-geral/polo/excel`,
      filename: 'relatorio_geral_times.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    const backAction = this.hasPermission('ver_relatorio_geral') ? {
      path: '/relatorios/v2/relatorio-geral-polo',
      state: [],
    } : undefined;

    return (
      <Relatorio
        showAvaliadorInput={false}
        title="RELATÓRIO GERAL | Times"
        dataUrl="v2/relatorios/relatorio-geral/polo"
        mediaNacionalUrl="v2/relatorios/relatorio-geral/geral/media"
        columns={this.columns}
        exportData={exportData}
        backAction={backAction}
        codigo="RELATORIO_GERAL"
        codigoMensagem="RELATORIO_GERAL_TIMES"
      />
    );
  }
}

export default RelatorioGeralPolo;
