import { useState, useEffect } from 'react';
import axios from 'axios';
import { getAxiosConfig } from '../services';

export default (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  async function fetchUrl() {
    const response = await axios.get(url, getAxiosConfig());
    const json = await response.data;
    setData(json);
    setLoading(false);
  }
  useEffect(() => {
    fetchUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [data, loading];
};
