import React from 'react';

const TableDateFilterInput = ({ filter, onChange }) => (
  <input
    type="date"
    onChange={e => onChange(e.target.value)}
    value={filter ? filter.value : ''}
  />
);

export default TableDateFilterInput;
