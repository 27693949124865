import axios from 'axios';
import { getAxiosConfig } from './auth';
import { API_URL } from '../consts';

const fetchHistoricoCorrecoes = params => axios
  .get(`${API_URL}/correcoes/historico`, { params, ...getAxiosConfig() })
    .then(response => response.data);


export const fetchHistoricoDeviosCorrecoes = redacao => axios
  .get(`${API_URL}/v2/historico-correcao/${redacao}`, { ...getAxiosConfig() })
    .then(response => response.data);

export const fetchDesvios = params => axios.get(`${API_URL}/grades/setas_desvio`, { params, ...getAxiosConfig() })
    .then(response => response.data);

export default fetchHistoricoCorrecoes;
