import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Redacao from '../../../containers/Redacao';
import BancoRedacoesContext from '../../../contexts/BancoRedacoesContext';
import HistoricoCorrecoes from '../historico_correcoes/HistoricoCorrecoes';

const ImagemRedacaoContent = () => {
  const {
    redacao: {
      id, src, situacao, nota, src_imagem_original, token,
    }, fetchImagemError, verHistoricoCandidato, historicoCorrecoes,
    fetchingHistoricoCorrecoes, verNomeAvaliadorHistorico, downloadImagemOriginal,
  } = useContext(BancoRedacoesContext);

  const redacaoSize = verHistoricoCandidato ? '8' : '12';

  return (
    <div className="row">
      <div className={`col-md-${redacaoSize} col-sm-${redacaoSize} col-xs-${redacaoSize}`}>
        <Redacao
          src={src}
          show={!fetchImagemError}
          fetchImagemError={fetchImagemError}
        />
      </div>
      {verHistoricoCandidato && (
        <div className="col-md-4 col-sm-4 col-xs-4">
          {<HistoricoCorrecoes
            historicoCorrecoes={historicoCorrecoes}
            fetching={fetchingHistoricoCorrecoes}
            verNomeAvaliadorHistorico={verNomeAvaliadorHistorico}
            showNotaFinal
            redacao={{ nota, situacao }}
          />}
          <p>
            {(downloadImagemOriginal && src_imagem_original) && <a download={`${id}.JPG`} href={src_imagem_original}>Download imagem original</a>}
          </p>
          <p>
            {token && <Link to={`/vista-prova-externa/${token}`}>Vista de prova</Link>}
          </p>
        </div>
      )}
    </div>
  );
};

export default ImagemRedacaoContent;
