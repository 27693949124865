import { SET_TABLE_DATA, SET_TABLE_FILTERS, SET_TABLE_LOADING, SET_TABLE_PAGE, SET_TABLE_PAGE_SIZE, SET_TABLE_SORTED } from './types';

const TableReducer = (state, action) => {
 switch (action.type) {
   case SET_TABLE_DATA:
    return {
      ...state,
      data: action.payload,
      loading: false,
    };

    case SET_TABLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_TABLE_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case SET_TABLE_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };

    case SET_TABLE_SORTED:
      return {
        ...state,
        sorted: action.payload,
      };

    case SET_TABLE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case 'SET_DEFAULT_FILTERS':
      return {
        ...state,
        defaultFilters: action.payload,
      };

   default:
     return state;
 }
};

export default TableReducer;
