import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import classnames from 'classnames';
import { get } from 'lodash';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services';
import Loading from '../components/Loading';
import { ModalConfirmacao } from '../components';

class TriagemFea extends React.Component {
  state = {
    data: [],
    showModalConfirmacao: false,
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const id = get(this.props, 'match.params.id');
    const prevId = get(prevProps, 'match.params.id');

    if (id !== prevId) {
      this.fetchData();
    }
  }

  get columns() {
    return [
      {
        Header: 'Imagem',
        accessor: 'co_barra_redacao',
      },
      {
        Header: 'Parecer',
        accessor: 'parecer',
        Cell: this.renderParecer,
      },
    ]
  }

  get id() {
    return get(this.props, 'match.params.id');
  }

  fetchData = () => {
    this.setState({ loading: true });

    axios.get(`${API_URL}/correcoes/triagem_fea/${this.id}`, {
        ...getAxiosConfig(),
      })
      .then((response) => {
        this.setState({ data: response.data, fetchDataSuccess: true, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  handleSubmit(parecer) {
    this.setState({ submitting: true, loading: true });

    axios.patch(`${API_URL}/correcoes/triagem_fea/${this.id}`, {
      parecer,
    }, getAxiosConfig())
      .then((response) => {
        this.buscarRedacaoParaTriagem();
        this.setState({ submitting: false })
      })
  }

  buscarRedacaoParaTriagem = () => {
    this.setState({ buscandoRedacaoParaTriagem: true });
    const { history } = this.props;

    axios.get(`${API_URL}/correcoes/triagem_fea/proxima`, getAxiosConfig())
      .then((response) => {
        history.push(`/triagem-fea/${response.data.id}`);
      })
      .catch(() => {
        history.push('/triagem-fea');
        this.setState({ buscandoRedacaoParaTriagem: false });
      })
  }

  renderRedacao() {
    const { fetchDataSuccess, data } = this.state;

    return fetchDataSuccess && (
      <img src={get(data, 'imagem')} style={{ width: '100%' }} alt="Redação" />
    );
  }

  renderRespostaAtual() {
    const { data } = this.state;
    const fea = get(data, 'parecer');

    if (fea === true) {
      return <small>Resposta atual: <strong>SIM</strong></small>;
    }

    if (fea === false) {
      return <small>Resposta atual: <strong>NÃO</strong></small>;
    }

    return null;
  }

  render() {
    const {
      submitting,
      data,
      loading,
      showModalConfirmacao,
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <p className="ml-1">Máscara {get(data, 'mascara')}</p>
            {this.renderRedacao()}
          </div>
          <div className="col-md-3 mb-3">
            <div className="row">
              <div className="col-md-12 mb-3">
                <span>É FEA?</span>
              </div>
              {get(data, 'parecer') !== false && (
                <>
                <div className="col-md-6 mb-3">
                  <button
                    type="button"
                    className={classnames('btn btn-primary btn-block', { active: get(data, 'parecer') === true })}
                    disabled={submitting}
                    onClick={() => this.handleSubmit(true)}
                  >
                    SIM
                  </button>
                </div>
                <div className="col-md-6 mb-3">
                  <button
                    type="button"
                    className={classnames('btn btn-primary btn-block', { active: get(data, 'parecer') === false })}
                    disabled={submitting}
                    onClick={() => this.setState({ showModalConfirmacao: true })}
                  >
                    NÃO
                  </button>
                </div>
                </>
              )}
              <div className="col-md-12 mb-3">
                {this.renderRespostaAtual()}
              </div>
            </div>
          </div>
        </div>
        <ModalConfirmacao
          visible={showModalConfirmacao}
          onClose={() => this.setState({ showModalConfirmacao: false })}
          title="Triagem FEA"
          content="Deseja corrigir novamente essa redação? Essa operação não poderá ser desfeita."
          buttonFunction={() => this.handleSubmit(false)}
          textSim="Sim"
          textNao="Cancelar"
          submitting={submitting}
        />
      </div>
    );
  }
}

export default withRouter(TriagemFea);
