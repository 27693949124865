import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { API_URL } from '../../../../consts';
import { ComponentBase } from '../../../../base';

class PadraoOuroPolo extends ComponentBase {
  get columns() {
    return (
      [
        {
          header: '#',
          accessor: 'row',
          width: 50,
          Cell: row => <div>{row.index + 1}</div>,
          footer: {
            mediaNacional: {
              accessor: '',
              default: '-',
            },
          },
        },
        {
          header: 'Polo',
          withSortIcon: true,
          accessor: 'polo_descricao',
          Cell: d => (<div className="column-left">{d.value}</div>),
          minWidth: 200,
          footer: {
            mediaNacional: {
              accessor: '',
              default: 'Padrão Ouro Nacional',
            },
          },
        },
        {
          header: 'Time',
          withSortIcon: true,
          accessor: 'time_descricao',
          next: {
            pathname: '/relatorios/v2/padrao-ouro-avaliadores',
            stateFields: ['polo', 'time'],
          },
          Cell: d => (<div className="column-left">{d.value}</div>),
          minWidth: 200,
          footer: {
            mediaNacional: {
              accessor: '',
              default: '-',
            },
          },
        },
        {
          header: 'Padrão ouro corrigida',
          accessor: 'nr_padrao_ouro',
          withSortIcon: true,
          minWidth: 120,
          defaultValue: 0,
          footer: {
            mediaNacional: {
              accessor: 'nr_padrao_ouro',
              default: '-',
            },
          },
        },
        {
          header: 'Discrepância padrão ouro (%)',
          accessor: 'media_discrepancia_padrao_ouro',
          withSortIcon: true,
          sortMethod: true,
          minWidth: 120,
          defaultValue: '0,00%',
          footer: {
            mediaNacional: {
              accessor: 'media_discrepancia_padrao_ouro',
              default: '-',
            },
          },
        },
      ]
    );
  }

  render() {
    const exportData = {
      url: `${API_URL}/v2/relatorios/padrao-ouro/polos/excel`,
      filename: 'padrao_ouro_por_polo.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    const backAction = this.hasPermission('ver_relatorio_padrao_ouro_geral') ? {
      path: '/relatorios/v2/padrao-ouro-polo',
      state: [],
    } : undefined;

    return (
      <Relatorio
        isPoloInputRequired
        showAvaliadorInput={false}
        title="PADRÃO-OURO | Times"
        dataUrl="v2/relatorios/padrao-ouro/polos"
        mediaNacionalUrl="v2/relatorios/padrao-ouro/geral/media"
        columns={this.columns}
        exportData={exportData}
        backAction={backAction}
        codigoMensagem="PADRAO_OURO_TIMES"
        codigo="PADRAO_OURO"
        caption={{}}
      />
    );
  }
}

export default PadraoOuroPolo;
