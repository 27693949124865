import React from 'react';

const OrtografiaIcon = ({ fill }) => (
  <svg id="Group_4403" data-name="Group 4403" viewBox="0 0 14.446 15.051">
    <path id="Path_507" data-name="Path 507" fill="#858686" d="M8.746,7.058c.075-1.282.563-1.647.563-1.647l.756.783L7.758,0h-2.5L0,14.111H2.422L3.8,10.21h4.8C8.63,9.386,8.693,7.974,8.746,7.058ZM5.738,4.565A22.147,22.147,0,0,0,6.5,1.979c.063.257.183.66.35,1.211s.3.954.4,1.211L8.563,8.228H4.472Z"/>
    <path id="Path_508" data-name="Path 508" fill={fill} d="M232.284,151.314l-1.048-2.756,1.712-.166a.344.344,0,0,0,.293-.225.351.351,0,0,0-.075-.364l-4.966-5.14a.352.352,0,0,0-.376-.087.342.342,0,0,0-.225.312l-.312,7.121a.349.349,0,0,0,.554.3l1.4-1,1.048,2.756a.332.332,0,0,0,.186.194.352.352,0,0,0,.269.008l1.348-.506a.353.353,0,0,0,.2-.451Zm0,0" transform="translate(-218.816 -137.243)"/>
  </svg>
)

export default OrtografiaIcon
