import React, { Fragment } from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAxiosConfig } from '../services/auth';
import { API_URL } from '../consts';
import 'react-table/react-table.css';
import { ComponentBase } from '../base';
import dictionary from '../dictionary';
import {
  Footer,
  ErrorList,
  TableDateFilterInput,
  Loading,
} from '../components';
import { getError } from '../services/utils';
import { toastConfig } from '../utils';
import { defaultFilterMethod, dateFilterMethod } from '../table';

class ListaResumoCorrecoes extends ComponentBase {
  constructor(props) {
    super(props);
    this.state = {
      listaAvaliadores: [],
      id_avaliador_column: 0,
      loading: true,
    };
    this.handleError = this.handleError.bind(this);
  }

  handleError(error) {
    const { history } = this.props;
    const { status, data } = getError(error);

    switch (status) {
      case 400:
        toast.error(<ErrorList errors={data} />, toastConfig);
        break;
      case 401:
      case 403:
      case 404:
        history.push('/404');
        break;
      default:
        toast.error(dictionary.ERRO_DESCONHECIDO, toastConfig);
    }
  }

  componentDidMount() {
    this.fetchAvaliadores();
  }

  fetchAvaliadores() {
    axios.get(`${API_URL}/correcoes/resumo`, getAxiosConfig())
      .then((response) => {
        const avaliadores = response.data;
        this.setState({
          listaAvaliadores: avaliadores,
          loading: false,
        });
      })
      .catch(this.handleError);
  }

  visualizarNotas(rowInfo, column) {
    const { history } = this.props;
    const { listaAvaliadores } = this.state;
    const id_avaliador_column = column.original.id;
    const { id } = listaAvaliadores.find(x => (x.id === id_avaliador_column));
    if (id === id_avaliador_column && id != null) {
      history.push(`/resumo-correcoes/${id}`);
    } else {
      alert('Acesso negado / id não confere');
    }
  }

  render() {
    const { listaAvaliadores, loading } = this.state;
    const columnsReferencia = [];
    columnsReferencia.push(
       {
        Header: '',
        filterable: false,
        Cell: row => <div>{row.index + 1}</div>,
        maxWidth: 40,
      },
    );
    columnsReferencia.push(
      {
        Header: '#',
        accessor: 'id',
        maxWidth: 60,
      },
    );
    columnsReferencia.push(
      {
        Header: 'CPF',
        accessor: 'cpf',
      },
    );
    columnsReferencia.push(
      {
        Header: 'Nome',
        accessor: 'nome',
        minWidth: 200,
      },
    );
    columnsReferencia.push(
      {
        Header: 'E-mail',
        accessor: 'email',
        minWidth: 200,
      },
    );
    if (this.hasFeature('mostrar_nota_correcao_resultado')) {
      columnsReferencia.push(
        {
          Header: 'Nota',
          accessor: 'nota',
          maxWidth: 80,
        },
      );
    }
    columnsReferencia.push(
      {
        Header: 'Correções',
        accessor: 'correcoes',
      },
    );
    columnsReferencia.push(
      {
        Header: 'Início',
        accessor: 'data_inicio',
        minWidth: 150,
        Filter: TableDateFilterInput,
        filterMethod: dateFilterMethod,
      },
    );
    columnsReferencia.push(
      {
        Header: 'Última Atualização',
        accessor: 'data_termino',
        minWidth: 150,
        Filter: TableDateFilterInput,
        filterMethod: dateFilterMethod,
      },
    );
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="page-container-border">
            <section>
              <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                  <h1 className="text-title">Resumo de Notas</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <section className=" mt-4">
                    <ReactTable
                      data={listaAvaliadores}
                      columns={columnsReferencia}
                      className="-highlight lista-resumo-correcoes"
                      defaultPageSize={10}
                      previousText=""
                      nextText=""
                      noDataText="Sem conteúdo"
                      pageText="Pág."
                      ofText="de"
                      rowsText="registros"
                      resizable={false}
                      filterable
                      defaultFilterMethod={defaultFilterMethod}
                      loading={loading}
                      loadingText={<Loading />}
                      getTrGroupProps={(rowInfo, column) => ({
                        onClick: () => {
                          this.visualizarNotas(rowInfo, column);
                        },
                      })}
                    />
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export default withRouter(ListaResumoCorrecoes);
