import React from 'react';
import { API_URL } from '../../../../consts';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { ComponentBase } from '../../../../base';
import { statusCorretorCaption } from '../../../../components/v2/consts';

class TerceiraCorrecaoTime extends ComponentBase {
  get columns() {
    return [
      {
        header: '#',
        accessor: 'row',
        width: 50,
        Cell: row => <div>{row.index + 1}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
          mediaTime: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Polo',
        accessor: 'polo_descricao',
        withSortIcon: true,
        minWidth: 180,
        Cell: d => <div className="column-left">{d.value}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: 'Terceiras Nacional',
          },
          mediaTime: {
            accessor: '',
            default: 'Terceiras Time',
          },
        },
      },
      {
        header: 'Time',
        accessor: 'time_descricao',
        withSortIcon: true,
        minWidth: 180,
        Cell: d => <div className="column-left">{d.value}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
          mediaTime: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Avaliador',
        accessor: 'avaliador',
        withSortIcon: true,
        minWidth: 180,
        Cell: d => <div className="column-left">{d.value}</div>,
        next: {
          pathname: '/relatorios/v2/terceira-correcao-avaliador',
          stateFields: ['polo', 'time', 'avaliador'],
        },
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
          mediaTime: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Terceiras corrigidas',
        accessor: 'terceiras_corrigidas',
        withSortIcon: true,
        defaultValue: 0,
        minWidth: 110,
        footer: {
          mediaNacional: {
            accessor: 'terceiras_corrigidas',
            default: '-',
          },
          mediaTime: {
            accessor: 'terceiras_corrigidas',
            default: '-',
          },
        },
      },
      {
        header: 'Terceiras aproveitadas',
        accessor: 'terceiras_aproveitadas',
        withSortIcon: true,
        defaultValue: 0,
        sortMethod: true,
        minWidth: 130,
        footer: {
          mediaNacional: {
            accessor: 'terceiras_aproveitadas',
            default: '-',
          },
          mediaTime: {
            accessor: 'terceiras_aproveitadas',
            default: '-',
          },
        },
      },
      {
        header: 'Geraram 4ª correção',
        accessor: 'foram_quarta',
        withSortIcon: true,
        defaultValue: 0,
        sortMethod: true,
        minWidth: 130,
        footer: {
          mediaNacional: {
            accessor: 'foram_quarta',
            default: '-',
          },
          mediaTime: {
            accessor: 'foram_quarta',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitados após quarta correção',
        accessor: 'aproveitadas_quarta',
        withSortIcon: true,
        defaultValue: 0,
        sortMethod: true,
        minWidth: 130,
        footer: {
          mediaNacional: {
            accessor: 'aproveitadas_quarta',
            default: '-',
          },
          mediaTime: {
            accessor: 'aproveitadas_quarta',
            default: '-',
          },
        },
      },
      {
        header: 'Não aproveitadas após quarta correção',
        accessor: 'nao_aproveitadas_quarta',
        withSortIcon: true,
        defaultValue: 0,
        sortMethod: true,
        minWidth: 130,
        footer: {
          mediaNacional: {
            accessor: 'nao_aproveitadas_quarta',
            default: '-',
          },
          mediaTime: {
            accessor: 'nao_aproveitadas_quarta',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitamento dentro das que foram para quarta (%)',
        accessor: 'aproveitamento_quarta',
        withSortIcon: true,
        sortMethod: true,
        minWidth: 150,
        defaultValue: '0%',
        footer: {
          mediaNacional: {
            accessor: 'aproveitamento_quarta',
            default: '-',
          },
          mediaTime: {
            accessor: 'aproveitamento_quarta',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitamento dentro das terceiras corrigidas (%)',
        accessor: 'aproveitamento_terceira',
        withSortIcon: true,
        sortMethod: true,
        minWidth: 150,
        defaultValue: '0%',
        footer: {
          mediaNacional: {
            accessor: 'aproveitamento_terceira',
            default: '-',
          },
          mediaTime: {
            accessor: 'aproveitamento_terceira',
            default: '-',
          },
        },
      },
    ];
  }

  render() {
    const backAction = this.hasPermission('ver_relatorio_terceira_correcao_polo') ? {
      path: '/relatorios/v2/terceira-correcao-polo',
      state: ['polo'],
    } : undefined;

    const exportData = {
      url: `${API_URL}/v2/relatorios/terceira-correcao/times/excel`,
      filename: 'terceira_correcao_time.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    const caption = {
      title: 'Legenda',
      captions: statusCorretorCaption,
    }

    return (
      <Relatorio
        isPoloInputRequired
        isTimeInputRequired
        title="TERCEIRA CORREÇÃO | Avaliadores"
        dataUrl="v2/relatorios/terceira-correcao/times"
        mediaNacionalUrl="v2/relatorios/terceira-correcao/geral/media"
        mediaTimeUrl="v2/relatorios/terceira-correcao/times/media"
        fetchCorretoresInativos="correcoes/corretores_inativos"
        columns={this.columns}
        exportData={exportData}
        backAction={backAction}
        codigoMensagem="TERCEIRA_CORRECAO_AVALIADORES"
        codigo="TERCEIRA_CORRECAO_TIME"
        caption={caption}
        baseAvaliadores
      />
    );
  }
};

export default TerceiraCorrecaoTime;
