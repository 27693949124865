import React from 'react'
import { Form } from 'informed';
import { TextInput, RadioBoxInput, ReactSelectInput, Loading } from '../../../components';
import Redacao from '../../../containers/Redacao';
import { CORRECAO, TIPO_REDACAO_DESEMPENHO } from '../../../consts';


class ImportacaoRedacaoDesempenhoForm extends React.Component {
  render() {
    const {
      fetchRedacao,
      onChange,
      onSubmit,
      notaFinal,
      getApi,
      src,
      loading,
      submitting,
    } = this.props

    return (
      <Form
        onValueChange={onChange}
        onSubmit={onSubmit}
        getApi={getApi}
      >
        <div className="d-flex align-items-end">
          <div>
            <TextInput label="Máscara da redação" field="redacao" />
          </div>
          <button
            className="ml-2 btn btn-primary"
            onClick={fetchRedacao}
            type="button"
          >
            Conferir
          </button>
        </div>
        {loading && <Loading />}
        {
          (src && !loading) && (
            <div className="row mt-3">
              <section className="col-8 col-redacao">
                <Redacao
                  src={src}
                  scrollTools
                  show
                />
              </section>
              <div className="col-4 col-competencias">
                <section className="correcao-respostas">
                  <div className="action-correcao">
                    <div className="mt-3">
                      <ReactSelectInput
                        label="Tipo redação"
                        field="tipo"
                        options={TIPO_REDACAO_DESEMPENHO}
                      />
                    </div>
                  </div>
                  <div className="action-correcao">
                    <center>
                      <h1 className="no-border-text-title">Gabarito</h1>
                    </center>
                  </div>
                  <div>
                    <div className="card-header-competencia">
                      COMPETÊNCIA  I
                    </div>
                    <div className="card-body p-0">
                      <RadioBoxInput
                        field="competencia1"
                        options={CORRECAO.COMPETENCIA1}
                      />
                    </div>
                    <div className="card-header-competencia">
                      COMPETÊNCIA  II
                    </div>
                    <div className="card-body p-0">
                      <RadioBoxInput
                        field="competencia2"
                        options={CORRECAO.COMPETENCIA2}
                      />
                    </div>
                    <div className="card-header-competencia">
                      COMPETÊNCIA  III
                    </div>
                    <div className="card-body p-0">
                      <RadioBoxInput
                        field="competencia3"
                        options={CORRECAO.COMPETENCIA3}
                      />
                    </div>
                    <div className="card-header-competencia">
                      COMPETÊNCIA  IV
                    </div>
                    <div className="card-body p-0">
                      <RadioBoxInput
                        field="competencia4"
                        options={CORRECAO.COMPETENCIA4}
                      />
                    </div>
                    <div className="card-header-competencia">
                      COMPETÊNCIA  V
                    </div>
                    <div className="card-body p-0">
                      <RadioBoxInput
                        field="competencia5"
                        options={CORRECAO.COMPETENCIA5}
                      />
                    </div>
                  </div>            
                  <div className="action-correcao">
                    <center>
                      <article className="input-nota-final">
                        <label>NOTA FINAL: </label>
                        <span> {notaFinal}</span>
                      </article>
                    </center>
                    <button className={`btn btn-primary bg-default-color-correcao mt-3 ${submitting ? 'disabled' : ''}`}>
                      {submitting ? 'ENVIANDO REDAÇÃO' : 'ENVIAR REDAÇÃO'}
                    </button>
                  </div>
                </section>
              </div>
            </div>
          )
        }
      </Form>
    )
  }
}

export default ImportacaoRedacaoDesempenhoForm
