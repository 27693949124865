import React, { useState } from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import RadioInput from '../inputs/RadioInput';
import useFetch from '../../../hooks/useFetch';
import { API_URL } from '../../../consts';

const MotivoTrocaStatusModal = ({
  visible,
  onClose,
  statusAtual,
  novoStatus,
  onConfirm,
  confirming,
  user,
}) => {
  const [motivoTrocaStatus, setMotivoTrocaStatus] = useState(null);
  const [motivosTrocaStatus] = useFetch(`${API_URL}/v3/status_corretor/motivo_troca_status`);
  const handleClose = () => {
    onClose();
    setMotivoTrocaStatus(null);
  };
  const handleConfirm = () => {
    onConfirm(motivoTrocaStatus);
    handleClose();
  };

  const renderContent = () => {
    return (
      <div>
        <div className="modal-header pb-0">
          <div className="row w-100">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <h1 className="text-title">Alterar status</h1>
            </div>
          </div>
        </div>
        <div className="modal-body pt-0">
          <div className="row">
            <div className="col-md-12">
              <p>Você está alterando o status do avaliador <strong>{user}</strong> de <strong>{statusAtual.descricao}</strong> para <strong>{novoStatus.descricao}</strong>.</p>
              {novoStatus.pedir_motivo_para_troca && (
                <div>
                  <p>Selecione o motivo para troca:</p>
                  {motivosTrocaStatus.filter(d => d.status === novoStatus.id).map(d => (
                    <RadioInput key={d.id} checked={motivoTrocaStatus === d.id} onChange={() => setMotivoTrocaStatus(d.id)} label={d.descricao} />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 text-right">
              <button type="button" className="btn btn-default mr-3" onClick={handleClose}>CANCELAR</button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleConfirm}
                disabled={confirming || (novoStatus.pedir_motivo_para_troca && !motivoTrocaStatus)}
              >
                ALTERAR STATUS
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section>
      <Rodal closeOnEsc visible={visible} onClose={handleClose}>
        {renderContent()}
      </Rodal>
    </section>
  );
};

export default MotivoTrocaStatusModal;
