import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { toastConfig } from '../utils';

class EnviarSairButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const {
      onClick, disabled, clickOnDisabledMessage, submitting,
    } = this.props;
    if (disabled) {
      if (!submitting && clickOnDisabledMessage) {
        toast.warning(clickOnDisabledMessage, toastConfig);
      }
    } else if (onClick) {
      onClick();
    }
  }

  render() {
    const { disabled, submitting } = this.props;

    return (
      <button
        type="button"
        className={`btn btn-primary bg-default-color-correcao ${disabled ? 'disabled' : ''}`}
        onClick={this.handleClick}
      >
        {submitting ? 'ENVIANDO' : 'ENVIAR E SAIR'}
      </button>
    );
  }
}

EnviarSairButton.propTypes = {
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  onClick: PropTypes.func,
  clickOnDisabledMessage: PropTypes.string,
};

EnviarSairButton.defaultProps = {
  disabled: false,
  onClick: null,
  clickOnDisabledMessage: '',
  submitting: false,
};

export default EnviarSairButton;
