import React from 'react';
import PropTypes from 'prop-types';
import Rodal from 'rodal';

const RelatorioModal = ({
  isModalOpen,
  onModalClose,
  data,
}) => (
  <Rodal
    closeOnEsc
    visible={isModalOpen}
    onClose={onModalClose}
    width={720}
  >
    <div>
      <div className="modal-header pb-0">
        <div className="row w-100">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <h1 className="text-title">Informações</h1>
          </div>
        </div>
      </div>
      <div className="modal-body pt-0">
        <div className="row">
          <div className="col-md-12">
            <pre>{data}</pre>
          </div>
        </div>
      </div>
    </div>
  </Rodal>
);

RelatorioModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  data: PropTypes.string.isRequired,
};

export default RelatorioModal;
