import { SET_DESVIOS_DATA, SET_DESVIOS_ERROR, SET_HISTORICO_DESVIOS_CORRECOES_DATA, SET_HISTORICO_DESVIOS_CORRECOES_ERROR } from './types';

const HistoricoDesviosCorrecoesReducer = (state, action) => {
  switch (action.type) {
    case SET_HISTORICO_DESVIOS_CORRECOES_DATA:
      return {
        ...state,
        historicosDesviosCorrecoes: action.payload,
        loadingHistoricosDesviosCorrecoes: false,
      };
    case SET_HISTORICO_DESVIOS_CORRECOES_ERROR:
      return {
        ...state,
        historicoDesviosCorrecoesError: true,
        loadingHistoricosDesviosCorrecoes: false,
      };
    case SET_DESVIOS_DATA:
      return {
        ...state,
        desvios: action.payload,
      };
    case SET_DESVIOS_ERROR:
      return { ...state, desviosError: true };
    default:
      return state;
  }
};

export default HistoricoDesviosCorrecoesReducer;
