import React from 'react';
import UserImportColumnRules from '../../components/UserImportColumnRules';
import HierarquiaImportColumnRules from '../../components/v2/HierarquiaImportColumnRules';
import ImportRuleset from '../../components/v2/ImportRuleset';
import ImportsPage from './ImportsPage';

class EnemUserImportPage extends React.Component {
  render() {
    return (
      <ImportsPage
        endpoint="/user_import/enem/user"
        title="Importar times e corretores (ENEM)"
        ruleset={(
          <ImportRuleset sheets={['times', 'corretores']}>
            {({ activeSheet }) => (
              <>
                {activeSheet === 'times' && <HierarquiaImportColumnRules />}
                {activeSheet === 'corretores' && <UserImportColumnRules />}
              </>
            )}
          </ImportRuleset>
        )}
      />
    )
  }
}

export default EnemUserImportPage;
