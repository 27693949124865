import React from 'react';
import InputText from './InputText';


class RangeDateInput extends React.Component {
  render() {
    return (
      <div className="grid-form-1 grid-form-rows-3 w-100">
        <InputText label="De" field="data_inicio" type="date" />
        <InputText label="Até" field="data_final" type="date" />
      </div>
    );
  }
}

export default RangeDateInput;
