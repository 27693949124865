import React from 'react';
import { get } from 'lodash';
import axios from 'axios';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { getAxiosConfig } from '../../../../services';
import { API_URL } from '../../../../consts';
import { ComponentBase } from '../../../../base';


class AcompanhamentoGeral extends ComponentBase {
  constructor(props) {
    super(props);
    this.state = {
      etapasEnsino: [],
      fetchingEtapaEnsino: false,
    };
  }

  componentDidMount() {
    this.fetchEtapasEnsino();
  }

  fetchEtapasEnsino() {
    this.setState({ fetchingEtapaEnsino: true });
    axios.get(`${API_URL}/projeto/etapa_ensino`, getAxiosConfig())
      .then((response) => {
        const etapasEnsino = response.data.map(etapa => ({
          value: etapa.id,
          label: etapa.nome,
        }));
        this.setState({ etapasEnsino, fetchingEtapaEnsino: false });
      });
  }

  get columns() {
    return [
      {
        header: 'Projeto',
        accessor: 'projeto',
        Cell: d => <strong className="column-left">{d.value}</strong>,
        minWidth: 130,
        footer: {
          mediaNacional: {
            accessor: '',
            default: 'Total',
          },
        },
      },
      {
        header: 'Etapa de Ensino',
        accessor: 'etapa_ensino',
        Cell: d => <strong className="column-left">{d.value}</strong>,
        minWidth: 100,
        show: this.hasFeature('mostrar_etapa_de_ensino'),
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Total Redações',
        accessor: 'nr_redacoes',
        minWidth: 140,
        footer: {
          mediaNacional: {
            accessor: 'nr_redacoes',
            default: '-',
          },
        },
      },
      {
        header: 'Total Em Branco',
        accessor: 'nr_redacoes_branco',
        minWidth: 140,
        footer: {
          mediaNacional: {
            accessor: 'nr_redacoes_branco',
            default: '-',
          },
        },
      },
      {
        header: 'Total Texto Insuficiente',
        accessor: 'nr_redacoes_texto_insuficiente',
        minWidth: 140,
        footer: {
          mediaNacional: {
            accessor: 'nr_redacoes_texto_insuficiente',
            default: '-',
          },
        },
      },
      {
        header: '1ª Correção Concluída',
        accessor: 'nr_1_correcao_concluida',
        minWidth: 140,
        footer: {
          mediaNacional: {
            accessor: 'nr_1_correcao_concluida',
            default: '-',
          },
        },
      },
      {
        header: '2ª Correção Concluída',
        accessor: 'nr_2_correcao_concluida',
        minWidth: 140,
        footer: {
          mediaNacional: {
            accessor: 'nr_2_correcao_concluida',
            default: '-',
          },
        },
      },
      {
        header: '3ª Correção Concluída',
        accessor: 'nr_3_correcao_concluida',
        minWidth: 140,
        footer: {
          mediaNacional: {
            accessor: 'nr_3_correcao_concluida',
            default: '-',
          },
        },
      },
      {
        header: '4ª Correção Concluída',
        accessor: 'nr_4_correcao_concluida',
        minWidth: 140,
        show: this.hasFeature('mostrar_quarta_correcao'),
        footer: {
          mediaNacional: {
            accessor: 'nr_4_correcao_concluida',
            default: '-',
          },
        },
      },
      {
        header: 'Ouro Concluída',
        accessor: 'nr_ouro_concluida',
        minWidth: 140,
        show: this.hasFeature('mostrar_correcao_ouro'),
        footer: {
          mediaNacional: {
            accessor: 'nr_ouro_concluida',
            default: '-',
          },
        },
      },
      {
        header: 'Moda Concluída',
        accessor: 'nr_moda_concluida',
        minWidth: 140,
        show: this.hasFeature('mostrar_correcao_moda'),
        footer: {
          mediaNacional: {
            accessor: 'nr_moda_concluida',
            default: '-',
          },
        },
      },
      {
        header: 'Auditoria Concluída',
        accessor: 'nr_auditoria_concluida',
        minWidth: 140,
        show: this.hasFeature('mostrar_correcao_auditoria'),
        footer: {
          mediaNacional: {
            accessor: 'nr_auditoria_concluida',
            default: '-',
          },
        },
      },
      {
        header: 'Em Andamento FilaPessoal + Ocorrência',
        accessor: 'nr_redacoes_em_andamento',
        minWidth: 140,
        footer: {
          mediaNacional: {
            accessor: 'nr_redacoes_em_andamento',
            default: '-',
          },
        },
      },
      {
        header: 'Total Redações Concluídas',
        accessor: 'nr_redacoes_concluidas',
        minWidth: 140,
        footer: {
          mediaNacional: {
            accessor: 'nr_redacoes_concluidas',
            default: '-',
          },
        },
      },
      {
        header: '% Concluidas',
        accessor: 'pc_concluidas',
        Cell: d => (d.value ? `${d.value}%` : ''),
        minWidth: 140,
        footer: {
          mediaNacional: {
            accessor: 'pc_concluidas',
            Cell: d => (d.value ? `${d.value}%` : ''),
            default: '-',
          },
        },
      },
      {
        header: '% Pendente',
        accessor: 'pc_pendentes',
        Cell: d => (d.value ? `${d.value}%` : ''),
        minWidth: 140,
        footer: {
          mediaNacional: {
            accessor: 'pc_pendentes',
            Cell: d => (d.value ? `${d.value}%` : ''),
            default: '-',
          },
        },
      },
    ];
  }

  onCustomFormChange = (event, callback) => {
    const etapa_ensino_id = get(event, 'target.value');
    callback({ etapa_ensino_id });
  }

  renderCustomForm = (callback) => {
    const { etapasEnsino, fetchingEtapaEnsino } = this.state;

    if (this.hasFeature('mostrar_etapa_de_ensino')) {
      return (
        <>
          <label htmlFor="form-state-name">Etapa de Ensino</label>
          <select onChange={event => this.onCustomFormChange(event, callback)} className="form-control mb-4">
            {
              fetchingEtapaEnsino ? (
                <option disabled value="">carregando...</option>
              ) : (
                <>
                  <option value="">-----</option>
                  {etapasEnsino.map(etapa => (
                    <option key={etapa.value} value={etapa.value}>{etapa.label}</option>
                  ))}
                </>
              )
            }
          </select>
        </>
      );
    }
    return null;
  }

  render() {
    const exportData = {
      url: `${API_URL}/v2/relatorios/acompanhamento-correcoes/geral/excel`,
      filename: 'acompanhamento_geral.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        title="Acompanhamento Geral"
        dataUrl="v2/relatorios/acompanhamento-correcoes/geral"
        mediaNacionalUrl="v2/relatorios/acompanhamento-correcoes/geral/media"
        columns={this.columns}
        codigo="ACOMPANHAMENTO_CORRECOES"
        exportData={exportData}
        defaultPageSize={10}
        showPageSizeOptions={false}
        showPagination={false}
        customForm={this.renderCustomForm}
        codigoMensagem="ACOMPANHAMENTO_GERAL"
        hideForm
      />
    );
  }
}

export default AcompanhamentoGeral;
