import debounce from 'debounce-promise';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import TableContext from '../contexts/TableContext';
import TableReducer from '../reducers/table/TableReducers';
import { SET_TABLE_DATA, SET_TABLE_LOADING } from '../reducers/table/types';

const TableState = ({
 children, fetch, setInitialState,
}) => {
  const initialState = {
    page: 0,
    pageSize: 10,
    sorted: [],
    loading: true,
    filters: {},
    data: [],
    ...setInitialState,
  };

  const [state, dispatch] = useReducer(TableReducer, initialState);

  const {
    loading, page, pageSize, sorted, filters, data,
  } = state;

  const fetchData = debounce(() => {
    dispatch({ type: SET_TABLE_LOADING, payload: false });
    const response = fetch({ ...filters });

    response
      .then((d) => {
        dispatch({ type: SET_TABLE_DATA, payload: d });
      })
      .catch((error) => {
        const status = get(error, 'response.status', '-');
        const errors = get(error, 'response.data', []);
        dispatch({ type: SET_TABLE_LOADING, payload: false });
        switch (status) {
          case 400:
            Object.keys(errors).forEach((field) => {
              if (field === 'non_field_errors') {
                toast.error(<div>{errors.non_field_errors.map(d => <>{d}<br /></>)}</div>, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: false,
                });
              } else {
                try {
                  toast.error(errors[field][0]);
                } catch (_) {
                  // DO NOTHING
                }
              }
            });

            break;
          case 401:
            window.location.href = '/login';
            break;
          case 403:
            window.location.href = '/';
            toast.error('Você não tem permissão acessar essa url');
            break;
          default:
            toast.error('Ocorreu um erro. Por favor, tente novamente.');

            break;
        }
      });
  }, 300);

  const onFilteredChange = filtered => localStorage.setItem('tableFilters', JSON.stringify(filtered));

  useEffect(() => {
    fetchData();
  }, [sorted, page, pageSize, filters]);

  const getDefaultFiltered = () => JSON.parse(localStorage.getItem('tableFilters') || '[]');

  return (
    <TableContext.Provider
      value={{
        loading,
        filters,
        data,
        filterable: true,
        onFilteredChange,
        defaultPageSize: 10,
        defaultFiltered: getDefaultFiltered(),
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

TableState.propTypes = {
  children: PropTypes.node,
  fetch: PropTypes.func,
  setInitialState: PropTypes.objectOf(),
};

TableState.defaultProps = {
  children: null,
  fetch: () => {},
  setInitialState: {},
};

export default TableState;
