import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import tableTextFilterMethod from '../../../utils/table-text-filter-method';
import AvaliadorModal from './AvaliadorModal';
import Table from '../Table';
import dictionary from '../../../dictionary';
import { toastConfig } from '../../../utils';
import { API_URL, COTA_AVALIADOR_OPTIONS } from '../../../consts';
import { getError } from '../../../services/utils';
import { getAxiosConfig } from '../../../services';

const Supervisores = ({
 users,
 onAction,
 currentUser,
 onCurrentUserChange,
 fetchingCurrentUser,
 fetchingUsers,
 statuses,
 suspensoes,
 fetchingSuspensoes,
 fetchingAproveitamento,
 contador,
 tempo_adicional,
 onAlterarContador,
 showContador,
 showHistoricoSuspensao,
 showPolo,
 showTime,
 showCorrecoesHabilitadas,
 showCota,
 showStatus,
 showAtualizacaoLote,
 mudarTimeAvaliador,
 cotaOptions,
 hierarquiasOptions,
 updateTable,
 avaliadoresSelecionados,
 selecionarAvaliador,
 selecionarTodosAvaliadores,
 onAcessarComo,
 showAcessarComo,
 podeHabilitarQuarta,
 podeTrocarCota,
 noDataText,
}) => {
  const [userModalIsOpen, setUserModalIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleChangeUserTeam = (timeSelecionado, id) => {
    const value = { time_id: timeSelecionado.value };
    setSubmitting(true);
    axios.patch(`${API_URL}/correcoes/corretores/${id}`, value, getAxiosConfig())
      .then(() => {
        toast.success(dictionary.SUCESSO_PATCH, toastConfig);
        setUserModalIsOpen(false);
        updateTable();
      })
      .catch((error) => {
        const { status, data } = getError(error);
        if (status === 400 && data.non_field_errors) {
          toast.error(data.non_field_errors[0], toastConfig);
        } else if (status === 403) {
          toast.error(data.detail, toastConfig);
        } else {
          toast.error(dictionary.ERRO_PATCH_CORRETOR, toastConfig);
        }
      })
      .then(() => {
        setSubmitting(true);
      });
  };

  const openUserModal = (userId) => {
    setUserModalIsOpen(true);
    onCurrentUserChange(userId);
  };
  const getTrProps = (_, rowInfo) => {
    const statusColor = get(statuses.find(d => d.id === get(rowInfo, 'original.status')), 'cor');
    if (statusColor) {
      return { style: { backgroundColor: statusColor } };
    }
    return {};
  };

  const allChecked = users.length === avaliadoresSelecionados.length;
  const usersIds = users.map(user => user.id);

  const columns = [
    {
      accessor: 'id',
      sortable: false,
      width: 50,
      show: showAtualizacaoLote,
      headerClassName: 'd-block',
      Header: () => (
        <div className="d-flex align-items-center justify-content-center">
          <input
            type="checkbox"
            id="selectAll"
            checked={allChecked}
            onChange={() => {
              if (allChecked) {
                selecionarTodosAvaliadores([]);
              } else {
                selecionarTodosAvaliadores(usersIds);
              }
            }}
          />
        </div>
      ),
      Cell: row => (
        <input
          type="checkbox"
          id={row.original.id}
          checked={avaliadoresSelecionados.includes(row.original.id)}
          onChange={() => selecionarAvaliador(row.original.id)}
        />
      ),
    },
    {
      accessor: 'hierarquia_responsavel',
      Header: 'Polo',
      show: showPolo,
    },
    {
      accessor: 'hierarquia',
      Header: 'Time',
      show: showTime,
    },
    {
      accessor: 'group',
      Header: 'Perfil',
    },
    {
      accessor: 'name',
      Header: 'Nome',
      minWidth: 210,
    },
    {
      Header: 'Correções habilitadas',
      show: showCorrecoesHabilitadas,
      minWidth: 140,
      Cell: row => (
        <div>
          <label className="ml-4">
            <input
              disabled={!podeHabilitarQuarta}
              type="checkbox"
              onChange={e => onAction('change', row.original.id, { pode_corrigir_4: e.target.checked })}
              checked={row.original.pode_corrigir_4}
            /> 4ª
          </label>
        </div>
      ),
    },
    {
      Header: 'Cota',
      show: showCota,
      minWidth: 100,
      accessor: 'max_correcoes_dia',
    },
    {
      accessor: 'status',
      Header: 'Status',
      show: showStatus,
      minWidth: 130,
      Cell: row => (
        <select className="form-control" value={row.value || ''} disabled={!get(statuses.find(d => row.value === d.id), 'pode_alterar', true)} onChange={e => onAction('statusChange', row.original.id, e.target.value)}>
          {statuses.filter(status => status.pode_alterar || row.value === status.id).map(d => <option key={d.id} value={d.id}>{d.descricao}</option>)}
        </select>
      ),
    },
    {
      accessor: 'ultima_correcao',
      Header: 'Última correção',
      minWidth: 150,
    },
    {
      accessor: 'corrigidas',
      Header: 'Corrigidas',
      minWidth: 120,
      Cell: (row) => {
        if (fetchingAproveitamento) {
          return <div className="loading-style text-center">carregando...</div>;
        }
        return <div>{row.value}</div>;
      },
    },
    {
      accessor: 'id',
      width: 100,
      Cell: row => <button className="btn btn-default" type="button" onClick={() => openUserModal(row.original)}><i className="fa fa-eye" /></button>,
    },
  ];

  return (
    <Fragment>
      <Table
        data={users}
        columns={columns}
        pageSize={users.length < 30 ? users.length : 30}
        defaultFilterMethod={tableTextFilterMethod}
        showPageSizeOptions={false}
        showPagination={users.length > 0}
        loading={fetchingUsers}
        getTrProps={getTrProps}
        className="table-default table-v2-relatorios"
        NoDataComponent={({ children }) => fetchingUsers || <div className="table-empty-block">{children}</div>}
        noDataText={noDataText}
      />
      <AvaliadorModal
        submitting={submitting}
        mudarTimeAvaliador={mudarTimeAvaliador}
        onChangeUserTeam={handleChangeUserTeam}
        visible={userModalIsOpen}
        onClose={() => setUserModalIsOpen(!userModalIsOpen)}
        user={currentUser}
        suspensoes={suspensoes}
        fetchingSuspensoes={fetchingSuspensoes}
        fetchingUser={fetchingCurrentUser}
        contador={contador}
        tempo_adicional={tempo_adicional}
        onAlterarContador={onAlterarContador}
        showContador={showContador}
        hierarquiasOptions={hierarquiasOptions}
        showHistoricoSuspensao={showHistoricoSuspensao}
        onAcessarComo={onAcessarComo}
        showAcessarComo={showAcessarComo}
        perfil="supervisor"
      />
    </Fragment>
  );
};

Supervisores.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAction: PropTypes.func.isRequired,
  currentUser: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onCurrentUserChange: PropTypes.func.isRequired,
  fetchingCurrentUser: PropTypes.bool.isRequired,
  fetchingUsers: PropTypes.bool.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.object),
  suspensoes: PropTypes.arrayOf(PropTypes.shape({
    criado_em: PropTypes.string,
    excluido_em: PropTypes.string,
  })),
  fetchingSuspensoes: PropTypes.bool,
  fetchingAproveitamento: PropTypes.bool.isRequired,
  contador: PropTypes.number,
  tempo_adicional: PropTypes.string,
  onAlterarContador: PropTypes.func,
  showContador: PropTypes.bool,
  showHistoricoSuspensao: PropTypes.bool,
  showPolo: PropTypes.bool,
  showTime: PropTypes.bool,
  showCorrecoesHabilitadas: PropTypes.bool,
  showCota: PropTypes.bool,
  showStatus: PropTypes.bool,
  mudarTimeAvaliador: PropTypes.bool,
  showAtualizacaoLote: PropTypes.bool,
  noDataText: PropTypes.string,
  podeHabilitarQuarta: PropTypes.bool,
  podeTrocarCota: PropTypes.bool,
  cotaOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};


Supervisores.defaultProps = {
  suspensoes: [],
  statuses: [],
  fetchingSuspensoes: false,
  contador: 0,
  tempo_adicional: '00:00:00',
  onAlterarContador: () => {},
  showContador: false,
  showHistoricoSuspensao: false,
  showPolo: false,
  showTime: false,
  showCorrecoesHabilitadas: false,
  showCota: false,
  showStatus: false,
  mudarTimeAvaliador: false,
  showAtualizacaoLote: false,
  cotaOptions: COTA_AVALIADOR_OPTIONS,
  noDataText: '',
  podeHabilitarQuarta: false,
  podeTrocarCota: false,
};

export default Supervisores;
