import React, { Fragment } from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { get, cloneDeep, find, debounce } from 'lodash';
import { toast } from 'react-toastify';
import { getAxiosConfig } from '../services';
import { handleRequestError, getError } from '../services/utils';
import { setAuthorizedToken } from '../services/auth';
import { API_URL, HIERARQUIA } from '../consts';
import {
    DadosAvaliador,
    DadosAvaliadorModulo9,
    StatusTag,
    Footer,
    Loading,
  } from '../components';
import { ComponentBase } from '../base';
import dictionary from '../dictionary';
import { toastConfig } from '../utils';

class ListaAvaliadores extends ComponentBase {
  constructor(props) {
    super(props);
    this.state = {
      sorted: [
        {
          id: 'nome',
          desc: false,
        },
      ],
      corretores: [],
      hierarquias: [],
      detailsModalIsOpen: false,
      contador: 0,
      filtered: [],
      page: 0,
      pageSize: 10,
      loadingAvaliador: true,
    };
    this.handleRowClick = this.handleRowClick.bind(this);
    this.closeDadosAvaliadorModal = this.closeDadosAvaliadorModal.bind(this);
    this.fetchAvaliadores = this.fetchAvaliadores.bind(this);
    this.updateAvaliadorAfterPatch = this.updateAvaliadorAfterPatch.bind(this);
    this.mapCorretor = this.mapCorretor.bind(this);
    this.shouldFetchHierarquia = true;
  }

  componentDidMount() {
    this.fetchAvaliadores();
    this.fetchHierarquias();
  }

  componentDidUpdate(prevProps) {
    if (this.shouldFetchHierarquia && this.hasPermission('mudar_time_avaliador')) {
      this.fetchHierarquias();
      this.shouldFetchHierarquia = false;
    }
  }

  fetchHierarquias() {
    axios.get(`${API_URL}/v2/hierarquias`, getAxiosConfig())
    .then((response) => {
      const { data } = response;
      this.setState({
        hierarquias: data.filter(hierarquia => hierarquia.id_tipo_hierarquia === HIERARQUIA.TIME).map(item => (
          { value: item.id, label: item.descricao }
        )),
        coordenador: true,
      });
    })
    .catch(handleRequestError);
  }

  mapCorretor(corretor, index) {
    return {
      ...corretor,
      status_tag: (
        <StatusTag
          icone={get(corretor, 'status.icone')}
          cor={get(corretor, 'status.classe')}
          descricao={get(corretor, 'status.descricao')}
        />
      ),
      desempenho: (
        <p>STARS</p>
      ),
      ver: (
        <button
          type="button"
          onClick={() => this.handleRowClick(index)}
          className="btn btn-more"
        >
          <i className="fa fa-eye" />
        </button>
      ),
      acessar_como: (
        <button
          type="button"
          onClick={() => this.handleAcessarComo(corretor.cpf)}
          className="btn btn-more"
        >
          <i className="fa fa-sign-in-alt" />
        </button>
      ),
    };
  }

  handleAcessarComo(cpf) {
    axios.post(`${API_URL}/login-as/`, { user: cpf }, getAxiosConfig())
      .then(({ data }) => {
        setAuthorizedToken(data.token)
        localStorage.removeItem('listSettings');
        window.location.href = '/';
      })
      .catch((error) => {
        const { status, data } = getError(error);
        let message = dictionary.ERRO_DESCONHECIDO;
        if (status === 403 && data.detail) {
          message = data.detail;
        } else if (status === 400 && data.user) {
          message = data.user[0];
        }
        toast.error(message, toastConfig);
      })
  }

  handleFormChange(formValue) {
    this.setState({ formValue });
  }

  fetchAvaliadores = debounce(() => {
    const {
      page,
      pageSize,
      sorted,
      filtered,
    } = this.state;

    const filters = {};
    filtered.forEach((filter) => {
      filters[filter.id] = filter.value;
    });
    const ordering = sorted.map(field => `${field.desc ? '-' : ''}${field.id}`).join(',');
    this.setState({ loadingAvaliador: true });
    axios.get(`${API_URL}/correcoes/relatorio`, {
        params: {
          page: page + 1,
          page_size: pageSize,
          ordering,
          ...filters,
        },
        ...getAxiosConfig(),
      })
      .then((response) => {
        const corretores = response.data.results.map(this.mapCorretor);
        this.setState({
          corretores,
          total_pages: response.data.total_pages,
          loadingAvaliador: false,
          permissoes: response.data.permissoes,
        });
      })
  }, 500)

  updateAvaliadorAfterPatch(values) {
    const { corretores, currentIndex } = this.state;
    corretores[currentIndex] = this.mapCorretor(values, currentIndex);
    this.setState({
      corretores: cloneDeep(corretores),
    });
  }

  handleRowClick(index) {
    window.scrollTo(0, 0);
    this.setState({
      currentIndex: index,
      detailsModalIsOpen: true,
    });
  }

  updateAvaliadorAfterPatchModulo9(values) {
    const { corretores, currentIndex } = this.state;
    corretores[currentIndex] = this.mapCorretor(values, currentIndex);
    this.setState({
      corretores: cloneDeep(corretores),
    });
  }

  closeDadosAvaliadorModal() {
    this.setState({
      detailsModalIsOpen: false,
    });
  }

  getHeaderWithSortIcon(label, accessor) {
    const { sorted } = this.state;
    const field = find(sorted, f => f.id === accessor);
    let sortIcon = '';
    if (field) {
      sortIcon = (
        <i
          className={`pull-right fas fa-arrow-${field.desc ? 'down' : 'up'}`}
          style={{ fontSize: 10 }}
        />
      );
    }
    return <span>{sortIcon}{label}</span>;
  }

  getColumns() {
    const { geral, fgv, polo } = this.state.permissoes || {};
    const columns = [
      {
        style: { textAlign: 'left' },
        Header: this.getHeaderWithSortIcon('Nome', 'nome'),
        accessor: 'nome',
      },
      {
        style: { textAlign: 'left' },
        Header: this.getHeaderWithSortIcon('Tipo Correção', 'tipo_correcao'),
        accessor: 'tipo_correcao',
      },
      {
        style: { textAlign: 'left' },
        Header: this.getHeaderWithSortIcon('Perfil', 'perfil'),
        accessor: 'perfil',
      },
      {
        style: { textAlign: 'center' },
        Header: this.getHeaderWithSortIcon('Cota', 'max_correcoes_dia'),
        accessor: 'max_correcoes_dia',
      },
      {
        style: { textAlign: 'center' },
        Header: this.getHeaderWithSortIcon('Status', 'status_tag'),
        accessor: 'status_tag',
      },
      {
        Header: 'Ver', accessor: 'ver', width: 80, filterable: false, sortable: false,
      },
    ];

    if (this.hasFeature('acessar_como_alguem') && this.hasPermission('acessar_como_alguem')) {
      columns.push({
        Header: 'Acessar como',
        accessor: 'acessar_como',
        width: 80,
        filterable: false,
        sortable: false,
      });
    }
    if (polo) {
      columns.splice(0, 0,
        {
          style: { textAlign: 'left' },
          Header: this.getHeaderWithSortIcon('Time', 'time'),
          accessor: 'time',
        });
    }
    if (fgv || geral) {
     columns.splice(0, 0,
        {
          style: { textAlign: 'left' },
          Header: this.getHeaderWithSortIcon('Polo', 'polo'),
          accessor: 'polo',
        });
      columns.splice(1, 0,
        {
          style: { textAlign: 'left' },
          Header: this.getHeaderWithSortIcon('Time', 'time'),
          accessor: 'time',
        });
    }
    return columns;
  }

  render() {
    const {
        detailsModalIsOpen,
        currentIndex,
        corretores,
        hierarquias,
        page,
        pageSize,
        total_pages,
        loadingAvaliador,
    } = this.state;

    return (
      <Fragment>
        <section>
          <div className="container-fluid">
            <div className="page-container-border">
              <section>
                <div className="row">
                  <div className="col-md-12 col-xs-12 col-sm-12">
                    <h1 className="text-title">Avaliadores</h1>
                  </div>
                </div>
                <Fragment>
                  <ReactTable
                    manual
                    className="table-lista-avaliadores"
                    data={corretores}
                    columns={this.getColumns()}
                    defaultPageSize={5}
                    previousText=""
                    nextText=""
                    noDataText="Sem conteúdo"
                    pageText="Pág."
                    ofText="de"
                    rowsText="registros"
                    filterable
                    page={page}
                    loading={loadingAvaliador}
                    loadingText={<Loading />}
                    pageSize={pageSize}
                    pages={total_pages}
                    onPageChange={page => this.setState({ page }, this.fetchAvaliadores)}
                    onPageSizeChange={(pageSize, page) => this.setState({ pageSize, page }, this.fetchAvaliadores)}
                    onFilteredChange={filtered => this.setState({ filtered, page: 0 }, this.fetchAvaliadores)}
                    onSortedChange={sorted => this.setState({ sorted }, this.fetchAvaliadores)}
                  />
                </Fragment>
              </section>
            </div>
          </div>
          {
            !this.hasFeature('dados_avaliador_modulo_9') ? (
              <DadosAvaliador
                avaliador={corretores[currentIndex]}
                visible={detailsModalIsOpen}
                onClose={this.closeDadosAvaliadorModal}
                patchCallback={this.fetchAvaliadores}
                mudarTimeAvaliador={this.hasPermission('mudar_time_avaliador')}
                hierarquias={hierarquias}
                auxiliar={this.hasFeature('auxiliar')}
                podeCorrigir3={this.hasPermission('liberar_terceira_correcao')}
              />
          )
          : (
            <DadosAvaliadorModulo9
              avaliador={corretores[currentIndex]}
              visible={detailsModalIsOpen}
              onClose={this.closeDadosAvaliadorModal}
              patchCallback={this.updateAvaliadorAfterPatch}
            />
          )
          }
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default ListaAvaliadores;
