import React from 'react';

class RedirectPreTeste extends React.Component {
    componentDidMount() {
        window.location.href = 'https://d2uss7keh8gpd1.cloudfront.net/login';
    }

    render() {
        return (
            <p>Carregando...</p>
        );
    }
}
export default RedirectPreTeste;
