import React from 'react';
import RecursoState from '../../../states/RecursoState';
import Recurso from '../../../components/v2/recursos/Recurso';
import PermissionState from '../../../states/PermissionState';

const RecursosPage = () => (
  <PermissionState>
    <RecursoState>
      <Recurso />
    </RecursoState>
  </PermissionState>
);

export default RecursosPage;
