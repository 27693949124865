import React from 'react';
import PropTypes from 'prop-types';

const CellLoading = ({ color }) => (
  <i style={{ color }} className="fas fa-circle-notch fa-spin" />
);

CellLoading.propTypes = {
  color: PropTypes.string,
};

CellLoading.defaultProps = {
  color: '#033F7B',
};

export default CellLoading;
