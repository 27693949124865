import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import debounce from 'debounce-promise';
import { Form } from 'informed';
import { Link, withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { getAxiosConfig } from '../services';
import { API_URL, OCORRENCIA } from '../consts';
import { TextAreaInput, RadioInput } from '.';
import OcorrenciaBase from '../pages/OcorrenciaBase';
import { toastConfig } from '../utils';
import { dateSortMethod } from '../utils/relatorios';
import dictionary from '../dictionary';

const initialValues = {
  destino_ocorrencia_id: 1,
};

class DetalhesOcorrencia extends OcorrenciaBase {
  constructor(props) {
    super(props);
    this.state = {
      accordions: {
        ocorrenciaRespondida: true,
      },
      errors: {},
      submitting: false,
      hasResposta: false,
      submittingRescan: false,
      modalVisible: false,
      changeImage: false,
    };
    this.handleResponderOcorrencia = this.handleResponderOcorrencia.bind(this);
    this.handleSolicitarReescaneamento = this.handleSolicitarReescaneamento.bind(this);
    this.handleResponderOcorrenciaImagem = this.handleResponderOcorrenciaImagem.bind(this);
    this.renderReescan = this.renderReescan.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.setState({ modalVisible: false });
  }

  getAccordionClass(accordion) {
    const { accordions } = this.state;
    return accordions[accordion] ? 'show' : 'hidden';
  }

  getAccordionArrow(accordion) {
    const { accordions } = this.state;
    return accordions[accordion] ? 'fa-angle-up' : 'fa-angle-down';
  }

  getSairUrl() {
    const {
      location: { search },
    } = this.props;
    return `/tickets${search}`;
  }

  handleAccordion(accordion) {
    const { accordions } = this.state;
    const newAccordions = {};
    Object.keys(accordions).forEach((ac) => {
      newAccordions[ac] = ac === accordion && !accordions[accordion];
    });
    this.setState({ accordions: newAccordions });
  }

  handleSolicitarReescaneamento() {
    const { ocorrencia, history } = this.props;
    this.setState({ submittingRescan: true });
    axios.post(`${API_URL}/tickets/${ocorrencia.id}/rescan`, {}, getAxiosConfig())
      .then(() => {
        toast.success(dictionary.RESCAN_SUCCESS, toastConfig);
        history.push(this.getSairUrl());
        this.setState({ submittingRescan: false });
      })
      .catch(this.handleError);
  }

  handleResponderOcorrenciaImagem(changeImage) {
    const { formState } = this.state;
    this.setState({ changeImage }, () => this.handleResponderOcorrencia(formState.values));
  }

  handleResponderOcorrencia = debounce((values) => {
    this.setState({ submitting: true });
    const id = get(this.props, 'match.params.id');
    const chatId = get(this.props, 'ocorrencia.chat.id');

    const { history, getLines } = this.props;

    const { destino_ocorrencia_id } = values;
    const postData = {
      annotations: JSON.stringify({ lines: getLines() }),
      ...values,
    };
    delete postData.destino_ocorrencia_id;
    if (destino_ocorrencia_id === 1) {
      axios.patch(`${API_URL}/tickets/${id}/chats/${chatId}`, postData, {
        ...getAxiosConfig(),
      })
      .then(() => {
        toast.success(dictionary.SUCESSO_PATCH_OCORRENCIA, toastConfig);
        history.push(this.getSairUrl());
      })
      .catch((error) => {
        this.setState({ submitting: false });
        this.handleError(error);
      });
    } else {
      axios.post(`${API_URL}/tickets/${id}/chats`, { ...postData, question: values.answer }, getAxiosConfig())
      .then(() => {
        toast.success(dictionary.SUCESSO_PATCH_OCORRENCIA, toastConfig);
        history.push(this.getSairUrl());
      })
      .catch((error) => {
        this.setState({ submitting: false });
        this.handleError(error);
      });
    }
  }, 100)

  respostaIsEmpty() {
    const value = get(this.state, 'formState.values.answer');
    return !value || (value && !value.trim());
  }

  submitDisabled() {
    const { submitting } = this.state;
    return submitting || this.respostaIsEmpty();
  }

  renderResposta() {
    const {
      ocorrencia, edit, podeEncaminharOcorrencia, podeSolicitarReescaneamento,
    } = this.props;
    const { formState } = this.state;
    const resolved_at = get(ocorrencia, 'chat.resolved_at');

    if (resolved_at) {
      return (
        <Fragment>
          <p><strong>Resposta</strong> {resolved_at && `(${resolved_at})`}</p>
          <div className="ocorrencia-resposta">
            <p>{get(ocorrencia, 'chat.answer')}</p>
          </div>
        </Fragment>
      );
    }

    if (edit) {
      return (
        <Form
          onChange={formState => this.setState({ formState })}
          getApi={(formApi) => { this.formApi = formApi; }}
          onSubmit={this.handleResponderOcorrencia}
          initialValues={initialValues}
          onSubmitFailure={formErrors => this.setState({ errors: formErrors })}
        >
          <>
            <div className={podeEncaminharOcorrencia ? '' : 'd-none'}>
              <RadioInput
                field="destino_ocorrencia_id"
                options={[
                  { value: 1, label: 'Responder' },
                  { value: 2, label: 'Encaminhar para superior' },
                ]}
              />
            </div>
            <div className="mb-4">
              <TextAreaInput
                field="answer"
                rows="10"
                className="w-100 form-control"
                placeholder={get(formState, 'values.destino_ocorrencia_id', 1) === 1 ? 'Resposta' : 'Pergunta ao superior'}
              />
            </div>
            {((ocorrencia.chat.status_id === OCORRENCIA.STATUS.EM_ABERTO)) && (
              <button
                type="submit"
                disabled={this.submitDisabled()}
                className={`btn bg-dark-default-color w-100 ${this.submitDisabled() ? 'disabled' : ''}`}
              >
                {get(formState, 'values.destino_ocorrencia_id', 1) === 1 ? 'RESPONDER' : 'ENVIAR'}
              </button>
            )}
            {podeSolicitarReescaneamento && this.renderReescan()}
          </>
        </Form>
      );
    }

    return <small>A ocorrência ainda não foi respondida.</small>;
  }

  getResponderOriginalUrl() {
    const ocorrenciasBase = get(this.props, 'ocorrencia.ocorrencias_base', []);
    if (ocorrenciasBase.length === 0) {
      return '';
    }

    return `/tickets/${ocorrenciasBase[0].id}`;
  }

  getOrderedHistory(historico) {
    const historyData = historico.reduce((acc, item) => {
      const questionObj = {
        message: item.question,
        author: item.author,
        author_group: item.author_group,
        date: item.created_at,
        question: true,
      };
      const answerObj = {
        message: item.answer,
        author: item.answerable,
        author_group: item.answerable_group,
        date: item.resolved_at,
        question: false,
      };
      acc.push(questionObj);
      acc.push(answerObj);
      return acc;
    }, [])

    return historyData.sort((a, b) => dateSortMethod(a.date, b.date))
  }

  handleEncaminharTimeTecnico = () => {
    const { ocorrencia, history } = this.props;
    axios.post(
      `${API_URL}/tickets/${ocorrencia.id}/transfer`,
      {},
      getAxiosConfig(),
    ).then(() => {
      toast.success(dictionary.ENCAMINHAR_OCORRENCIA_TIME_TECNICO, toastConfig);
      history.push(this.getSairUrl());
    }).catch(this.handleError);
  }

  renderReescan() {
    const { ocorrencia, edit } = this.props;
    if (edit) {
      if (ocorrencia.categoria_id === OCORRENCIA.CATEGORIA.PROBLEMA_IMAGEM) {
        if (ocorrencia.status.id === OCORRENCIA.STATUS.REESCANEAMENTO_RESPONDIDO) {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button
                type="button"
                className={`btn bg-dark-default-color mr-1 mt-2 ${this.submitDisabled() ? 'disabled' : ''}`}
                disabled={this.submitDisabled()}
                onClick={() => this.handleResponderOcorrenciaImagem(false)}
              >
                MANTER ORIGINAL
              </button>
              <button
                type="button"
                className={`btn bg-default-color ml-1 mt-2 ${this.submitDisabled() ? 'disabled' : ''}`}
                disabled={this.submitDisabled()}
                onClick={() => this.handleResponderOcorrenciaImagem(true)}
              >
                TROCAR IMAGEM
              </button>
            </div>
          );
        }
        return (
          <button
            type="button"
            className="btn bg-warning-color w-100 mt-4"
            onClick={() => this.handleSolicitarReescaneamento()}
            disabled={ocorrencia.lote_solicitado !== null || this.submitDisabled()}
          >
            { ocorrencia.lote_solicitado === null ? 'SOLICITAR REESCANEAMENTO' : 'REESCANEAMENTO SOLICITADO'}
          </button>
        );
      }
    }
    return '';
  }

  render() {
    const {
      ocorrencia,
      sair,
      historico,
      ver_tipo_correcao,
    } = this.props;

    const orderedHistory = this.getOrderedHistory(historico);

    const { submitting } = this.state;
    return (
      <section id="ocorrencia-respondida">
        <div className="card card-ocorrencia card-detalhes-ocorrencia">
          <button
            type="button"
            className={`card-header-ocorrencia ${get(ocorrencia, 'resposta') ? 'card-header-ocorrencia-respondida' : ''}`}
            onClick={() => this.handleAccordion('ocorrenciaRespondida')}
            data-target="#ocorrencia-resposta"
          >
            OCORRÊNCIA {get(ocorrencia, 'status.name', '').toUpperCase()}
            <i className={`mt-1 fas ${this.getAccordionArrow('ocorrenciaRespondida')} float-right`} />
          </button>
          <div
            className={`collapse ${this.getAccordionClass('ocorrenciaRespondida')}`}
            aria-labelledby="header-itens"
            data-parent="#ocorrencia-resposta"
          >
            <div className="card-body">
              <p className="card-ocorrencia-title">
                OCORRÊNCIA {get(ocorrencia, 'id')}
              </p>
              <p>
                <strong>{get(ocorrencia, 'author_group')}:</strong> {get(ocorrencia, 'author')}
              </p>
              {ver_tipo_correcao && (
                <p>
                  <strong>Tipo da Correção: </strong> {get(ocorrencia, 'correction_type')}
                </p>
              )}
              <p>
                <strong>Solicitação</strong> ({get(ocorrencia, 'created_at')})
              </p>
              <p>
                <strong>Código da Imagem</strong> ({get(ocorrencia, 'essay')})
              </p>
              <p>
                <strong>{get(ocorrencia, 'category')} / </strong>{get(ocorrencia, 'type')}
              </p>
              {get(ocorrencia, 'chat.question') && (
                <article>
                  <p><strong>Pergunta ({get(ocorrencia, 'chat.author')})</strong></p>
                  <div className="ocorrencia-pergunta">
                    <p>{get(ocorrencia, 'chat.question')}</p>
                  </div>
                </article>
              )}
              {this.renderResposta()}
              {(get(ocorrencia, 'can_transfer_to_technical_team') && get(ocorrencia, 'chat.can_awnser_chat')) && (
                  <button
                    type="button"
                    className="btn bg-warning-color w-100 mt-4"
                    onClick={this.handleEncaminharTimeTecnico}
                    disabled={this.submitDisabled()}
                  >
                    ENCAMINHAR PARA O TIME TÉCNICO
                  </button>
              )}
              {/* {this.renderReescan()} */}
              <Link to={this.getSairUrl()}>
                {sair && (
                  <button
                    type="button"
                    className="btn bg-warning-color w-100 mt-4"
                    disabled={submitting}
                  >
                    SAIR
                  </button>
                )}
              </Link>
              {
                historico.some(h => h.question || h.answer) && (
                  <section className="ocorrencia-historico">
                    <h6 className="card-historico-title">HISTÓRICO</h6>
                    {
                      orderedHistory.map(ocorrenciaHistorico => (
                        <article>
                          {get(ocorrenciaHistorico, 'question') ? (
                            <>
                              <div className="position-relative mt-2 history-right">
                                <article className="history-question">
                                  {ocorrenciaHistorico.message}
                                </article>
                                <p className="history-subtitle">{ocorrenciaHistorico.author}</p>
                                <p className="history-group right">{ocorrenciaHistorico.author_group}</p>
                                <p className="history-group right">{ocorrenciaHistorico.date}</p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="position-relative mt-2">
                                <article className="history-answer">
                                  {ocorrenciaHistorico.message}
                                </article>
                                <p className="history-subtitle right">{ocorrenciaHistorico.author}</p>
                                <p className="history-group right">{ocorrenciaHistorico.author_group}</p>
                                <p className="history-group right">{ocorrenciaHistorico.date}</p>
                              </div>
                            </>
                          )}
                        </article>
                    ))}
                  </section>
                )
              }

            </div>
          </div>
        </div>
      </section>
    );
  }
}

DetalhesOcorrencia.propTypes = {
  ocorrencia: PropTypes.shape({
    id: PropTypes.number,
    data_solicitacao: PropTypes.string,
    data_resposta: PropTypes.string,
    categoria: PropTypes.string,
    situacao: PropTypes.string,
    resposta: PropTypes.string,
    descricao: PropTypes.string,
    status: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
),
    avaliador: PropTypes.string,
    ocorrencia_pai_id: PropTypes.number,
    lote_solicitado: PropTypes.shape({
      id: PropTypes.number,
      descricao: PropTypes.string,
      enviado: PropTypes.bool,
      respondido: PropTypes.bool,
    }),
  }),
  sair: PropTypes.bool,
  edit: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.label,
  })),
  historico: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    data_solicitacao: PropTypes.string,
    data_resposta: PropTypes.string,
    categoria: PropTypes.string,
    situacao: PropTypes.string,
    resposta: PropTypes.string,
    descricao: PropTypes.string,
    status: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
),
    avaliador: PropTypes.string,
    ocorrencia_pai_id: PropTypes.number,
  })),
  sairUrl: PropTypes.string,
  podeSolicitarReescaneamento: PropTypes.bool,
};

DetalhesOcorrencia.defaultProps = {
  ocorrencia: {},
  sair: true,
  edit: false,
  historico: [],
  sairUrl: '/tickets',
  podeSolicitarReescaneamento: false,
};

export default withRouter(DetalhesOcorrencia);
