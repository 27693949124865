import axios from 'axios';
import { API_URL } from '../consts';
import { getAxiosConfig } from './auth';

const fetchBancoRedacoes = params => axios
    .get(`${API_URL}/v2/banco-redacoes`, { params, ...getAxiosConfig() })
        .then(response => response.data);

export const fetchVistaProva = params => axios
    .get(`${API_URL}/v2/vista-prova`, { params, ...getAxiosConfig() })
        .then(response => response.data);

export const fetchVistaProvaTokens = params => axios
    .get(`${API_URL}/v2/vista-prova/tokens`, { params, ...getAxiosConfig() })
        .then(response => response.data);

export default fetchBancoRedacoes;
