import React from 'react';
import classnames from 'classnames';
import axios from 'axios';
import { Form } from 'informed';
import Container from '../../components/v2/Container';
import TextInput from '../../components/TextInput';
import { API_URL } from '../../consts';

class PreTesteEnemModaImportPage extends React.Component {
  state = {
    validation: null,
    error: null,
  }

  handleError = (error) => {
    if (error.response && error.response.status === 400) {
      this.setState({ error: error.response.data.message });
    }
  }

  handleSubmit = () => {
    const { values } = this.formApi.getState();
    axios.post(`${API_URL}/user_import/enem_pre_teste/moda`, values)
      .then(() => {

      })
      .catch(this.handleError);
  }

  validate = () => {
    const { values } = this.formApi.getState();
    axios.post(`${API_URL}/user_import/enem_pre_teste/moda/validate`, values)
      .then((response) => {
        this.setState({ validation: response.data });
      })
      .catch(this.handleError);
  }

  setApi = (formApi) => {
    this.formApi = formApi;
  }

  render() {
    const initialValues = {
      num_redacoes: 4200,
      num_redacoes_caderno: 25,
      faixa12: 5,
      faixa3: 7,
      faixa4: 8,
      faixa56: 5,
    };
    const { validation, error } = this.state;

    return (
      <Container title="Importação de redações moda (ENEM PRÉ-TESTE)">
        <Form getApi={this.setApi} initialValues={initialValues}>
          <div className="d-flex align-items-center mt-2">
            <TextInput field="num_redacoes" type="number" label="Qtde. de redações" />
            <TextInput field="num_redacoes_caderno" type="number" label="Qtde. de redações por caderno" />
          </div>
          <div className="d-flex align-items-center mt-2 mb-2">
            <TextInput field="faixa12" type="number" label="Qtde. redações faixas 1 + 2" />
            <TextInput field="faixa3" type="number" label="Qtde. redações faixas 3" />
            <TextInput field="faixa4" type="number" label="Qtde. redações faixas 4" />
            <TextInput field="faixa56" type="number" label="Qtde. redações faixas 5 + 6" />
          </div>
          {validation && (
            <div>
              <p>Qtde. cadernos: <strong>{validation.num_cadernos}</strong></p>
              <p>Qtde. corretores: <strong>{validation.users}</strong></p>
              <p>Qtde. cadernos cada redação aparece: <strong>{validation.max_cadernos_redacao}</strong></p>
            </div>
          )}
          <button
            className={classnames('btn btn-primary', { disabled: false })}
            disabled={false}
            onClick={this.handleSubmit}
            type="button"
          >
            Importar
          </button>
          <button
            className={classnames('btn btn-primary', { disabled: false })}
            disabled={false}
            onClick={this.validate}
            type="button"
          >
            Validar
          </button>
          {error && <p className="text-danger">{error}</p>}
        </Form>
      </Container>
    );
  }
}

export default PreTesteEnemModaImportPage;
