import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { MODULE, API_URL } from '../../../../consts';
import { ComponentBase } from '../../../../base';


class DistribuicaoNotasSituacaoPolo extends ComponentBase {
  get columns() {
    return [
      {
        header: '#',
        accessor: 'row',
        minWidth: 50,
        Cell: row => <div>{row.index + 1}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Polo',
        withSortIcon: true,
        accessor: 'polo_descricao',
        Cell: d => (<div className="column-left">{d.value}</div>),
        minWidth: 200,
        footer: {
          mediaNacional: {
            accessor: '',
            default: 'Dist. Nacional',
          },
        },
      },
      {
        header: 'Time',
        withSortIcon: true,
        accessor: 'time_descricao',
        Cell: d => (<div className="column-left w-100">{d.value}</div>),
        minWidth: 200,
        next: {
          pathname: '/relatorios/v2/distribuicao-notas-situacao-avaliadores',
          stateFields: ['polo', 'time'],
        },
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Corrigidas',
        withSortIcon: true,
        accessor: 'nr_corrigidas',
        defaultValue: 0,
        minWidth: 100,
        footer: {
          mediaNacional: {
            accessor: 'nr_corrigidas',
            default: '-',
          },
        },
      },
      {
        header: 'Situação',
        withSortIcon: true,
        accessor: 'nr_situacoes',
        defaultValue: 0,
        minWidth: 100,
        footer: {
          mediaNacional: {
            accessor: 'media_situacoes',
            default: '-',
          },
        },
      },
      {
        header: MODULE === 'enem' ? 'FEA (%)' : 'AN (%)',
        withSortIcon: true,
        sortMethod: true,
        accessor: 'media_fea',
        defaultValue: '0,00%',
        minWidth: 100,
        footer: {
          mediaNacional: {
            accessor: 'media_fea',
            default: '-',
          },
        },
      },
      {
        header: 'Cópia (%)',
        withSortIcon: true,
        sortMethod: true,
        accessor: 'media_copia',
        defaultValue: '0,00%',
        minWidth: 100,
        footer: {
          mediaNacional: {
            accessor: 'media_copia',
            default: '-',
          },
        },
      },
      {
        header: 'FT (%)',
        withSortIcon: true,
        sortMethod: true,
        accessor: 'media_ft',
        defaultValue: '0,00%',
        minWidth: 100,
        footer: {
          mediaNacional: {
            accessor: 'media_ft',
            default: '-',
          },
        },
      },
      {
        header: 'NATT (%)',
        withSortIcon: true,
        sortMethod: true,
        accessor: 'media_natt',
        defaultValue: '0,00%',
        minWidth: 100,
        footer: {
          mediaNacional: {
            accessor: 'media_natt',
            default: '-',
          },
        },
      },
      {
        header: 'PD (%)',
        withSortIcon: true,
        sortMethod: true,
        accessor: 'media_pd',
        defaultValue: '0,00%',
        show: MODULE === 'enem',
        minWidth: 100,
        footer: {
          mediaNacional: {
            accessor: 'media_pd',
            default: '-',
          },
        },
      },
    ];
  }

  render() {
    const backAction = this.hasPermission('ver_relatorio_distribuicao_notas_situacao_geral') ? {
      path: '/relatorios/v2/distribuicao-notas-situacao-polo',
      state: [],
    } : undefined;

    const exportData = {
      url: `${API_URL}/v2/relatorios/distribuicao-notas-situacao/polos/excel`,
      filename: 'distribuicao_notas_por_situacao_polo.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        isPoloInputRequired
        showAvaliadorInput={false}
        title="DISTRIBUIÇÃO DE SITUAÇÕES | Times"
        dataUrl="v2/relatorios/distribuicao-notas-situacao/polos"
        mediaNacionalUrl="v2/relatorios/distribuicao-notas-situacao/geral/media"
        columns={this.columns}
        backAction={backAction}
        codigo="DISTRIBUICAO_NOTAS_SITUACAO"
        codigoMensagem="DISTRIBUICAO_NOTAS_SITUACAO_TIMES"
        exportData={exportData}
      />
    );
  }
}

export default DistribuicaoNotasSituacaoPolo;
