import React from 'react';
import Rodal from 'rodal';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import 'rodal/lib/rodal.css';
import PropTypes from 'prop-types';
import { Form, Text } from 'informed';
import axios from 'axios';
import Countdown from 'react-countdown-now';
import userPlaceholder from '../assets/images/users/placeholder.svg';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services/auth';
import { toastConfig } from '../utils';

class DadosAvaliadorModulo9 extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.string,
    avaliador: PropTypes.string,
    patchCallback: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    onClose: null,
    patchCallback: null,
    id: null,
    avaliador: '',
  };

  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      submitting: false,
      contador: 0,
    };
  }

  componentDidUpdate(prevProps) {
    const { visible } = this.props;
    const prevVisible = prevProps.visible;
    const currentVisible = visible;
    if (prevVisible !== currentVisible) {
      this.fetchContador();
    }
  }

  handleCancel() {
    const { onClose } = this.props;
    onClose();
    this.setFormValues();
  }

  fetchContador() {
    const id = get(this.props, 'avaliador.id');
    axios.get(`${API_URL}/correcoes/${id}/contador`, getAxiosConfig())
    .then((response) => {
      const { contador } = response.data;
      this.setState({ contador: Date.now() + contador });
    });
  }

  handleSubmit(values) {
    const { onClose } = this.props;
    const id = get(this.props, 'avaliador.id');
    const data = {
      id,
      tempo_restante: values.tempo_restante,
    };
    axios.patch(`${API_URL}/correcoes/contador`, data, getAxiosConfig())
    .then(() => {
      toast.success('Alterações salvar com sucesso!', toastConfig);
      onClose();
    })
    .catch(() => {
      toast.error('Erro ao salvar as alterações. Tente novamente mais tarde!', toastConfig);
      onClose();
    });
  }

  render() {
    const { visible, onClose, avaliador } = this.props;
    const { submitting, contador } = this.state;
    const renderer = ({
      hours, minutes, seconds,
    }) => <span className="countdown-style">{hours}:{minutes}:{seconds}</span>;
    return (
      <section className="modal-dados-avaliador modulo9">
        <Form
          getApi={(formApi) => { this.formApi = formApi; }}
          onSubmit={this.handleSubmit}
        >
          {({ formState }) => (
            <Rodal visible={visible} onClose={onClose} closeOnEsc>
            <div className="modal-header">
              <div className="row w-100">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1 className="text-title">Dados Pessoais</h1>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <figure className="avaliador-img">
                    <img src={userPlaceholder} className="img-responsive" alt="avaliador-img" />
                  </figure>
                  <div className="desempenho-section">
                    <div className="modal-form">
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <span className="avaliador-font-sm mb-2">
                            <strong>Tempo Restante: </strong>
                              <Countdown
                                date={contador}
                                renderer={renderer}
                              />
                          </span>
                        </div>
                        <div className="col-md-12">
                          <strong>Alterar tempo Restante:</strong>
                          <Text field="tempo_restante" className="form-control mt-2" type="time" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-7">
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="avaliador-inf">
                        {get(avaliador, 'nome')}
                        <i className="fa fa-map-marker-alt ml-3" />
                        <span className="avaliador-font-sm ml-2">
                          {get(avaliador, 'local')}
                        </span>
                      </h4>
                    </div>
                    <div className="col-md-12">
                      <span className="avaliador-font-sm default">
                        <strong>Status:</strong>
                      </span>
                      <span className="avaliador-font-sm ml-2">
                        {get(avaliador, 'status.descricao')}
                      </span>
                    </div>
                    <div className="col-md-12">
                      <div className="btn-tag">
                        <span className="avaliador-font-sm">
                          <strong>TOTAL DE CORREÇÕES: {get(avaliador, 'total_correcoes')}</strong>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="data-section">
                        <h5 className="pb-3"><i className="fas fa-user mr-2" /> Dados Principais</h5>
                        <div className="inf-section mt-3">
                          <p className="avaliador-font-sm">Telefone:
                              <strong className="default ml-2">{get(avaliador, 'telefone')}</strong>
                          </p>
                        </div>
                        <div className="inf-section">
                          <p className="avaliador-font-sm">Email:
                              <strong className="default text-lw ml-2">{get(avaliador, 'email')}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-6">
                  <button
                    type="button"
                    className="btn bg-dark-warning-color ml-2"
                    onClick={onClose}
                  >
                    CANCELAR
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="submit"
                    className="btn bg-default-color ml-2"
                    disabled={submitting}
                  >
                      SALVAR
                  </button>
                </div>
              </div>
            </div>
            </Rodal>
          )}
        </Form>
      </section>
    );
  }
}

export default DadosAvaliadorModulo9;
