import { get } from 'lodash';
import React, { useContext } from 'react';
import HistoricosDesviosCorrecoesContext from '../../../contexts/HistoricosDesviosCorrecoesContext';
import permissionContext from '../../../contexts/PermissionContext';
import RecursoContext from '../../../contexts/RecursoContext';
import HistoricoCorrecoes from '../historico_correcoes/HistoricoCorrecoes';
import AnswerHeader from '../questions/AnswerHeader';
import QuestionHeader from '../questions/QuestionHeader';

const RecursoHeader = () => {
  const { recurso, loadingRecurso } = useContext(RecursoContext);
  const { hasPermission } = useContext(permissionContext);
  const { historicosDesviosCorrecoes, loadingHistoricosDesviosCorrecoes } = useContext(HistoricosDesviosCorrecoesContext);
  const nomeCandidato = get(recurso, 'candidato') || '';

  if (loadingRecurso) {
    return (<></>);
  }

  return (
    <>
      <div className="mt-3">
        <QuestionHeader
          title={`${nomeCandidato} # ${get(recurso, 'redacao_id')}`}
          subtitle={recurso.criado_em}
        />
      </div>
      <div className="question-box">
        <p>{recurso.pergunta}</p>
        <p className="box-highlight">
          <span className="font-weight-600 mr-1">Nota atual: </span>
          {get(recurso, 'redacao.nota_final')}
        </p>
      </div>
      {get(recurso, 'resposta') && (
        <div className="mt-3">
          <AnswerHeader
            title={`${get(recurso, 'responsavel', '')}`}
            subtitle={recurso.resolvido_em}
          />
          <div className="answer-box">
            <p>{recurso.resposta}</p>
            <p className="box-highlight">
              <span className="font-weight-600 mr-1">Nota deferida: </span>
              {get(recurso, 'nota_deferida') || get(recurso, 'redacao.nota_final')}
            </p>
          </div>
        </div>
      )}
      {hasPermission('pode_divulgar_recurso') && get(recurso, 'resposta_formatada') && (
        <div className="mt-3">
          <AnswerHeader
            title={`${get(recurso, 'divulgado_por', '')}`}
            subtitle={recurso.divulgado_em}
          />
          <div className="answer-box">
            <p>{recurso.resposta_formatada}</p>
          </div>
        </div>
      )}
      <p className="mt-3">
        <span className="primary-label">Grade de correção: </span>
        <HistoricoCorrecoes
          historicoCorrecoes={historicosDesviosCorrecoes}
          verNomeAvaliadorHistorico={false}
          fetching={loadingHistoricosDesviosCorrecoes}
          showNotaFinal={false}
          redacao={recurso.redacao || {}}
          verHeader={false}
        />
      </p>
      {get(recurso, 'tipo_deferimento') && (
        <p className="mt-3">
          <span className="primary-label">Resultado: </span>
          <span className="font-weight-200">{get(recurso, 'tipo_deferimento.nome')}</span>
        </p>
      )}
      <p className="mt-3">
        <span className="primary-label">Data limite de resposta: </span>
        <span className="font-weight-200">{recurso.data_limite || '-'}</span>
      </p>
    </>
  );
};

export default RecursoHeader;
