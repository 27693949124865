import React from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { setAuthorizedToken } from '../services/auth';

class LoginRedirect extends React.Component {

 componentDidMount() {
   const { match, location } = this.props;
   const token = get(match, 'params.token');
   setAuthorizedToken(token);
   const params = new URLSearchParams(location.search);
   window.location.href = params.get('next') || '/';
 }

 render() {
   return <p>Redirecionando...</p>;
 }
}

export default withRouter(LoginRedirect);
