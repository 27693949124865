import React from 'react';
import ReactTable from 'react-table';
import tableTextFilterMethod from '../../utils/table-text-filter-method';
import Loading from '../Loading';

const translations = {
  previousText: 'anterior',
  nextText: 'próximo',
  loadingText: <Loading />,
  noDataText: 'nenhum dado encontrado',
  pageText: 'pág.',
  ofText: 'de',
  rowsText: 'registros',
};

const Table = props => (
  <ReactTable
    className="table-default"
    defaultFilterMethod={tableTextFilterMethod}
    {...translations}
    {...props}
  />
);

export default Table;
