import React, { Fragment } from 'react';
import ReactTable from 'react-table';
import { get } from 'lodash';
import axios from 'axios';
import { find } from 'lodash';
import { getAxiosConfig } from '../services';
import { API_URL } from '../consts';

const sortCustom = (a, b) => {
    if (a && b) {
      a = parseFloat(a);
      b = parseFloat(b);
      return a - b;
    }

    if (!b) return a;
    return b;
  };
class TabelaAcompanhamento extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            relatorio: [],
            size_table: 0,
            loading: true,
            sorted: [
                {
                  id: 'data',
                  desc: true,
                },
              ],
        };
    }

    componentDidMount() {
      axios.all([
        this.fetchColunas(),
        this.fetchRelatorio(),
      ])
        .then(() => this.setState({ loading: false }))
    }

    getHeaderWithSortIcon(label, accessor) {
        const { sorted } = this.state;
        const field = find(sorted, f => f.id === accessor);
        let sortIcon = '';
        if (field) {
          sortIcon = (
            <i
              className={`right-arrow fas fa-arrow-${field.desc ? 'down' : 'up'}`}
              style={{ fontSize: 10 }}
            />
          );
        }
        return <span>{sortIcon}<strong>{label}</strong></span>;
      }

    fetchRelatorio() {
      return axios.get(`${API_URL}/correcoes/legacy/acompanhamento-correcoes`, getAxiosConfig())
        .then((response) => {
            const relatorio = response.data;
            this.setState({
                relatorio,
                size_table: relatorio.length,
                pode_corrigir_3: get(response, 'data[0].pode_corrigir_3'),
            });
        });
    }

    fetchColunas() {
      return axios.get(`${API_URL}/correcoes/tipos-correcoes-acompanhamento`, getAxiosConfig())
        .then((response) => {
          this.setState({
            show_3_correcao: response.data.ver_acompanhamento_3,
            show_1_2_correcao: response.data.ver_acompanhamento_1_2,
          })
        })
    }

    renderTable() {
        const {
          ver_acompanhamento_supervisor,
          ver_4_correcao_acompanhamento,
          ver_3_correcao_acompanhamento,
          ver_1_2_correcao_acompanhamento,
          ver_avaliacao_desempenho,
        } = this.props;
        const {
          relatorio,
          loading,
          size_table,
          show_3_correcao,
          show_1_2_correcao,
        } = this.state;
        let columns = [];

        const show3Correcao = show_3_correcao || ver_3_correcao_acompanhamento
        const show12Correcao = show_1_2_correcao || ver_1_2_correcao_acompanhamento

        if (ver_acompanhamento_supervisor) {
            columns = [
                {
                    Header: 'Acompanhamento da 4ª Correção',
                    columns: [
                        {
                            Header: this.getHeaderWithSortIcon('Lote', 'lote'),
                            accessor: 'lote',
                            minWidth: 50,
                        },
                        {
                            Header: this.getHeaderWithSortIcon('Redações Corrigidas pela BPA (4ª Correção)', 'corrigidas'),
                            accessor: 'corrigidas',
                        },
                    ],
                },
            ];
        } else {
            columns = [
                {
                    Header: this.getHeaderWithSortIcon('Data', 'data'),
                    accessor: 'data',
                    minWidth: 150,

                },
                {
                    Header: 'Tipos de Correção',
                    columns: [
                        {
                            Header: this.getHeaderWithSortIcon('1ª e 2ª Correções', 'primeira_segunda'),
                            accessor: 'primeira_segunda',
                            sortMethod: sortCustom,
                            minWidth: 150,
                            show: show12Correcao || false,
                        },
                        {
                            Header: this.getHeaderWithSortIcon('3ª Correção', 'terceira'),
                            accessor: 'terceira',
                            sortMethod: sortCustom,
                            show: show3Correcao || false,
                        },
                        {
                            Header: this.getHeaderWithSortIcon('Avaliação de Desempenho', 'avaliacao_desempenho'),
                            accessor: 'avaliacao_desempenho',
                            sortMethod: sortCustom,
                            show: ver_avaliacao_desempenho,
                            minWidth: 200,
                        },
                        {
                            Header: this.getHeaderWithSortIcon('Em branco', 'brancas'),
                            accessor: 'brancas',
                            sortMethod: sortCustom,
                            minWidth: 150,
                        },
                    ],
                },
                {
                    Header: this.getHeaderWithSortIcon('Total', 'total'),
                    accessor: 'total',
                    sortMethod: sortCustom,
                },
            ];
            if (ver_4_correcao_acompanhamento) {
                columns[1].columns.splice(2, 0,
                    {
                        Header: this.getHeaderWithSortIcon('4ª Correção', 'quarta'),
                        accessor: 'quarta',
                        sortMethod: sortCustom,
                    });
            }
        }

        return (
         <Fragment>
            <ReactTable
              data={relatorio}
              className="-highlight tabela-acompanhamento-css"
              noDataText="Sem conteúdo"
              rowsText="registros"
              loading={loading}
              loadingText="Carregando informações..."
              pageSize={size_table}
              columns={columns}
              resizable={false}
              showPagination={false}
              onSortedChange={sorted => this.setState({ sorted })}
            />
         </Fragment>
        );
      }

    render() {
        return (
            <Fragment>
                {this.renderTable()}
            </Fragment>
           );
    }
}

export default TabelaAcompanhamento;
