import React from 'react';
import { API_URL } from '../../../../consts';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { ComponentBase } from '../../../../base';


class TerceiraCorrecaoAvaliador extends ComponentBase{
  get columns() {
    return [
      {
        header: 'Corretor',
        columns: [
          {
            header: 'Redação',
            accessor: 'redacao',
            hasRedacao: true,
          },
          {
            header: 'Data',
            accessor: 'data',
          },
          {
            header: 'Aproveitamento',
            accessor: 'aproveitamento',
            minWidth: 150,
          },
          {
            header: 'Situação',
            accessor: 'terceira_situacao',
            minWidth: 90,
          },
        ].concat(
          [1, 2, 3, 4].map((competencia) => {
            const numeral = {
              1: 'I',
              2: 'II',
              3: 'III',
              4: 'IV',
            }[competencia];

            return {
              header: `C${numeral}`,
              accessor: `terceira_c${competencia}`,
              minWidth: 60,
            };
          }),
          {
            header: 'CV',
            accessor: 'terceira_c5',
            Cell: d => d.original.terceira_c5 === -1 ? 0 : d.original.terceira_c5,
            minWidth: 60,
          },
          {
            header: 'DDH',
            accessor: 'terceira_c5',
            minWidth: 70,
            Cell: row => row.value === -1 && <i className="fas fa-check" />,
          },
          {
            header: 'Nota',
            accessor: 'terceira_soma',
            minWidth: 70,
          },
        ),
      },
      {
        header: '4ª Correção',
        columns: [
          {
            header: 'Situação',
            accessor: 'quarta_situacao',
            minWidth: 90,
          },
        ].concat(
          [1, 2, 3, 4].map((competencia) => {
            const numeral = {
              1: 'I',
              2: 'II',
              3: 'III',
              4: 'IV',
            }[competencia];

            return {
              header: `C${numeral}`,
              accessor: `quarta_c${competencia}`,
              minWidth: 60,
            };
          }),
          {
            header: 'CV',
            accessor: 'quarta_c5',
            Cell: d => d.original.quarta_c5 === -1 ? 0 : d.original.quarta_c5,
            minWidth: 60,
          },
          {
            header: 'DDH',
            accessor: 'quarta_c5',
            Cell: row => row.value === -1 && <i className="fas fa-check" />,
            minWidth: 70,
          },
          {
            header: 'Nota',
            accessor: 'quarta_soma',
            minWidth: 70,
          },
        ),
      },
    ];
  }

  render() {
    const backAction = this.hasPermission('ver_relatorio_terceira_correcao_time') ? {
      path: '/relatorios/v2/terceira-correcao-time',
      state: ['polo', 'time'],
    } : undefined;

    const exportData = {
      url: `${API_URL}/v2/relatorios/terceira-correcao/avaliadores/excel`,
      filename: 'terceira_correcao_avaliador.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        isPoloInputRequired
        isTimeInputRequired
        isAvaliadorInputRequired
        title="TERCEIRA CORREÇÃO | individual"
        dataUrl="v2/relatorios/terceira-correcao/avaliadores"
        columns={this.columns}
        exportData={exportData}
        backAction={backAction}
        codigoMensagem="TERCEIRA_CORRECAO_INDIVIDUAL"
        codigo="TERCEIRA_CORRECAO_AVALIADOR"
        caption={{}}
      />
    );
  }

};

export default TerceiraCorrecaoAvaliador;
