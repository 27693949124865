import React, { useContext } from 'react';
import PropType from 'prop-types';
import ImagemRedacaoTitle from '../banco_redacoes/ImagemRedacaoTitle';
import BancoRedacoesContext from '../../../contexts/BancoRedacoesContext';
import LegendItem from '../layout/LegendItem';


const ImagemRedacaoExternaHeader = ({ desvios }) => {
  const {
    redacao: {
      id, src_imagem_original,
    }, downloadImagemOriginal, showLegend,
  } = useContext(BancoRedacoesContext);

  return (
    <div className="redacao-header">
      <div className="d-flex align-items-center justify-content-between w-100">
        <div>
          <ImagemRedacaoTitle />
          {(downloadImagemOriginal && src_imagem_original) && <a download={`${id}.JPG`} href={src_imagem_original}>Download imagem original</a>}
        </div>
        {showLegend && (
          <div>
            <h5>Legenda</h5>
            <ul className="legends-list">
              <LegendItem
                label="Marcações"
                color="#D7A926"
              />
              <LegendItem
                label="Caneta"
                color="#D05050"
              />
              {
                desvios.map(desvio => (
                  <LegendItem
                    key={desvio.id}
                    color={desvio.cor}
                    label={desvio.descricao}
                    tooltip={desvio.tooltip}
                  />
                ))
              }
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

ImagemRedacaoExternaHeader.propTypes = {
  desvios: PropType.arrayOf(PropType.shape({
    id: PropType.number,
    indicador: PropType.oneOfType([PropType.string, PropType.number]),
    cor: PropType.oneOfType([PropType.string, PropType.number]),
    espessura: PropType.oneOfType([PropType.string, PropType.number]),
    comprimento: PropType.oneOfType([PropType.string, PropType.number]),
    tooltip: PropType.oneOfType([PropType.string, PropType.number]),
    descricao: PropType.oneOfType([PropType.string, PropType.number]),
    valor: PropType.oneOfType([PropType.string, PropType.number]),
  })),
};

ImagemRedacaoExternaHeader.defaultProps = {
  desvios: [],
};

export default ImagemRedacaoExternaHeader;
