import React, { Component } from 'react';
import * as Talk from 'talkjs';
import PropTypes from 'prop-types';
import axios from 'axios';
import { CHAT_APP_ID, API_URL } from '../consts';
import { getAxiosConfig } from '../services/auth';


class ChatInbox extends Component {
    constructor(props) {
        super(props);
        this.talkSession = undefined;
        this.state = {
          conversations: [],
          pop_up: false,
          unreadIds: [],
          conversationId: '',
          otherUserId: '',
          messageTimestamp: '',
          showButton: false,
        };
        this.togglePopUp = this.togglePopUp.bind(this);
        this.handleChatClose = this.handleChatClose.bind(this);
    }

    componentDidMount() {
      this.createConversations();
    }


    handleChatClose() {
      const { inbox } = this.state;
      inbox.destroy();
      this.setState({
        conversationId: '',
        showButton: false,
      });
    }

    createConversations() {
      Talk.ready
        .then(() => {
          const { current_user, contacts, signature } = this.props;
          contacts.forEach((user) => {
            const { conversations } = this.state;
            const me = new Talk.User(current_user);
            this.talkSession = new Talk.Session({
                appId: CHAT_APP_ID,
                me,
                signature,
            });
            const other = new Talk.User(user);
            // const conversationId = Talk.oneOnOneId(me, other);
            const users = [];
            users.push(user.id);
            users.push(current_user.id);
            const conversationId = users.sort().join('_');
            const conversation = this.talkSession.getOrCreateConversation(conversationId);
            conversation.setParticipant(me);
            conversation.setParticipant(other);
            const inbox = this.talkSession.createChatbox(conversation);
            inbox.mount(this.container);

            this.talkSession.unreads.on('change', (unreadMessages) => {
              const conversationIds = unreadMessages.map(message => message.lastMessage.conversation.id);
              this.setState({
                unreadIds: conversationIds,
              });
            });

            this.talkSession.on('message', (message) => {
              const { otherUserId, messageTimestamp } = this.state;
              if (message.isByMe && messageTimestamp !== message.timestamp) {
                this.setState({ messageTimestamp: message.timestamp });
                const data = {
                  remetente: message.senderId,
                  destinatario: otherUserId,
                  mensagem: message.body,
                };
                axios.post(`${API_URL}/chat/mensagem`, data, getAxiosConfig());
              }
            });

            const newConversation = {
              id: conversationId,
              usuario: user.name,
              userId: user.id,
              photoUrl: user.photoUrl,
            };
            this.setState({ conversations: [...conversations, newConversation], pop_up: false });
          });
        });
    }

    togglePopUp() {
      const { pop_up } = this.state;
      this.setState({
        pop_up: !pop_up,
        conversationId: '',
        showButton: false,
      });
    }

    renderChatBox(id, otherUserId) {
      const { conversationId } = this.state;
      if (id !== conversationId) {
        this.setState({ disableButtons: true, conversationId: id, otherUserId });
        Talk.ready
          .then(() => {
            const conversation = this.talkSession.getOrCreateConversation(id);
            const inbox = this.talkSession.createChatbox(conversation);
            this.setState({ inbox });
            inbox.mount(this.container)
              .then(() => {
                this.setState({ disableButtons: false, showButton: true });
              })
              .catch(() => {
                this.setState({ disableButtons: false });
              });
          })
          .catch(() => {
            this.setState({ disableButtons: false });
          });
      }
    }

    render() {
        const {
          conversations,
          disableButtons,
          pop_up,
          unreadIds,
          conversationId,
          showButton,
        } = this.state;
        return (
          <div className="chat-conversation-pop-up">
              <div style={{ width: 0, height: 0 }} ref={(c) => { this.container = c; }} />
              {
                showButton && (
                  <button className="show-button" type="button" onClick={() => this.handleChatClose()}><i className="fas fa-times" /></button>
                )
              }
              {
                pop_up === true ? (
                  <span>
                    <div className={`chat-conversation-users ${showButton && 'hide'}`}>
                        <div className="chat-conversation-users-header">
                          <span>
                            <i className="fas fa-comment-alt" />
                            <p>Conversas</p>
                          </span>
                          <button type="button" onClick={this.togglePopUp} className="inbox-button">
                            <i className="fas fa-times" />
                          </button>
                        </div>
                        <div className="chat-conversation-users-body">
                          {
                            conversations.map(conversation => (
                              <React.Fragment key={conversation.id}>
                                <button type="button" className={conversationId === conversation.id ? 'active' : null} disabled={disableButtons} onClick={() => this.renderChatBox(conversation.id, conversation.userId)}>
                                  {
                                    unreadIds.includes(conversation.id) && (
                                      <span className="chat-unread" />)
                                  }
                                  {
                                    conversation.photoUrl
                                      ? <img className="user-chat-pic" src={conversation.photoUrl} alt={conversation.usuario} />
                                      : <i className="fas fa-user" style={{ fontSize: 20 }} />
                                  }
                                  <strong className="user-chat-details">{conversation.usuario}</strong>
                                  {
                                    unreadIds.includes(conversation.id) && (
                                      <i className="fas fa-exclamation exclamation-chat" />)
                                  }
                                </button>
                                <center>
                                  <hr className="chat-line" />
                                </center>
                              </React.Fragment>
                            ))
                          }
                        </div>
                    </div>
                    <div className="chat-conversation-main" ref={(c) => { this.container = c; }} />
                  </span>
                ) : (
                  <button type="button" className="chat-popup-button" onClick={this.togglePopUp}>
                    <i className="far fa-comment mr-2" />
                    <span>CHAT</span>
                    {
                      unreadIds.length !== 0 && (
                        <p className="chat-popup-counter">{unreadIds.length < 10 ? unreadIds.length : '9+'}</p>)
                    }
                  </button>
                )
              }
          </div>
        );
    }
}

ChatInbox.propTypes = {
  current_user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    email: PropTypes.string,
    configuration: PropTypes.string,
    photoUrl: PropTypes.string,
  }).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    email: PropTypes.string,
    configuration: PropTypes.string,
    photoUrl: PropTypes.string,
  })),
  signature: PropTypes.string.isRequired,
};

ChatInbox.defaultProps = {
  contacts: [],
};

export default ChatInbox;
