import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { API_URL } from '../../../../consts';
import { ComponentBase } from '../../../../base';

class PadraoOuroGeral extends ComponentBase {
  get columns() {
    return (
      [
        {
          header: '#',
          accessor: 'row',
          width: 50,
          Cell: row => <div>{row.index + 1}</div>,
          footer: {
            mediaNacional: {
              accessor: '',
              default: '-',
            },
          },
        },
        {
          header: 'Polo',
          withSortIcon: true,
          accessor: 'polo_descricao',
          next: {
            pathname: '/relatorios/v2/padrao-ouro-time',
            stateFields: ['polo'],
          },
          Cell: d => (<div className="column-left">{d.value}</div>),
          minWidth: 200,
          footer: {
            mediaNacional: {
              accessor: '',
              default: 'Padrão Ouro Nacional',
            },
          },
        },
        {
          header: 'Padrão ouro corrigida',
          accessor: 'nr_padrao_ouro',
          withSortIcon: true,
          minWidth: 120,
          defaultValue: 0,
          footer: {
            mediaNacional: {
              accessor: 'nr_padrao_ouro',
              default: '-',
            },
          },
        },
        {
          header: 'Discrepância padrão ouro (%)',
          accessor: 'media_discrepancia_padrao_ouro',
          withSortIcon: true,
          sortMethod: true,
          minWidth: 120,
          defaultValue: '0,00%',
          footer: {
            mediaNacional: {
              accessor: 'media_discrepancia_padrao_ouro',
              default: '-',
            },
          },
        },
      ]
    );
  }

  render() {
    const exportData = {
      url: `${API_URL}/v2/relatorios/padrao-ouro/geral/excel`,
      filename: 'padrao_ouro_geral.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };
    return (
      <Relatorio
        showAvaliadorInput={false}
        showTimeInput={false}
        title="PADRÃO-OURO | Polos"
        dataUrl="v2/relatorios/padrao-ouro/geral"
        mediaNacionalUrl="v2/relatorios/padrao-ouro/geral/media"
        columns={this.columns}
        exportData={exportData}
        codigoMensagem="PADRAO_OURO_POLOS"
        codigo="PADRAO_OURO"
        caption={{}}
      />
    );
  }
}

export default PadraoOuroGeral;
