import PropTypes from 'prop-types';
import React from 'react';
import BancoRedacoesContext from '../../../contexts/BancoRedacoesContext';
import Loading from '../../Loading';
import ImagemRedacaoExterna from './ImagemRedacaoExterna';

const VistaProvaExterna = ({
    verDadosCandidato, redacao,
    fetchImagemError, fetching, historicoCorrecoes, fetchingHistoricoCorrecoes, desvios,
    verNomeAvaliadorHistorico, downloadImagemOriginal, shapes, showLegend,
}) => (
  <BancoRedacoesContext.Provider value={{
    verDadosCandidato,
    redacao,
    fetchImagemError,
    fetching,
    historicoCorrecoes,
    fetchingHistoricoCorrecoes,
    verNomeAvaliadorHistorico,
    downloadImagemOriginal,
    showLegend,
    desvios,
  }}
  >
    {redacao ? <ImagemRedacaoExterna shapes={shapes} desvios={desvios} /> : <Loading />}
  </BancoRedacoesContext.Provider>
);

VistaProvaExterna.propTypes = {
  verDadosCandidato: PropTypes.bool.isRequired,
  redacao: PropTypes.shape({
    id: PropTypes.number,
    srcImagemOriginal: PropTypes.string,
    participante: PropTypes.string,
    cpf: PropTypes.string,
    inscricao: PropTypes.string,
  }),
  historicoCorrecoes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    tipo: PropTypes.string,
    avaliador: PropTypes.string,
  })).isRequired,
  fetchImagemError: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  fetchingHistoricoCorrecoes: PropTypes.bool.isRequired,
  verNomeAvaliadorHistorico: PropTypes.bool.isRequired,
  downloadImagemOriginal: PropTypes.bool.isRequired,
  showLegend: PropTypes.bool,
  desvios: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    indicador: PropTypes.number,
    cor: PropTypes.string,
    espessura: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    comprimento: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tooltip: PropTypes.string,
    descricao: PropTypes.string,
    valor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  shapes: PropTypes.shape({
    id: PropTypes.number,
  }),
};

VistaProvaExterna.defaultProps = {
  redacao: undefined,
  desvios: [],
  shapes: {},
  showLegend: false,
};

export default VistaProvaExterna;
