import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { size, get } from 'lodash';
import 'react-table/react-table.css';
import Select from 'react-select';
import axios from 'axios';
import Footer from '../components/Footer';
import OcorrenciaBase from './OcorrenciaBase';
import { TabelaOcorrencia } from '../containers';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services/auth';

class ListaOcorrencia extends OcorrenciaBase {
  componentDidMount() {
    if (localStorage.getItem('labelTabs')) {
      this.setState({ labelTabs: JSON.parse(localStorage.getItem('labelTabs')) });
    }
    if (localStorage.getItem('selectValue')) {
      const tabLabel = JSON.parse(localStorage.getItem('selectValue'))[0].tipo_usuario.substring(0, 11) === 'Coordenação' ? 'Coord.Polo' : 'Supervisor';
      this.setState({
        tabLabel,
        value_select: JSON.parse(localStorage.getItem('selectValue')),
        supervisor: JSON.parse(localStorage.getItem('selectValue')),
        filtroCoordenador: { supervisor: JSON.parse(localStorage.getItem('selectValue'))[0].value },
      });
    }
    if (size(this.props, 'permissions')) {
      this.setActiveTab();
    }
    this.fetchHierarquias();
    this.showCoordenador();
  }

  componentDidUpdate(prevProps) {
    const { search } = this.props.location;
    const PrevSearch = prevProps.location.search;
    if (search !== PrevSearch) {
      this.verificaTabAtivaLocalStorage();
    }
    const prevPermissions = prevProps.permissions;
    const { permissions } = this.props;
    if (prevPermissions !== permissions) {
      this.setActiveTab();
    }
    // this.verificaParams();
  }

  componentWillUnmount() {
    localStorage.removeItem('labelTabs');
  }

  verificaTabAtivaLocalStorage = () => {
    if (window.location.search !== '') {
      localStorage.setItem('storageTabActive', window.location.search);
    } else {
      localStorage.setItem('storageTabActive', window.location.pathname);
    }
  }

  verificaParams() {
    const {
      location: { state },
    } = this.props;
    if (state) {
      this.setState({
        supervisor: get(state, 'supervisor'),
        showHasSupervisor: get(state, 'showHasSupervisor'),
      });
    }
  }

  fetchHierarquias = () => {
    axios.get(`${API_URL}/hierarquias`, getAxiosConfig())
      .then((response) => {
        const hierarquias = response.data.map(hierarquia => ({
          value: hierarquia.usuario_responsavel, label: hierarquia.descricao, tipo_usuario: hierarquia.tipo_usuario,
        }));
        hierarquias[hierarquias.length - 1].value === undefined && hierarquias.splice(-1, 1);
        const coordenador = hierarquias[0];
        const { abertas_por_mim } = response.data[response.data.length - 1];
        this.setState({
          coordenador,
          hierarquias,
          abertas_por_mim: abertas_por_mim,
          loadingSelect: false,
        });
      });
  }

  handleSelectChange(value) {
    const { coordenador } = this.state;
    const supervisor = [value];
    localStorage.setItem('selectValue', JSON.stringify(supervisor));
    this.setState({
      supervisor,
      filtroCoordenador: { supervisor: value.value },
      filtro_atual: value.label,
    });
    const labelTabs = get(supervisor[0], 'label') !== get(coordenador, 'label');
    const tabLabel = value.tipo_usuario.substring(0, 11) === 'Coordenação' ? 'Coord.Polo' : 'Supervisor';
    localStorage.setItem('labelTabs', labelTabs);
    this.setState({
      tabLabel: tabLabel,
      labelTabs,
    });
    this.renderTableOcorrencia();
  }

  renderTableOcorrencia() {
    const { location: { search } } = this.props;
    const {
      filtroCoordenador,
      showHasSupervisor,
    } = this.state;
    const activeTab = search === '?pending=true' ? 2 : 1;
    const showAbertasPorMim = this.hasPermission('exibir_ocorrencias_abertas_por_mim');
    const showDestinadasAMim = this.hasPermission('exibir_ocorrencias_destinadas_a_mim');
    const ver_tipo_correcao = this.hasPermission('ver_tipo_correcao');
    const ver_encaminhado_em = this.hasPermission('show_forward_date');
    const ver_column_autor_ocorrencia = this.hasPermission('ver_coluna_autor_lista_ocorrencias');
    return (
      <Fragment>
        <div className="mt-3">
          {showAbertasPorMim
            && activeTab === 1
            && (
            <TabelaOcorrencia
              url="/tickets"
              user={get(this.props, 'user', {})}
              ver_tipo_correcao={ver_tipo_correcao}
              ver_column_autor={ver_column_autor_ocorrencia}
              filtroCoordenador={filtroCoordenador}
              ver_encaminhado_em={ver_encaminhado_em}
            />
          )}
          {showHasSupervisor
            && activeTab === 1
            && (
            <TabelaOcorrencia
              url="/tickets"
              user={get(this.props, 'user', {})}
              ver_tipo_correcao={ver_tipo_correcao}
              ver_column_autor={ver_column_autor_ocorrencia}
              filtroCoordenador={filtroCoordenador}
              ver_encaminhado_em={ver_encaminhado_em}
            />
          )}
          {showDestinadasAMim && activeTab === 2
            && (
            <TabelaOcorrencia
              url="/tickets?pending=true"
              user={get(this.props, 'user', {})}
              ver_tipo_correcao={ver_tipo_correcao}
              ver_column_autor={ver_column_autor_ocorrencia}
              filtroCoordenador={filtroCoordenador}
              ver_encaminhado_em={ver_encaminhado_em}
            />
            )}
          {!showAbertasPorMim
            && !showDestinadasAMim && (
            <p className="text-danger">A visualização de ocorrências está desabilitada.</p>
          )}
        </div>
      </Fragment>
    );
  }

  setActiveTab() {
    const showAbertasPorMim = this.hasPermission('exibir_ocorrencias_abertas_por_mim');
    const showDestinadasAMim = this.hasPermission('exibir_ocorrencias_destinadas_a_mim');
    const { history } = this.props;
    const storageTabActive = localStorage.getItem('storageTabActive');
    const activeTab = storageTabActive === '?history=true' ? 'historyTab' : 'pendingTab';

    if (showDestinadasAMim && activeTab === 'pendingTab') {
      history.push('/tickets?pending=true');
    } else if (showAbertasPorMim) {
      history.push('/tickets?history=true');
    }
  }

  render() {
    const { location: { search } } = this.props;
    const {
      loadingSelect,
      hierarquias,
      supervisor,
      coordenador,
      labelTabs,
    } = this.state;
    const showHasSupervisor = this.hasPermission('ver_ocorrencia_como_supervisor');
    const activeTab = search === '?pending=true' ? 2 : 1;
    const showAbertasPorMim = this.hasPermission('exibir_ocorrencias_abertas_por_mim');
    const showDestinadasAMim = this.hasPermission('exibir_ocorrencias_destinadas_a_mim');
    const showTabs = showAbertasPorMim && showDestinadasAMim;
    const value_select = JSON.parse(localStorage.getItem('selectValue')) ? JSON.parse(localStorage.getItem('selectValue')) : supervisor.length === 0 ? coordenador : supervisor;
    return (
      <Fragment>
        <section className="lista-ocorrencias-section">
          <div className="container-fluid">
            <div className="page-container-border">
              <section>
                <div className="row">
                  <div className="col-md-12 col-xs-12 col-sm-12">
                    <h1 className="text-title no-border">
                    {showHasSupervisor && labelTabs ? `Ocorrências do ${get(supervisor[0], 'label')}` : 'Minhas Ocorrências'}
                    </h1>
                  </div>
                </div>
                {showTabs || showHasSupervisor ? (
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                        to="/tickets?pending=true"
                      >
                        Pendentes
                      </Link>
                    </li>
                    <li className="nav-item ml-4">
                      <Link
                        className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                        to="/tickets?history=true"
                      >
                        Histórico
                      </Link>
                    </li>
                    {showHasSupervisor && (
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4">
                        <Select
                          placeholder="Selecione o time..."
                          isLoading={loadingSelect}
                          isSearchable
                          onChange={value => this.handleSelectChange(value)}
                          options={hierarquias}
                          value={value_select}
                        />
                      </div>
                    )}
                  </ul>
                ) : <hr />}
                {this.renderTableOcorrencia()}
              </section>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default ListaOcorrencia;
