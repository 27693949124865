import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Rodal from 'rodal';
import { get } from 'lodash';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Footer from '../../Footer';
import RelatorioForm from './RelatorioForm';
import { API_URL } from '../../../consts';
import { getAxiosConfig } from '../../../services';
import RelatorioTable from './RelatorioTable';
import { getHeaderWithSortIcon, commaSeparatedDecimalSortMethod } from '../../../utils/relatorios';
import { FooterTable } from '../..';
import ExportExcelButton from '../../ExportExcelButton';
import Redacao from '../../../containers/Redacao';
import RelatorioModal from './RelatorioModal';
import { fetchAvaliadores } from '../../../services/hierarquia';


class Relatorio extends React.Component {
  state = {
    fetchingData: false,
    fetchingMediaNacional: false,
    fetchingMediaTime: false,
    enableExport: false,
    fetchImagemError: false,
    loadingRedacao: {},
    src: '',
    showModalInfo: false,
    data: [],
    mediaNacional: {},
    mediaTime: {},
    tolerancias: {},
    params: {},
    isOpen: false,
    ultimaAtualizacao: null,
    mensagens: [],
  }

  componentDidMount = () => {
    const { hideForm } = this.props;
    if (hideForm) {
      this.updateRelatorio({});
    }
    this.fetchControleRelatorio();
    this.fetchTolerancia();
    this.fetchMensagens()
  }

  fetchRedacao = async (redacao_id) => {
    const config = {
      ...getAxiosConfig(),
      params: {
        mascara_redacao: redacao_id,
      },
    };
    this.setState(prevState => ({
      loadingRedacao: { ...prevState.loadingRedacao, [redacao_id]: true },
    }));
    const res = await axios.get(`${API_URL}/v2/banco-redacoes`, config);
    const src = get(res, 'data.src');
    if (!src) {
      toast.error('Ocorreu um erro ao carregar a imagem.');
    }
    this.setState(prevState => ({
      src,
      isOpen: src !== null,
      fetchImagemError: src === null,
      loadingRedacao: { ...prevState.loadingRedacao, [redacao_id]: false },
    }));
  }

  fetchMensagens() {
    axios.get(`${API_URL}/v2/relatorios/mensagem/`, getAxiosConfig())
      .then(res => {
          const mensagens = get(res, 'data', []).reduce((acc, item) => (
            { ...acc, [item.codigo]: item.conteudo }
          ), {});
          this.setState({ mensagens });
      });
  }

  getData = (reports) => {
    const { baseAvaliadores } = this.props;
    const { avaliadores } = this.state;

    if (baseAvaliadores) {
      const reportObj = reports.reduce((acc, report) => {
        return { ...acc, [report.usuario_id]: { ...report } };
      }, {})
      return avaliadores.map(avaliador => ({
        ...avaliador,
        avaliador: avaliador.last_name,
        usuario_id: avaliador.id,
        ...reportObj[avaliador.id]
      }))
    }

    return reports
  }

  fetchData = async (params) => {
    const { dataUrl, fetchCorretoresInativos } = this.props;
    this.setState({ fetchingData: true });
    this.fetchBaseData(params)
    try {
      const response = await axios.get(`${API_URL}/${dataUrl}`, { params, ...getAxiosConfig() });
      this.setState({
        data: this.getData(response.data),
        fetchingData: false,
      });

      if (fetchCorretoresInativos) {
        this.fetchStatus(params);
      }
    } catch (e) {
      this.setState({ fetchingData: false });
    }
  }

  fetchControleRelatorio = () => {
    const { codigo } = this.props;
    axios.get(`${API_URL}/v2/relatorios/controle-relatorio/${codigo}`, getAxiosConfig())
      .then(response => this.setState({ ultimaAtualizacao: response.data.atualizado_em }))
  }

  mapResponse = (data, params) => {
    const { columns } = this.props;
    const emptyData = columns.reduce((acc, item) => {
      if (item.columns) {
        item.columns.forEach(column => {
          acc[column.accessor] = null
        })
      }
      return { ...acc, [item.accessor]: null };
    }, {})
    return {
      ...data,
      ...emptyData,
      ...params,
    }
  }

  fetchBaseData = (params) => {
    const { baseAvaliadores } = this.props;
    if (baseAvaliadores) {
      fetchAvaliadores(params.time_id, params).then(response => {
        const avaliadores = response.map(user => this.mapResponse(user, params))
        this.setState({ avaliadores })
      })
    }
  }

  fetchStatus = async (params) => {
    const { fetchCorretoresInativos } = this.props;
    const res = await axios.get(`${API_URL}/${fetchCorretoresInativos}`, { params, ...getAxiosConfig() });
    this.setState(prevState => ({
      data: prevState.data.map(d => ({
        ...d,
        status: res.data.find(status => status.id === d.usuario_id),
      })),
      fetchingData: false,
    }));
  }

  fetchMediaNacional = (params) => {
    const { mediaNacionalUrl } = this.props;

    if (mediaNacionalUrl) {
      this.setState({ fetchingMediaNacional: true });
      axios.get(`${API_URL}/${mediaNacionalUrl}`, {
          params: {
            data_inicio: params.data_inicio,
            data_final: params.data_final,
            etapa_ensino_id: params.etapa_ensino_id,
          },
          ...getAxiosConfig(),
        }).then(response => this.setState({
        mediaNacional: response.data,
        fetchingMediaNacional: false,
      }));
    }
  }

  fetchMediaTime = (params) => {
    const { mediaTimeUrl } = this.props;

    if (mediaTimeUrl && params.time_id) {
      this.setState({ fetchingMediaTime: true });
      axios.get(`${API_URL}/${mediaTimeUrl}`, {
        params: {
          data_inicio: params.data_inicio,
          data_final: params.data_final,
          time_id: params.time_id,
        },
        ...getAxiosConfig(),
      }).then(response => this.setState({
        mediaTime: response.data,
        fetchingMediaTime: false,
    }));
    }
  }

  fetchMediaPolo = (params) => {
    const { mediaPoloUrl } = this.props;
    if (mediaPoloUrl) {
      this.setState({ fetchingMediaTime: true });
      axios.get(`${API_URL}/${mediaPoloUrl}`, {
        params: {
          data_inicio: params.data_inicio,
          data_final: params.data_final,
          polo_id: params.polo_id,
        },
        ...getAxiosConfig(),
      }).then(response => this.setState({
        mediaTime: response.data,
        fetchingMediaTime: false,
    }));
    }
  }

  fetchTolerancia = () => {
    const { codigo } = this.props;

    if (codigo) {
      axios.get(`${API_URL}/v2/relatorios/tolerancia/${codigo}`, getAxiosConfig()).then(
        response => this.setState({ tolerancias: response.data }),
      );
    }
  }

  updateRelatorio = (params) => {
    this.setState({ params, enableExport: true });
    this.fetchData(params);
    this.fetchMediaNacional(params);
    this.fetchMediaTime(params);
    this.fetchMediaPolo(params);
  }

  onNextClick = (row, next) => {
    const { history } = this.props;
    const state = {};

    next.stateFields.forEach((stateField) => {
      state[stateField] = {
        label: row.original[`${stateField}_descricao`],
        value: row.original[`${stateField}_id`],
      };
    });
    history.push({
      pathname: next.pathname,
      state,
    });
  }

  getNextButton = (row, column) => (
    <button
      type="button"
      className="redacao-hyperlink column-left"
      onClick={() => this.onNextClick(row, column.next)}
    >
      {column.Cell ? column.Cell(row) : get(row.original, column.accessor)}
    </button>
  )


  formatAlert = (value, tolerancia, media) => {
    const formatData = data => +String(data).replace(',', '.').replace('%', '');

    const diffBruto = Math.abs(formatData(value) - formatData(media));
    const diffPercentual = diffBruto / formatData(media) * 100;
    if (diffPercentual > formatData(tolerancia)) {
      return 'alerta-box';
    }
    return null;
  }


  formatAlertBruto = (value, tolerancia) => {
    const formatData = data => +String(data).replace(',', '.').replace('%', '');

    const valorBruto = formatData(value);
    if (formatData(tolerancia) > valorBruto) {
      return 'alerta-box';
    }
    return null;
  }


  formatCell = (row, column) => {
    const { mediaNacional, tolerancias, loadingRedacao } = this.state;
    const media_nacional_accessor = get(column, 'footer.mediaNacional.accessor');

    const defaultValue = get(column, 'defaultValue', null);
    const value = get(row.original, column.accessor, defaultValue);
    const tolerancia = get(tolerancias, column.accessor);
    const media = get(mediaNacional, media_nacional_accessor);

    if (column.hasRedacao) {
      return (
        <button
          type="button"
          className="btn btn-link"
          onClick={() => this.fetchRedacao(get(row, 'original.redacao'))}
          disabled={loadingRedacao[row.original.redacao]}
        >
          {
            loadingRedacao[row.original.redacao]
            ? <i className="fas fa-spinner fa-pulse" />
            : row.original.redacao
          }
        </button>
      );
    }
    if (tolerancia) {
      if (column.alertaNumeroBruto) {
        const className = this.formatAlertBruto(value, tolerancia);
        return <div className={className}>{value}</div>;
      }
      const className = this.formatAlert(value, tolerancia, media);
      return <div className={className}>{value}</div>;
    }
    if (column.next) {
      return this.getNextButton(row, column);
    }
    return column.Cell ? column.Cell(row) : value;
  }


  mapColumns = (columns) => {
    const { mediaNacional, mediaTime } = this.state;
    return columns.map((column) => {
      const columnData = {
        ...column,
        Header: column.withSortIcon ? getHeaderWithSortIcon(
          column.header, column.accessor,
        ) : column.header,
        columns: column.columns ? this.mapColumns(column.columns) : undefined,
        accessor: column.accessor,
        show: column.show === undefined || column.show,
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        Cell: row => this.formatCell(row, column),
        sortMethod: column.sortMethod && commaSeparatedDecimalSortMethod,
        Footer: column.footer ? (
          <FooterTable
            mediaNal={get(mediaNacional, get(column, 'footer.mediaNacional.accessor'), get(column, 'footer.mediaNacional.default'))}
            media={get(mediaTime, get(column, 'footer.mediaTime.accessor'), get(column, 'footer.mediaTime.default'))}
          />
        ) : undefined,
      };
      Object.keys(columnData).forEach((key) => {
        if (!columnData[key] && key !== 'show') {
          delete columnData[key];
        }
      });
      return columnData;
    });
  }

  renderRedacao() {
    const {
      src,
      fetchImagemError,
    } = this.state;
    if (fetchImagemError) {
      return null;
    }

    if (!src) {
      return null;
    }

    return (
      <div style={{ margin: '40px' }}>
        <Redacao
          ref={(node) => {
            this.redacao = node;
          }}
          scrollTools
          src={src}
          show
          fetchImagemError={fetchImagemError}
        />
      </div>
    );
  }

  render() {
    const {
      title,
      columns,
      showPoloInput,
      showAvaliadorInput,
      showTimeInput,
      isPoloInputRequired,
      isTimeInputRequired,
      exportData,
      hideForm,
      defaultPageSize,
      showPageSizeOptions,
      showPagination,
      customForm,
      codigoMensagem,
    } = this.props;
    const {
      data, fetchingData, fetchingMediaNacional, fetchingMediaTime, params, enableExport,
      isOpen, src, showModalInfo, ultimaAtualizacao, mensagens,
    } = this.state;

    const reportInformation = codigoMensagem && mensagens[codigoMensagem];

    return (
      <>
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div className="page-container-border position-relative">
            <section className="mt-3">
              <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                  <h1 className="text-title">
                    {title}
                  </h1>
                  <div className="pull-right">
                    {exportData.hasPermission && (
                      <ExportExcelButton
                        url={exportData.url}
                        filename={exportData.filename}
                        params={params}
                        disabled={!enableExport}
                      />
                    )}
                    {
                      reportInformation && (
                        <button
                          type="button"
                          className="btn btn-link d-flex align-items-center"
                          onClick={() => this.setState({ showModalInfo: true })}
                        >
                          <i className="far fa-question-circle" />
                        </button>
                      )
                    }
                  </div>
                </div>
              </div>
              {customForm(this.updateRelatorio)}
              {
                hideForm || (
                  <RelatorioForm
                    fetchingRelatorio={fetchingData && fetchingMediaNacional && fetchingMediaTime}
                    onApplyFilters={this.updateRelatorio}
                    showPoloInput={showPoloInput}
                    showAvaliadorInput={showAvaliadorInput}
                    showTimeInput={showTimeInput}
                    isTimeInputRequired={isTimeInputRequired}
                    isPoloInputRequired={isPoloInputRequired}
                    {...this.props}
                  />
                )
              }
              <RelatorioTable
                data={data}
                columns={this.mapColumns(columns)}
                loading={fetchingData}
                defaultPageSize={defaultPageSize}
                showPageSizeOptions={showPageSizeOptions}
                showPagination={showPagination}
              />
              <RelatorioModal
                isModalOpen={showModalInfo}
                onModalClose={() => this.setState({ showModalInfo: false })}
                data={reportInformation}
              />
              {
                ultimaAtualizacao && (
                  <div className="d-flex justify-content-end mt-2">
                    <small>Última atualização: {ultimaAtualizacao}</small>
                  </div>
                )
              }
            </section>
          </div>
          <Rodal
            visible={isOpen}
            onClose={() => this.setState({ isOpen: false, src: '' })}
            closeOnEsc
            className="responsabilidade-modal non-fixed-rodal"
          >
            {src && this.renderRedacao()}
          </Rodal>
        </div>
        <Footer />
      </>
    );
  }
}

Relatorio.propTypes = {
  isPoloInputRequired: PropTypes.bool,
  isTimeInputRequired: PropTypes.bool,
  title: PropTypes.string.isRequired,
  dataUrl: PropTypes.string.isRequired,
  mediaNacionalUrl: PropTypes.string,
  mediaTimeUrl: PropTypes.string,
  mediaPoloUrl: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string.isRequired,
    withSortIcon: PropTypes.bool,
    accessor: PropTypes.string,
    show: PropTypes.bool,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    Cell: PropTypes.func,
  })).isRequired,
  showAvaliadorInput: PropTypes.bool,
  showTimeInput: PropTypes.bool,
  showPoloInput: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  codigo: PropTypes.string,
  exportData: PropTypes.shape({
    filename: PropTypes.string,
    url: PropTypes.string,
    hasPermission: PropTypes.bool,
  }),
  fetchCorretoresInativos: PropTypes.string,
  hideForm: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  showPageSizeOptions: PropTypes.bool,
  showPagination: PropTypes.bool,
  customForm: PropTypes.func,
  codigoMensagem: PropTypes.string,
  baseAvaliadores: PropTypes.bool,
};

Relatorio.defaultProps = {
  isPoloInputRequired: false,
  isTimeInputRequired: false,
  showPoloInput: true,
  showAvaliadorInput: true,
  showTimeInput: true,
  mediaNacionalUrl: undefined,
  mediaTimeUrl: undefined,
  mediaPoloUrl: undefined,
  codigo: undefined,
  exportData: {},
  fetchCorretoresInativos: null,
  hideForm: false,
  defaultPageSize: 20,
  showPageSizeOptions: true,
  showPagination: true,
  customForm: () => {},
  codigoMensagem: '',
  baseAvaliadores: false,
};

export default withRouter(Relatorio);
