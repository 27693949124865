import React, { useContext } from 'react';
import ImagemRedacaoTitle from './ImagemRedacaoTitle';
import BancoRedacoesContext from '../../../contexts/BancoRedacoesContext';

const ImagemRedacaoHeader = () => {
  const { onBackClick } = useContext(BancoRedacoesContext);

  return (
    <div className="redacao-header">
      <ImagemRedacaoTitle />
      <button
        id="_buscar_banco_imagem"
        type="button"
        onClick={onBackClick}
        className="btn btn-primary"
      >
          Voltar
      </button>
    </div>
  );
};

export default ImagemRedacaoHeader;
