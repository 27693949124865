import React from 'react';
import Rodal from 'rodal';
import PropTypes from 'prop-types';

class ModalGabarito extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    showFooter: PropTypes.bool,
  };

  static defaultProps = {
    title: 'Título',
    content: 'Confirmação',
    showFooter: true,
  }

  render() {
    const {
      visible,
      onClose,
      title,
      content,
      showFooter,
    } = this.props;
    return (
      <Rodal visible={visible} onClose={onClose}>
        <div className="modalConfirmacao">
          <div className="boxTitulo">
            <h1 className="tituloConfirmacao">{title}</h1>
          </div>
          <article className="conteudoConfirmacao">
            {content}
          </article>
          {showFooter && (
            <div>
              <div className="botoesConfirmacao">
                <button type="button" className="btn btn-secondary" onClick={onClose}>Fechar</button>
              </div>
            </div>
          )}
        </div>
      </Rodal>
    );
  }
}
export default ModalGabarito;
