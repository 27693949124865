import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import Rodal from 'rodal';
import { API_URL, BASE_URL } from '../consts';
import { getAxiosConfig } from '../services';

const CONTADOR_REDIRECIONAMENTO_QUARTA_MODAL = 5;
const CONTADOR_REDIRECIONAMENTO_INICIO_MODAL = 7;
const INTERVALO_VERIFICACAO_QUARTA = 5;
const MensagemInicioBanca = () => (
  <>
    <p>Uma quarta correção foi iniciada.</p>
    <p>Você será redirecionado em alguns instantes.</p>
  </>
);
const MensagemAbandonoBanca = () => (
  <>
    <p>A quarta correção foi encerrada pelo supervisor ou não há mais redações disponíveis para correção.</p>
    <p>Você será redirecionado para a página inicial em alguns instantes.</p>
  </>
);

export default ({ children, user }) => {
  const [correcao, setCorrecao] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState('inicioBanca');

  const redirecionaQuartaCorrecao = (correcaoId) => {
    const url = `${BASE_URL}/quarta-correcao-auxiliar/${correcaoId}`;
    if (window.location.pathname !== url) {
      setModal('inicioBanca');
      setShowModal(true);
      setTimeout(() => {
        window.location.href = url;
      }, CONTADOR_REDIRECIONAMENTO_QUARTA_MODAL * 1000);
    }
  };

  const voltaTelaInicial = () => {
    setModal('abandonoBanca');
    setShowModal(true);
    setTimeout(() => {
      window.location.href = `${BASE_URL}/`;
    }, CONTADOR_REDIRECIONAMENTO_INICIO_MODAL * 1000);
  };

  useEffect(() => {
    const verificaQuartaCorrecao = () => {
      const verificarQuartaCorrecao = get(user, 'permissions.redirecionar_auxiliar_4a_correcao');
      if (verificarQuartaCorrecao) {
        axios.get(`${API_URL}/correcoes/quarta-correcao-auxiliar`, getAxiosConfig())
          .then((response) => {
            const novaCorrecao = response.data.correcao;
            const visualizandoCorrecao = window.location.pathname.startsWith(
              `${BASE_URL}/quarta-correcao-auxiliar`,
            );
            if (novaCorrecao === null && visualizandoCorrecao) {
              voltaTelaInicial();
            } else if (novaCorrecao) {
              redirecionaQuartaCorrecao(novaCorrecao);
            }
            setCorrecao(novaCorrecao);
          });
      }
    };
    const timer = setInterval(verificaQuartaCorrecao, INTERVALO_VERIFICACAO_QUARTA * 1000);
    return () => clearInterval(timer);
  }, [user, correcao]);

  return (
    <>
      <Rodal visible={showModal} onClose={() => null}>
        {modal === 'inicioBanca' ? <MensagemInicioBanca /> : <MensagemAbandonoBanca />}
      </Rodal>
      {children}
    </>
  );
};
