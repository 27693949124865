export const SET_PROVA_LOADING = 'SET_PROVA_LOADING';
export const SET_PROVA_DATA = 'SET_PROVA_DATA';
export const SET_RECURSOS_LOADING = 'SET_RECURSOS_LOADING';
export const SET_RECURSOS_DATA = 'SET_RECURSOS_DATA';
export const SET_SUBMIT = 'SET_SUBMIT';
export const SET_UPDATED_RECURSO_DATA = 'SET_UPDATED_RECURSO_DATA';
export const SET_FORM_ERRORS = 'SET_FORM_ERRORS';
export const SET_FORM_API = 'SET_FORM_API';
export const SET_FORM_STATE = 'SET_FORM_STATE';
export const SET_TIPOS_DEFERIMENTO = 'SET_TIPOS_DEFERIMENTO';
