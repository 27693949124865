import PropTypes from 'prop-types';
import React from 'react';
import { TextInput } from '../..';

const InputText = ({ label, className, error, ...props }) => (
  <div className={className}>
    {label && <label className="mt-2 mr-4 label-min-width">{label}</label>}
    <div className="w-100">
      <TextInput {...props} style={error ? { border: 'solid 1px red' } : null} />
      {error ? <small style={{ color: 'red' }}>{error}</small> : null}
    </div>
  </div>
);

InputText.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
};

InputText.defaultProps = {
  label: null,
  className: '',
  error: '',
};

export default InputText;
