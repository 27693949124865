import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({ color }) => (
  <div className="loading-style text-center mt-5 pt-5">
    <i style={{ color }} className="fas fa-circle-notch fa-spin" />
  </div>
);

Loading.propTypes = {
  color: PropTypes.string,
};

Loading.defaultProps = {
  color: '#033F7B',
};

export default Loading;
