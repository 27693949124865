import React from 'react';
import { detect } from 'detect-browser';
import imgLogo from '../assets/images/logo/logo.png';
import '../assets/css/styles.css';
import imgChrome from '../assets/images/browsers/chrome.png';
import imgFirefox from '../assets/images/browsers/firefox.png';
import imgSafari from '../assets/images/browsers/safari.png';

class NavegadorIncompativel extends React.Component {
    render() {
        const browser = detect();
        const platform = browser.os;
        return (
            <section>
                <div className="container">
                    <div className="navegador-incompativel text-center">
                        <figure className="logo mb-0 text-center">
                            <img src={imgLogo} alt="img-logo" className="img-fluid" />
                        </figure>
                        <div className="exclamacao text-center">
                            <i className="fas fa-exclamation-circle" />
                        </div>
                        <div className="incompativel-texto  text-center">
                            <p>O navegador é incompatível com o sistema. </p><br />
                            <p>Por favor, instale um dos navegadores recomendados abaixo para acessar o sistema corretamente.</p><br /><br />
                        </div>
                        <div className="container">
                            <div className="navegadores-compativeis text-center">
                                <div className="col">
                                    <div className="navegador">
                                        <figure className="logo mb-0">
                                            <img src={imgChrome} alt="Chrome" className="img-fluid" />
                                        </figure>
                                        <figcaption className="legenda">Chrome</figcaption>
                                        <figcaption>
                                            <span style={{ fontSize: 10, fontWeight: 700 }}>Versão 65.0 ou superior</span>
                                            <a rel="external" href="https://www.google.com/intl/pt-BR_ALL/chrome/">
                                                <i className="fas fa-download" />
                                                Download
                                            </a>
                                        </figcaption>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="navegador text-center">
                                        <figure className="logo mb-0">
                                            <img src={imgFirefox} alt="Firefox" className="img-fluid" />
                                        </figure>
                                        <figcaption className="legenda">Firefox</figcaption>
                                        <figcaption>
                                            <span style={{ fontSize: 10, fontWeight: 700 }}>Versão 59.0 ou superior</span>
                                            <a rel="external" href="https://www.mozilla.org/pt-BR/firefox/new/">
                                                <i className="fas fa-download" />
                                                Download
                                            </a>
                                        </figcaption>
                                    </div>
                                </div>
                                {
                                    platform === 'iOS' || 'Mac Os' ? (
                                    <div className="col">
                                        <div className="navegador">
                                            <figure className="logo mb-0">
                                                <img src={imgSafari} alt="Safari" className="img-fluid" />
                                            </figure>
                                            <figcaption className="legenda">Safari</figcaption>
                                            <figcaption>
                                                <span style={{ fontSize: 10, fontWeight: 700 }}>Versão 11.0 ou superior</span>
                                                <a rel="external" href="https://safari.br.uptodown.com/windows">
                                                    <i className="fas fa-download" />
                                                    Download
                                                </a>
                                            </figcaption>
                                        </div>
                                    </div>
                                    ) : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default NavegadorIncompativel;
