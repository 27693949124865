import React from 'react';
import ImportRuleset from '../../components/v2/ImportRuleset';
import ImportsPage from './ImportsPage';

class PreTesteEnemOuroImportPage extends React.Component {
  render() {
    return (
      <ImportsPage
        endpoint="/user_import/enem_pre_teste/ouro"
        title="Importar redações ouro (ENEM PRÉ-TESTE)"
        ruleset={(
          <ImportRuleset sheets={['provas']}>
            {({ activeSheet }) => (
              <>
                {activeSheet === 'provas' && <span />}
              </>
            )}
          </ImportRuleset>
        )}
      />
    )
  }
}

export default PreTesteEnemOuroImportPage;
