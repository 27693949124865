import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classnames from 'classnames';
import Container from '../../components/v2/Container';
import { API_URL } from '../../consts';
import ImportErrors from '../../components/v2/ImportErrors';

class ImportsPage extends React.Component {
  state = {
    importFeedback: null,
    importing: false,
    files: [],
    error: null,
  }

  handleImport = () => {
    const { endpoint } = this.props;
    this.setState({ importing: true, error: null, errorMessage: null });
    const form = new FormData();
    const { files } = this.state;
    form.append('file', files[0]);
    axios.post(`${API_URL}${endpoint}`, form, {
      headers: { 'Content-Type': 'form/multipart-form-data' },
    })
      .then(() => {
        this.setState({
          importFeedback: 'Importação concluída.',
          importing: false,
        });
      })
      .catch((err) => {
        let errorMessage;
        let error;

        if (!err.response || err.response.status !== 400) {
          errorMessage = 'Ocorreu um erro inesperado. Certifique-se de que as regras acima foram seguidas.';
        } else if (err.response.data.message) {
          errorMessage = err.response.data.message;
        } else {
          error = err.response.data;
        }

        this.setState({ error, errorMessage, importing: false });
      });
  }

  handleFileChange = (e) => {
    this.setState({ files: e.target.files });
  }

  render() {
    const { ruleset, title } = this.props;
    const {
      importFeedback, importing, files, error, errorMessage,
    } = this.state;
    const importButtonIsDisabled = importing || files.length === 0;


    return (
      <Container title={title}>
        {ruleset}
        <hr />
        <input type="file" onChange={this.handleFileChange} />
        <button
          className={classnames('ml-2 btn btn-primary', { disabled: importButtonIsDisabled })}
          disabled={importButtonIsDisabled}
          onClick={this.handleImport}
          type="button"
        >
          Importar
        </button>
        {importing && <div><i className="fa fa-circle-notch fa-spin" /> Importando...</div>}
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
        {error && error.non_field_errors && (
          <ul className="text-danger">
            {error.non_field_errors.map(err => <li key={err}>{err}</li>)}
          </ul>
        )}
        {error && !error.non_field_errors && Object.keys(error).map(err => (
          <ImportErrors rows={error[err]} tabName={err} />
        ))}
        {importFeedback && <p className="text-success">{importFeedback}</p>}
      </Container>
    );
  }
}

ImportsPage.propTypes = {
  title: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  ruleset: PropTypes.node.isRequired,
};

export default ImportsPage;
