import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { API_URL } from '../../../../consts';
import { ComponentBase } from '../../../../base';

class RelatorioGeral extends ComponentBase {
  get columns() {
    const mostrarDsp = this.hasFeature('mostrar_dsp');
    const columnsBase = [
      {
        header: '#',
        accessor: 'row',
        width: 50,
        Cell: row => <div>{row.index + 1}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Polos',
        accessor: 'polo_descricao',
        withSortIcon: true,
        minWidth: 200,
        next: this.hasPermission('ver_relatorio_geral_polo') && {
          pathname: '/relatorios/v2/relatorio-geral-time',
          stateFields: ['polo'],
        },
        Cell: d => (<div className="column-left">{d.value}</div>),
        footer: {
          mediaNacional: {
            accessor: '',
            default: 'Nacional',
          },
        },
      },
      {
        header: 'Total corrigidas',
        accessor: 'nr_corrigidas',
        withSortIcon: true,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_corrigidas',
            default: '-',
          },
        },
      },
      {
        header: 'Média corrigidas (por dia)',
        accessor: 'media_diaria',
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'media_diaria',
            default: '-',
          },
        },
      },
      {
        header: 'Tempo médio (minutos)',
        accessor: 'tempo_medio',
        footer: {
          mediaNacional: {
            accessor: 'tempo_medio',
            default: '-',
          },
        },
      },
      {
        header: 'Aproveitamento de notas (%)',
        accessor: 'aproveitamento',
        footer: {
          mediaNacional: {
            accessor: 'aproveitamento',
            default: '-',
          },
        },
      },
    ];
    if (mostrarDsp) {
      columnsBase.push({
        header: 'DSP',
        accessor: 'dsp',
        width: 180,
        alertaNumeroBruto: true,
        footer: {
          mediaNacional: {
            accessor: 'dsp',
            default: '-',
          },
        },
      });
    }

    return columnsBase;
  }

  render() {
    const exportData = {
      url: `${API_URL}/v2/relatorios/relatorio-geral/geral/excel`,
      filename: 'relatorio_geral_polos.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        showAvaliadorInput={false}
        showTimeInput={false}
        title="RELATÓRIO GERAL | Polos"
        dataUrl="v2/relatorios/relatorio-geral/geral"
        mediaNacionalUrl="v2/relatorios/relatorio-geral/geral/media"
        columns={this.columns}
        exportData={exportData}
        codigo="RELATORIO_GERAL"
        codigoMensagem="RELATORIO_GERAL_POLOS"
      />
    );
  }
}

export default RelatorioGeral;
