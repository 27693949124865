import React, { useContext } from 'react';
import HistoricoCorrecaoContext from '../../../contexts/HistoricoCorrecaoContext';


const HistoricoCorrecaoHeader = () => {
  const {
    verNomeAvaliadorHistorico,
    index,
    correcao,
   } = useContext(HistoricoCorrecaoContext);

  return (
    <div className="avaliador-table-labels">
      <span className="avaliador-title-label">{`Avaliador ${index + 1} (${correcao.tipo})`}</span>
      {
        verNomeAvaliadorHistorico && (
          <span className="avaliador-name-label">{correcao.avaliador}</span>
        )
      }
    </div>
  );
};

export default HistoricoCorrecaoHeader;
