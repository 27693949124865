import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services';

const AbandonarBancaButton = ({ history, correcaoId, onModalChange }) => {
  const [abandonandoBanca, setAbandonandoBanca] = useState(false);

  const abandonarBanca = () => {
    setAbandonandoBanca(true);
    axios.post(`${API_URL}/correcoes/${correcaoId}/abandonar-banca`, {}, getAxiosConfig())
      .then(() => {
        history.push('/');
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao abandonar a 4ª correção. Por favor, tente novamente.');
        setAbandonandoBanca(false);
      });
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary bg-danger-color"
        onClick={abandonarBanca}
        disabled={abandonandoBanca}
      >
        Sair da 4ª correção
      </button>
      <button
        type="button"
        className="btn btn-none"
        onClick={() => onModalChange()}
      >
        <i className="fa fa-question-circle" />
      </button>
    </>

  );
};

AbandonarBancaButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  correcaoId: PropTypes.string.isRequired,
};

export default withRouter(AbandonarBancaButton);
