import React from 'react';
import { get } from 'lodash';

class ComponentBase extends React.Component {
  getMessage(code) {
    const messages = get(this.props, 'messages', {});
    return messages[code];
  }

  hasPermission(permission) {
    const permissions = get(this.props, 'user.permissions', {});
    return get(permissions, '__all__') === true || permissions[permission] === true;
  }

  hasFeature(feature) {
    const features = get(this.props, 'features', []);
    return features[feature] === true;
  }
}

export default ComponentBase;
