import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import EnemUserImportPage from './EnemUserImportPage';
import PreTesteEnemUserImportPage from './PreTesteEnemUserImportPage';
import PreTesteEnemOuroImportPage from './PreTesteEnemOuroImportPage';
import PreTesteEnemModaImportPage from './PreTesteEnemModaImportPage';
import IndexPage from './IndexPage';
import ImportacaoRedacaoDesempenhoPage from './redacoes_desempenho/ImportacaoRedacaoDesempenhoPage';
import RedacoesDesempenhoPage from './redacoes_desempenho/RedacoesDesempenhoPage';
import LoginAsPage from './LoginAsPage';

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/admin"
      component={IndexPage}
    />
    <PrivateRoute
      exact
      path="/user_import/enem/user"
      component={EnemUserImportPage}
    />
    <PrivateRoute
      exact
      path="/user_import/enem_pre_teste/user"
      component={PreTesteEnemUserImportPage}
    />
    <PrivateRoute
      exact
      path="/user_import/enem_pre_teste/ouro"
      component={PreTesteEnemOuroImportPage}
    />
    <PrivateRoute
      exact
      path="/user_import/enem_pre_teste/moda"
      component={PreTesteEnemModaImportPage}
    />
    <PrivateRoute
      exact
      path="/admin/redacoes-desempenho"
      permission="importar_redacoes_desempenho"
      component={RedacoesDesempenhoPage}
    />
    <PrivateRoute
      exact
      path="/admin/importar-redacao-desempenho"
      permission="importar_redacoes_desempenho"
      component={ImportacaoRedacaoDesempenhoPage}
    />
    <PrivateRoute
      exact
      path="/admin/login-as"
      permission="logar_como_qualquer_usuario"
      component={LoginAsPage}
    />
  </Switch>
);
