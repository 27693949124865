import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Rodal from 'rodal';
import { Form, Text } from 'informed';
import ReactTooltip from 'react-tooltip';
import Countdown from 'react-countdown-now';
import { get } from 'lodash';
import 'rodal/lib/rodal.css';
import { Loading } from '../..';
import SelectInput from '../inputs/SelectInput';

const AvaliadorModal = ({
  visible,
  onClose,
  user,
  fetchingUser,
  suspensoes,
  fetchingSuspensoes,
  contador,
  tempo_adicional,
  onAlterarContador,
  showContador,
  showHistoricoSuspensao,
  mudarTimeAvaliador,
  submitting,
  onChangeUserTeam,
  hierarquiasOptions,
  onAcessarComo,
  showAcessarComo,
  perfil,
}) => {
    const nome = get(user, 'nome');
    const endereco = get(user, 'endereco');
    const email = get(user, 'email');
    const telefone_celular = get(user, 'telefone_celular');
    const municipio = get(user, 'municipio');
    const uf = get(user, 'uf');

  const renderSuspensoes = () => {
    if (fetchingSuspensoes) {
      return <Loading />;
    }

    if (suspensoes.length === 0) {
      return <p className="avaliador-font-sm">Nenhuma suspensão registrada para este corretor</p>;
    }

    return (
      <Fragment>
        {
          suspensoes.map((suspensao, i) => (
            <Fragment key={suspensao.id}>
              {i !== 0 && <hr />}
              <div className="inf-section mt-3">
                <p className="avaliador-font-sm">
                  Início da suspensão:
                  <strong className="default ml-2">{suspensao.criado_em || '-'}</strong>
                </p>
              </div>
              <div className="inf-section">
                <p className="avaliador-font-sm">
                  Fim da suspensão:
                  <strong className="default text-lw ml-2">{suspensao.excluido_em || '-'}</strong>
                </p>
              </div>
            </Fragment>
          ))
        }
      </Fragment>
    );
  };

  const hierarquias = hierarquiasOptions && hierarquiasOptions.map(hierarquia => ({
    value: hierarquia.id,
    label: hierarquia.descricao,
  }));

  const renderAlterarContador = () => {
    const renderer = ({
      hours, minutes, seconds,
    }) => <span className="countdown-style">{hours}:{minutes}:{seconds}</span>;

    if (contador) {
      return (
        <Form onSubmit={values => onAlterarContador(user.id, values)}>
          <div className="desempenho-section">
            <div className="modal-form">
              <div className="row mt-3">
                <div className="col-md-12 d-flex flex-column">
                  <ReactTooltip />
                  <span data-tip="Tempo que o avaliador tem de correção." className="avaliador-font-sm mb-2">
                    <strong>Tempo Restante: </strong>
                      <Countdown
                        date={contador}
                        renderer={renderer}
                      />
                  </span>
                  <span data-tip="Tempo que será adicionado ao tempo restante quando o avaliador buscar +1." className="avaliador-font-sm mb-2">
                    <strong>Tempo Adicional: </strong>
                    <span className="countdown-style">{tempo_adicional}</span>
                  </span>
                </div>
                <div className="col-md-12">
                  <strong>Alterar tempo Restante:</strong>
                  <Text field="tempo_restante" className="form-control mt-2" type="time" />
                </div>
              </div>
              <div className="mt-2">
                <button type="submit" className="btn bg-info text-white">
                    ALTERAR TEMPO
                </button>
              </div>
            </div>
          </div>
        </Form>
      );
    }
    return <p className="avaliador-font-sm">Exercício ainda não foi iniciado.</p>;
  };

  const renderContent = () => (
    <Fragment>
      <div className="modal-header pb-0">
        <div className="row w-100">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <h1 className="text-title">{fetchingUser ? <Loading /> : nome}</h1>
          </div>
        </div>
      </div>
      <div className="modal-body pt-0">
        <div className="row">
          <div className="col-md-12">
            <div className="data-section">
              <h5 className="pb-3"><i className="fas fa-user mr-2" /> Contato</h5>
              <div className="inf-section mt-3">
                <p className="avaliador-font-sm">
                  Telefone:
                  <strong className="default ml-2">{telefone_celular}</strong>
                </p>
              </div>
              <div className="inf-section">
                <p className="avaliador-font-sm">
                  Email:
                  <strong className="default text-lw ml-2">{email}</strong>
                </p>
              </div>
            </div>
            <div className="data-section">
              <h5 className="pb-3"><i className="fas fa-map-marker-alt mr-2" /> Localização</h5>
              <div className="inf-section mt-3">
                <p className="avaliador-font-sm">
                  Endereço:
                  <strong className="default ml-2">{endereco}</strong>
                </p>
              </div>
              <div className="inf-section mt-3">
                <p className="avaliador-font-sm">
                  Cidade:
                  <strong className="default ml-2">{municipio} ({uf})</strong>
                </p>
              </div>
            </div>
            {
              showHistoricoSuspensao && (
                <div className="data-section">
                  <h5 className="pb-3"><i className="fas fa-history mr-2" /> Histórico de suspensão</h5>
                  {renderSuspensoes()}
                </div>
              )
            }
            {
              showContador && (
                <div className="data-section">
                  <h5 className="pb-3"><i className="fas fa-hourglass-half mr-2" /> Contador</h5>
                  {renderAlterarContador()}
                </div>
              )
            }
            {
              mudarTimeAvaliador && (
                <div className="data-section">
                  <h5 className="pb-3"><i className="fas fa-hourglass-half mr-2" /> Mudar time do {perfil}</h5>
                  <SelectInput
                    field="time_id"
                    placeholder="Selecione o time"
                    onChange={values => onChangeUserTeam(values, user.id)}
                    options={hierarquias}
                    disabled={submitting}
                  />
                </div>
              )
            }
            {
              showAcessarComo && (
                <div className="data-section">
                  <h5 className="pb-3"><i className="fas fa-sign-in-alt mr-2" /> Acessar como o {perfil}</h5>
                  <button
                    type="button"
                    className="btn bg-info text-white w-100"
                    onClick={() => onAcessarComo(user.cpf)}
                  >
                    ACESSAR
                  </button>
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              className="btn bg-warning-color ml-2"
              onClick={onClose}
            >
              FECHAR
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );

  return (
    <section>
      <Rodal visible={visible} onClose={onClose}>
        {fetchingUser ? <Loading /> : renderContent()}
      </Rodal>
    </section>
  );
};

AvaliadorModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onChangeUserTeam: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.number,
    nome: PropTypes.string,
    endereco: PropTypes.string,
    email: PropTypes.string,
    telefone_celular: PropTypes.string,
    municipio: PropTypes.string,
    uf: PropTypes.string,
  }).isRequired,
  fetchingUser: PropTypes.bool.isRequired,
  suspensoes: PropTypes.arrayOf(PropTypes.shape({
    criado_em: PropTypes.string,
    excluido_em: PropTypes.string,
  })),
  fetchingSuspensoes: PropTypes.bool,
  hierarquiasOptions: PropTypes.arrayOf(PropTypes.object),
  perfil: PropTypes.string,
};

AvaliadorModal.defaultProps = {
  suspensoes: [],
  hierarquiasOptions: [],
  submitting: false,
  fetchingSuspensoes: false,
  perfil: 'avaliador',
  onChangeUserTeam: () => {},
};

export default AvaliadorModal;
