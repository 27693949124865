import React from 'react'
import { get } from 'lodash';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { ComponentBase } from '../../../../base';
import { API_URL } from '../../../../consts';

class AproveitamentoNotasAvaliador extends ComponentBase {
  get columns() {
    const { projeto } = this.props;
    const possui_competencia_5 = get(projeto, 'possui_competencia_5')
    return [
      {
        header: 'Corretor',
        columns: [
          {
            header: 'Redação',
            accessor: 'redacao',
            hasRedacao: true,
          },
          {
            header: 'Data',
            accessor: 'data',
          },
          {
            header: 'Aproveitamento',
            accessor: 'aproveitamento',
            minWidth: 150,
          },
          {
            header: 'Situação',
            accessor: 'avaliador_situacao',
            minWidth: 90,
          },
        ].concat(
          [1, 2, 3, 4].map((competencia) => {
            const numeral = {
              1: 'I',
              2: 'II',
              3: 'III',
              4: 'IV',
            }[competencia];

            return {
              header: `C${numeral}`,
              accessor: `avaliador_c${competencia}`,
              minWidth: 60,
            };
          }),
          {
            header: 'CV',
            accessor: 'avaliador_c5',
            Cell: d => d.original.avaliador_c5 === -1 ? 0 : d.original.avaliador_c5,
            minWidth: 60,
            show: possui_competencia_5,
          },
          {
            header: 'DDH',
            accessor: 'avaliador_is_ddh',
            Cell: d => d.original.avaliador_is_ddh && <i className="fas fa-check" />,
            minWidth: 70,
            show: possui_competencia_5,
          },
          {
            header: 'Nota',
            accessor: 'avaliador_soma',
            minWidth: 70,
            sortMethod: true,
          },
        ),
      },
      {
        header: 'Avaliador espelho',
        columns: [
          {
            header: 'Situação',
            accessor: 'espelho_situacao',
            minWidth: 90,
          },
        ].concat(
          [1, 2, 3, 4].map((competencia) => {
            const numeral = {
              1: 'I',
              2: 'II',
              3: 'III',
              4: 'IV',
            }[competencia];

            return {
              header: `C${numeral}`,
              accessor: `espelho_c${competencia}`,
              minWidth: 60,
            };
          }),
          {
            header: 'CV',
            accessor: 'espelho_c5',
            Cell: d => d.original.espelho_c5 === -1 ? 0 : d.original.espelho_c5,
            minWidth: 60,
            show: possui_competencia_5,
          },
          {
            header: 'DDH',
            accessor: 'espelho_is_ddh',
            Cell: d => d.original.espelho_is_ddh && <i className="fas fa-check" />,
            minWidth: 70,
            show: possui_competencia_5,
          },
          {
            header: 'Nota',
            accessor: 'espelho_soma',
            minWidth: 70,
            sortMethod: true,
          },
        ),
      },
      {
        header: 'Terceiro Avaliador',
        columns: [
          {
            header: 'Situação',
            accessor: 'terceiro_situacao',
            minWidth: 90,
          },
        ].concat(
          [1, 2, 3, 4].map((competencia) => {
            const numeral = {
              1: 'I',
              2: 'II',
              3: 'III',
              4: 'IV',
            }[competencia];

            return {
              header: `C${numeral}`,
              accessor: `terceiro_c${competencia}`,
              minWidth: 60,
            };
          }),
          {
            header: 'CV',
            accessor: 'terceiro_c5',
            Cell: d => d.original.terceiro_c5 === -1 ? 0 : d.original.terceiro_c5,
            minWidth: 60,
            show: possui_competencia_5,
          },
          {
            header: 'DDH',
            accessor: 'terceiro_is_ddh',
            Cell: d => d.original.terceiro_is_ddh && <i className="fas fa-check" />,
            minWidth: 70,
            show: possui_competencia_5,
          },
          {
            header: 'Nota',
            accessor: 'terceiro_soma',
            minWidth: 70,
            sortMethod: true,
          },
        ),
      },
    ];
  }

  render() {
    const backAction = this.hasPermission('ver_relatorio_aproveitamento_time') ? {
      path: '/relatorios/v2/aproveitamento-avaliadores',
      state: ['polo', 'time'],
    } : undefined;

    const exportData = {
      url: `${API_URL}/v2/relatorios/aproveitamento-notas/avaliador/excel`,
      filename: 'aproveitamento_notas_por_avaliador.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        title="APROVEITAMENTO DE NOTAS | Individual"
        dataUrl="v2/relatorios/aproveitamento-notas/avaliador"
        columns={this.columns}
        isTimeInputRequired
        isPoloInputRequired
        isAvaliadorInputRequired
        backAction={backAction}
        exportData={exportData}
        codigoMensagem="APROVEITAMENTO_NOTAS"
        codigo="APROVEITAMENTO_NOTAS"
        caption={{}}
      />
    );
  }
}

export default AproveitamentoNotasAvaliador;
