import React from 'react';
import { get } from 'lodash';
import { asField } from 'informed';
import Select from 'react-select';


const getValue = (value, options, isMulti) => {
  if (isMulti) {
    value = value || [];
    return options.filter(option => value.find(v => v === option.value));
  }
  return options.find(option => option.value === value);
};

const mapOptions = options => options.map(v => v.value);

const ReactSelectInput = asField(({
 field, fieldState, fieldApi, ...props
}) => {
  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const {
    label,
    onChange,
    onBlur,
    initialValue,
    forwardedRef,
    options,
    selectAll,
    unselectAll,
    isMulti,
    isDisabled,
    ...rest
  } = props;

  return (
    <React.Fragment>
      <label htmlFor={field}>{label}</label>
      <Select
        isDisabled={isDisabled}
        className={`${isDisabled ? 'no-drop' : ''}`}
        {...rest}
        isMulti={isMulti}
        ref={forwardedRef}
        value={getValue(value, options, isMulti)}
        onChange={(value) => {
          if (isMulti) {
            setValue(mapOptions(value));
          } else {
            setValue(get(value, 'value'));
          }
          if (onChange) {
            onChange(value);
          }
        }}
        onBlur={(e) => {
          setTouched();
          if (onBlur) {
            onBlur(e);
          }
        }}
        styles={fieldState.error && ({
          control: base => ({
            ...base,
            border: 'solid 2px #DB6969',
          }),
        })}
        options={options}
      />
      {fieldState.error && <small className={fieldState.error ? 'text-danger' : null}>{fieldState.error}</small>}
      {selectAll && (
        <button type="button" className="btn btn-sm mt-1 mr-1" onClick={() => setValue(mapOptions(options))}>
          Selecionar todos
        </button>
      )}
      {unselectAll && (
        <button type="button" className="btn btn-sm mt-1" onClick={() => setValue([])}>
          Limpar todos
        </button>
      )}
    </React.Fragment>
  );
});

ReactSelectInput.defaultProps = {
  placeholder: 'selecione',
  loadingMessage: () => 'carregando...',
};

export default ReactSelectInput;
