import React, { useEffect, useState } from 'react';
import RecursosList from '../../../components/v2/recursos/RecursosList';
import { fetchRecursos, fetchStatusRecurso } from '../../../services/recursos';
import PermissionState from '../../../states/PermissionState';
import TableState from '../../../states/TableState';


const RecursosListPage = () => {
  const [statusOptions, setStatusOptions] = useState([]);

  const mapFunc = d => ({ value: d.id, label: d.nome });

  useEffect(() => {
    fetchStatusRecurso().then(
      data => setStatusOptions(data.map(mapFunc)),
    );
  }, []);

  return (
    <PermissionState>
      <TableState fetch={fetchRecursos}>
        <RecursosList statusOptions={statusOptions} />
      </TableState>
    </PermissionState>
  );
};

export default RecursosListPage;
