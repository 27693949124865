import React from 'react';
import PropType from 'prop-types';
import { get } from 'lodash';
import SelectInput from './SelectInput';

class HierarquiaInput extends React.Component {
  render() {
    const {
      polo, polos, onPoloChange, fetchingPolos,
      time, times, onTimeChange, fetchingTimes, showTimeInput,
      avaliador, avaliadores, onAvaliadorChange, fetchingAvaliadores, showAvaliadorInput,
      errors,
    } = this.props;

    return (
      <div className="grid-form-1 grid-form-rows-3 align-items-start w-100 mr-4">

        <SelectInput
          label="Polo"
          value={polo}
          onChange={value => onPoloChange(value)}
          options={polos}
          isLoading={fetchingPolos}
          error={get(errors, 'polo')}
        />
        {showTimeInput && (
          <SelectInput
            label="Time"
            value={time}
            onChange={value => onTimeChange(value)}
            options={times}
            isLoading={fetchingTimes}
            noOptionsMessage={polo ? 'Nenhum time para o polo selecionado' : 'Selecione o polo para carregar os times'}
            error={get(errors, 'time')}
          />
        )}
        {showAvaliadorInput && (
          <SelectInput
            label="Avaliador"
            value={avaliador}
            onChange={value => onAvaliadorChange(value)}
            options={avaliadores}
            isLoading={fetchingAvaliadores}
            error={get(errors, 'avaliador')}
            noOptionsMessage={time ? 'Nenhum avaliador para o time selecionado' : 'Selecione o time para carregar os avaliadores'}
          />
        )}
      </div>
    );
  }
}

HierarquiaInput.propTypes = {
  polo: PropType.shape({
    value: PropType.number,
    label: PropType.string,
  }),
  polos: PropType.arrayOf(
    PropType.shape({
      value: PropType.number,
      label: PropType.string,
    }),
  ),
  onPoloChange: PropType.func.isRequired,
  fetchingPolos: PropType.bool.isRequired,
  time: PropType.shape({
    value: PropType.number,
    label: PropType.string,
  }),
  times: PropType.arrayOf(
    PropType.shape({
      value: PropType.number,
      label: PropType.string,
    }),
  ),
  onTimeChange: PropType.func.isRequired,
  fetchingTimes: PropType.bool.isRequired,
  showTimeInput: PropType.bool,
  avaliador: PropType.shape({
    value: PropType.string,
    label: PropType.string,
  }),
  avaliadores: PropType.arrayOf(
    PropType.shape({
      value: PropType.number,
      label: PropType.string,
    }),
  ),
  onAvaliadorChange: PropType.func.isRequired,
  fetchingAvaliadores: PropType.bool.isRequired,
  showAvaliadorInput: PropType.bool,
  errors: PropType.shape({
    avaliador: null,
    time: null,
    polo: null,
  }),
};


HierarquiaInput.defaultProps = {
  polo: undefined,
  polos: [],
  time: undefined,
  times: [],
  avaliador: undefined,
  avaliadores: [],
  showAvaliadorInput: true,
  showTimeInput: true,
  errors: {
    avaliador: null,
    time: null,
    polo: null,
  },
};
export default HierarquiaInput;
