import React from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { get, debounce } from 'lodash';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services';
import Loading from '../components/Loading';

class ListaTriagemFea extends React.Component {
  state = {
    data: [],
    filtered: [],
    sorted: [],
    page: 0,
    pageSize: 10,
    loading: true,
  }

  componentDidMount() {
    Promise.all([
      this.fetchData(),
      this.fetchResumoTriagem(),
    ])
      .then(() => {
        this.setState({ loading: false });
      })
  }

  get columns() {
    return [
      {
        Header: 'Imagem',
        accessor: 'mascara',
        Cell: row => <Link to={`/triagem-fea/${row.original.id}`}>{row.original.mascara}</Link>
      },
      {
        Header: 'É FEA?',
        accessor: 'parecer',
        Cell: this.renderParecer,
        filterable: false,
      },
    ]
  }

  fetchData = () => {
    this.setState({ fetchingData: true });
    const {
      page,
      pageSize,
      filtered,
      sorted,
    } = this.state;

    const filters = {};
    filtered.forEach((filter) => {
      filters[filter.id] = filter.value;
    });
    const ordering = sorted.map(field => `${field.desc ? '-' : ''}${field.id}`).join(',');

    return axios.get(`${API_URL}/correcoes/triagem_fea`, {
        params: {
          page: page + 1,
          page_size: pageSize,
          ordering,
          ...filters,
        },
        ...getAxiosConfig(),
      })
      .then((response) => {
        this.setState({
          data: response.data.results,
          count: response.data.count,
          total_pages: response.data.total_pages,
          fetchingData: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          fetchingData: false,
        });
      });
  }

  fetchResumoTriagem() {
    return axios.get(`${API_URL}/correcoes/triagem_fea/resumo`, {
      ...getAxiosConfig(),
    })
      .then((response) => {
        this.setState({ resumoTriagem: response.data });
      })
  }

  debouncedFetchData = debounce(this.fetchData, 1000)

  buscarRedacaoParaTriagem = () => {
    this.setState({ buscandoRedacaoParaTriagem: true });

    axios.get(`${API_URL}/correcoes/triagem_fea/proxima`, getAxiosConfig())
      .then((response) => {
        const { history } = this.props;
        history.push(`/triagem-fea/${response.data.id}`);
      })
      .catch(() => {
        this.setState({ buscandoRedacaoParaTriagem: false });
      })
  }

  renderParecer(row) {
    const fea = row.value;

    if (fea === true) {
      return <span><i className="fas fa-check text-success" /></span>;
    }

    if (fea === false) {
      return <span><i className="fas fa-times text-danger" /></span>;
    }

    return '-';
  }

  render() {
    const {
      data,
      loading,
      total_pages,
      page,
      pageSize,
      filtered,
      buscandoRedacaoParaTriagem,
      resumoTriagem,
      fetchingData,
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-title">Triagem FEA</h1>
            <p><strong>{get(resumoTriagem, 'total')}</strong> redações</p>
            <p><strong>{get(resumoTriagem, 'fea')}</strong> confirmadas como FEA</p>
            <p><strong>{get(resumoTriagem, 'nao_fea')}</strong> não confirmadas como FEA</p>
          </div>
          <div className="col-md-12 mb-3">
            <button
              onClick={this.buscarRedacaoParaTriagem}
              className="btn btn-primary"
              disabled={buscandoRedacaoParaTriagem}
            >
              REALIZAR TRIAGEM
            </button>
          </div>
          <div className="col-md-12">
            <ReactTable
              manual
              data={data}
              columns={this.columns}
              className="table-lista-ocorrencias"
              previousText=""
              nextText=""
              noDataText="Sem conteúdo"
              pageText="Pág."
              ofText="de"
              rowsText="registros"
              loading={fetchingData}
              minRows={0}
              loadingText={<Loading />}
              filterable
              pages={total_pages}
              page={page}
              pageSize={pageSize}
              filtered={filtered}
              onPageChange={a => this.setState({ page: a }, this.fetchData)}
              onPageSizeChange={(a, b) => this.setState({ pageSize: a, page: b }, this.fetchData)}
              onFilteredChange={a => this.setState({ filtered: a, page: 0 }, this.debouncedFetchData)}
              onSortedChange={sorted => this.setState({ sorted }, this.fetchData)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ListaTriagemFea;
