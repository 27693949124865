import React from 'react';
import PropTypes from 'prop-types';
import { CellLoading } from '.';


class FooterTable extends React.Component {
  static propTypes = {
    media: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    loadingFirstRow: PropTypes.bool,
    loadingSecondRow: PropTypes.bool,
    mediaNal: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  };

  static defaultProps = {
    media: '',
    mediaNal: '',
    loadingFirstRow: false,
    loadingSecondRow: false,
  };

  renderFirstRow() {
    const { media, loadingFirstRow } = this.props;
    if (loadingFirstRow) {
      return <CellLoading color="#fff" />;
    }
    return media === null ? '-' : media;
  }

  renderSecondRow() {
    const { mediaNal, loadingSecondRow } = this.props;
    if (loadingSecondRow) {
      return <CellLoading color="#fff" />;
    }

    return mediaNal === null ? '-' : mediaNal;
  }

  render() {
    const { mediaNal, media } = this.props;
    return (
      <table>
        <tbody>
          {
            media !== '' && (
            <tr>
              <td>
                {this.renderFirstRow()}
              </td>
            </tr>
            )
          }
          {
            mediaNal !== '' && (
          <tr>
            <td>
              {this.renderSecondRow()}
            </td>
          </tr>
            )
          }
        </tbody>
      </table>
    );
  }
}

export default FooterTable;
