import axios from 'axios';
import { API_URL, HIERARQUIA } from '../consts';
import { getAxiosConfig } from './auth';

export const fetchMinhasHierarquias = params => axios.get(`${API_URL}/v3/hierarquias/me`, { params, ...getAxiosConfig() }).then(response => response.data);

export const fetchMeusPolos = params => fetchMinhasHierarquias({ id_tipo_hierarquia: HIERARQUIA.POLO, ...params });

export const fetchMeusTimes = params => fetchMinhasHierarquias({ id_tipo_hierarquia: HIERARQUIA.TIME, ...params });

export const fetchAvaliadores = (hierarquia, params = {}) => axios.get(`${API_URL}/v2/${hierarquia}/avaliadores`, { params, ...getAxiosConfig() }).then(response => response.data);
