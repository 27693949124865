import React, { Fragment } from 'react';
import ReactTable from 'react-table';
import axios from 'axios';
import { get } from 'lodash';
import ReactTooltip from 'react-tooltip';
import {
  Footer,
  AvisosImportantesItem,
  MinhasOcorrenciasItem,
  CorrecoesGraph,
  ContadorCorrecao,
  ModalConfirmacao,
  ModalVerificarAuxiliar,
  Loading,
  TabelaAcompanhamento,
  FooterTable,
  TabelaBuscarProjeto,
} from '../components';
import CorrecaoBase from './CorrecaoBase';
import { getAxiosConfig } from '../services';
import { API_URL, TIPO_SISTEMA } from '../consts';

class DashboardCorretor extends CorrecaoBase {
  constructor(props) {
    super(props);
    this.handleBuscarMaisUm = this.handleBuscarMaisUm.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onCloseBuscar = this.onCloseBuscar.bind(this);
    this.onCloseModalSupervisor = this.onCloseModalSupervisor.bind(this);
    this.state = {
      cardOcorrencia: [],
      avisos: [],
      minhasOcorrencias: [],
      visible: false,
      corrigidas: 0,
      iniciada: 0,
      modal_supervisor: false,
      modal_supervisor_errors: {},
      loading: true,
      notificacaoError: false,
      loadingAcompanhentoAuditoria: false,
      tipo_auditoria_id: undefined,
      acompanhamentoAuditoria: {
        values: [],
        total: {},
      },
    };
  }

  componentDidMount() {
    this.fetchMinhasCorrecoes();
    this.fetchTotalCorrigidas();
    this.fetchAvisosImportantes();
    this.fetchPrimeiraIniciada();
    if (this.hasPermission('pode_corrigir_auditoria')) {
      this.fetchAcompanhamentoAuditoria();
    }
  }

  fetchOcorrencia() {
    axios.get(`${API_URL}/ocorrencias`, getAxiosConfig())
      .then((response) => {
        const { results } = response.data;
        this.setState({ cardOcorrencia: results });
      });
  }

  fetchAvisosImportantes() {
    axios.get(`${API_URL}/avisos`, getAxiosConfig())
      .then((response) => {
        const { results } = response.data;
        this.setState({ avisos: results, loading: false });
      })
      .catch(() => {
        this.setState({
          loading: false,
          notificacaoError: true,
        });
      });
  }

  fetchMinhasCorrecoes() {
    axios.get(`${API_URL}/correcoes/grafico`, getAxiosConfig())
      .then((response) => {
        const minhasOcorrencias = response.data;
        this.setState({ minhasOcorrencias });
      })
      .catch((error) => {
        this.setState({ minhasOcorrenciasError: true });
      });
  }

  fetchTotalCorrigidas() {
    axios.get(`${API_URL}/correcoes/`, getAxiosConfig())
      .then((response) => {
        const { ...corrigidas } = response.data.records;
        this.setState({
          corrigidas: corrigidas[0].total_corrigidas,
        });
      });
  }

  postCorretor() {
    const data = {
        data_validade_correcao: Date.now(),
    };
    axios.post(`${API_URL}/correcoes/contador`, data, getAxiosConfig())
      .then()
      .catch((error) => {
        this.setState({ submitting: false });
        this.handleError(error);
      });
  }

  fetchPrimeiraIniciada() {
    axios.get(`${API_URL}/correcoes/iniciada`, getAxiosConfig())
    .then((response) => {
      const iniciada = response.data;
      this.setState({ iniciada });
    })
    .catch(this.handleError);
  }


  handleBuscarMaisUm(projeto_id) {
    if (this.hasFeature('limitar_tempo_avaliador') && this.state.iniciada === 0) {
      this.setState({ visible: true });
    } else {
      this.setState({ buscandoMaisUm: true });
      this.buscarMaisUm(() => this.setState({ buscandoMaisUm: false }), projeto_id);
    }
  }

  fetchAcompanhamentoAuditoria() {
    this.setState({ loadingAcompanhamentoAuditoria: true });
    axios.get(`${API_URL}/correcoes/auditoria`, getAxiosConfig())
      .then((response) => {
        const acompanhamentoAuditoria = response.data;
        this.setState({ loadingAcompanhamentoAuditoria: false, acompanhamentoAuditoria });
      })
      .catch(this.handleError);
  }

  onClose() {
    this.setState({ visible: false });
  }

  onCloseBuscar() {
    this.setState({ buscandoMaisUm: true });
    this.buscarMaisUm(() => this.setState({ buscandoMaisUm: false }));
    this.postCorretor();
  }

  onCloseModalSupervisor() {
    this.setState({ modal_supervisor: false, buscandoMaisUm: false });
  }

  renderAvisos() {
    const { loading, avisos, notificacaoError } = this.state;
    if (loading) {
      return <Loading />;
    } if (avisos.length === 0 || notificacaoError) {
      return <p> Você não possui nenhum aviso </p>;
    }
    return avisos.map((aviso, index) => (
      <React.Fragment key={aviso.id}>
        {index > 0 && <hr />}
        <AvisosImportantesItem {...aviso} />
      </React.Fragment>
    ));
  }

  handleBuscarMaisUmAuditoria(tipo_auditoria_id) {
    this.setState({ tipo_auditoria_id }, () => this.buscarMaisUm(undefined, tipo_auditoria_id));
  }

  renderAcompanhamentoAuditoria() {
    const { loadingAcompanhamentoAuditoria, acompanhamentoAuditoria } = this.state;
    return (
      <div className="row">
        <div className="col col-xs-12 panel-default panel-darkblue">
          <div className="panel-title">
            <h2 className="pt-1">Acompanhamento de Auditoria</h2>
          </div>
          <div className="panel-body">
            <ReactTable
              data={acompanhamentoAuditoria.values}
              columns={[
                {
                  Header: 'Tipo',
                  accessor: 'descricao',
                  Footer: (
                    <FooterTable media="Total" />
                  ),
                },
                {
                  Header: 'Geradas',
                  accessor: 'geradas',
                  Footer: (
                    <FooterTable media={get(acompanhamentoAuditoria.total, 'geradas')} />
                  ),
                },
                {
                  Header: 'Corrigidas',
                  accessor: 'corrigidas',
                  Footer: (
                    <FooterTable media={get(acompanhamentoAuditoria.total, 'corrigidas')} />
                  ),
                },
                {
                  Header: 'Corrigidas Por Mim',
                  accessor: 'corrigidas_por_mim',
                  Footer: (
                    <FooterTable media={get(acompanhamentoAuditoria.total, 'corrigidas_por_mim')} />
                  ),
                },
                {
                  Header: 'Pendentes Para Mim',
                  accessor: 'pendentes',
                  Footer: (
                    <FooterTable media={get(acompanhamentoAuditoria.total, 'pendentes')} />
                  ),
                },
                {
                  Header: 'Ação',
                  id: 'id_tipo_correcao',
                  accessor: data => (
                    <Fragment>
                      <button
                        data-tip="Já existe uma auditória de outro tipo para correção."
                        data-for={`${!data.botao_disponivel ? 'error-' : ''}auditoria`}
                        type="button"
                        className={`btn bg-default-color-correcao ${!data.botao_disponivel ? 'disabled' : ''}`}
                        disabled={!data.botao_disponivel}
                        onClick={() => this.handleBuscarMaisUmAuditoria(data.id)}
                      >
                        Buscar +1
                      </button>
                      <ReactTooltip id="error-auditoria" effect="solid" />
                    </Fragment>
                  ),
                  Footer: (
                    <FooterTable media="-" />
                  ),
                },
              ]}
              className="-highlight tabela-auditoria-dashboard"
              defaultPageSize={10}
              previousText=""
              nextText=""
              showPagination={false}
              noDataText="Sem conteúdo"
              pageText="Pág."
              ofText="de"
              rowsText="registros"
              resizable={false}
              pageSize={acompanhamentoAuditoria.values.length}
              loading={loadingAcompanhamentoAuditoria}
              loadingText={<Loading />}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      cardOcorrencia, minhasOcorrencias, buscandoMaisUm, visible, modal_supervisor, modal_supervisor_errors, id_correcao,
    } = this.state;
    const ver_acompanhamento_supervisor = this.hasPermission('ver_acompanhamento_supervisor') && this.hasFeature('mostrar_quarta_correcao');
    const ver_4_correcao_acompanhamento = this.hasPermission('ver_4_correcao_acompanhamento') && this.hasFeature('mostrar_quarta_correcao');
    const ver_3_correcao_acompanhamento = this.hasPermission('ver_3_correcao_acompanhamento');
    const ver_1_2_correcao_acompanhamento = this.hasPermission('ver_1_2_correcao_acompanhamento');
    const ver_avaliacao_desempenho = this.hasFeature('ver_avaliacao_desempenho');
    return (
      <Fragment>
        <ReactTooltip />
        <section className="dashboard-section">
          <div className="container">
            <div className="page-container-border">
              <section>
                <div className="row">
                  <div className="col col-xs-12 panel-default">
                    <div className="panel-title">
                      <h2 className="pt-1">Avisos Importantes</h2>
                    </div>
                    <div className="panel-body">
                      {this.renderAvisos()}
                    </div>
                  </div>
                  {this.hasFeature('dashboard_exibir_painel_ocorrencias') && (
                    <div className="col-md-4 col-xs-12 panel-default">
                      <div className="panel-title">
                        <h2 className="pt-1">Minhas ocorrências</h2>
                      </div>
                      <div className="panel-body">
                        {
                          cardOcorrencia.length === 0 ? (
                            'Você não possui ocorrências'
                            )
                            : (
                              cardOcorrencia.map(record => (
                                <MinhasOcorrenciasItem
                                  id={get(record, 'id')}
                                  data={get(record, 'data_solicitacao')}
                                  status_color={get(record, 'status.classe')}
                                  status_descricao={get(record, 'status.descricao')}
                                />
                                ))
                                )
                              }
                      </div>
                    </div>
                  )}
                </div>
              </section>
              <section>
                  {TIPO_SISTEMA === 'correcao' && this.hasPermission('pode_corrigir_auditoria') && this.renderAcompanhamentoAuditoria()}
              </section>
              {(TIPO_SISTEMA === 'correcao' && this.hasPermission('buscar_mais_um')) && this.hasPermission('ver_informacao_correcao') && (
              <section>
                <div className="row">
                  <div className="col col-xs-12 panel-default panel-darkblue">
                    <div className="panel-title">
                      <div className="row">
                        <div className="col">
                          <h2 className="pt-2">Buscar +1</h2>
                        </div>
                      </div>
                    </div>
                    {
                      this.hasFeature('ver_acompanhamento_correcoes') && this.hasPermission('ver_acompanhamento_correcoes') && (
                        <TabelaBuscarProjeto buscandoMaisUm={buscandoMaisUm} onBuscarMaisUm={this.handleBuscarMaisUm} />
                      )
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col col-xs-12 panel-default panel-darkblue">
                    <div className="panel-title">
                      <div className="row">
                        <div className="col">
                          <h2 className="pt-2">Correções</h2>
                        </div>
                      </div>
                    </div>
                    {this.hasFeature('dashboard_exibir_contador_correcoes') && <ContadorCorrecao messages={this.props.messages} /> }
                    {this.hasFeature('dashboard_exibir_grafico_minhas_correcoes') && <CorrecoesGraph data={minhasOcorrencias} />}
                    {
                      this.hasFeature('ver_acompanhamento_correcoes') && this.hasPermission('ver_acompanhamento_correcoes') && (
                        <TabelaAcompanhamento
                          ver_acompanhamento_supervisor={ver_acompanhamento_supervisor}
                          ver_4_correcao_acompanhamento={ver_4_correcao_acompanhamento}
                          ver_3_correcao_acompanhamento={ver_3_correcao_acompanhamento}
                          ver_1_2_correcao_acompanhamento={ver_1_2_correcao_acompanhamento}
                          ver_avaliacao_desempenho={ver_avaliacao_desempenho}
                        />
                      )
                    }
                  </div>
                </div>
              </section>
              )}
            </div>
          </div>
        </section>
        <Footer />
        <ModalConfirmacao
          visible={visible}
          onClose={this.onClose}
          title="Atenção"
          content={this.getMessage('INICIAR_EXERCICIO')}
          buttonFunction={this.onCloseBuscar}
          textSim="SIM"
          textNao="NÃO"
        />
        <ModalVerificarAuxiliar
          visible={modal_supervisor}
          onClose={this.onCloseModalSupervisor}
          modal_supervisor_errors={modal_supervisor_errors}
          history={this.props.history}
          id_correcao={id_correcao}
        />
      </Fragment>
    );
  }
}

export default DashboardCorretor;
