import React from 'react';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { MODULE, API_URL } from '../../../../consts';
import { ComponentBase } from '../../../../base';
import caption from '../../../../components/v2/consts';

class DistribuicaoNotasSituacaoTime extends ComponentBase {
  get columns() {
    return [
      {
        header: '#',
        accessor: 'row',
        minWidth: 50,
        Cell: row => <div>{row.index + 1}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
          mediaTime: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Polo',
        withSortIcon: true,
        accessor: 'polo_descricao',
        minWidth: 200,
        Cell: d => (<div className="column-left">{d.value}</div>),
        footer: {
          mediaNacional: {
            accessor: '',
            default: 'Dist. Nacional',
          },
          mediaTime: {
            accessor: '',
            default: 'Dist. Time',
          },
        },
      },
      {
        header: 'Time',
        withSortIcon: true,
        accessor: 'time_descricao',
        Cell: d => (<div className="column-left">{d.value}</div>),
        minWidth: 200,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
          mediaTime: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Avaliadores',
        withSortIcon: true,
        accessor: 'avaliador',
        minWidth: 300,
        Cell: d => (<div className="column-left">{d.value}</div>),
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
          mediaTime: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Corrigidas',
        withSortIcon: true,
        accessor: 'nr_corrigidas',
        minWidth: 100,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_corrigidas',
            default: '-',
          },
          mediaTime: {
            accessor: 'nr_corrigidas',
            default: '-',
          },
        },
      },
      {
        header: 'Situação',
        withSortIcon: true,
        accessor: 'nr_situacoes',
        defaultValue: 0,
        minWidth: 100,
        footer: {
          mediaNacional: {
            accessor: 'media_situacoes',
            default: '-',
          },
          mediaTime: {
            accessor: 'media_situacoes',
            default: '-',
          },
        },
      },
      {
        header: MODULE === 'enem' ? 'FEA (%)' : 'AN (%)',
        withSortIcon: true,
        sortMethod: true,
        accessor: 'media_fea',
        minWidth: 100,
        defaultValue: '0,00%',
        footer: {
          mediaNacional: {
            accessor: 'media_fea',
            default: '-',
          },
          mediaTime: {
            accessor: 'media_fea',
            default: '-',
          },
        },
      },
      {
        header: 'Cópia (%)',
        withSortIcon: true,
        sortMethod: true,
        accessor: 'media_copia',
        minWidth: 100,
        defaultValue: '0,00%',
        footer: {
          mediaNacional: {
            accessor: 'media_copia',
            default: '-',
          },
          mediaTime: {
            accessor: 'media_copia',
            default: '-',
          },
        },
      },
      {
        header: 'FT (%)',
        withSortIcon: true,
        sortMethod: true,
        accessor: 'media_ft',
        minWidth: 100,
        defaultValue: '0,00%',
        footer: {
          mediaNacional: {
            accessor: 'media_ft',
            default: '-',
          },
          mediaTime: {
            accessor: 'media_ft',
            default: '-',
          },
        },
      },
      {
        header: 'NATT (%)',
        withSortIcon: true,
        sortMethod: true,
        accessor: 'media_natt',
        minWidth: 100,
        defaultValue: '0,00%',
        footer: {
          mediaNacional: {
            accessor: 'media_natt',
            default: '-',
          },
          mediaTime: {
            accessor: 'media_natt',
            default: '-',
          },
        },
      },
      {
        header: 'PD (%)',
        withSortIcon: true,
        sortMethod: true,
        accessor: 'media_pd',
        show: MODULE === 'enem',
        minWidth: 100,
        defaultValue: '0,00%',
        footer: {
          mediaNacional: {
            accessor: 'media_pd',
            default: '-',
          },
          mediaTime: {
            accessor: 'media_pd',
            default: '-',
          },
        },
      },
    ];
  }

  render() {
    const backAction = this.hasPermission('ver_relatorio_distribuicao_notas_situacao_polo') ? {
      path: '/relatorios/v2/distribuicao-notas-situacao-time',
      state: ['polo'],
    } : undefined;

    const exportData = {
      url: `${API_URL}/v2/relatorios/distribuicao-notas-situacao/times/excel`,
      filename: 'distribuicao_notas_por_situacao_time.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        isPoloInputRequired
        isTimeInputRequired
        title="DISTRIBUIÇÃO DE SITUAÇÕES | Avaliadores"
        dataUrl="v2/relatorios/distribuicao-notas-situacao/times"
        mediaNacionalUrl="v2/relatorios/distribuicao-notas-situacao/geral/media"
        mediaTimeUrl="v2/relatorios/distribuicao-notas-situacao/geral/media"
        fetchCorretoresInativos="correcoes/corretores_inativos"
        columns={this.columns}
        backAction={backAction}
        codigo="DISTRIBUICAO_NOTAS_SITUACAO"
        exportData={exportData}
        caption={caption}
        codigoMensagem="DISTRIBUICAO_NOTAS_SITUACAO_AVALIADORES"
        baseAvaliadores
      />
    );
  }
}

export default DistribuicaoNotasSituacaoTime;
