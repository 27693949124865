import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ComponentBase } from '../../../base';
import OperatorChoiceInput from '../../OperatorChoiceInput';
import SelectInput from '../inputs/SelectInput';
import {
  alertaOptions,
  correcoesHabilitadas,
  correcoesHabilitadasSupervisores,
} from '../consts';
import { COTA_AVALIADOR_OPTIONS } from '../../../consts';


class AvaliadoresForm extends ComponentBase {
  render() {
    const {
      showDSP,
      showAproveitamento,
      showPreTeste,
      onChange,
      corrigidas,
      dsp,
      aproveitamento,
      pre_teste,
      showAlertas,
      showCota,
      showTextoCota,
      showStatus,
      statuses,
      showCorrecoesHabilitadas,
      podeHabilitarTerceira,
      podeHabilitarQuarta,
      correcoes,
      max_correcoes_dia,
      status,
      alertas,
      cotaOptions,
    } = this.props;

    return (
      <>
        <div className="grid-form-2 grid-form-rows-4 align-items-start w-100 grid-auto-flow-column mr-4">
          {showCorrecoesHabilitadas && (
            <SelectInput
              label="Correções habilitadas"
              value={get(correcoes, 'value', null)}
              onChange={value => onChange(value, 'correcoes', 'correcoesHabilitadasFilter')}
              options={podeHabilitarQuarta ? correcoesHabilitadasSupervisores : correcoesHabilitadas.filter(ch => (ch.value === 'pode_corrigir_3' ? podeHabilitarTerceira : true))}
              isMulti
            />
          )}
          {showCota && (
            <SelectInput
              label="Cota"
              value={get(max_correcoes_dia, 'value', null)}
              onChange={value => onChange(value, 'max_correcoes_dia', 'defaultFilter')}
              options={cotaOptions}
            />
          )}
          {showTextoCota && (
            <OperatorChoiceInput
              text={get(max_correcoes_dia, 'value.text')}
              opperator={get(max_correcoes_dia, 'value.opperator')}
              label="Cota"
              onChange={value => onChange(value, 'max_correcoes_dia', 'operatorChoiceFilterMethod')}
            />
          )}
          {showStatus && (
            <SelectInput
              label="Status"
              value={get(status, 'value', null)}
              onChange={value => onChange(value, 'status', 'defaultFilter')}
              options={statuses.map(d => ({ value: d.id, label: d.descricao }))}
            />
          )}
          {showAlertas && (
            <SelectInput
              label="Alertas"
              value={get(alertas, 'value', null)}
              onChange={value => onChange(value, 'alertas', 'alertasFilter')}
              options={alertaOptions}
            />
          )}
          <OperatorChoiceInput
            text={get(corrigidas, 'value.text')}
            opperator={get(corrigidas, 'value.opperator')}
            label="Corrigidas"
            onChange={value => onChange(value, 'corrigidas', 'operatorChoiceFilterMethod')}
          />
          {showDSP && (
            <OperatorChoiceInput
              text={get(dsp, 'value.text')}
              opperator={get(dsp, 'value.opperator')}
              label="DSP"
              onChange={value => onChange(value, 'dsp', 'operatorChoiceFilterMethod')}
            />
          )}
          {showAproveitamento && (
            <OperatorChoiceInput
              text={get(aproveitamento, 'value.text')}
              opperator={get(aproveitamento, 'value.opperator')}
              label="Aproveitadas"
              onChange={value => onChange(value, 'aproveitamento', 'operatorChoiceFilterMethod')}
            />
          )}
          {showPreTeste && (
            <OperatorChoiceInput
              text={get(pre_teste, 'value.text')}
              opperator={get(pre_teste, 'value.opperator')}
              label="Pré-teste"
              onChange={value => onChange(value, 'pre_teste', 'operatorChoiceFilterMethod')}
            />
          )}
        </div>
      </>
    );
  }
}

AvaliadoresForm.propTypes = {
  showDSP: PropTypes.bool,
  showAproveitamento: PropTypes.bool,
  showAlertas: PropTypes.bool,
  showPreTeste: PropTypes.bool,
  showCota: PropTypes.bool,
  showStatus: PropTypes.bool,
  showCorrecoesHabilitadas: PropTypes.bool,
  podeHabilitarTerceira: PropTypes.bool,
  showTextoCota: PropTypes.bool,
  corrigidas: PropTypes.shape({
    opperator: PropTypes.string,
    text: PropTypes.string,
  }),
  dsp: PropTypes.shape({
    opperator: PropTypes.string,
    text: PropTypes.string,
  }),
  aproveitamento: PropTypes.shape({
    opperator: PropTypes.string,
    text: PropTypes.string,
  }),
  pre_teste: PropTypes.shape({
    opperator: PropTypes.string,
    text: PropTypes.string,
  }),
  cotaOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  correcoes: PropTypes.shape({
    value: PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  max_correcoes_dia: PropTypes.shape({
    value: PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  status: PropTypes.shape({
    value: PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  alertas: PropTypes.shape({
    value: PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

AvaliadoresForm.defaultProps = {
  showAlertas: false,
  showStatus: false,
  showCota: false,
  showTextoCota: false,
  showDSP: false,
  podeHabilitarTerceira: false,
  showCorrecoesHabilitadas: false,
  showAproveitamento: false,
  showPreTeste: false,
  statuses: [],
  corrigidas: {},
  dsp: {},
  aproveitamento: {},
  pre_teste: {},
  correcoes: {},
  max_correcoes_dia: {},
  status: {},
  alertas: {},
  cotaOptions: COTA_AVALIADOR_OPTIONS,
};

export default AvaliadoresForm;
