import { detect } from 'detect-browser';
import { Form } from 'informed';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import shortid from 'shortid';
import imgLogo from '../assets/images/logo/Logo_fundep.webp';
import { Alert, Footer, TextInput } from '../components';
import { PROJECT_SUBTITLE, PROJECT_TITLE, URL_INSCRICOES } from '../consts';
import { login, setAuthorizedToken, setUnauthorizedToken } from '../services/auth';
import { setFormErrors } from '../services/utils';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    loggingIn: false,
    non_field_errors: [],
  };

  // componentDidMount() {
  //   // this.verificaNavegador();
  // }

  verificaNavegador() {
    const browser = detect();
    const versaoMinimaChrome = 65;
    const versaoMininaFirefox = 59;
    const versaoMininaSafari = 11;
    const version = parseInt(browser.version.split('.')[0], 10);
    const { history } = this.props;
    switch (browser && browser.name) {
      case 'chrome':
        if (version < versaoMinimaChrome) {
          history.push('/navegador-incompativel');
        }
        break;
      case 'firefox':
        if (version < versaoMininaFirefox) {
          history.push('/navegador-incompativel');
        }
        break;
      case 'safari':
      case 'ios':
        if (version < versaoMininaSafari) {
          history.push('/navegador-incompativel');
        }
        break;
      default:
        history.push('/navegador-incompativel');
    }
  }

  handleSubmit(values) {
    this.setState({ loggingIn: true });

    login(values).then((response) => {
      const { history } = this.props;
      const {
        token,
        two_factor_authentication,
        ...data
      } = response.data;
      if (two_factor_authentication) {
        setUnauthorizedToken(token);
        history.push('/login/confirmation', {
          ...data,
        });
      } else {
        setAuthorizedToken(token);
        history.push('/');
      }
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          const errors = get(error, 'response.data', {});
          const { non_field_errors, ...fieldErrors } = errors;
          this.setState({ non_field_errors: non_field_errors || [] });
          setFormErrors(this.formApi, fieldErrors);
        } else {
          alert('Ocorreu um erro no servidor.');
        }
      } else if (error.request) {
        alert('Erro de conexão com o servidor.');
      } else {
        alert('Erro desconhecido.');
      }
      this.setState({ loggingIn: false });
    });
  }

  render() {
    const { loggingIn, non_field_errors } = this.state;
    return (
      <section className="login-bg login-template">
        <div className="container area-content">
          <div className="box-form text-center">
            <figure>
              <img src={imgLogo} className="logo-login" alt="logo-fgv" />
            </figure>
            <figcaption className="info-modulo mt-1">
              <span className="title-exame">{PROJECT_TITLE}</span>
              <span className="title-modulo ml-1">{PROJECT_SUBTITLE}</span>
            </figcaption>
            <Form
              onSubmit={this.handleSubmit}
              getApi={(formApi) => { this.formApi = formApi; }}
            >
              <div className="form-sign">
                {non_field_errors.map(error => (
                  <Alert
                    key={shortid.generate()}
                    category="danger"
                    onClick={() => this.setState({ non_field_errors: non_field_errors.filter(e => e !== error) })}
                  >
                    {error}
                  </Alert>
                ))}
                <div className="group-input mt-1">
                  <TextInput
                    icon="user"
                    id="_input_cpf_user"
                    placeholder="Login"
                    field="username"
                  />
                </div>
                <div className="group-input mb-4">
                  <TextInput
                    icon="lock"
                    id="_input_senha"
                    placeholder="SENHA"
                    field="password"
                    type="password"
                  />
                </div>

                <div className="action-login mt-3">
                  <div className="custom-control custom-checkbox">
                    <input id="lembrar-senha" className="custom-control-input" type="checkbox" />
                    <label htmlFor="lembrar-senha" className="mt-2 custom-control-label">
                      Lembrar Senha
                    </label>
                  </div>

                  <button
                    type="submit"
                    id="_button_logar"
                    className="btn bg-default-color-xs"
                    disabled={loggingIn}
                  >
                    {loggingIn ? 'ENTRANDO...' : 'ENTRAR'}
                  </button>
                </div>
              </div>
            </Form>
          </div>
          {/* <div className="text-center recuperar-senha mt-2">
            <a href={URL_INSCRICOES} target="_blank" rel="noopener noreferrer">Esqueci minha senha</a>
          </div> */}
        </div>
        <div className="o-footer">
          <Footer />
        </div>
      </section>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(Login);
