import React from 'react';
import axios from 'axios';
import Redacao from '../containers/Redacao';
import { API_URL } from '../consts';
import { Loading } from '../components';
import { getAxiosConfig } from '../services';

class QuartaCorrecaoAuxiliar extends React.Component {
  state = {
    imagem: null,
    fetchingImagem: true,
    mascaraRedacao: '',
  }

  componentDidMount() {
    this.fetchImagem();
  }

  onInitCanvas(callback) {
    if (callback) callback();
  }

  fetchImagem() {
    const { match } = this.props;
    axios.get(`${API_URL}/correcoes/redacao-imagem/${match.params.correcaoId}`, {
      params: {
        model: 'correcao',
      },
      ...getAxiosConfig(),
    })
      .then((response) => {
        this.setState({
          imagem: response.data.link_imagem_recortada,
          mascaraRedacao: response.data.mascara_redacao,
          fetchingImagem: false,
        });
      });
  }

  render() {
    const { imagem, fetchingImagem, mascaraRedacao } = this.state;

    if (fetchingImagem) {
      return <Loading />;
    }


    return (
    <div className="container">
      <div className="page-container-border">
        <div className="redacao-header mb-3">
          <p>Redação <strong>{mascaraRedacao}</strong></p>
        </div>
        <Redacao
          show
          src={imagem}
          onInitCanvas={this.onInitCanvas}
          fetchImagemError={false}
        />
      </div>
    </div>
    );
  }
}

export default QuartaCorrecaoAuxiliar;
