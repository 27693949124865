import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import PropTypes from 'prop-types';
import { MAX_CORRECOES_DIA } from '../../../consts';

class AvaliadoresAtualizacaoLoteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pode_corrigir_1: false,
      pode_corrigir_2: false,
      pode_corrigir_3: false,
      max_correcoes_dia: null,
    };
  }

  onChangeValue = (field) => {
    this.setState(prevState => ({
      [field]: !prevState[field],
    }));
  }

  renderContent() {
    const {
      pode_corrigir_1,
      pode_corrigir_2,
      pode_corrigir_3,
    } = this.state;
    const {
      nr_avaliadores,
      onConfirm,
      onClose,
      podeHabilitarTerceira,
    } = this.props;

    const nrAvaliadoresInvalido = nr_avaliadores > 400;

    return (
      <div>
        <div className="modal-header pb-0">
          <div className="row w-100">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <h1 className="text-title">Atualizar em lote</h1>
            </div>
          </div>
        </div>
        <div className="modal-body pt-0">
          <div className="row">
            <div className="col-md-12">
              <p>Foram selecionados <strong className={nrAvaliadoresInvalido && 'red'}>{nr_avaliadores}</strong> avaliadores para atualização em lote.</p>
            </div>
          </div>
          <div className="row mt-3">
            {
              nrAvaliadoresInvalido ? (
                <div className="col-md-12">
                  <p className="red h6">Não é possível atualizar mais de 400 avaliadores em um lote.</p>
                </div>
              ) : (
                <>
                  <div className="col-md-6">
                    <label htmlFor="form-state-name">Cota</label>
                    <select onChange={event => this.setState({ max_correcoes_dia: event.target.value })} className="form-control">
                      {MAX_CORRECOES_DIA.map(d => <option key={d} value={d}>{d}</option>)}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="form-state-name">Pode Corrigir</label>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          value={pode_corrigir_1}
                          onChange={() => this.onChangeValue('pode_corrigir_1')}
                        /> 1ª
                      </label>
                      <label className="ml-4">
                        <input
                          type="checkbox"
                          value={pode_corrigir_2}
                          onChange={() => this.onChangeValue('pode_corrigir_2')}
                        /> 2ª
                      </label>
                      {podeHabilitarTerceira && (
                        <label className="ml-4">
                          <input
                            type="checkbox"
                            value={pode_corrigir_3}
                            onChange={() => this.onChangeValue('pode_corrigir_3')}
                          /> 3ª
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 text-right">
                    <button type="button" className="btn btn-default mr-3" onClick={onClose}>CANCELAR</button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => onConfirm(this.state)}
                    >
                      ATUALIZAR AVALIADORES
                    </button>
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      onClose,
      visible,
    } = this.props;

    return (
      <section>
        <Rodal closeOnEsc visible={visible} onClose={onClose}>
          {this.renderContent()}
        </Rodal>
      </section>
    );
  }
}

AvaliadoresAtualizacaoLoteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  podeHabilitarTerceira: PropTypes.bool.isRequired,
  nr_avaliadores: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
};


export default AvaliadoresAtualizacaoLoteModal;
