import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../services/auth';
import { NavbarGeral } from '.';
import { UserContext } from '../pages/Main';

const PrivateRoute = ({ navbar, permission, shouldRenderMenu, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
    isAuthenticated()
      ? (
        <UserContext.Consumer>
          {({ user, features, messages, reportMessages, onLogout, projeto }) => {
            if (user && user.permissions && permission) {
              // eslint-disable-next-line no-underscore-dangle
              if (user.permissions[permission] !== true && !user.permissions.__all__) {
                return <Redirect to="/404" />;
              }
            }
            return (
              <React.Fragment>
                {navbar && <NavbarGeral user={user} projeto={projeto} features={features} onLogout={onLogout} shouldRenderMenu={shouldRenderMenu} />}
                <Component {...props} user={user} projeto={projeto} features={features} messages={messages} reportMessages={reportMessages} />
              </React.Fragment>
            );
          }}
        </UserContext.Consumer>
      )
      : <Redirect to="/login" />
  )}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  navbar: PropTypes.bool,
  onLogout: PropTypes.func,
  permission: PropTypes.string,
  shouldRenderMenu: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  navbar: true,
  permission: '',
  onLogout: () => {},
  shouldRenderMenu: true,
};

export default PrivateRoute;
