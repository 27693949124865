import React from 'react';
import { get, find } from 'lodash';

export const defaultFilterMethod = (filter, row) => {
  const filterValue = get(filter, 'value', '').toLowerCase();
  const rowValue = get(row, filter.id, '').toString().toLowerCase();
  return rowValue.includes(filterValue);
};

export const dateFilterMethod = (filter, row) => {
  if (filter && filter.value && filter.id) {
    const date = new Date(filter.value);
    return row[filter.id].includes([
      `${date.getDate() < 10 ? '0' : ''}${date.getDate() + 1}`,
      `${date.getMonth() < 10 ? '0' : ''}${date.getMonth() + 1}`,
      date.getFullYear().toString().slice(2),
    ].join('/'));
  }

  return false;
};

export const getHeaderWithSortIcon = (label, accessor, data) => {
  const field = find(data, f => f.id === accessor);
  let sortIcon = '';
  if (field) {
    sortIcon = (
      <i
        className={`pull-right fas fa-arrow-${field.desc ? 'down' : 'up'}`}
        style={{ fontSize: 10 }}
      />
    );
  }
  return <span>{sortIcon}{label}</span>;
};
