import React from 'react';
import { get } from 'lodash';
import Relatorio from '../../../../components/v2/relatorio/Relatorio';
import { ComponentBase } from '../../../../base';
import { API_URL } from '../../../../consts';

class DistribuicaoNotasCompetenciaGeral extends ComponentBase {
  get columns() {
    const { projeto } = this.props;
    const possui_competencia_5 = get(projeto, 'possui_competencia_5')

    const competencias = possui_competencia_5 ? [1, 2, 3, 4, 5] : [1, 2, 3, 4]
    return [
      {
        header: '#',
        accessor: 'row',
        minWidth: 50,
        Cell: row => <div>{row.index + 1}</div>,
        footer: {
          mediaNacional: {
            accessor: '',
            default: '-',
          },
        },
      },
      {
        header: 'Polo',
        withSortIcon: true,
        accessor: 'polo_descricao',
        minWidth: 200,
        Cell: d => (<div className="column-left">{d.value}</div>),
        next: {
          pathname: '/relatorios/v2/distribuicao-notas-competencia-time',
          stateFields: ['polo'],
        },
        footer: {
          mediaNacional: {
            accessor: '',
            default: 'Dist. Nacional',
          },
        },
      },
      {
        header: 'Corrigidas',
        accessor: 'nr_corrigidas',
        withSortIcon: true,
        minWidth: 120,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'nr_corrigidas',
            default: '-',
          },
        },
      },
      {
        header: 'Com Nota Normal',
        withSortIcon: true,
        accessor: 'nr_com_nota_normal',
        minWidth: 180,
        defaultValue: 0,
        footer: {
          mediaNacional: {
            accessor: 'media_com_nota_normal',
            default: '-',
          },
        },
      },
    ].concat(
      competencias.map((competencia) => {
        const numeral = {
          1: 'I',
          2: 'II',
          3: 'III',
          4: 'IV',
          5: 'V',
        }[competencia];

        let marcacoes;
        if (competencia === 2) {
          marcacoes = [1, 2, 3, 4, 5];
        } else {
          marcacoes = [0, 1, 2, 3, 4, 5];
        }

        return {
          header: `Competência ${numeral} (%)`,
          columns: marcacoes.map(marcacao => ({
            header: marcacao,
            headerClassName: 'table-competencia',
            sortMethod: true,
            withSortIcon: true,
            accessor: `media_competencia${competencia}_${marcacao}`,
            defaultValue: '0,00',
            maxWidth: 70,
            footer: {
              mediaNacional: {
                accessor: `media_competencia${competencia}_${marcacao}`,
                default: '-',
              },
            },
          })),
        };
      }),
    );
  }

  render() {
    const exportData = {
      url: `${API_URL}/v2/relatorios/distribuicao-notas-competencia/geral/excel`,
      filename: 'distribuicao_notas_competencia_geral.xlsx',
      hasPermission: this.hasPermission('exportar_relatorio'),
    };

    return (
      <Relatorio
        showAvaliadorInput={false}
        showTimeInput={false}
        title="DISTRIBUIÇÃO DE NOTAS | Polos"
        dataUrl="v2/relatorios/distribuicao-notas-competencia/geral"
        mediaNacionalUrl="v2/relatorios/distribuicao-notas-competencia/geral/media"
        columns={this.columns}
        codigo="DISTRIBUICAO_NOTAS_COMPETENCIA"
        exportData={exportData}
        codigoMensagem="DISTRIBUICAO_NOTAS_COMPETENCIA_POLOS"
      />
    );
  }
}

export default DistribuicaoNotasCompetenciaGeral;
