import React from 'react';

const RadioInput = ({ label, ...rest }) => (
  <div className="radio-button-custom">
    <label className="ml-4 label-input-situacoes">{label}
      <input
        type="radio"
        {...rest}
      />
      <span className="checkmark-radio 123" />
    </label>
    <br />
  </div>
);

export default RadioInput;
